import React from 'react';
import { useMutation } from '@apollo/client';
import { ADD_OR_REMOVE_PARTICIPANT } from 'queries';
import { GetMyRights } from 'helperFunctions';
import { Checkbox } from 'components';

export default function LootParticipants({ participants, Users }) {
    const [addOrRemoveParticipant] = useMutation(ADD_OR_REMOVE_PARTICIPANT);
    const { AdminOrDm } = GetMyRights();

    return (
        <div className="flex-row-wrapped m-t-10">
            <div className="m-t-5 text-bolder m-r-10">Účastníci:</div>
            {Users.map((User) => (
                <div
                    key={User.id}
                    style={{
                        'border': `4px solid ${User.color}`,
                        'borderRadius': 5,
                        backgroundColor: `${User.color}30`,
                    }}
                    onClick={() => addOrRemoveParticipant({ variables: { id: User.id } })}
                    className="m-r-10 p-t-2 p-b-2 p-l-10 p-r-10 clickable"
                >
                    <Checkbox
                        className="form-control-checkbox bkg-transparent p-0"
                        label={User.character}
                        style={{ color: User.color }}
                        value={participants.includes(User.id)}
                        disabled={!AdminOrDm}
                        right
                        centerHor
                    />
                </div>
            ))}
        </div>
    );
}
