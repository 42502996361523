import React from 'react';
import { Card, CardHeader, CardBody, Button, FormGroup, Label, Col, Input } from 'reactstrap';
import { ImageInput, Checkbox } from 'components';
import { toBase64 } from 'helperFunctions';
import { ADD_CREATURE } from 'queries';
import { useMutation } from '@apollo/client';

export default function CreatureAdd() {
    const [title, setTitle] = React.useState('');
    const [visibility, setVisibility] = React.useState(false);
    const [portrait, setPortrait] = React.useState(null);
    const [order, setOrder] = React.useState(0);
    const [level, setLevel] = React.useState(0);
    const [important, setImportant] = React.useState(false);

    const [vitality, setVitality] = React.useState(0);
    const [endurance, setEndurance] = React.useState(0);
    const [strength, setStrength] = React.useState(0);
    const [speed, setSpeed] = React.useState(0);
    const [dexterity, setDexterity] = React.useState(0);
    const [defence, setDefence] = React.useState(0);
    const [energy, setEnergy] = React.useState(0);

    const [adding, setAdding] = React.useState(false);

    const [addCreature] = useMutation(ADD_CREATURE);

    const add = async () => {
        setAdding(true);
        let body = {
            title,
            visibility,
            order,
            level: isNaN(parseInt(level)) ? 0 : parseInt(level),
            important,
            vitality: isNaN(parseInt(vitality)) === '' ? 0 : parseInt(vitality),
            endurance: isNaN(parseInt(endurance)) === '' ? 0 : parseInt(endurance),
            strength: isNaN(parseInt(strength)) === '' ? 0 : parseInt(strength),
            speed: isNaN(parseInt(speed)) === '' ? 0 : parseInt(speed),
            dexterity: isNaN(parseInt(dexterity)) === '' ? 0 : parseInt(dexterity),
            defence: isNaN(parseInt(defence)) === '' ? 0 : parseInt(defence),
            energy: isNaN(parseInt(energy)) === '' ? 0 : parseInt(energy),
        };

        if (portrait) {
            body.portrait = await toBase64(portrait);
        }

        addCreature({ variables: body })
            .then(() => {
                setTitle('');
                setVisibility(false);
                setPortrait(null);
                setOrder(0);
                setImportant(false);
                setVitality(0);
                setEndurance(0);
                setStrength(0);
                setSpeed(0);
                setDexterity(0);
                setDefence(0);
                setEnergy(0);
                setAdding(0);
            })
            .catch((e) => {
                setAdding(false);
                console.log(e);
            });
    };

    return (
        <div className="settings-right">
            <Card>
                <CardHeader className="flex-row">Nový tvor</CardHeader>
                <CardBody>
                    <FormGroup className="flex-row">
                        <Label for="occupation" sm={2}>
                            Názov
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="occupation"
                                value={title}
                                placeholder="Zadaj názov tvora"
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="occupation" sm={2}>
                            Level
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="occupation"
                                value={level}
                                type="number"
                                placeholder="Zadaj level tvora"
                                onChange={(e) => setLevel(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Checkbox
                            className="form-control-checkbox"
                            label="Poznajú tvora?"
                            value={visibility}
                            right
                            centerHor
                            onChange={() => setVisibility(!visibility)}
                        />
                    </FormGroup>
                    <ImageInput
                        label="Profil tvora"
                        id="creaturePortrait-new"
                        setImage={setPortrait}
                        image={portrait}
                        placeholder="Vyberte profil tvora"
                        height={400}
                    />
                    <FormGroup className="flex-row">
                        <Label for="order" sm={2}>
                            Poradie
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="order"
                                value={order}
                                placeholder="Zadaj poradie"
                                onChange={(e) => setOrder(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Checkbox
                            className="form-control-checkbox"
                            label="Dôležitý?"
                            value={important}
                            right
                            centerHor
                            onChange={() => setImportant(!important)}
                        />
                    </FormGroup>

                    <h4>Základné atribúty</h4>

                    <FormGroup className="flex-row">
                        <Label for="vitality" sm={2}>
                            Vitalita
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="vitality"
                                value={vitality}
                                placeholder="Zadaj vitalitu"
                                onChange={(e) => setVitality(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="endurance" sm={2}>
                            Výdrž
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="endurance"
                                value={endurance}
                                placeholder="Zadaj výdrž"
                                onChange={(e) => setEndurance(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="strength" sm={2}>
                            Sila
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="strength"
                                value={strength}
                                placeholder="Zadaj silu"
                                onChange={(e) => setStrength(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="speed" sm={2}>
                            Rýchlosť
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="speed"
                                value={speed}
                                placeholder="Zadaj rýchlosť"
                                onChange={(e) => setSpeed(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="dexterity" sm={2}>
                            Obratnosť
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="dexterity"
                                value={dexterity}
                                placeholder="Zadaj obratnosť"
                                onChange={(e) => setDexterity(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="defence" sm={2}>
                            Obrana
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="defence"
                                value={defence}
                                placeholder="Zadaj obranu"
                                onChange={(e) => setDefence(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="energy" sm={2}>
                            Energia
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="energy"
                                value={energy}
                                placeholder="Zadaj energiu"
                                onChange={(e) => setEnergy(e.target.value)}
                            />
                        </Col>
                    </FormGroup>

                    <div className="flex-row">
                        <Button
                            color="success"
                            disabled={adding || portrait === null}
                            onClick={add}
                        >
                            {adding ? 'Pridáva sa...' : 'Pridať tvora'}
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
