import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import LibraryEntry from './libraryEntry';
import BestiaryList from './bestiary';
import BestiaryEntry from './bestiary/bestiaryEntry';
import TraderList from './trader';
import TraderEntry from './trader/traderEntry';
import { Empty } from 'components';
import { sortBy, splitArrayByFilter } from 'helperFunctions';
import { useParams, useNavigate } from 'react-router';
import {
    COMBAT_HISTORIES,
    COMBAT_HISTORIES_SUBSCRIPTION,
    CREATURES,
    CREATURES_SUBSCRIPTION,
    DICE_POKER_HISTORIES,
    DICE_POKER_HISTORIES_SUBSCRIPTION,
    LIBRARY_ENTRIES,
    LIBRARY_ENTRIES_SUBSCRIPTION,
    MAPS,
    MAPS_SUBSCRIPTION,
    OCCUPATIONS,
    OCCUPATIONS_SUBSCRIPTION,
    PLACES,
    PLACES_SUBSCRIPTION,
    RACES,
    RACES_SUBSCRIPTION,
    RECIPES,
    RECIPES_SUBSCRIPTION,
    SPELLS,
    SPELLS_SUBSCRIPTION,
    TRADERS,
    TRADERS_SUBSCRIPTION,
} from 'queries';
import { useQuery, useSubscription } from '@apollo/client';
import { GET_LIBRARY_OPEN } from 'apollo/queries';
import MapList from './map';
import MapEntry from './map/mapEntry';
import PlaceList from './place';
import PlaceEntry from './place/placeEntry';
import RecipeList from './recipe';
import RecipeEntry from './recipe/recipeEntry';
import RaceEntry from './race/raceEntry';
import RaceList from './race';
import OccupationEntry from './occupation/occupationEntry';
import OccupationList from './occupation';
import SpellList from './spells';
import SpellEntry from './spells/spellEntry';
import DicePokerHistoryList from './dicePokerHistory';
import DicePokerEntry from './dicePokerHistory/dicePokerEntry';
import CombatHistoryList from './combatHistory';
import CombatEntry from './combatHistory/combatEntry';

//povolania, magia - pridat odhalena
export default function LibraryList() {
    const { type, id } = useParams();
    const ID = parseInt(id);
    const navigate = useNavigate();

    const [show, setShow] = React.useState([]);
    const [fixedShow, setFixedShow] = React.useState([]);

    const { data: libraryOpenData } = useQuery(GET_LIBRARY_OPEN);
    const ShowLibraryList = libraryOpenData.ShowLibraryList;
    const {
        data: libraryEntriesData,
        loading: libraryEntriesLoading,
        refetch: libraryEntriesRefetch,
    } = useQuery(LIBRARY_ENTRIES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LIBRARY_ENTRIES_SUBSCRIPTION, {
        onData: () => {
            libraryEntriesRefetch();
        },
    });

    const groupLibraryEntries = (LibraryEntries) => {
        let GroupedLibraryEntries = [];
        LibraryEntries.forEach((LibraryEntry) => {
            const Index = GroupedLibraryEntries.findIndex(
                (Group) => Group.id === LibraryEntry.category.id
            );
            if (Index === -1) {
                GroupedLibraryEntries.push({
                    ...LibraryEntry.category,
                    LibraryEntries: [LibraryEntry],
                });
            } else {
                GroupedLibraryEntries[Index].LibraryEntries.push(LibraryEntry);
            }
        });
        return sortBy(GroupedLibraryEntries, [{ asc: false, key: 'order' }]);
    };

    const LibraryEntries = libraryEntriesLoading ? [] : libraryEntriesData.libraryEntries;
    const GroupedLibraryEntries = groupLibraryEntries(LibraryEntries);
    const [firstLibraryGroups, secondLibraryGroups] = splitArrayByFilter(
        sortBy(GroupedLibraryEntries, [{ asc: true, key: 'order' }]),
        (Group) => Group.order < -1
    );

    React.useEffect(() => {
        if (type) {
            setFixedShow([type, ...fixedShow]);
        } else {
            const Entry = LibraryEntries.find((Entry) => Entry.id === ID);
            if (Entry && !show.includes(Entry.category.id)) {
                setShow([...show, Entry.category.id]);
            }
        }
        // eslint-disable-next-line
    }, [libraryEntriesData, ID]);

    const {
        data: creaturesData,
        loading: creaturesLoading,
        refetch: creaturesRefetch,
    } = useQuery(CREATURES, {
        variables: {
            visibleOnly: true,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(CREATURES_SUBSCRIPTION, {
        onData: () => {
            creaturesRefetch({
                variables: {
                    visibleOnly: true,
                },
            });
        },
    });

    const Creatures = creaturesLoading ? [] : creaturesData.creatures;

    const {
        data: tradersData,
        loading: tradersLoading,
        refetch: tradersRefetch,
    } = useQuery(TRADERS, {
        variables: {
            visibleOnly: true,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(TRADERS_SUBSCRIPTION, {
        onData: () => {
            tradersRefetch({
                visibleOnly: true,
            });
        },
    });

    const Traders = tradersLoading ? [] : tradersData.traders;

    const {
        data: mapsData,
        loading: mapsLoading,
        refetch: mapsRefetch,
    } = useQuery(MAPS, {
        variables: {
            visibleOnly: true,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(MAPS_SUBSCRIPTION, {
        onData: () => {
            mapsRefetch({
                visibleOnly: true,
            });
        },
    });

    const Maps = mapsLoading ? [] : mapsData.maps;

    const {
        data: placesData,
        loading: placesLoading,
        refetch: placesRefetch,
    } = useQuery(PLACES, {
        variables: {
            visibleOnly: true,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(PLACES_SUBSCRIPTION, {
        onData: () => {
            placesRefetch({
                visibleOnly: true,
            });
        },
    });

    const Places = placesLoading ? [] : placesData.places;

    const {
        data: recipesData,
        loading: recipesLoading,
        refetch: recipesRefetch,
    } = useQuery(RECIPES, {
        variables: {
            visibleOnly: true,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(RECIPES_SUBSCRIPTION, {
        onData: () => {
            recipesRefetch({
                visibleOnly: true,
            });
        },
    });

    const Recipes = recipesLoading ? [] : recipesData.recipes;

    const {
        data: racesData,
        loading: racesLoading,
        refetch: racesRefetch,
    } = useQuery(RACES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(RACES_SUBSCRIPTION, {
        onData: () => {
            racesRefetch();
        },
    });

    const Races = racesLoading ? [] : racesData.races;

    const {
        data: occupationsData,
        loading: occupationsLoading,
        refetch: occupationsRefetch,
    } = useQuery(OCCUPATIONS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(OCCUPATIONS_SUBSCRIPTION, {
        onData: () => {
            occupationsRefetch();
        },
    });
    const Occupations = occupationsLoading ? [] : occupationsData.occupations;

    const {
        data: spellsData,
        loading: spellsLoading,
        refetch: spellsRefetch,
    } = useQuery(SPELLS, {
        variables: {
            visibleOnly: true,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(SPELLS_SUBSCRIPTION, {
        onData: () => {
            spellsRefetch({
                visibleOnly: true,
            });
        },
    });
    const Spells = spellsLoading ? [] : spellsData.spells;

    const {
        data: dicePokerHistoriesData,
        loading: dicePokerHistoriesLoading,
        refetch: dicePokerHistoriesRefetch,
    } = useQuery(DICE_POKER_HISTORIES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(DICE_POKER_HISTORIES_SUBSCRIPTION, {
        onData: () => {
            dicePokerHistoriesRefetch();
        },
    });

    const DicePokerHistories = dicePokerHistoriesLoading
        ? []
        : dicePokerHistoriesData.dicePokerHistories;

    const {
        data: combatHistoriesData,
        loading: combatHistoriesLoading,
        refetch: combatHistoriesRefetch,
    } = useQuery(COMBAT_HISTORIES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(COMBAT_HISTORIES_SUBSCRIPTION, {
        onData: () => {
            combatHistoriesRefetch({
                visibleOnly: true,
            });
        },
    });

    const CombatHistories = combatHistoriesLoading ? [] : combatHistoriesData.combatHistories;

    return (
        <div className="flex-row">
            {(ShowLibraryList || !id) && (
                <div className="library-left">
                    <ListGroup className="library-list-categories">
                        {firstLibraryGroups.map((Category) => (
                            <Empty key={Category.id}>
                                <ListGroupItem
                                    key={Category.id}
                                    active={show.includes(Category.id)}
                                    action
                                    className="clickable m-t-10"
                                    onClick={() => {
                                        if (show.includes(Category.id)) {
                                            setShow(show.filter((id) => id !== Category.id));
                                        } else {
                                            setShow([...show, Category.id]);
                                        }
                                    }}
                                >
                                    {Category.title}
                                </ListGroupItem>
                                {show.includes(Category.id) && (
                                    <ListGroup className="m-l-20 library-list-entries">
                                        {Category.LibraryEntries.map((LibraryEntry) => (
                                            <ListGroupItem
                                                key={LibraryEntry.id}
                                                active={!type && ID === LibraryEntry.id}
                                                action
                                                className="clickable"
                                                onClick={() => {
                                                    navigate(`/library/${LibraryEntry.id}`);
                                                }}
                                            >
                                                {LibraryEntry.title}
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                )}
                            </Empty>
                        ))}
                    </ListGroup>
                    <BestiaryList
                        setFixedShow={setFixedShow}
                        fixedShow={fixedShow}
                        id={ID}
                        type={type}
                        Creatures={Creatures}
                    />
                    <TraderList
                        setFixedShow={setFixedShow}
                        fixedShow={fixedShow}
                        id={ID}
                        type={type}
                        Traders={Traders}
                    />
                    <MapList
                        setFixedShow={setFixedShow}
                        fixedShow={fixedShow}
                        id={ID}
                        type={type}
                        Maps={Maps}
                    />
                    <RecipeList
                        setFixedShow={setFixedShow}
                        fixedShow={fixedShow}
                        id={ID}
                        type={type}
                        Recipes={Recipes}
                    />
                    <PlaceList
                        setFixedShow={setFixedShow}
                        fixedShow={fixedShow}
                        id={ID}
                        type={type}
                        Places={Places}
                    />
                    <RaceList
                        setFixedShow={setFixedShow}
                        fixedShow={fixedShow}
                        id={ID}
                        type={type}
                        Races={Races}
                    />
                    <OccupationList
                        setFixedShow={setFixedShow}
                        fixedShow={fixedShow}
                        id={ID}
                        type={type}
                        Occupations={Occupations}
                    />
                    <SpellList
                        setFixedShow={setFixedShow}
                        fixedShow={fixedShow}
                        id={ID}
                        type={type}
                        Spells={Spells}
                    />
                    <DicePokerHistoryList
                        setFixedShow={setFixedShow}
                        fixedShow={fixedShow}
                        id={ID}
                        type={type}
                        DicePokerHistories={DicePokerHistories}
                    />
                    <CombatHistoryList
                        setFixedShow={setFixedShow}
                        fixedShow={fixedShow}
                        id={ID}
                        type={type}
                        CombatHistories={CombatHistories}
                    />
                    <ListGroup className="library-list-categories">
                        {secondLibraryGroups.map((Category) => (
                            <Empty key={Category.id}>
                                <ListGroupItem
                                    key={Category.id}
                                    active={show.includes(Category.id)}
                                    action
                                    className="clickable m-t-10"
                                    onClick={() => {
                                        if (show.includes(Category.id)) {
                                            setShow(show.filter((id) => id !== Category.id));
                                        } else {
                                            setShow([...show, Category.id]);
                                        }
                                    }}
                                >
                                    {Category.title}
                                </ListGroupItem>
                                {show.includes(Category.id) && (
                                    <ListGroup className="m-l-20 library-list-entries">
                                        {Category.LibraryEntries.map((LibraryEntry) => (
                                            <ListGroupItem
                                                key={LibraryEntry.id}
                                                active={!type && ID === LibraryEntry.id}
                                                action
                                                className="clickable"
                                                onClick={() => {
                                                    navigate(`/library/${LibraryEntry.id}`);
                                                }}
                                            >
                                                {LibraryEntry.title}
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                )}
                            </Empty>
                        ))}
                    </ListGroup>
                </div>
            )}
            {type === undefined && id && LibraryEntries.some((Library) => Library.id === ID) && (
                <LibraryEntry id={ID} />
            )}
            {type === 'bestiary' && id && Creatures.some((Creature) => Creature.id === ID) && (
                <BestiaryEntry id={ID} />
            )}
            {type === 'traders' && id && Traders.some((Trader) => Trader.id === ID) && (
                <TraderEntry id={ID} />
            )}
            {type === 'maps' && id && Maps.some((Map) => Map.id === ID) && <MapEntry id={ID} />}
            {type === 'recipes' && id && Recipes.some((Recipe) => Recipe.id === ID) && (
                <RecipeEntry id={ID} />
            )}
            {type === 'places' && id && Places.some((Place) => Place.id === ID) && (
                <PlaceEntry id={ID} />
            )}
            {type === 'races' && id && Races.some((Race) => Race.id === ID) && (
                <RaceEntry id={ID} />
            )}
            {type === 'occupations' &&
                id &&
                Occupations.some((Occupation) => Occupation.id === ID) && (
                    <OccupationEntry id={ID} />
                )}
            {type === 'spells' && id && Spells.some((Spell) => Spell.id === ID) && (
                <SpellEntry id={ID} />
            )}
            {type === 'dicePoker' &&
                id &&
                DicePokerHistories.some((History) => History.id === ID) && (
                    <DicePokerEntry id={ID} />
                )}
            {type === 'combat' && id && CombatHistories.some((History) => History.id === ID) && (
                <CombatEntry id={ID} />
            )}
        </div>
    );
}
