import React from 'react';
import { Card, CardHeader, CardBody, Button, FormGroup, Label, Col, Input } from 'reactstrap';
import { Tooltip, Checkbox, ImageInput } from 'components';
import { SketchPicker } from 'react-color';
import Select from 'react-select';
import { sameColors, toSelArr, GetMyData, toSelItem, toBase64 } from 'helperFunctions';
import {
    UPDATE_USER,
    OCCUPATIONS,
    OCCUPATIONS_SUBSCRIPTION,
    RACES,
    RACES_SUBSCRIPTION,
    USER_COLORS,
    USER_COLORS_SUBSCRIPTION,
    USER,
    USERS_SUBSCRIPTION,
    DELETE_USER,
} from 'queries';

import { useMutation, useQuery, useSubscription } from '@apollo/client';

export default function UserEdit(props) {
    const { userID } = props;
    const currentUser = GetMyData();

    const [email, setEmail] = React.useState('Načítava sa...');
    const [nickname, setNickname] = React.useState('Načítava sa...');
    const [color, setColor] = React.useState('#f759f2');
    const [character, setCharacter] = React.useState('Načítava sa...');
    const [race, setRace] = React.useState(null);
    const [occupation, setOccupation] = React.useState(null);
    const [icon, setIcon] = React.useState(null);
    const [portrait, setPortrait] = React.useState(null);

    const [dm, setDm] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [changePassword, setChangePassword] = React.useState(false);

    const [updateUser] = useMutation(UPDATE_USER);
    const [deleteUser] = useMutation(DELETE_USER);

    const {
        data: racesData,
        loading: racesLoading,
        refetch: racesRefetch,
    } = useQuery(RACES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(RACES_SUBSCRIPTION, {
        onData: () => {
            racesRefetch();
        },
    });

    const {
        data: occupationsData,
        loading: occupationsLoading,
        refetch: occupationsRefetch,
    } = useQuery(OCCUPATIONS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(OCCUPATIONS_SUBSCRIPTION, {
        onData: () => {
            occupationsRefetch();
        },
    });

    const {
        data: userColorsData,
        loading: userColorsLoading,
        refetch: userColorsRefetch,
    } = useQuery(USER_COLORS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(USER_COLORS_SUBSCRIPTION, {
        onData: () => {
            userColorsRefetch();
        },
    });

    const {
        data: userData,
        loading: userLoading,
        refetch: userRefetch,
    } = useQuery(USER, {
        fetchPolicy: 'network-only',
        variables: {
            id: userID,
        },
    });

    useSubscription(USERS_SUBSCRIPTION, {
        onData: () => {
            userRefetch();
        },
    });

    const User = userLoading ? null : userData.user;

    const userColors = userColorsLoading ? [] : userColorsData.userColors;
    const similiarColors = userColors.filter(
        (User) => User.id !== userID && sameColors(User.color, color)
    );
    const Races = racesLoading ? [] : racesData.races;
    const Occupations = occupationsLoading ? [] : occupationsData.occupations;

    React.useEffect(() => {
        userRefetch({ id: userID });
        // eslint-disable-next-line
    }, [userID]);

    React.useEffect(() => {
        if (!userLoading) {
            setEmail(User.email);
            setNickname(User.nickname);
            setColor(User.color);
            setCharacter(User.character);
            setRace(toSelItem(User.race));
            setOccupation(toSelItem(User.occupation));
            setDm(User.dm);
            setIcon(null);
            setPortrait(null);
        }
        // eslint-disable-next-line
    }, [User]);

    const saveUser = async () => {
        setSaving(true);
        let body = {
            id: userID,
            email,
            nickname,
            color,
            character,
            race: race.id,
            occupation: occupation.id,
            dm,
        };

        if (icon) {
            body.icon = await toBase64(icon);
        }
        if (portrait) {
            body.portrait = await toBase64(portrait);
        }
        updateUser({ variables: body })
            .then(() => {
                //update STATE images
                setSaving(false);
                setIcon(null);
                setPortrait(null);
            })
            .catch((e) => {
                setSaving(false);
                console.log(e);
            });
    };
    const deleteUserFunc = () => {
        if (window.confirm('Vážne?')) {
            deleteUser({ variables: { id: userID } });
        }
    };

    if (userLoading || userColorsLoading || racesLoading || occupationsLoading) {
        return (
            <div className="settings-right">
                <Card>
                    <CardHeader className="flex-row"></CardHeader>
                    <CardBody>Nacitava sa...</CardBody>
                </Card>
            </div>
        );
    }

    return (
        <div className="settings-right">
            <Card>
                <CardHeader className="flex-row">
                    <Input
                        id="email"
                        value={email}
                        className="invisible-input"
                        placeholder="E-mail"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <span className="m-l-auto">
                        <Button
                            color={User.activated ? 'danger' : 'success'}
                            className="list-tooltip center-hor"
                            onClick={() => {
                                updateUser({
                                    variables: {
                                        id: User.id,
                                        activated: !User.activated,
                                    },
                                });
                            }}
                            id={User.activated ? 'userDeactivate' : 'userActivate'}
                        >
                            <i
                                className={`fa fa-${
                                    User.activated ? 'times' : 'check'
                                } color-white`}
                            />
                        </Button>
                    </span>
                    <Tooltip
                        placement="bottom"
                        target={User.activated ? 'userDeactivate' : 'userActivate'}
                        text={
                            User.activated ? 'Zakázať prihlasovanie sa' : 'Povoliť prihlasovanie sa'
                        }
                    />
                </CardHeader>
                <CardBody>
                    <FormGroup className="flex-row">
                        <Label for="nickname" sm={2}>
                            Prezývka
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="nickname"
                                value={nickname}
                                placeholder="Zadaj prezývku"
                                onChange={(e) => setNickname(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="color" sm={2}>
                            Farba hráča
                        </Label>
                        <Col className="flex-row" sm={10}>
                            <SketchPicker
                                id="color"
                                color={color}
                                onChangeComplete={(value) => setColor(value.hex)}
                            />
                            <div
                                className="height-300 width-100 p-10 text-bolder"
                                style={{ backgroundColor: color }}
                            >
                                Hráčová farba
                            </div>
                            {similiarColors.length > 0 && (
                                <div className="height-300 width-400 text-bolder">
                                    <div className="p-10">
                                        Táto farba je príliž podobná tymto hráčom!
                                    </div>
                                    {similiarColors.map((User) => (
                                        <div
                                            className="p-10"
                                            key={User.color}
                                            style={{
                                                height: Math.min(
                                                    70,
                                                    (300 - 24) / similiarColors.length
                                                ),
                                                backgroundColor: User.color,
                                            }}
                                        >{`${User.nickname}(${User.character})`}</div>
                                    ))}
                                </div>
                            )}
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="character" sm={2}>
                            Meno charakteru
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="character"
                                value={character}
                                placeholder="Zadaj meno charakteru"
                                onChange={(e) => setCharacter(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label sm={2}>Rasa</Label>
                        <Col sm={10}>
                            <Select
                                value={race}
                                options={toSelArr(Races)}
                                placeholder="Vyber rasu"
                                onChange={(race) => setRace(race)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label sm={2}>Povolanie</Label>
                        <Col sm={10}>
                            <Select
                                value={occupation}
                                options={toSelArr(Occupations)}
                                placeholder="Vyber povolanie"
                                onChange={(occupation) => setOccupation(occupation)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Checkbox
                            className="form-control-checkbox"
                            label="Dungeon master"
                            right
                            value={dm}
                            centerHor
                            onChange={() => setDm(!dm)}
                        />
                    </FormGroup>
                    <ImageInput
                        label="Ikonka (štvorcová)"
                        id={`userIcon${userID}`}
                        setImage={setIcon}
                        image={icon}
                        placeholder="Vyberte ikonku"
                        original={User?.icon}
                        height={100}
                        width={100}
                    />
                    <ImageInput
                        label="Portrét(obdĺžnik)"
                        id={`userPorait${userID}`}
                        setImage={setPortrait}
                        image={portrait}
                        placeholder="Vyberte portrét"
                        original={User?.portrait}
                        height={300}
                        width={200}
                    />
                    <div className="flex-row">
                        <Button
                            color="primary"
                            disabled={saving || similiarColors.length > 0}
                            onClick={saveUser}
                        >
                            {saving ? 'Ukladá sa...' : 'Uložiť používateľa'}
                        </Button>
                        {currentUser.admin && !User.admin && (
                            <Button color="danger" className="m-l-auto" onClick={deleteUserFunc}>
                                Zmazať
                            </Button>
                        )}
                        <Button
                            color="link"
                            className="m-l-auto"
                            disabled={saving}
                            onClick={() => setChangePassword(!changePassword)}
                        >
                            Zmeniť heslo
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
