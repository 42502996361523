import React from 'react';
import { Button, Label, ListGroup, ListGroupItem } from 'reactstrap';
import OccupationEdit from './edit';
import OccupationAdd from './add';
import { Tooltip } from 'components';
import { useParams, useNavigate } from 'react-router';
import { OCCUPATIONS, OCCUPATIONS_SUBSCRIPTION } from 'queries';
import { useQuery, useSubscription } from '@apollo/client';

export default function OccupationList() {
    const { id } = useParams();
    const occupationID = parseInt(id);
    const navigate = useNavigate();
    const {
        data: occupationsData,
        loading: occupationsLoading,
        refetch: occupationsRefetch,
    } = useQuery(OCCUPATIONS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(OCCUPATIONS_SUBSCRIPTION, {
        onData: () => {
            occupationsRefetch();
        },
    });

    const Occupations = occupationsLoading ? [] : occupationsData.occupations;

    return (
        <div className="flex-row">
            <div className="settings-left">
                <div className="flex-row">
                    <Label className="m-t-5 color-white">Povolania</Label>
                    <Button
                        color="link"
                        id="add-occupation"
                        className="center-hor m-l-auto m-b-5"
                        onClick={() => navigate('/nastavenia_hry/povolania/add')}
                    >
                        <i className="fa fa-plus" />
                    </Button>
                    <Tooltip
                        placement="right"
                        target="add-occupation"
                        text="Vytvoriť nové povolanie"
                    />
                </div>
                <ListGroup>
                    {Occupations.map((occupation) => (
                        <ListGroupItem
                            key={occupation.id}
                            active={occupationID === occupation.id}
                            action
                            className="clickable"
                            onClick={() => {
                                navigate(`/nastavenia_hry/povolania/${occupation.id}`);
                            }}
                        >
                            {occupation.title}
                        </ListGroupItem>
                    ))}
                </ListGroup>
            </div>
            {!isNaN(occupationID) &&
                Occupations.some((occupation) => occupation.id === occupationID) && (
                    <OccupationEdit occupationID={occupationID} />
                )}
            {id === 'add' && <OccupationAdd />}
        </div>
    );
}
