import { gql } from '@apollo/client';

const attributes = `
attributes {
    defence {
      base
      extra
      lost
    }
    dexterity {
      base
      extra
      lost
    }
    endurance {
      base
      extra
      lost
    }
    energy {
      base
      extra
      lost
    }
    speed {
      base
      extra
      lost
    }
    strength {
      base
      extra
      lost
    }
    vitality {
      base
      extra
      lost
    }
}
`;

const opponents = `opponents {
    id
    uniqueSpeed
    team
    stunned
    dead
    nickname
    attacked
    damage
    defencePoints
    attackPoints
    score
    ${attributes}
    creature {
      createdAt
      id
      level
      portrait
      title
    }
    user {
      character
      id
      color
      portrait
      killCount
      dailySpell
    }
  }`;

export const COMBAT_SIMULATOR = gql`
    query combatSimulator {
        combatSimulator {
            id
            currentAttacker
            currentDefender
            attacker
            defender
            fightStarted
            progress
            round
            title
            battlefield {
                id
                title
                battlefield
                battlefieldData {
                  id
                  createdAt
                  updatedAt
                  X
                  Y
                  type
                  userId
                }
              }
            ${opponents}
        }
    }
`;

export const COMBAT_USERS_AND_CREATURES = gql`
    query combatUsersAndCreatures {
        combatUsersAndCreatures {
            id
            creatureId
            userId
            type
            label
            portrait
            color
            ${attributes}
        }
    }
`;

export const ADD_OPPONENT = gql`
    mutation addOpponent(
        $uniqueSpeed: Int!
        $team: Int!
        $creatureId: Int
        $userId: Int
        $nickname: String
        $defenceExtra: Int
        $dexterityExtra: Int
        $enduranceExtra: Int
        $energyExtra: Int
        $speedExtra: Int
        $strengthExtra: Int
        $vitalityExtra: Int
        $defenceLost: Int
        $dexterityLost: Int
        $enduranceLost: Int
        $energyLost: Int
        $speedLost: Int
        $strengthLost: Int
        $vitalityLost: Int
    ) {
        addOpponent(
            uniqueSpeed: $uniqueSpeed
            team: $team
            creatureId: $creatureId
            userId: $userId
            nickname: $nickname
            defenceExtra: $defenceExtra
            dexterityExtra: $dexterityExtra
            enduranceExtra: $enduranceExtra
            energyExtra: $energyExtra
            speedExtra: $speedExtra
            strengthExtra: $strengthExtra
            vitalityExtra: $vitalityExtra
            defenceLost: $defenceLost
            dexterityLost: $dexterityLost
            enduranceLost: $enduranceLost
            energyLost: $energyLost
            speedLost: $speedLost
            strengthLost: $strengthLost
            vitalityLost: $vitalityLost
        )
    }
`;

export const UPDATE_OPPONENT = gql`
    mutation updateOpponent(
        $id: Int!
        $uniqueSpeed: Int
        $team: Int
        $stunned: Boolean
        $nickname: String
        $defenceExtra: Int
        $dexterityExtra: Int
        $enduranceExtra: Int
        $energyExtra: Int
        $speedExtra: Int
        $strengthExtra: Int
        $vitalityExtra: Int
        $defenceLost: Int
        $dexterityLost: Int
        $enduranceLost: Int
        $energyLost: Int
        $speedLost: Int
        $strengthLost: Int
        $vitalityLost: Int
    ) {
        updateOpponent(
            id: $id
            uniqueSpeed: $uniqueSpeed
            team: $team
            stunned: $stunned
            nickname: $nickname
            defenceExtra: $defenceExtra
            dexterityExtra: $dexterityExtra
            enduranceExtra: $enduranceExtra
            energyExtra: $energyExtra
            speedExtra: $speedExtra
            strengthExtra: $strengthExtra
            vitalityExtra: $vitalityExtra
            defenceLost: $defenceLost
            dexterityLost: $dexterityLost
            enduranceLost: $enduranceLost
            energyLost: $energyLost
            speedLost: $speedLost
            strengthLost: $strengthLost
            vitalityLost: $vitalityLost
        )
    }
`;

export const REMOVE_OPPONENT = gql`
    mutation removeOpponent($id: Int!) {
        removeOpponent(id: $id)
    }
`;

export const START_COMBAT = gql`
    mutation startCombat($title: String!, $battlefieldId: Int) {
        startCombat(title: $title, battlefieldId: $battlefieldId)
    }
`;

export const CHANGE_ATTACKER = gql`
    mutation changeAttacker($id: Int!) {
        changeAttacker(id: $id)
    }
`;

export const CHANGE_DEFENDER = gql`
    mutation changeDefender($id: Int!) {
        changeDefender(id: $id)
    }
`;

export const START_FIGHT = gql`
    mutation startFight {
        startFight
    }
`;

export const CANCEL_FIGHT = gql`
    mutation cancelFight {
        cancelFight
    }
`;

export const SKIP_TURN = gql`
    mutation skipTurn {
        skipTurn
    }
`;

export const SET_BATTLEFIELD = gql`
    mutation setBattlefield($battlefieldId: Int) {
        setBattlefield(battlefieldId: $battlefieldId)
    }
`;

export const ATTACKER_ROLL_DAMAGE = gql`
    mutation attackerRollDamage {
        attackerRollDamage
    }
`;

export const COMBAT_ROLL_DEXTERITY = gql`
    mutation combatRollDexterity($attacker: Boolean!) {
        combatRollDexterity(attacker: $attacker)
    }
`;

export const SPECIAL_ATTACK = gql`
    mutation specialAttack($attacker: Boolean!, $specialAttack: Boolean!) {
        specialAttack(attacker: $attacker, specialAttack: $specialAttack)
    }
`;

export const SPECIAL_ATTACK_ROLL = gql`
    mutation specialAttackRoll($attacker: Boolean!) {
        specialAttackRoll(attacker: $attacker)
    }
`;

export const SPECIAL_ATTACK_EFFECT = gql`
    mutation specialAttackEffect(
        $attacker: Boolean!
        $kill: Boolean
        $damage: Int
        $stun: Boolean
    ) {
        specialAttackEffect(attacker: $attacker, kill: $kill, damage: $damage, stun: $stun)
    }
`;

export const SPECIAL_MAGIC = gql`
    mutation specialMagic($attacker: Boolean!, $specialMagic: Boolean!) {
        specialMagic(attacker: $attacker, specialMagic: $specialMagic)
    }
`;

export const SPECIAL_MAGIC_ROLL = gql`
    mutation specialMagicRoll($attacker: Boolean!) {
        specialMagicRoll(attacker: $attacker)
    }
`;

export const SPECIAL_MAGIC_EFFECT = gql`
    mutation specialMagicEffect($attacker: Boolean!, $damage: Int, $heal: Int, $stun: Boolean) {
        specialMagicEffect(attacker: $attacker, damage: $damage, heal: $heal, stun: $stun)
    }
`;

export const END_FIGHT_PREMATURALLY = gql`
    mutation endFightPrematurally {
        endFightPrematurally
    }
`;

export const END_FIGHT_ROUND = gql`
    mutation endFightRound($reason: String) {
        endFightRound(reason: $reason)
    }
`;

export const END_COMBAT = gql`
    mutation endCombat {
        endCombat
    }
`;

export const RESET_COMBAT = gql`
    mutation resetCombat {
        resetCombat
    }
`;

export const COMBAT_SIMULATOR_SUBSCRIPTION = gql`
    subscription combatSimulatorSubscription {
        combatSimulatorSubscription
    }
`;
