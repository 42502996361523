import React from 'react';
import Attribute from './attribute';
import { randomSimpleString } from 'helperFunctions';

export default function Attributes(props) {
    const { extraDiv } = props;
    const [randomID] = React.useState(randomSimpleString());

    return (
        <div className="combat-attributes">
            <Attribute {...props} randomID={randomID} title="Vitalita" attribute="vitality" />
            <Attribute {...props} randomID={randomID} title="Výdrž" attribute="endurance" />
            <Attribute {...props} randomID={randomID} title="Sila" attribute="strength" />
            <Attribute {...props} randomID={randomID} title="Rýchlosť" attribute="speed" />
            <Attribute {...props} randomID={randomID} title="Obratnosť" attribute="dexterity" />
            <Attribute {...props} randomID={randomID} title="Obrana" attribute="defence" />
            <Attribute {...props} randomID={randomID} title="Energia" attribute="energy" />
            {extraDiv ? extraDiv : null}
        </div>
    );
}
