import { gql } from '@apollo/client';

export const LOOT_MANAGER_ITEMS = gql`
    query lootManagerItems {
        lootManagerItems {
            id
            createdAt
            updatedAt
            quantity
            sell
            user {
                id
                nickname
                color
                character
            }
            item {
                id
                createdAt
                updatedAt
                title
                description
                icon
                price
                type {
                    id
                    value
                    label
                    inventoryTitle
                    inventoryLabel
                    inventoryTab
                    inventoryOrder
                    color
                }
                weight
                replaces
                energy
                defence
                dexterity
                speed
                strength
                vitality
                endurance
                capacity
            }
        }
    }
`;

export const ADD_LOOT_MANAGER_ITEM = gql`
    mutation addLootManagerItem($itemId: Int!, $quantity: Int!) {
        addLootManagerItem(itemId: $itemId, quantity: $quantity) {
            id
        }
    }
`;

export const REMOVE_LOOT_MANAGER_ITEM = gql`
    mutation removeLootManagerItem($id: Int!) {
        removeLootManagerItem(id: $id) {
            id
        }
    }
`;

export const SPLIT_LOOT_MANAGER_ITEM = gql`
    mutation splitLootManagerItem($id: Int!) {
        splitLootManagerItem(id: $id) {
            id
        }
    }
`;

export const UPDATE_LOOT_MANAGER_ITEM = gql`
    mutation updateLootManagerItem($id: Int!, $quantity: Int, $sell: Boolean, $userId: Int) {
        updateLootManagerItem(id: $id, quantity: $quantity, sell: $sell, userId: $userId) {
            id
        }
    }
`;

export const CLEAR_ALL_LOOT_MANAGER_ITEMS = gql`
    mutation clearAllLootManagerItems {
        clearAllLootManagerItems
    }
`;

export const LOOT_MANAGER_DISTRIBUTE_ITEMS = gql`
    mutation lootManagerDistributeItems {
        lootManagerDistributeItems
    }
`;

export const LOOT_MANAGER_ITEMS_SUBSCRIPTION = gql`
    subscription lootManagerItemsSubscription {
        lootManagerItemsSubscription
    }
`;
