import React from 'react';
import { Card, CardHeader, CardBody, Button, FormGroup, Label, Col, Input } from 'reactstrap';
import { Checkbox, ImageInput } from 'components';
import Select from 'react-select';

import Textarea from 'react-textarea-autosize';
import { ADD_SPELL, MAGE_CLASSES } from 'queries';
import { useMutation, useQuery } from '@apollo/client';
import { toBase64, toSelArr } from 'helperFunctions';

export default function SpellAdd() {
    const [description, setDescription] = React.useState('');
    const [visibility, setVisibility] = React.useState(false);
    const [title, setTitle] = React.useState('');
    const [icon, setIcon] = React.useState(null);
    const [level, setLevel] = React.useState(1);
    const [mageClass, setMageClass] = React.useState(null);
    const [adding, setAdding] = React.useState(false);

    const [addSpell] = useMutation(ADD_SPELL);

    const { data: mageClassesData, loading: mageClassesLoading } = useQuery(MAGE_CLASSES, {
        fetchPolicy: 'network-only',
    });

    const mageClasses = mageClassesLoading ? [] : mageClassesData.mageClasses;

    const add = async () => {
        setAdding(true);
        let body = {
            title,
            description,
            visibility,
            mageClass: mageClass.id,
            level: parseInt(level),
            icon: await toBase64(icon),
        };

        addSpell({ variables: body })
            .then(() => {
                setAdding(false);
                setTitle('');
                setDescription('');
                setVisibility(false);
                setLevel(1);
            })
            .catch((e) => {
                setAdding(false);
                console.log(e);
            });
    };

    return (
        <div className="settings-right">
            <Card>
                <CardHeader className="flex-row">Nové kúzlo</CardHeader>
                <CardBody>
                    <FormGroup className="flex-row">
                        <Label for="spell" sm={2}>
                            Názov
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="spell"
                                value={title}
                                placeholder="Zadaj názov kúzla"
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Checkbox
                            className="form-control-checkbox"
                            label="Spoznali kúzlo"
                            value={visibility}
                            right
                            centerHor
                            onChange={() => setVisibility(!visibility)}
                        />
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="level" sm={2}>
                            Úroveň
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="level"
                                value={level}
                                placeholder="Zadaj úroveň mágie"
                                onChange={(e) => setLevel(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label sm={2}>Trieda mágie</Label>
                        <Col sm={10}>
                            <Select
                                value={mageClass}
                                options={toSelArr(mageClasses, 'label')}
                                placeholder="Vyber triedu mágie"
                                onChange={(mageClass) => setMageClass(mageClass)}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup className="flex-row">
                        <Label for="description" sm={2}>
                            Popis
                        </Label>
                        <Col sm={10}>
                            <Textarea
                                className="form-control"
                                id="description"
                                placeholder="Zadaj popis kúzla"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <ImageInput
                        setImage={setIcon}
                        image={icon}
                        id="spellIcon-new"
                        label="Ikona magie"
                        placeholder="Vyberte ikonu povolania"
                        height={300}
                    />
                    <div className="flex-row">
                        <Button
                            color="success"
                            disabled={adding || icon === null || mageClass === null}
                            onClick={add}
                        >
                            {adding ? 'Pridáva sa...' : 'Pridať kúzlo'}
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
