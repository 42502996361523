import React from 'react';
import { timestampToPracticalDate } from 'helperFunctions';
import { Empty, Image } from 'components';
import { Button } from 'reactstrap';
import { DENY_CRAFTING, FINISH_CRAFTING } from 'queries';
import { useMutation } from '@apollo/client';

export default function Requests({ Craftings }) {
    const [finishCrafting] = useMutation(FINISH_CRAFTING);
    const [denyCrafting] = useMutation(DENY_CRAFTING);

    const CraftingsWithMissing = Craftings.map((Crafting) => {
        let allRequired = [];
        Crafting.recipe.ingredients.forEach((ingredient) => {
            const index = allRequired.findIndex((required) => required.id === ingredient.itemId);
            if (index !== -1) {
                allRequired[index].quantity =
                    allRequired[index].quantity + ingredient.quantity * Crafting.quantity;
            } else {
                allRequired.push({
                    id: ingredient.itemId,
                    quantity: ingredient.quantity * Crafting.quantity,
                    Item: ingredient,
                });
            }
        });
        Crafting.recipe.tools.forEach((tool) => {
            const index = allRequired.findIndex((required) => required.id === tool.itemId);
            if (index !== -1) {
                allRequired[index].quantity = allRequired[index].quantity + tool.quantity;
            } else {
                allRequired.push({
                    id: tool.itemId,
                    quantity: tool.quantity,
                    Item: tool,
                });
            }
        });

        return {
            ...Crafting,
            missing: allRequired
                .filter((required) => {
                    const inventoryItem = Crafting.user.inventory.find(
                        (Item) => Item.itemId === required.id
                    );
                    return !inventoryItem || inventoryItem.quantity < required.quantity;
                })
                .map((required) => {
                    const inventoryItem = Crafting.user.inventory.find(
                        (Item) => Item.itemId === required.id
                    );
                    if (!inventoryItem) {
                        return {
                            Item: required.Item,
                            needed: required.quantity,
                            has: 0,
                        };
                    }
                    return {
                        Item: required.Item,
                        needed: required.quantity,
                        has: inventoryItem.quantity,
                    };
                }),
        };
    });

    return (
        <div className="width-400 p-l-10 p-r-10 max-height-50vh overflow-x">
            {CraftingsWithMissing.map((Crafting) => (
                <div key={Crafting.id} className="double-border m-b-5">
                    <div>
                        <span>{`[${timestampToPracticalDate(Crafting.createdAt)}]`}</span>
                        <span
                            className="text-bolder m-l-5 m-r-5"
                            style={{ color: Crafting.user.color }}
                        >
                            {Crafting.user.character}
                        </span>
                        | Recept:<span className="text-bolder m-l-5">{Crafting.recipe.title}</span>
                    </div>
                    <div>
                        {Crafting.missing.length > 0 ? (
                            <Empty>
                                <div className="text-bolder color-red">Chýbajúce veci</div>
                                <div className="flex-row-wrapped m-b-5">
                                    {Crafting.missing.map((Missing) => (
                                        <div
                                            className="flex-row font-18 m-r-20 text-bolder"
                                            key={Missing.Item.id}
                                        >
                                            <div className="center-hor m-r-5 color-red">{`${
                                                Missing.needed - Missing.has
                                            } x`}</div>
                                            {Missing.Item.icon ? (
                                                <Image
                                                    height={30}
                                                    path={Missing.Item.icon}
                                                    hideEmpty
                                                    focus
                                                    customFocus={Missing.Item.title}
                                                />
                                            ) : (
                                                <div className="center-hor m-r-5">
                                                    {Missing.Item.title}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </Empty>
                        ) : (
                            <div>Nechýbajú žiadne prvky</div>
                        )}
                        <div className="flex-row m-b-5">
                            <Button
                                className="center-ver"
                                color="success"
                                onClick={() => finishCrafting({ variables: { id: Crafting.id } })}
                            >
                                Potvrdiť
                            </Button>
                            <Button
                                className="center-ver"
                                color="danger"
                                onClick={() => denyCrafting({ variables: { id: Crafting.id } })}
                            >
                                Zamietnuť
                            </Button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
