import { REMOVE_OPPONENT, UPDATE_OPPONENT } from 'queries';
import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import teams from 'configs/teams';
import { GetMyRights, capitalizeFirstLetter } from 'helperFunctions';
import Attributes from './attributes';
import { Empty, Image, Tooltip } from 'components';
import { useMutation } from '@apollo/client';
import Select from 'react-select';
import { pickSelectStyle } from 'configs/selectStyles';

export default function OpponentDisplay({ Opponent, type, noMajorChanges, noChanges }) {
    const { Dm } = GetMyRights();

    noMajorChanges = noChanges ? true : noMajorChanges;
    const [newNickname, setNewNickname] = React.useState('');

    const [updateOpponentMutation] = useMutation(UPDATE_OPPONENT);
    const [removeOpponentMutation] = useMutation(REMOVE_OPPONENT);

    const team = teams.find((team) => team.value === Opponent.team);

    return (
        <div className="p-t-10 p-l-5 p-r-5 p-b-5 width-470">
            <h4 className="text-bolder">
                {Dm && !noMajorChanges && (
                    <Empty>
                        <i
                            className="fa fa-trash color-red clickable m-r-5"
                            id={`delete-opponent-${Opponent.id}`}
                            onClick={() => {
                                if (
                                    window.confirm(
                                        `Si si istý že chceš odobrať hráča ${Opponent.nickname} z tohto boja?`
                                    )
                                ) {
                                    removeOpponentMutation({ variables: { id: Opponent.id } });
                                }
                            }}
                        />
                        <Tooltip
                            placement="top"
                            target={`delete-opponent-${Opponent.id}`}
                            text="Vymazať z boja?"
                        />
                    </Empty>
                )}
                {type}:
                <span
                    className="m-l-5"
                    style={{ color: Opponent.user ? Opponent.user.color : 'grey' }}
                >
                    {Opponent.nickname}
                </span>
            </h4>
            <div className="flex-row m-t-10 m-b-10">
                <Image
                    className="flex"
                    path={Opponent.user ? Opponent.user.portrait : Opponent.creature.portrait}
                    bottom
                    height={250}
                />
                <Attributes
                    noChanges={noChanges}
                    Opponent={Opponent}
                    updateAttribute={(attribute, type, value) => {
                        updateOpponentMutation({
                            variables: {
                                id: Opponent.id,
                                [`${attribute}${capitalizeFirstLetter(type)}`]: value,
                            },
                        });
                    }}
                    updateUniqueSpeed={(newSpeed) => {
                        updateOpponentMutation({
                            variables: { id: Opponent.id, uniqueSpeed: newSpeed },
                        });
                    }}
                    extraDiv={
                        <div>
                            {Opponent.creature ? (
                                <div className="text-bolder">Level: {Opponent.creature.level}</div>
                            ) : (
                                <div className="text-bolder">Vraždy: {Opponent.user.killCount}</div>
                            )}
                            {Dm && !noMajorChanges ? (
                                <Select
                                    value={team}
                                    options={teams}
                                    placeholder="Tým"
                                    onChange={(Team) => {
                                        updateOpponentMutation({
                                            variables: { id: Opponent.id, team: Team.value },
                                        });
                                    }}
                                    styles={pickSelectStyle(['colored'])}
                                />
                            ) : (
                                <div
                                    style={{ backgroundColor: team.color, color: 'white' }}
                                    className="text-bolder text-hor-middle"
                                >
                                    {team.label}
                                </div>
                            )}
                        </div>
                    }
                />
            </div>

            {Opponent.type === 'creature' && (
                <FormGroup className="flex-row m-t-5">
                    <Label className="width-70 center-hor">Meno:</Label>
                    <Input
                        value={newNickname !== null ? newNickname : Opponent.nickname}
                        onFocus={() => setNewNickname(Opponent.nickname)}
                        onBlur={() => {
                            updateOpponentMutation({ variables: { nickname: newNickname } });
                            setNewNickname(null);
                        }}
                        onChange={(e) => setNewNickname(e.target.value)}
                    />
                </FormGroup>
            )}
        </div>
    );
}
