import React from 'react';
import { Button, Label, ListGroup, ListGroupItem } from 'reactstrap';
import TraderEdit from './edit';
import TraderAdd from './add';
import { Tooltip } from 'components';
import { useParams, useNavigate } from 'react-router';
import { TRADERS, TRADERS_SUBSCRIPTION } from 'queries';
import { useQuery, useSubscription } from '@apollo/client';

export default function TraderList() {
    const { id } = useParams();
    const traderID = parseInt(id);
    const navigate = useNavigate();
    const {
        data: tradersData,
        loading: tradersLoading,
        refetch: tradersRefetch,
    } = useQuery(TRADERS, {
        variables: {
            visibleOnly: false,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(TRADERS_SUBSCRIPTION, {
        onData: () => {
            tradersRefetch({ visibleOnly: false });
        },
    });

    const Traders = tradersLoading ? [] : tradersData.traders;

    return (
        <div className="flex-row">
            <div className="settings-left">
                <div className="flex-row">
                    <Label className="m-t-5 color-white">Obchodníci</Label>
                    <Button
                        color="link"
                        id="add-trader"
                        className="center-hor m-l-auto m-b-5"
                        onClick={() => navigate('/nastavenia_hry/obchodnici/add')}
                    >
                        <i className="fa fa-plus" />
                    </Button>
                    <Tooltip
                        placement="right"
                        target="add-trader"
                        text="Vytvoriť nového obchodníka"
                    />
                </div>
                <ListGroup>
                    {Traders.map((trader) => (
                        <ListGroupItem
                            key={trader.id}
                            active={traderID === trader.id}
                            action
                            className="clickable"
                            onClick={() => {
                                navigate(`/nastavenia_hry/obchodnici/${trader.id}`);
                            }}
                        >
                            {trader.title}
                            <span
                                className="center-right-absolute highlighted-text"
                                style={{ color: trader.visibility ? '#218838' : '#bd2130' }}
                            >
                                {trader.visibility ? 'Spoznané' : 'Neznáme'}
                            </span>
                        </ListGroupItem>
                    ))}
                </ListGroup>
            </div>
            {!isNaN(traderID) && Traders.some((trader) => trader.id === traderID) && (
                <TraderEdit traderID={traderID} />
            )}
            {id === 'add' && <TraderAdd />}
        </div>
    );
}
