import { Empty, Tooltip } from 'components';
import { GetMyRights } from 'helperFunctions';
import React from 'react';
import { Input } from 'reactstrap';

export default function Attribute({
    Opponent,
    updateAttribute,
    attribute,
    title,
    randomID,
    noChanges,
}) {
    const Attribute = Opponent.attributes[attribute];
    const { Dm } = GetMyRights();

    const [combatExtra, setCombatExtra] = React.useState(null);
    const [combatLost, setCombatLost] = React.useState(null);

    const getRemainingColor = (value, maxValue, flipValue = true) => {
        let useValue = value / maxValue;
        if (useValue > 1) {
            useValue = -0.5;
        } else if (useValue < 0) {
            useValue = -1.5;
        } else if (flipValue) {
            useValue = Math.abs(useValue - 1);
        }

        const hue = ((1 - useValue) * 120).toString(10);
        return `hsl(${hue},100%,50%)`;
    };

    const total = Attribute.base + Attribute.extra;
    const current = total - Attribute.lost;
    const loweringAttribute = ['vitality', 'energy', 'endurance'].includes(attribute);

    return (
        <div className="flex-row m-t-3 clean-input text-bolder">
            <div
                className="width-90 p-t-3 clickable"
                id={`character-attribute-${title}-${randomID}`}
            >
                {title}
            </div>
            <div className="width-60 p-t-3">
                <span
                    style={{
                        color: loweringAttribute
                            ? getRemainingColor(current, total, true)
                            : 'black',
                    }}
                >
                    {current}
                </span>
                {loweringAttribute && `/${total}`}
            </div>
            <Tooltip
                placement="left"
                target={`character-attribute-${title}-${randomID}`}
                text={
                    <div>
                        <div>
                            <span className="text-bolder">Základ:</span>{' '}
                            <span style={{ color: 'green' }}>{Attribute.base}</span>
                        </div>
                        <div>
                            <span className="text-bolder">Bonus:</span>{' '}
                            <span style={{ color: Attribute.extra > 0 ? 'green' : 'red' }}>
                                {Attribute.extra}
                            </span>
                        </div>
                        <div>
                            <span className="text-bolder">Stratil:</span>{' '}
                            <span style={{ color: Attribute.lost > 0 ? 'red' : 'green' }}>
                                {Attribute.lost * -1}
                            </span>
                        </div>
                    </div>
                }
            />
            {Dm && !noChanges && (
                <Empty>
                    <Input
                        className="width-30 m-l-5 color-green"
                        type="number"
                        value={combatExtra !== null ? combatExtra : Attribute.extra}
                        onFocus={() => setCombatExtra(Attribute.extra)}
                        onBlur={() => {
                            updateAttribute(
                                attribute,
                                'extra',
                                isNaN(parseInt(combatExtra)) ? 0 : parseInt(combatExtra)
                            );
                            setCombatExtra(null);
                        }}
                        onChange={(e) => setCombatExtra(e.target.value)}
                    />
                    <Input
                        className="width-30 m-l-10 color-red"
                        type="number"
                        value={combatLost !== null ? combatLost : Attribute.lost}
                        onFocus={() => setCombatLost(Attribute.lost)}
                        onBlur={() => {
                            updateAttribute(
                                attribute,
                                'lost',
                                isNaN(parseInt(combatLost)) ? 0 : parseInt(combatLost)
                            );
                            setCombatLost(null);
                        }}
                        onChange={(e) => setCombatLost(e.target.value)}
                    />
                </Empty>
            )}
        </div>
    );
}
