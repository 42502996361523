import React from 'react';
import {
    sortBy,
    toSelArr,
    formattedMessage,
    getRemainingColor,
    GetMyRights,
    GetMyData,
} from 'helperFunctions';
import Select from 'react-select';
import { Card, CardBody, Label, FormGroup, Col, Input, Button } from 'reactstrap';
import classnames from 'classnames';
import { pickSelectStyle } from 'configs/selectStyles';
import dices from 'configs/dices';
import { Empty, Tooltip } from 'components';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import {
    DICE_POKER,
    DICE_POKER_SUBSCRIPTION,
    POKER_CANCEL_GAME,
    POKER_END_ROUND,
    POKER_REPLACE_DICES,
    POKER_REROLL_DICE,
    POKER_ROLL_DICE,
    POKER_SHOW_DICES,
    POKER_START_GAME,
    USERS,
    USERS_SUBSCRIPTION,
} from 'queries';

const NPC = { label: 'NPC', value: null, id: null, color: 'grey', nickname: '' };
const d6 = dices.find((dice) => dice.id === 'd6');

export default function DicePoker() {
    const { Dm, Admin } = GetMyRights();
    const currentUser = GetMyData();
    const [selectedDices1, setSelectedDices1] = React.useState([]);
    const [selectedDices2, setSelectedDices2] = React.useState([]);
    const [player1, setPlayer1] = React.useState(NPC);
    const [player2, setPlayer2] = React.useState(NPC);
    const [pokerStartGame] = useMutation(POKER_START_GAME); //(player1: String, player2: String)
    const [pokerRollDice] = useMutation(POKER_ROLL_DICE); //(player: Int, index: Int)
    const [pokerReplaceDices] = useMutation(POKER_REPLACE_DICES); //(player: Int, indexes: [Int])
    const [pokerRerollDice] = useMutation(POKER_REROLL_DICE); //(player: Int, index: Int)
    const [pokerShowDices] = useMutation(POKER_SHOW_DICES);
    const [pokerCancelGame] = useMutation(POKER_CANCEL_GAME);
    const [pokerEndRound] = useMutation(POKER_END_ROUND); //(winner: Int)

    const {
        data: dicePokerData,
        loading: dicePokerLoading,
        refetch: dicePokerRefetch,
    } = useQuery(DICE_POKER, {
        fetchPolicy: 'network-only',
    });

    useSubscription(DICE_POKER_SUBSCRIPTION, {
        onData: () => {
            dicePokerRefetch();
        },
    });

    const {
        data: usersData,
        loading: usersLoading,
        refetch: usersRefetch,
    } = useQuery(USERS, {
        fetchPolicy: 'network-only',
        variables: {
            dm: false,
            includeInactive: false,
        },
    });

    useSubscription(USERS_SUBSCRIPTION, {
        onData: () => {
            usersRefetch({
                dm: false,
                includeInactive: false,
            });
        },
    });
    const Users = usersLoading ? [] : usersData.users;

    const processDicePoker = (DicePoker) => {
        return {
            ...DicePoker,
            player1: DicePoker.player1 ? { ...JSON.parse(DicePoker.player1), player: 1 } : null,
            player2: DicePoker.player2 ? { ...JSON.parse(DicePoker.player2), player: 2 } : null,
        };
    };

    if (dicePokerLoading) {
        return <div className="color-white">Načítava sa...</div>;
    }

    const DicePoker = processDicePoker(dicePokerData.dicePoker);

    const reset = () => {
        if (window.confirm('Si si istý?')) {
            pokerCancelGame();
        }
    };

    const throwDice = (player, index) => {
        if (DicePoker.part !== 1 || (!Dm && DicePoker[player].id !== currentUser.id)) {
            return;
        }
        pokerRollDice({ variables: { player: DicePoker[player].player, index } });
    };

    const rethrowDice = (player, index) => {
        if (
            DicePoker.part !== 2 ||
            (!Dm && DicePoker[player].id !== currentUser.id) ||
            !DicePoker[player].replacedDices.some((replacement) => replacement.index === index) ||
            DicePoker[player].replacedDices.find((replacement) => replacement.index === index)
                .newRoll !== null
        ) {
            return;
        }
        pokerRerollDice({ variables: { player: DicePoker[player].player, index } });
    };

    const startGame = () => {
        pokerStartGame({
            variables: {
                player1: JSON.stringify({
                    id: player1.id,
                    nickname: player1.nickname,
                    color: player1.color,
                    rolls: new Array(6).fill(null),
                    replacedDices: null,
                    wins: 0,
                }),
                player2: JSON.stringify({
                    id: player2.id,
                    nickname: player2.nickname,
                    color: player2.color,
                    rolls: new Array(6).fill(null),
                    replacedDices: null,
                    wins: 0,
                }),
            },
        });
    };

    const endRound = (winner) => {
        pokerEndRound({
            variables: {
                winner,
            },
        });
    };

    //select hadzajuceho charakteru
    //check hadzajucej kocky
    //po hode presunut kocku spat na GM
    //ak niesi hadzajuci charakter, tak kocky nie su kliknutelne
    //gm ignoruje selected dice

    const SelectPlayers = sortBy(
        [
            NPC,
            ...toSelArr(Users, 'character').map((User) => ({ ...User, nickname: User.character })),
        ],
        [{ key: 'label', asc: 'true' }]
    );

    const getTotals = (final = false) => {
        let p1Occurances = {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
        };
        let p2Occurances = {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
        };
        const Player1 = DicePoker.player1;
        const Player2 = DicePoker.player2;
        Player1.rolls.forEach((dice, index) => {
            let value = dice;
            if (final) {
                const replacement = Player1.replacedDices.find(
                    (replacement) => replacement.index === index
                );
                value = replacement === undefined ? dice : replacement.newRoll;
            }
            p1Occurances[value] += 1;
        });
        Player2.rolls.forEach((dice, index) => {
            let value = dice;
            if (final) {
                const replacement = Player2.replacedDices.find(
                    (replacement) => replacement.index === index
                );
                value = replacement === undefined ? dice : replacement.newRoll;
            }
            p2Occurances[value] += 1;
        });
        let p1Total = 0;
        let p1Calculation = [
            'Hráč ',
            {
                text: Player1.nickname,
                style: { color: Player1.color, text: Player1.nickname },
                className: 'text-bolder',
            },
            ' hodil ',
        ];
        if (p1Occurances[1] > 1) {
            p1Total += p1Occurances[1];
            p1Calculation.push(`${p1Occurances[1]}x`);
            p1Calculation.push({ className: 'text-bolder', text: '1 ' });
        }
        if (p1Occurances[2] > 1) {
            p1Total += p1Occurances[2] * 2;
            p1Calculation.push(`${p1Occurances[2]}x`);
            p1Calculation.push({ className: 'text-bolder', text: '2 ' });
        }
        if (p1Occurances[3] > 1) {
            p1Total += p1Occurances[3] * 3;
            p1Calculation.push(`${p1Occurances[3]}x`);
            p1Calculation.push({ className: 'text-bolder', text: '3 ' });
        }
        if (p1Occurances[4] > 1) {
            p1Total += p1Occurances[4] * 4;
            p1Calculation.push(`${p1Occurances[4]}x`);
            p1Calculation.push({ className: 'text-bolder', text: '4 ' });
        }
        if (p1Occurances[5] > 1) {
            p1Total += p1Occurances[5] * 5;
            p1Calculation.push(`${p1Occurances[5]}x`);
            p1Calculation.push({ className: 'text-bolder', text: '5 ' });
        }
        if (p1Occurances[6] > 1) {
            p1Total += p1Occurances[6] * 6;
            p1Calculation.push(`${p1Occurances[6]}x`);
            p1Calculation.push({ className: 'text-bolder', text: '6 ' });
        }
        if (p1Calculation.length === 3) {
            p1Calculation.push('postupku!');
            p1Total = 999;
            p1Occurances = {
                1: 10,
                2: 10,
                3: 10,
                4: 10,
                5: 10,
                6: 10,
            };
        } else {
            p1Calculation.push('= ');
            p1Calculation.push({ text: `${p1Total}`, className: 'text-bolder color-green' });
        }

        let p2Total = 0;
        let p2Calculation = [
            'Hráč ',
            {
                text: Player2.nickname,
                style: { color: Player2.color, text: Player2.nickname },
                className: 'text-bolder',
            },
            ' hodil ',
        ];
        if (p2Occurances[1] > 1) {
            p2Total += p2Occurances[1];
            p2Calculation.push(`${p2Occurances[1]}x`);
            p2Calculation.push({ className: 'text-bolder', text: '1 ' });
        }
        if (p2Occurances[2] > 1) {
            p2Total += p2Occurances[2] * 2;
            p2Calculation.push(`${p2Occurances[2]}x`);
            p2Calculation.push({ className: 'text-bolder', text: '2 ' });
        }
        if (p2Occurances[3] > 1) {
            p2Total += p2Occurances[3] * 3;
            p2Calculation.push(`${p2Occurances[3]}x`);
            p2Calculation.push({ className: 'text-bolder', text: '3 ' });
        }
        if (p2Occurances[4] > 1) {
            p2Total += p2Occurances[4] * 4;
            p2Calculation.push(`${p2Occurances[4]}x`);
            p2Calculation.push({ className: 'text-bolder', text: '4 ' });
        }
        if (p2Occurances[5] > 1) {
            p2Total += p2Occurances[5] * 5;
            p2Calculation.push(`${p2Occurances[5]}x`);
            p2Calculation.push({ className: 'text-bolder', text: '5 ' });
        }
        if (p2Occurances[6] > 1) {
            p2Total += p2Occurances[6] * 6;
            p2Calculation.push(`${p2Occurances[6]}x`);
            p2Calculation.push({ className: 'text-bolder', text: '6 ' });
        }
        if (p2Calculation.length === 3) {
            p2Calculation.push('postupku!');
            p2Total = 999;
            p2Occurances = {
                1: 10,
                2: 10,
                3: 10,
                4: 10,
                5: 10,
                6: 10,
            };
        } else {
            p2Calculation.push('= ');
            p2Calculation.push({ text: `${p2Total}`, className: 'text-bolder color-green' });
        }

        const player1SortedFinalDices = !final
            ? []
            : Player1.rolls
                  .map((dice, index) => {
                      const replacement = Player1.replacedDices.find(
                          (replacement) => replacement.index === index
                      );
                      if (replacement !== undefined) {
                          return replacement.newRoll;
                      }
                      return dice;
                  })
                  .sort((dice1, dice2) => {
                      if (p1Occurances[dice1] > 1 && p1Occurances[dice2] > 1) {
                          return dice1 - dice2;
                      }
                      if (p1Occurances[dice1] > 1) {
                          return 1;
                      }
                      if (p1Occurances[dice2] > 1) {
                          return -1;
                      }
                      return dice1 - dice2;
                  });

        const player2SortedFinalDices = !final
            ? []
            : Player2.rolls
                  .map((dice, index) => {
                      const replacement = Player2.replacedDices.find(
                          (replacement) => replacement.index === index
                      );
                      if (replacement !== undefined) {
                          return replacement.newRoll;
                      }
                      return dice;
                  })
                  .sort((dice1, dice2) => {
                      if (p2Occurances[dice1] > 1 && p2Occurances[dice2] > 1) {
                          return dice1 - dice2;
                      }
                      if (p2Occurances[dice1] > 1) {
                          return 1;
                      }
                      if (p2Occurances[dice2] > 1) {
                          return -1;
                      }
                      return dice1 - dice2;
                  });

        return {
            p1Total,
            p2Total,
            p1Calculation,
            p2Calculation,
            p1Occurances,
            p2Occurances,
            player1SortedFinalDices,
            player2SortedFinalDices,
        };
    };

    const PickingCharacters = () =>
        !Dm ? (
            <div className="color-green text-center">Vyberajú sa oponenti...</div>
        ) : (
            <Empty>
                <div className="flex-row">
                    <Col className="m-r-10">
                        <FormGroup className="flex-row">
                            <Label className="m-r-10 center-hor">Hráč 1:</Label>
                            <div className="flex">
                                <Select
                                    value={player1}
                                    options={SelectPlayers}
                                    isDisabled={!Dm}
                                    placeholder="Hráč 1"
                                    onChange={(character) => {
                                        setPlayer1(character);
                                    }}
                                    styles={pickSelectStyle(['colored'])}
                                />
                            </div>
                        </FormGroup>
                        {player1.id === null && (
                            <Input
                                placeholder="Meno hráča"
                                value={player1.nickname}
                                onChange={(e) =>
                                    setPlayer1({ ...player1, nickname: e.target.value })
                                }
                            />
                        )}
                    </Col>
                    <Col className="m-l-10">
                        <FormGroup className="flex-row">
                            <Label className="m-r-10 center-hor">Hráč 2:</Label>
                            <div className="flex">
                                <Select
                                    value={player2}
                                    options={SelectPlayers}
                                    isDisabled={!Dm}
                                    placeholder="Hráč 2"
                                    onChange={(character) => {
                                        setPlayer2(character);
                                    }}
                                    styles={pickSelectStyle(['colored'])}
                                />
                            </div>
                        </FormGroup>
                        {player2.id === null && (
                            <Input
                                placeholder="Meno hráča"
                                value={player2.nickname}
                                onChange={(e) =>
                                    setPlayer2({ ...player2, nickname: e.target.value })
                                }
                            />
                        )}
                    </Col>
                </div>
                <div className="flex-row m-t-20">
                    <Button
                        color="success center-ver"
                        disabled={
                            !Dm ||
                            (player1.id === null && player1.nickname.length < 2) ||
                            (player2.id === null && player2.nickname.length < 2)
                        }
                        onClick={startGame}
                    >
                        Začať hru
                    </Button>
                </div>
            </Empty>
        );

    const GameInfo = () => (
        <Empty>
            <div className="text-center color-green font-18">Kolo {DicePoker.round}</div>
            <div className="text-center font-22">
                <span
                    style={{ color: DicePoker.player1.color }}
                    onClick={() => {
                        if (Admin) {
                            console.log(DicePoker.player1.rolls);
                        }
                    }}
                    className="m-r-10"
                >
                    {DicePoker.player1.nickname}
                </span>
                <span className="text-bolder" style={{ color: DicePoker.player1.color }}>
                    {DicePoker.player1.wins}
                </span>
                <span className="text-bolder m-r-5 m-l-5">:</span>
                <span className="text-bolder" style={{ color: DicePoker.player2.color }}>
                    {DicePoker.player2.wins}
                </span>
                <span
                    style={{ color: DicePoker.player2.color }}
                    onClick={() => {
                        if (Admin) {
                            console.log(DicePoker.player2.rolls);
                        }
                    }}
                    className="m-l-10"
                >
                    {DicePoker.player2.nickname}
                </span>
            </div>
        </Empty>
    );

    const Dices = () => (
        <Empty>
            <div className="flex-row">
                {PlayerDices('player1')}
                {PlayerDices('player2')}
            </div>
            {DicePoker.part === 1 && (
                <div className="flex-row m-t-10">
                    <Button
                        color="success"
                        className="center-ver"
                        disabled={
                            DicePoker.player1.rolls.includes(null) ||
                            DicePoker.player2.rolls.includes(null) ||
                            !Dm
                        }
                        onClick={pokerShowDices}
                    >
                        Zobraziť hody
                    </Button>
                </div>
            )}
        </Empty>
    );

    const PlayerDices = (player) => {
        const Player = DicePoker[player];
        const rolls = Player.rolls;
        const canRoll = (Player.id === null && Dm) || Player.id === currentUser.id;
        if (!canRoll) {
            return (
                <Col className="color-green">
                    {formattedMessage([
                        'Hráč ',
                        {
                            className: 'text-bolder',
                            style: { color: Player.color },
                            text: Player.nickname,
                        },
                        rolls.some((dice) => dice === null) ? ' anonymne hádže...' : ' dohádzal.',
                    ])}
                </Col>
            );
        }

        return (
            <Col className="flex-row">
                {rolls.map((roll, index) => {
                    if (roll === null) {
                        return (
                            <d6.dice
                                key={index}
                                className="clickable height-50 p-r-10"
                                onClick={() => throwDice(player, index)}
                            />
                        );
                    } else {
                        return (
                            <div
                                key={index}
                                className="dice-poker-result noselect"
                                style={{
                                    color: getRemainingColor(roll, 6, true, 3),
                                    borderColor: getRemainingColor(roll, 6, true, 3),
                                }}
                                onClick={() => {
                                    if (
                                        Admin &&
                                        Player.id === currentUser.id &&
                                        rolls.some((roll) => roll === null)
                                    ) {
                                        throwDice(player, index);
                                    }
                                }}
                            >
                                {roll}
                            </div>
                        );
                    }
                })}
            </Col>
        );
    };

    const OrderedDices = () => {
        const { p1Calculation, p2Calculation } = getTotals(false);
        return (
            <div className="flex-row">
                {PlayerOrderedDices('player1', p1Calculation)}
                {PlayerOrderedDices('player2', p2Calculation)}
            </div>
        );
    };

    const PlayerOrderedDices = (player, calculation) => {
        const Player = DicePoker[player];
        const rolls = Player.rolls;
        const canRoll = (Player.id === null && Dm) || Player.id === currentUser.id;
        const diceState = player === 'player1' ? selectedDices1 : selectedDices2;
        const diceStateFunc = player === 'player1' ? setSelectedDices1 : setSelectedDices2;

        return (
            <Col
                className={classnames({
                    'm-r-10': player === 'player1',
                    'm-l-10': player !== 'player1',
                })}
            >
                <div className="combat-roll text-center m-t-5">{formattedMessage(calculation)}</div>
                <div className="flex-row">
                    {rolls.map((roll, index) => (
                        <div
                            key={index}
                            className={classnames('dice-poker-result', {
                                clickable: canRoll && Player.replacedDices === null,
                            })}
                            style={{
                                color:
                                    diceState.includes(index) ||
                                    DicePoker[player].replacedDices?.some(
                                        (replacement) => replacement.index === index
                                    )
                                        ? 'grey'
                                        : getRemainingColor(roll, 6, true, 3),
                                borderColor:
                                    diceState.includes(index) ||
                                    DicePoker[player].replacedDices?.some(
                                        (replacement) => replacement.index === index
                                    )
                                        ? 'grey'
                                        : getRemainingColor(roll, 6, true, 3),
                            }}
                            onClick={() => {
                                if (canRoll && Player.replacedDices === null) {
                                    if (diceState.includes(index)) {
                                        diceStateFunc(diceState.filter((item) => item !== index));
                                    } else {
                                        diceStateFunc([...diceState, index]);
                                    }
                                }
                            }}
                        >
                            {roll}
                        </div>
                    ))}
                </div>
                {(DicePoker.player1.replacedDices === null ||
                    DicePoker.player2.replacedDices === null) && (
                    <div className="combat-roll m-t-5">
                        {canRoll
                            ? Player.replacedDices === null
                                ? 'Vyber kocky na druhý hod.'
                                : 'Čakaj na oponenta...'
                            : Player.replacedDices === null
                            ? 'Hráč vyberá kocky, ktoré hodí znova.'
                            : 'Vybral kocky na druhý hod, a čaká na oponenta'}
                    </div>
                )}
                {canRoll && Player.replacedDices === null && (
                    <Button
                        color="link"
                        onClick={() => {
                            pokerReplaceDices({
                                variables: {
                                    player: player === 'player1' ? 1 : 2,
                                    indexes: diceState,
                                },
                            });
                            diceStateFunc([]);
                        }}
                    >
                        {diceState.length === 0 ? 'Pokračovať so súčasnými' : 'Nahradiť vybrané'}
                    </Button>
                )}
            </Col>
        );
    };

    const RerollDices = () => (
        <Empty>
            <div className="combat-roll text-center m-t-5">Hráči hádžu znova kockami...</div>
            <div className="flex-row">
                {PlayerRerollDices('player1')}
                {PlayerRerollDices('player2')}
            </div>
        </Empty>
    );

    const PlayerRerollDices = (player) => {
        const Player = DicePoker[player];
        const rolls = Player.rolls;
        const replacedDices = Player.replacedDices;
        const canRoll = (Player.id === null && Dm) || Player.id === currentUser.id;
        return (
            <Col className="flex-row">
                {rolls.map((roll, index) => {
                    if (
                        replacedDices.some((replacement) => replacement.index === index) &&
                        replacedDices.find((replacement) => replacement.index === index).newRoll ===
                            null
                    ) {
                        return (
                            <d6.dice
                                key={index}
                                className="clickable height-50 p-r-10"
                                onClick={() => (canRoll ? rethrowDice(player, index) : () => {})}
                            />
                        );
                    } else {
                        return (
                            <div
                                key={index}
                                className="dice-poker-result"
                                style={{
                                    color: getRemainingColor(
                                        replacedDices.some(
                                            (replacement) => replacement.index === index
                                        )
                                            ? replacedDices.find(
                                                  (replacement) => replacement.index === index
                                              ).newRoll
                                            : roll,
                                        6,
                                        true,
                                        3
                                    ),
                                    borderColor: getRemainingColor(
                                        replacedDices.some(
                                            (replacement) => replacement.index === index
                                        )
                                            ? replacedDices.find(
                                                  (replacement) => replacement.index === index
                                              ).newRoll
                                            : roll,
                                        6,
                                        true,
                                        3
                                    ),
                                }}
                            >
                                {replacedDices.some((replacement) => replacement.index === index)
                                    ? replacedDices.find(
                                          (replacement) => replacement.index === index
                                      ).newRoll
                                    : roll}
                            </div>
                        );
                    }
                })}
            </Col>
        );
    };

    const Results = () => {
        const {
            p1Total,
            p2Total,
            p1Calculation,
            p2Calculation,
            p1Occurances,
            p2Occurances,
            player1SortedFinalDices,
            player2SortedFinalDices,
        } = getTotals(true);
        const Player1 = DicePoker.player1;
        const Player2 = DicePoker.player2;

        const Winner = p1Total > p2Total ? Player1 : p1Total < p2Total ? Player2 : null;

        return (
            <Empty>
                <div className="combat-roll text-center m-t-5">Výsledok kola...</div>
                <div className="flex-row">
                    <Col>
                        <div className="flex-row">
                            {player1SortedFinalDices.map((roll, index) => (
                                <div
                                    key={index}
                                    className="dice-poker-result"
                                    style={{
                                        color:
                                            p1Occurances[roll] === 1
                                                ? 'grey'
                                                : getRemainingColor(roll, 6, true, 3),
                                        borderColor:
                                            p1Occurances[roll] === 1
                                                ? 'grey'
                                                : getRemainingColor(roll, 6, true, 3),
                                    }}
                                >
                                    {roll}
                                </div>
                            ))}
                        </div>
                        <div className="combat-roll text-center m-t-5">
                            {formattedMessage(p1Calculation)}
                        </div>
                    </Col>
                    <Col>
                        <div className="flex-row">
                            {player2SortedFinalDices.map((roll, index) => (
                                <div
                                    key={index}
                                    className="dice-poker-result"
                                    style={{
                                        color:
                                            p2Occurances[roll] === 1
                                                ? 'grey'
                                                : getRemainingColor(roll, 6, true, 3),
                                        borderColor:
                                            p2Occurances[roll] === 1
                                                ? 'grey'
                                                : getRemainingColor(roll, 6, true, 3),
                                    }}
                                >
                                    {roll}
                                </div>
                            ))}
                        </div>
                        <div className="combat-roll text-center m-t-5">
                            {formattedMessage(p2Calculation)}
                        </div>
                    </Col>
                </div>
                <div className="combat-roll text-center m-t-5">
                    {formattedMessage([
                        'Výhercom kola je... ',
                        {
                            text:
                                Winner === null
                                    ? 'Nikto! Čas na nové kolo!'
                                    : `${Winner.nickname}!`,
                            className: 'text-bolder',
                            style: { color: Winner === null ? 'red' : Winner.color },
                        },
                    ])}
                </div>
                {Winner?.wins === 1 && (
                    <div className="combat-roll text-center m-t-5">
                        {formattedMessage([
                            {
                                text: Winner.nickname,
                                className: 'text-bolder font-18 m-r-10',
                                style: { color: Winner.color },
                            },
                            'je výhercom celej hry!',
                        ])}
                    </div>
                )}
                {Dm && (
                    <div className="flex-row">
                        <Button
                            color="warning"
                            className="center-ver m-t-10"
                            onClick={() =>
                                endRound(p1Total > p2Total ? 1 : p1Total < p2Total ? 2 : null)
                            }
                        >
                            Ukončiť {Winner?.wins === 1 ? 'hru' : 'kolo'}!
                        </Button>
                    </div>
                )}
            </Empty>
        );
    };

    return (
        <Card className="flex min-width-700">
            <CardBody>
                <h4 className="text-center color-green m-b-20">
                    {Dm && (
                        <Empty>
                            <i
                                id="cancel-game"
                                className={classnames(
                                    { 'clickable': Dm },
                                    'fa fa-cancel color-red m-r-10'
                                )}
                                onClick={() => {
                                    if (Dm) {
                                        reset();
                                    }
                                }}
                            />
                            <Tooltip placement="top" target="cancel-game" text="Zrušiť hru" />
                        </Empty>
                    )}
                    Kockový poker
                </h4>
                {DicePoker.part === 0 && PickingCharacters()}
                {DicePoker.part !== 0 && GameInfo()}
                <hr />
                {DicePoker.part === 1 && Dices()}
                {DicePoker.part === 2 && OrderedDices()}
                {DicePoker.part === 2 &&
                    DicePoker.player1.replacedDices !== null &&
                    DicePoker.player2.replacedDices !== null && (
                        <Empty>
                            {RerollDices()}
                            {DicePoker.player1.replacedDices.every(
                                (dice) => dice.newRoll !== null
                            ) &&
                                DicePoker.player2.replacedDices.every(
                                    (dice) => dice.newRoll !== null
                                ) &&
                                Results()}
                        </Empty>
                    )}
                <hr />
            </CardBody>
        </Card>
    );
}
