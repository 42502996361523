import React from 'react';
import { formattedMessage, sortBy, timestampToPracticalDate } from 'helperFunctions';
import { Button } from 'reactstrap';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import {
    DELETE_GM_NOTIFICATION,
    DELETE_GM_NOTIFICATIONS,
    SET_GM_NOTIFICATIONS_READ,
    SET_GM_NOTIFICATION_READ,
    USERS,
    USERS_SUBSCRIPTION,
} from 'queries';

export default function Notifications({ GMNotifications }) {
    const [setGMNotificationRead] = useMutation(SET_GM_NOTIFICATION_READ);
    const [deleteGMNotification] = useMutation(DELETE_GM_NOTIFICATION);
    const [setGMNotificationsRead] = useMutation(SET_GM_NOTIFICATIONS_READ);
    const [deleteGMNotifications] = useMutation(DELETE_GM_NOTIFICATIONS);

    const {
        data: usersData,
        loading: usersLoading,
        refetch: usersRefetch,
    } = useQuery(USERS, {
        fetchPolicy: 'network-only',
        variables: {
            dm: true,
            includeInactive: true,
        },
    });

    useSubscription(USERS_SUBSCRIPTION, {
        onData: () => {
            usersRefetch({
                dm: true,
                includeInactive: true,
            });
        },
    });

    const Users = usersLoading ? [] : usersData.users;

    const renderNotification = (Notification) => {
        const extraData = JSON.parse(Notification.extra);
        const replacedMessage = [
            `[${timestampToPracticalDate(Notification.createdAt)}] `,
            ...Notification.message,
        ].map((part) => {
            switch (part) {
                case '$character2':
                case '$character1': {
                    const User = Users.find(
                        (User) => User.id === extraData[part.substring(1, part.length)]
                    );
                    return {
                        text: User ? User.character : 'Načítava sa...',
                        className: 'text-bolder',
                        style: { color: User ? User.color : 'grey' },
                    };
                }
                case '$newLine': {
                    return '\n';
                }
                case '$genericItem1':
                case '$genericItem2':
                case '$genericItem3':
                case '$genericItem4': {
                    return {
                        text: extraData[part.substring(1, part.length)],
                        className: 'text-bolder',
                    };
                }
                default: {
                    return part;
                }
            }
        });

        return (
            <div
                className="p-10 double-border flex-row font-12 m-t-5"
                style={{ borderColor: Notification.read ? 'grey' : 'green' }}
                key={Notification.id}
            >
                <div className="flex">{formattedMessage(replacedMessage)}</div>
                <div width="50">
                    {!Notification.read && (
                        <i
                            className="fa fa-eye font-17 color-gold clickable center-hor m-r-5"
                            onClick={() =>
                                setGMNotificationRead({ variables: { id: Notification.id } })
                            }
                        />
                    )}
                    <i
                        className="fa fa-times font-17 color-red clickable center-hor"
                        onClick={() => {
                            deleteGMNotification({ variables: { id: Notification.id } });
                        }}
                    />
                </div>
            </div>
        );
    };

    return (
        <div className="p-l-15 p-r-15 width-400">
            <div className="max-height-50vh overflow-x">
                {sortBy(GMNotifications, [{ key: 'createdAt', asc: false }]).map((Notification) =>
                    renderNotification(Notification)
                )}
            </div>
            {GMNotifications.length === 0 && (
                <div className="text-bolder color-muted text-center">Žiadne notifikácie!</div>
            )}
            {GMNotifications.length > 0 && (
                <div className="flex-row m-b-5 m-t-10">
                    <Button
                        className="center-ver"
                        color="success"
                        onClick={() => {
                            if (window.confirm('Si si istý?')) {
                                setGMNotificationsRead();
                            }
                        }}
                    >
                        Označiť ako prečítané
                    </Button>
                    <Button
                        className="center-ver"
                        color="danger"
                        onClick={() => {
                            if (window.confirm('Si si istý?')) {
                                deleteGMNotifications();
                            }
                        }}
                    >
                        Vymazať všetky
                    </Button>
                </div>
            )}
        </div>
    );
}
