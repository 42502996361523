import { gql } from '@apollo/client';
export const GM_NOTIFICATIONS = gql`
    query GMNotifications {
        GMNotifications {
            createdAt
            extra
            id
            message
            read
            type
            updatedAt
        }
    }
`;
export const SET_GM_NOTIFICATION_READ = gql`
    mutation setGMNotificationRead($id: Int!) {
        setGMNotificationRead(id: $id) {
            id
        }
    }
`;
export const DELETE_GM_NOTIFICATION = gql`
    mutation deleteGMNotification($id: Int!) {
        deleteGMNotification(id: $id) {
            id
        }
    }
`;
export const SET_GM_NOTIFICATIONS_READ = gql`
    mutation setGMNotificationsRead {
        setGMNotificationsRead
    }
`;
export const DELETE_GM_NOTIFICATIONS = gql`
    mutation deleteGMNotifications {
        deleteGMNotifications
    }
`;

export const GM_NOTIFICATIONS_SUBSCRIPTION = gql`
    subscription GMNotificationsSubscription {
        GMNotificationsSubscription
    }
`;
