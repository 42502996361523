import { GetMyRights, filterUnique } from 'helperFunctions';
import OpponentDisplay from './opponent';
import React from 'react';
import { Button, Input, Label } from 'reactstrap';
import { Empty } from 'components/Empty';
import { START_COMBAT } from 'queries';
import { useMutation } from '@apollo/client';
import Select from 'react-select';
import { Image } from 'components/Image';
import { Tooltip } from 'components/tooltip';

export const renderBattlefieldOption = (Battlefield) => {
    return {
        ...Battlefield,
        value: Battlefield.id,
        label: (
            <Empty>
                <div id={`show-battlefield-miniature-${Battlefield.id}`}>{Battlefield.title}</div>

                <Tooltip
                    placement="right"
                    target={`show-battlefield-miniature-${Battlefield.id}`}
                    text={<Image path={Battlefield.battlefield} width={500} />}
                    style={{ backgroundColor: 'transparent' }}
                />
            </Empty>
        ),
    };
};

export const renderBattlefieldOptions = (Battlefields) => {
    return Battlefields.map(renderBattlefieldOption);
};

export default function CombatPreparation({ CombatSimulator, selectedOpponent, Battlefields }) {
    const [startCombat] = useMutation(START_COMBAT);
    const [battlefield, setBattlefield] = React.useState({ value: null, label: 'Žiadne' });
    const [combatTitle, setCombatTitle] = React.useState('');

    const { Dm } = GetMyRights();

    const canStartCombat = filterUnique(CombatSimulator.opponents, 'team').length > 1;

    return (
        <Empty>
            <div className="flex-row m-t-5">
                {selectedOpponent !== null &&
                    CombatSimulator.opponents.some(
                        (Opponent) => Opponent.id === selectedOpponent
                    ) && (
                        <OpponentDisplay
                            type="Oponent"
                            Opponent={CombatSimulator.opponents.find(
                                (Opponent) => Opponent.id === selectedOpponent
                            )}
                        />
                    )}
            </div>
            {Dm ? (
                <Empty>
                    <div className="flex-row">
                        <Label className="center-hor m-r-10">Nazvi boj:</Label>
                        <Input
                            className="width-350 m-r-10"
                            value={combatTitle}
                            onChange={(e) => setCombatTitle(e.target.value)}
                        />
                        <Select
                            value={battlefield}
                            options={[
                                { value: null, label: 'Žiadne' },
                                ...renderBattlefieldOptions(Battlefields),
                            ]}
                            placeholder="Bojovisko"
                            onChange={(Battlefield) => {
                                setBattlefield(Battlefield);
                            }}
                        />
                        <Button
                            color="success"
                            className="m-l-10"
                            disabled={!canStartCombat || combatTitle.length < 5}
                            onClick={() => {
                                startCombat({
                                    variables: {
                                        title: combatTitle,
                                        battlefieldId: battlefield.value,
                                    },
                                });
                            }}
                        >
                            Začať bitku
                        </Button>
                    </div>
                    {battlefield.value !== null && (
                        <Image path={battlefield?.battlefield} className="m-b-20 m-t-20" />
                    )}
                </Empty>
            ) : (
                <div>Čaká sa na začiatok bitky...</div>
            )}
        </Empty>
    );
}
