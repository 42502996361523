import React from 'react';
import { Image, Tooltip } from 'components';
import { Button, Card, CardHeader, CardBody, Label } from 'reactstrap';
import classnames from 'classnames';
import { stringToDiv } from 'helperFunctions';
import { OCCUPATION, OCCUPATIONS_SUBSCRIPTION } from 'queries';
import { useQuery, useSubscription } from '@apollo/client';
import { GET_LIBRARY_OPEN } from 'apollo/queries';
import { setShowLibraryList } from 'apollo/localSchema/actions';

export default function OccupationEntry({ id }) {
    const { data: libraryOpenData } = useQuery(GET_LIBRARY_OPEN);
    const ShowLibraryList = libraryOpenData.ShowLibraryList;
    const {
        data: occupationData,
        loading: occupationLoading,
        refetch: occupationRefetch,
    } = useQuery(OCCUPATION, {
        fetchPolicy: 'network-only',
        variables: {
            id,
        },
    });

    useSubscription(OCCUPATIONS_SUBSCRIPTION, {
        onData: () => {
            occupationRefetch({ variables: { id } });
        },
    });

    const Occupation = occupationLoading ? null : occupationData.occupation;
    if (!Occupation) {
        return <div>Načítava sa...</div>;
    }

    return (
        <Card className="library-right">
            <CardHeader>
                <Button color="link" onClick={setShowLibraryList} id="show-hide-library-list">
                    <i
                        className={classnames({
                            'fa fa-arrow-left color-blue': ShowLibraryList,
                            'fa fa-arrow-right color-blue': !ShowLibraryList,
                        })}
                    />
                    <i className="m-l-2 fa fa-book-atlas color-blue" />
                </Button>
                <Tooltip
                    occupationment="top"
                    target="show-hide-library-list"
                    text={ShowLibraryList ? 'Schovať knižnicu' : 'Zobraziť knižnicu'}
                />
                {`${Occupation.title} (Povolania)`}
            </CardHeader>
            <CardBody className="p-20">
                <h4 className="flex-row">{Occupation.title}</h4>
                <div className="flex-row m-t-20">
                    <Image width={250} height={400} path={Occupation.icon} hideEmpty showFull />
                    <div className="font-25 m-l-20">
                        {' '}
                        {Occupation.magic && <Label>Ovláda mágiu</Label>}
                        {stringToDiv(Occupation.description)}
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}
