import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Label, Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { Empty, Hide } from 'components';
import { GetMyData, GetSettings, GetMyRights } from 'helperFunctions';
import { LOGOUT_USER, TEST_MODE } from 'queries';
import { useMutation } from '@apollo/client';
import { setAsAdmin, setAsDm, setAsUser } from 'apollo/localSchema/actions';
import Profile from './profile';
const defNavClass = 'nav-link color-white text-bolder m-l-5 m-r-5';

export default function PageHeader() {
    const [logoutUser] = useMutation(LOGOUT_USER);
    const [updateTestMode] = useMutation(TEST_MODE);

    const [settingsOpen, setSettingsOpen] = React.useState(false);
    const [profileOpen, setProfileOpen] = React.useState(false);
    const [testModeOpen, setTestModeOpen] = React.useState(false);

    const currentUser = GetMyData();
    const { Admin, Dm } = GetMyRights();
    const { gamePaused, testMode } = GetSettings();

    const test = async () => {};

    return (
        <div className="page-header">
            <NavLink
                className={({ isActive }) => (isActive ? `${defNavClass} active` : defNavClass)}
                to={`/`}
            >
                Hra
            </NavLink>
            <NavLink
                className={({ isActive }) => (isActive ? `${defNavClass} active` : defNavClass)}
                to={'/party'}
            >
                Partia
            </NavLink>
            <NavLink
                className={({ isActive }) => (isActive ? `${defNavClass} active` : defNavClass)}
                to={'/library'}
            >
                Knižnica
            </NavLink>
            {!currentUser.dm && (
                <NavLink
                    className={({ isActive }) => (isActive ? `${defNavClass} active` : defNavClass)}
                    to={'/crafting'}
                >
                    Výroba
                </NavLink>
            )}
            <NavLink
                className={({ isActive }) =>
                    isActive ? `${defNavClass} active m-l-30` : `${defNavClass} m-l-30`
                }
                to={'/noGameArea'}
            >
                Charakter a poznámky
            </NavLink>
            <NavLink
                className={({ isActive }) => (isActive ? `${defNavClass} active` : defNavClass)}
                to={'/gameArea'}
            >
                Herná plocha
            </NavLink>

            <span className="m-l-auto m-r-auto page-title" onClick={test}>
                Elungia
                {gamePaused && <span className="color-red text-bolder m-l-5">Hra pozastavená</span>}
            </span>
            <span className="m-l-auto flex-row">
                {currentUser.admin && (
                    <Dropdown isOpen={testModeOpen} toggle={() => {}} className="settings-dropdown">
                        <DropdownToggle
                            className="settings-dropdown-toggle"
                            onClick={() => setTestModeOpen(!testModeOpen)}
                        >
                            <i style={{ marginTop: 5 }} className="fa fa-ghost color-blue" />
                        </DropdownToggle>
                        <DropdownMenu end>
                            <div className="p-20 width-500">
                                <div className="flex-row">
                                    <Button
                                        className="m-l-5 m-b-10 flex"
                                        color={testMode.active ? 'danger' : 'success'}
                                        onClick={() => {
                                            updateTestMode({
                                                variables: { active: !testMode.active },
                                            });
                                        }}
                                    >
                                        {testMode.active ? 'Aktívny' : 'Off'}
                                    </Button>
                                    <Button
                                        className="m-l-5 m-b-10 flex"
                                        color={testMode.strict ? 'warning' : 'secondary'}
                                        onClick={() => {
                                            updateTestMode({
                                                variables: {
                                                    strict: !testMode.strict,
                                                    poetic: false,
                                                    advantage: false,
                                                },
                                            });
                                        }}
                                    >
                                        Strict {testMode.strict ? 'Yes' : 'No'}
                                    </Button>
                                </div>
                                <div className="flex-row">
                                    <Button
                                        className="m-l-5 m-b-10 flex"
                                        color={testMode.poetic ? 'warning' : 'secondary'}
                                        onClick={() => {
                                            updateTestMode({
                                                variables: {
                                                    strict: false,
                                                    poetic: !testMode.poetic,
                                                    advantage: false,
                                                },
                                            });
                                        }}
                                    >
                                        Poetic {testMode.poetic ? 'Yes' : 'No'}
                                    </Button>
                                    <Button
                                        className="m-l-5 m-b-10 flex"
                                        color={testMode.advantage ? 'warning' : 'secondary'}
                                        onClick={() => {
                                            updateTestMode({
                                                variables: {
                                                    strict: false,
                                                    poetic: false,
                                                    advantage: !testMode.advantage,
                                                },
                                            });
                                        }}
                                    >
                                        Advantage {testMode.advantage ? 'Yes' : 'No'}
                                    </Button>
                                </div>
                                <div className="flex-row m-t-10">
                                    {[1, 2, 3, 4].map((num) => (
                                        <Button
                                            className="m-r-5"
                                            color={testMode.d4 === num ? 'success' : 'secondary'}
                                            key={num}
                                            onClick={() => {
                                                updateTestMode({
                                                    variables: { d4: num },
                                                });
                                            }}
                                        >
                                            {num}
                                        </Button>
                                    ))}
                                </div>
                                <div className="flex-row m-t-10">
                                    {[1, 2, 3, 4, 5, 6].map((num) => (
                                        <Button
                                            className="m-r-5"
                                            color={testMode.d6 === num ? 'success' : 'secondary'}
                                            key={num}
                                            onClick={() => {
                                                updateTestMode({
                                                    variables: { d6: num },
                                                });
                                            }}
                                        >
                                            {num}
                                        </Button>
                                    ))}
                                </div>
                                <div className="flex-row m-t-10">
                                    {[1, 2, 3, 4, 5, 6, 7, 8].map((num) => (
                                        <Button
                                            className="m-r-5"
                                            color={testMode.d8 === num ? 'success' : 'secondary'}
                                            key={num}
                                            onClick={() => {
                                                updateTestMode({
                                                    variables: { d8: num },
                                                });
                                            }}
                                        >
                                            {num}
                                        </Button>
                                    ))}
                                </div>
                                <div className="flex-row m-t-10">
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
                                        <Button
                                            className="m-r-5"
                                            color={testMode.d10 === num ? 'success' : 'secondary'}
                                            key={num}
                                            onClick={() => {
                                                updateTestMode({
                                                    variables: { d10: num },
                                                });
                                            }}
                                        >
                                            {num}
                                        </Button>
                                    ))}
                                </div>
                                <div className="flex-row m-t-10">
                                    {[1, 20, 30, 40, 50, 60, 70, 80, 90, 95].map((num) => (
                                        <Button
                                            className="m-r-5"
                                            color={testMode.d100 === num ? 'success' : 'secondary'}
                                            key={num}
                                            onClick={() => {
                                                updateTestMode({
                                                    variables: { d100: num },
                                                });
                                            }}
                                        >
                                            {num}
                                        </Button>
                                    ))}
                                </div>
                            </div>
                        </DropdownMenu>
                    </Dropdown>
                )}
                {!currentUser.dm && (
                    <Dropdown
                        isOpen={profileOpen}
                        toggle={() => setProfileOpen(!profileOpen)}
                        className="settings-dropdown"
                    >
                        <DropdownToggle className="settings-dropdown-toggle">
                            <i style={{ marginTop: 5 }} className="fa fa-user" /> Profil
                        </DropdownToggle>
                        <DropdownMenu end>
                            <Profile />
                        </DropdownMenu>
                    </Dropdown>
                )}
                <Dropdown
                    isOpen={settingsOpen}
                    toggle={() => setSettingsOpen(!settingsOpen)}
                    className="settings-dropdown"
                >
                    <DropdownToggle className="settings-dropdown-toggle">
                        <i style={{ marginTop: 5 }} className="fa fa-cog" />
                    </DropdownToggle>
                    <DropdownMenu end>
                        <div className="settings-box">
                            <Label className="overflow-x-hidden text-bolder">
                                Prihlásený ako {currentUser.nickname}
                            </Label>
                            {(currentUser.admin || Admin || Dm) && <DropdownItem divider />}
                            {currentUser.admin && (
                                <Empty>
                                    <Button
                                        className="m-l-5 m-b-10"
                                        color="primary"
                                        onClick={() => {
                                            setAsAdmin();
                                        }}
                                    >
                                        Admin
                                    </Button>
                                    <Button
                                        className="m-l-5 m-b-10"
                                        color="danger"
                                        onClick={() => {
                                            setAsDm();
                                        }}
                                    >
                                        DM
                                    </Button>
                                    <Button
                                        className="m-l-5 m-b-10"
                                        color="success"
                                        onClick={() => {
                                            setAsUser();
                                        }}
                                    >
                                        Hráč
                                    </Button>
                                </Empty>
                            )}
                            <Hide adminOrDm>
                                <DropdownItem>
                                    <NavLink
                                        className={({ isActive }) =>
                                            isActive ? 'nav-link active' : 'nav-link'
                                        }
                                        to={`/nastavenia_pouzivatelov`}
                                    >
                                        Spravovať používateľov
                                    </NavLink>
                                </DropdownItem>
                                <DropdownItem>
                                    <NavLink
                                        className={({ isActive }) =>
                                            isActive ? 'nav-link active' : 'nav-link'
                                        }
                                        to={`/nastavenia_hry/rasy`}
                                    >
                                        Spravovať hru
                                    </NavLink>
                                </DropdownItem>

                                <DropdownItem divider />
                            </Hide>
                            <div>
                                <Button
                                    color="danger"
                                    style={{ width: '100%' }}
                                    onClick={() => {
                                        if (window.confirm('Naozaj sa chceš odhlásiť?')) {
                                            logoutUser().then(() => {
                                                window.location.reload(false);
                                            });
                                        }
                                    }}
                                >
                                    <i className="fa fa-sign-out-alt" /> Odhlásiť
                                </Button>
                            </div>
                        </div>
                    </DropdownMenu>
                </Dropdown>
            </span>
        </div>
    );
}
