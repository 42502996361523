import { gql } from '@apollo/client';

export const MAP = gql`
    query map($id: Int!) {
        map(id: $id) {
            id
            title
            visibility
            updatedAt
            map
            description
            createdAt
        }
    }
`;

export const MAPS = gql`
    query maps($visibleOnly: Boolean) {
        maps(visibleOnly: $visibleOnly) {
            id
            title
            visibility
            map
        }
    }
`;
export const ADD_MAP = gql`
    mutation addMap($title: String, $description: String, $visibility: Boolean, $map: String) {
        addMap(title: $title, description: $description, visibility: $visibility, map: $map) {
            id
        }
    }
`;
export const UPDATE_MAP = gql`
    mutation updateMap(
        $id: Int!
        $title: String
        $description: String
        $visibility: Boolean
        $map: String
    ) {
        updateMap(
            id: $id
            title: $title
            description: $description
            visibility: $visibility
            map: $map
        ) {
            id
        }
    }
`;
export const DELETE_MAP = gql`
    mutation deleteMap($id: Int!) {
        deleteMap(id: $id) {
            id
        }
    }
`;

export const MAPS_SUBSCRIPTION = gql`
    subscription mapsSubscription {
        mapsSubscription
    }
`;
