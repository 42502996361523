import React from 'react';
import { Card, CardHeader, CardBody, Button, FormGroup, Label, Col, Input } from 'reactstrap';
import { Checkbox, ImageInput } from 'components';
import Textarea from 'react-textarea-autosize';
import { toBase64 } from 'helperFunctions';
import { ADD_MAP } from 'queries';
import { useMutation } from '@apollo/client';

export default function MapAdd() {
    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [visibility, setVisibility] = React.useState(false);
    const [map, setMap] = React.useState(null);
    const [adding, setAdding] = React.useState(false);

    const [addMap] = useMutation(ADD_MAP);

    const add = async () => {
        setAdding(true);
        let body = {
            title,
            description,
            visibility,
            important: false,
        };

        if (map) {
            body.map = await toBase64(map);
        }

        addMap({ variables: body })
            .then(() => {
                setTitle('');
                setDescription('');
                setVisibility(false);
                setAdding(false);
            })
            .catch((e) => {
                setAdding(false);
                console.log(e);
            });
    };

    return (
        <div className="settings-right">
            <Card>
                <CardHeader className="flex-row">Nová mapa</CardHeader>
                <CardBody>
                    <FormGroup className="flex-row">
                        <Label for="occupation" sm={2}>
                            Názov
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="occupation"
                                value={title}
                                placeholder="Zadaj názov mapy"
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="description" sm={2}>
                            Popis
                        </Label>
                        <Col sm={10}>
                            <Textarea
                                className="form-control"
                                id="description"
                                placeholder="Zadaj popis oblasti"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Checkbox
                            className="form-control-checkbox"
                            label="Spoznali mapu"
                            value={visibility}
                            right
                            centerHor
                            onChange={() => setVisibility(!visibility)}
                        />
                    </FormGroup>
                    <ImageInput
                        label="Mapa"
                        id="map-new"
                        setImage={setMap}
                        image={map}
                        placeholder="Vyberte mapu"
                        height={400}
                    />
                    <div className="flex-row">
                        <Button color="success" disabled={adding || map === null} onClick={add}>
                            {adding ? 'Pridáva sa...' : 'Pridať mapu'}
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
