import { gql } from '@apollo/client';

export const SPELL = gql`
    query spell($id: Int!) {
        spell(id: $id) {
            id
            createdAt
            updatedAt
            title
            description
            visibility
            icon
            level
            mageClass {
                label
                id
            }
        }
    }
`;

export const SPELLS = gql`
    query spells($visibleOnly: Boolean) {
        spells(visibleOnly: $visibleOnly) {
            id
            level
            mageClass {
                id
                label
            }
            title
            visibility
        }
    }
`;

export const MAGE_CLASSES = gql`
    query mageClasses {
        mageClasses {
            id
            label
        }
    }
`;

export const ADD_SPELL = gql`
    mutation addSpell(
        $title: String
        $description: String
        $visibility: Boolean
        $icon: String!
        $level: Int
        $mageClass: Int
    ) {
        addSpell(
            title: $title
            description: $description
            visibility: $visibility
            icon: $icon
            level: $level
            mageClass: $mageClass
        ) {
            id
        }
    }
`;
export const UPDATE_SPELL = gql`
    mutation updateSpell(
        $id: Int!
        $title: String
        $description: String
        $visibility: Boolean
        $icon: String
        $removeIcon: Boolean
        $level: Int
        $mageClass: Int
    ) {
        updateSpell(
            id: $id
            title: $title
            description: $description
            visibility: $visibility
            icon: $icon
            removeIcon: $removeIcon
            level: $level
            mageClass: $mageClass
        ) {
            id
        }
    }
`;
export const DELETE_SPELL = gql`
    mutation deleteSpell($id: Int!) {
        deleteSpell(id: $id) {
            id
        }
    }
`;

export const SPELLS_SUBSCRIPTION = gql`
    subscription spellsSubscription {
        spellsSubscription
    }
`;
