import React from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Button,
    FormGroup,
    Label,
    Col,
    Input,
    Table,
} from 'reactstrap';
import classnames from 'classnames';
import Select from 'react-select';
import { Checkbox, ImageInput } from 'components';
import EditItem from './itemEdit';
import AddItem from './itemAdd';
import { updateArrayItem, toBase64, toSelArr } from 'helperFunctions';
import {
    ADD_TRADER,
    ITEMS,
    ITEMS_SUBSCRIPTION,
    RECIPES,
    RECIPES_SUBSCRIPTION,
    ITEM_TYPES,
} from 'queries';
import { useQuery, useSubscription, useMutation } from '@apollo/client';
import RecipeEdit from './recipeEdit';

export default function TraderAdd() {
    const [title, setTitle] = React.useState('');
    const [visibility, setVisibility] = React.useState(false);
    const [portrait, setPortrait] = React.useState(null);
    const [items, setItems] = React.useState([]);
    const [recipes, setRecipes] = React.useState([]);
    const [existingItem, setExistingItem] = React.useState(null);
    const [existingRecipe, setExistingRecipe] = React.useState(null);
    const [adding, setAdding] = React.useState(false);

    const [addTrader] = useMutation(ADD_TRADER);

    const {
        data: itemsData,
        loading: itemsLoading,
        refetch: itemsRefetch,
    } = useQuery(ITEMS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(ITEMS_SUBSCRIPTION, {
        onData: () => {
            itemsRefetch();
        },
    });

    const Items = itemsLoading ? [] : itemsData.items;

    const {
        data: recipesData,
        loading: recipesLoading,
        refetch: recipesRefetch,
    } = useQuery(RECIPES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(RECIPES_SUBSCRIPTION, {
        onData: () => {
            recipesRefetch();
        },
    });

    const Recipes = recipesLoading ? [] : recipesData.recipes;

    const { data: itemTypesData, loading: itemTypesLoading } = useQuery(ITEM_TYPES, {
        fetchPolicy: 'network-only',
    });

    const itemTypes = itemTypesLoading ? [] : itemTypesData.itemTypes;

    const add = async () => {
        setAdding(true);
        let body = {
            title,
            visibility,
            items: items.map((item) => ({ itemId: item.id, order: item.order })),
            recipes: recipes.map((recipe) => ({ recipeId: recipe.id, order: recipe.order })),
        };

        if (portrait) {
            body.portrait = await toBase64(portrait);
        }

        addTrader({ variables: body })
            .then(() => {
                setAdding(false);
                setTitle('');
                setVisibility(false);
                setItems([]);
                setRecipes([]);
            })
            .catch((e) => {
                setAdding(false);
            });
    };

    const newOrder =
        [...items, ...recipes].reduce((cur, item) => (cur > item.order ? cur : item.order), 0) + 1;

    return (
        <div className="settings-right">
            <Card>
                <CardHeader className="flex-row">Nový obchodník</CardHeader>
                <CardBody>
                    <FormGroup className="flex-row">
                        <Label for="occupation" sm={2}>
                            Meno
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="occupation"
                                value={title}
                                placeholder="Zadaj meno obchodníka"
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Checkbox
                            className="form-control-checkbox"
                            label="Spoznali obchodníka"
                            value={visibility}
                            right
                            centerHor
                            onChange={() => setVisibility(!visibility)}
                        />
                    </FormGroup>
                    <ImageInput
                        label="Druh"
                        id="traderPortrait-new"
                        setImage={setPortrait}
                        image={portrait}
                        placeholder="Vyberte potrét"
                        height={200}
                    />

                    <h4>Služby a predávané veci</h4>
                    <Table striped>
                        <thead>
                            <tr>
                                <th width="50">Ikona</th>
                                <th>Názov</th>
                                <th>Popis</th>
                                <th>Typ</th>
                                <th>Cena</th>
                                <th>Váha</th>
                                <th>Poradie</th>
                                <th width="110">Akcie</th>
                            </tr>
                        </thead>
                        <tbody>
                            {[
                                ...items.map((item) => ({ ...item, dataType: 'item' })),
                                ...recipes.map((recipe) => ({ ...recipe, dataType: 'recipe' })),
                            ]
                                .sort((item1, item2) => item1.order - item2.order)
                                .map((item) =>
                                    item.dataType === 'item' ? (
                                        <EditItem
                                            key={`item-${item.id}`}
                                            item={item}
                                            itemTypes={itemTypes}
                                            updateItem={(newItem) => {
                                                let index = items.findIndex(
                                                    (existingItem) => existingItem.id === newItem.id
                                                );
                                                let newItems = [...items];
                                                newItems[index] = newItem;
                                                setItems(newItems);
                                            }}
                                            removeFromTrader={(id) =>
                                                setItems(items.filter((item) => item.id !== id))
                                            }
                                            deleteItem={(id) => {
                                                setItems(items.filter((item) => item.id !== id));
                                            }}
                                        />
                                    ) : (
                                        <RecipeEdit
                                            key={`recipe-${item.id}`}
                                            recipe={item}
                                            removeFromTrader={() => {
                                                setRecipes(
                                                    recipes.filter(
                                                        (recipe) => recipe.id !== item.id
                                                    )
                                                );
                                            }}
                                            updateOrder={(order) => {
                                                setRecipes(
                                                    updateArrayItem(
                                                        recipes,
                                                        {
                                                            ...item,
                                                            order,
                                                        },
                                                        'id'
                                                    )
                                                );
                                            }}
                                        />
                                    )
                                )}
                            <AddItem
                                key="add"
                                itemTypes={itemTypes}
                                onSubmit={(item) => {
                                    setItems([...items, item]);
                                }}
                            />
                            <tr key="add-existing-item">
                                <td colSpan="2">Pridať existujúcu vec</td>
                                <td colSpan="5">
                                    <Select
                                        value={existingItem}
                                        onChange={(existingItem) => {
                                            setExistingItem(existingItem);
                                        }}
                                        options={toSelArr(Items).filter(
                                            (Item) => !items.some((item) => item.id === Item.id)
                                        )}
                                    />
                                </td>
                                <td>
                                    <Button
                                        color="none"
                                        disabled={existingItem === null}
                                        onClick={() => {
                                            setItems([
                                                ...items,
                                                {
                                                    ...existingItem,
                                                    order: newOrder,
                                                },
                                            ]);
                                            setExistingItem(null);
                                        }}
                                    >
                                        <i
                                            className={classnames('fa fa-plus', {
                                                'success': existingItem !== null,
                                            })}
                                        />
                                    </Button>
                                </td>
                            </tr>
                            <tr key="add-existing-recipe">
                                <td colSpan="2">Pridať existujúci recept</td>
                                <td colSpan="5">
                                    <Select
                                        value={existingRecipe}
                                        onChange={(existingRecipe) => {
                                            setExistingRecipe(existingRecipe);
                                        }}
                                        options={toSelArr(Recipes).filter(
                                            (Recipe) =>
                                                !recipes.some((recipe) => recipe.id === Recipe.id)
                                        )}
                                    />
                                </td>
                                <td>
                                    <Button
                                        color="none"
                                        disabled={existingRecipe === null}
                                        onClick={() => {
                                            setRecipes([
                                                ...recipes,
                                                {
                                                    ...existingRecipe,
                                                    order: newOrder,
                                                },
                                            ]);
                                            setExistingRecipe(null);
                                        }}
                                    >
                                        <i
                                            className={classnames('fa fa-plus', {
                                                'success': existingRecipe !== null,
                                            })}
                                        />
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>

                    <div className="flex-row">
                        <Button color="success" disabled={adding || title.length < 2} onClick={add}>
                            {adding ? 'Pridáva sa...' : 'Pridať obchodníka'}
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
