import React from 'react';
import { Popover, FormGroup, Label, Button, Input } from 'reactstrap';
import Select from 'react-select';
import { ADD_TO_INVENTORY, ITEMS, ITEMS_SUBSCRIPTION, ITEM_TYPES } from 'queries';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { toSelArr } from 'helperFunctions';

const AllItemTypes = { id: null, value: null, label: 'Všetky' };

export default function AddInventoryItem(props) {
    const { cleanup, addToCleanup, open, id } = props;

    const [category, setCategory] = React.useState(AllItemTypes);
    const [newItem, setNewItem] = React.useState(null);
    const [quantity, setQuantity] = React.useState(1);
    const [processing, setProcessing] = React.useState(false);

    const [addToInventory] = useMutation(ADD_TO_INVENTORY);

    const { data: itemTypesData, loading: itemTypesLoading } = useQuery(ITEM_TYPES, {
        fetchPolicy: 'network-only',
    });

    const allItemTypes = itemTypesLoading
        ? [AllItemTypes]
        : [AllItemTypes, ...itemTypesData.itemTypes.filter((type) => type.id !== 1)];

    const {
        data: itemsData,
        loading: itemsLoading,
        refetch: itemsRefetch,
    } = useQuery(ITEMS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(ITEMS_SUBSCRIPTION, {
        onData: () => {
            itemsRefetch();
        },
    });

    const AllItems = itemsLoading ? [] : itemsData.items.filter((Item) => Item.type.id !== 1);

    React.useEffect(() => {
        addToCleanup({
            id: 'add',
            cleanup: () => {
                setCategory(allItemTypes[0]);
                setNewItem(null);
                setQuantity(1);
            },
        });
        // eslint-disable-next-line
    }, []);

    const add = async () => {
        setProcessing(true);
        addToInventory({
            variables: {
                userId: id,
                itemId: newItem.id,
                quantity: isNaN(parseInt(quantity)) ? 1 : parseInt(quantity),
            },
        })
            .then(() => {
                setNewItem(null);
                setQuantity(1);
                setProcessing(false);
            })
            .catch((e) => {
                console.log(e);
                setProcessing(false);
            });
    };

    return (
        <Popover
            className="popover-400"
            placement="top"
            isOpen={open}
            target={`add-item-btn-${id}`}
            toggle={cleanup}
        >
            <div className="p-20">
                <FormGroup>
                    <Label>Kategoria</Label>
                    <Select
                        value={category}
                        options={allItemTypes}
                        placeholder="Kategória"
                        onChange={(category) => {
                            setCategory(category);
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Vec</Label>
                    <Select
                        value={newItem}
                        options={
                            category.id !== null
                                ? toSelArr(AllItems).filter((Item) => Item.type.id === category.id)
                                : toSelArr(AllItems)
                        }
                        placeholder="Vec"
                        onChange={(item) => {
                            setNewItem(item);
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="selectedToolQuanity">Množstvo</Label>
                    <Input
                        type="number"
                        id="selectedToolQuanity"
                        value={quantity}
                        placeholder="Zadaj množstvo"
                        onChange={(e) => setQuantity(e.target.value)}
                    />
                </FormGroup>
                <div className="flex-row">
                    <Button
                        color="success"
                        disabled={
                            processing ||
                            newItem === null ||
                            isNaN(parseInt(quantity)) ||
                            parseInt(quantity) < 1
                        }
                        onClick={add}
                    >
                        {processing ? 'Pridáva sa...' : 'Pridať'}
                    </Button>

                    <Button color="warning" className="m-l-auto" onClick={cleanup}>
                        Zavrieť
                    </Button>
                </div>
            </div>
        </Popover>
    );
}
