import { Empty } from 'components';

export const stringToDiv = (string) => (
    <div>
        {string.split('\n').map((part, index) => (
            <div key={index}>{part}</div>
        ))}
    </div>
);

function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export const replaceFromString = (str, find = [], replacement = '') => {
    let newString = str;
    find.forEach(
        (occurance) =>
            (newString = newString.replace(new RegExp(escapeRegExp(occurance), 'g'), replacement))
    );
    return newString;
};

const randomSeparator = () => {
    const index = Math.floor(5 * Math.random());
    return ['!', '@', '$', '%', '#', '='][index];
};

export const randomSimpleString = () => {
    return (
        Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    );
};

export const randomString = () => {
    let basePass = `${(
        Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    ).substring(0, Math.floor(Math.random() * 5) + 3)}${randomSeparator()}${(
        Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    ).substring(0, Math.floor(Math.random() * 5) + 3)}`;
    for (let i = 0; i < basePass.length - 3; i++) {
        const randomChange = Math.floor(basePass.length * Math.random());
        basePass = `${basePass.substring(0, randomChange)}${basePass[
            randomChange
        ].toUpperCase()}${basePass.substring(randomChange + 1, basePass.length)}`;
    }
    return basePass;
};

export const formattedMessage = (messageData) => {
    return (
        <Empty>
            {messageData
                .filter((part) => part !== null)
                .map((part, index) => {
                    if (part === '\n') {
                        return <br key={index} />;
                    } else if (typeof part === 'number' || typeof part === 'string') {
                        return part;
                    } else {
                        return (
                            <span
                                key={index}
                                className={part.className ? part.className : ''}
                                style={part.style ? part.style : {}}
                            >
                                {part.text}
                            </span>
                        );
                    }
                })}
        </Empty>
    );
};

export const capitalizeFirstLetter = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};
