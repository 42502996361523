import React from 'react';
import { Empty, Image, Tooltip } from 'components';
import { Button, Card, CardHeader, CardBody, Input } from 'reactstrap';
import { GetMyData, GetSettings, timestampToString } from 'helperFunctions';
import classnames from 'classnames';
import {
    CRAFTINGS_SUBSCRIPTION,
    DELETE_CRAFTING,
    INVENTORY,
    INVENTORY_SUBSCRIPTION,
    ITEMS_SUBSCRIPTION,
    RECIPE,
    RECIPES_SUBSCRIPTION,
    REQUEST_CRAFTING,
} from 'queries';
import { GET_LIBRARY_OPEN } from 'apollo/queries';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { setShowLibraryList } from 'apollo/localSchema/actions';
export function Crafting({ inLibrary, recipeID }) {
    const [quantity, setQuantity] = React.useState(1);
    const [requestCrafting] = useMutation(REQUEST_CRAFTING);
    const [deleteCrafting] = useMutation(DELETE_CRAFTING);

    const currentUser = GetMyData();
    const { gamePaused } = GetSettings();
    const { data: libraryOpenData } = useQuery(GET_LIBRARY_OPEN);
    const ShowLibraryList = libraryOpenData.ShowLibraryList;
    const {
        data: inventoryData,
        loading: inventoryLoading,
        refetch: inventoryRefetch,
    } = useQuery(INVENTORY, {
        fetchPolicy: 'network-only',
        variables: {
            id: currentUser.id,
        },
    });

    useSubscription(INVENTORY_SUBSCRIPTION, {
        onData: () => {
            inventoryRefetch({ id: currentUser.id });
        },
        variables: {
            id: currentUser.id,
        },
    });
    const Inventory = inventoryLoading ? [] : inventoryData.inventory;
    const {
        data: recipeData,
        loading: recipeLoading,
        refetch: recipeRefetch,
    } = useQuery(RECIPE, {
        fetchPolicy: 'network-only',
        variables: {
            id: recipeID,
        },
    });

    useSubscription(ITEMS_SUBSCRIPTION, {
        onData: () => {
            recipeRefetch();
        },
    });
    useSubscription(CRAFTINGS_SUBSCRIPTION, {
        onData: () => {
            recipeRefetch();
        },
    });
    useSubscription(RECIPES_SUBSCRIPTION, {
        onData: () => {
            recipeRefetch();
        },
    });

    const Recipe = recipeLoading ? null : recipeData.recipe;

    if (recipeLoading) {
        return <div>Načítava sa...</div>;
    }

    const Quantity = isNaN(parseInt(quantity)) || parseInt(quantity) < 1 ? 1 : parseInt(quantity);

    const getAllRequired = () => {
        let allRequired = [];
        Recipe.ingredients.forEach((ingredient) => {
            const index = allRequired.findIndex((required) => required.id === ingredient.itemId);
            if (index !== -1) {
                allRequired[index].quantity =
                    allRequired[index].quantity + ingredient.quantity * Quantity;
            } else {
                allRequired.push({
                    id: ingredient.itemId,
                    quantity: ingredient.quantity * Quantity,
                    Item: ingredient,
                });
            }
        });
        Recipe.tools.forEach((tool) => {
            const index = allRequired.findIndex((required) => required.id === tool.itemId);
            if (index !== -1) {
                allRequired[index].quantity = allRequired[index].quantity + tool.quantity;
            } else {
                allRequired.push({
                    id: tool.itemId,
                    quantity: tool.quantity,
                    Item: tool,
                });
            }
        });
        return allRequired;
    };

    const getMissing = () => {
        return getAllRequired()
            .filter((required) => {
                const inventoryItem = Inventory.find((Item) => Item.itemId === required.id);
                return !inventoryItem || inventoryItem.quantity < required.quantity;
            })
            .map((required) => {
                const inventoryItem = Inventory.find((Item) => Item.itemId === required.id);
                if (!inventoryItem) {
                    return {
                        Item: required.Item,
                        needed: required.quantity,
                        has: 0,
                    };
                }
                return {
                    Item: required.Item,
                    needed: required.quantity,
                    has: inventoryItem.quantity,
                };
            });
    };

    const requestCraftingFunc = () => {
        requestCrafting({
            variables: {
                recipeId: recipeID,
                quantity: parseInt(quantity),
            },
        });
        setQuantity(1);
    };

    const deleteRequest = (id) => {
        deleteCrafting({ variables: { id } });
    };

    return (
        <Card className={classnames({ 'library-right': inLibrary })}>
            {inLibrary && (
                <CardHeader>
                    <Button color="link" onClick={setShowLibraryList} id="show-hide-library-list">
                        <i
                            className={classnames({
                                'fa fa-arrow-left color-blue': ShowLibraryList,
                                'fa fa-arrow-right color-blue': !ShowLibraryList,
                            })}
                        />
                        <i className="m-l-2 fa fa-book-atlas color-blue" />
                    </Button>
                    <Tooltip
                        placement="top"
                        target="show-hide-library-list"
                        text={ShowLibraryList ? 'Schovať knižnicu' : 'Zobraziť knižnicu'}
                    />
                    {`${Recipe.title} (Recepty)`}
                </CardHeader>
            )}
            <CardBody className="p-20 flex-row">
                <div className="flex-row flex">
                    <div className="flex">
                        <div className="text-italics m-b-10">{Recipe.description}</div>
                        <h4>{Recipe.title}</h4>
                        <Image height={200} path={Recipe.icon} />
                        {Recipe.ingredients.length > 0 && (
                            <Empty>
                                <h4 className="m-t-20">Ingrediencie</h4>
                                <div className="flex-row">
                                    {Recipe.ingredients.map((Item, index) => (
                                        <div className="flex-row font-25" key={Item.id}>
                                            <div className="center-hor m-r-10">{`${
                                                Item.quantity * Quantity
                                            } x`}</div>
                                            {Item.icon ? (
                                                <Image
                                                    height={70}
                                                    path={Item.icon}
                                                    hideEmpty
                                                    focus
                                                    customFocus={Item.title}
                                                />
                                            ) : (
                                                <div className="center-hor m-r-10">
                                                    {Item.title}
                                                </div>
                                            )}
                                            {Recipe.ingredients.length !== index + 1 && (
                                                <div className="center-hor m-l-10 m-r-10">+</div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <hr />
                            </Empty>
                        )}
                        {Recipe.tools.length > 0 && (
                            <Empty>
                                <h4>Nástroje</h4>
                                <div className="flex-row">
                                    {Recipe.tools.map((Item) => (
                                        <div className="flex-row font-25 m-r-20" key={Item.id}>
                                            <div className="center-hor m-r-10">{`${Item.quantity} x`}</div>
                                            {Item.icon ? (
                                                <Image
                                                    height={70}
                                                    path={Item.icon}
                                                    hideEmpty
                                                    focus
                                                    customFocus={Item.title}
                                                />
                                            ) : (
                                                <div className="center-hor m-r-10">
                                                    {Item.title}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <hr />
                            </Empty>
                        )}
                        {Recipe.results.length > 0 && (
                            <Empty>
                                <h4>Výsledky</h4>
                                <div className="flex-row">
                                    {Recipe.results.map((Item, index) => (
                                        <div className="flex-row font-25" key={Item.id}>
                                            <div className="center-hor m-r-10">{`${
                                                Item.quantity * Quantity
                                            } x`}</div>
                                            {Item.icon ? (
                                                <Image
                                                    height={70}
                                                    path={Item.icon}
                                                    hideEmpty
                                                    focus
                                                    customFocus={Item.title}
                                                />
                                            ) : (
                                                <div className="center-hor m-r-10">
                                                    {Item.title}
                                                </div>
                                            )}
                                            {Recipe.results.length !== index + 1 && (
                                                <div className="center-hor m-l-10 m-r-10">+</div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </Empty>
                        )}
                        {getMissing().length > 0 && (
                            <Empty>
                                <hr />
                                <h4 className="color-red">Chýbajúce veci</h4>
                                <div className="flex-row">
                                    {getMissing().map((Missing) => (
                                        <div
                                            className="flex-row font-25 m-r-20"
                                            key={Missing.Item.id}
                                        >
                                            <div className="center-hor m-r-10 color-red">{`${
                                                Missing.needed - Missing.has
                                            } x`}</div>
                                            {Missing.Item.icon ? (
                                                <Image
                                                    height={70}
                                                    path={Missing.Item.icon}
                                                    hideEmpty
                                                    focus
                                                    customFocus={Missing.Item.title}
                                                />
                                            ) : (
                                                <div className="center-hor m-r-10">
                                                    {Missing.Item.title}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </Empty>
                        )}
                    </div>
                </div>
                {!inLibrary && (
                    <div className="max-width-600 min-width-400">
                        <div className="flex-row">
                            <Input
                                className="width-150 font-20"
                                value={quantity}
                                onChange={(e) => setQuantity(e.target.value)}
                            />
                            <Button
                                color="success"
                                className="full-width m-l-20  font-20"
                                disabled={
                                    isNaN(parseInt(quantity)) ||
                                    parseInt(quantity) < 1 ||
                                    gamePaused
                                }
                                onClick={requestCraftingFunc}
                            >
                                Požiadať výrobu
                            </Button>
                        </div>
                        <h5 className="m-t-10">Predošlá výroba</h5>
                        {Recipe.craftings.map((Request) => (
                            <div className="double-border flex-row m-t-10 p-5" key={Request.id}>
                                <div className="text-bolder m-r-10 min-width-90">
                                    {timestampToString(Request.createdAt, 'dd.MM HH:mm')}
                                </div>
                                <div>
                                    {Request.quantity} x{' '}
                                    <span className="color-blue">{Recipe.title}</span>
                                </div>
                                <div className="m-l-auto text-bolder">
                                    {Request.denied
                                        ? 'Zamietnuté'
                                        : Request.crafted
                                        ? 'Vyrobené'
                                        : 'Prebieha schválenie..'}
                                </div>
                                <i
                                    onClick={() => deleteRequest(Request.id)}
                                    className="fa fa-times color-red clickable center-hor m-l-5"
                                />
                            </div>
                        ))}
                    </div>
                )}
            </CardBody>
        </Card>
    );
}
