import React from 'react';
import {
    GetMyRights,
    GetMyData,
    GetSettings,
    toSelArr,
    timestampToPracticalDate,
} from 'helperFunctions';
import Select from 'react-select';
import dices from 'configs/dices';
import { Card, CardBody, Label, FormGroup, Button, Input } from 'reactstrap';
import classnames from 'classnames';
import {
    DELETE_DICEROLL,
    DICEROLLS,
    DICEROLLS_SUBSCRIPTION,
    ROLL_DICE,
    SET_DICEROLLER,
    USERS,
    USERS_SUBSCRIPTION,
} from 'queries';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { Checkbox } from 'components/checkbox';

export default function Diceroller() {
    const { Dm } = GetMyRights();
    const currentUser = GetMyData();
    const { dicerollerFixed, dicerollerSpecialRoll, userRolling } = GetSettings();
    const [rolling, setRolling] = React.useState(false);
    const [situation, setSituation] = React.useState('');
    const [offset, setOffset] = React.useState(0);
    const [DicerollHistory, setDicerollHistory] = React.useState([]);

    const [setDiceroller] = useMutation(SET_DICEROLLER);
    const [rollDice] = useMutation(ROLL_DICE);
    const [deleteDiceroll] = useMutation(DELETE_DICEROLL);

    const {
        data: usersData,
        loading: usersLoading,
        refetch: usersRefetch,
    } = useQuery(USERS, {
        fetchPolicy: 'network-only',
        variables: {
            dm: false,
            includeInactive: false,
        },
    });

    useSubscription(USERS_SUBSCRIPTION, {
        onData: () => {
            usersRefetch({
                dm: false,
                includeInactive: false,
            });
        },
    });
    const Users = usersLoading ? [] : usersData.users;

    const { data: dicerollsData } = useQuery(DICEROLLS, {
        fetchPolicy: 'network-only',
        variables: {
            today: false,
            offset,
        },
    });

    React.useEffect(() => {
        if (dicerollsData?.dicerolls) {
            setDicerollHistory(dicerollsData?.dicerolls);
        }
        // eslint-disable-next-line
    }, [dicerollsData?.dicerolls]);

    const {
        data: currentDicerollsData,
        loading: currentDicerollsLoading,
        refetch: currentDicerollsRefetch,
    } = useQuery(DICEROLLS, {
        fetchPolicy: 'network-only',
        variables: {
            today: true,
        },
    });
    const CurrentDicerollHistory = currentDicerollsLoading ? [] : currentDicerollsData.dicerolls;

    useSubscription(DICEROLLS_SUBSCRIPTION, {
        onData: () => {
            currentDicerollsRefetch();
        },
    });

    const SelectUsers = [
        { label: 'Dungeon Master', value: null, id: null },
        ...toSelArr(Users, 'character'),
    ];

    const getDiceColor = (diceId) => {
        const dice = dices.find((dice) => dice.id === diceId);
        if (dice) {
            return dice.color;
        }
        return null;
    };

    const EntireHistory = [...CurrentDicerollHistory, null, ...DicerollHistory];
    const diceDisabled =
        (userRolling?.id !== currentUser.id && !Dm) || rolling || (!Dm && situation.length < 4);

    return (
        <Card className="flex min-width-700">
            <CardBody>
                <div className="flex-row">
                    <FormGroup className="flex-row">
                        <Label className="m-r-10 center-hor">Hádzajúci:</Label>
                        <Select
                            value={SelectUsers.find((User) => User.id === userRolling.id)}
                            options={SelectUsers}
                            isDisabled={!Dm}
                            placeholder="Hádzajúci charakter"
                            onChange={(user) => {
                                setDiceroller({ variables: { userId: user.id } });
                            }}
                        />
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <div className="center-hor">
                            <Checkbox
                                label="Opakované?"
                                value={dicerollerFixed}
                                disabled={!Dm}
                                onChange={() => {
                                    setDiceroller({
                                        variables: { dicerollerFixed: !dicerollerFixed },
                                    });
                                }}
                                centerHor
                            />
                        </div>
                    </FormGroup>
                    {Dm && (
                        <FormGroup className="flex-row">
                            <div className="center-hor">
                                <Checkbox
                                    label="Hod d13?"
                                    value={dicerollerSpecialRoll}
                                    disabled={!Dm}
                                    onChange={() => {
                                        setDiceroller({
                                            variables: {
                                                dicerollerSpecialRoll: !dicerollerSpecialRoll,
                                            },
                                        });
                                    }}
                                    centerHor
                                />
                            </div>
                        </FormGroup>
                    )}
                </div>
                <div className="flex-row noselect p-20">
                    {!dicerollerSpecialRoll &&
                        dices.map((dice) => (
                            <dice.dice
                                key={dice.id}
                                className={classnames(
                                    {
                                        'clickable': !diceDisabled,
                                    },
                                    'height-140 center-ver'
                                )}
                                onClick={() => {
                                    if (diceDisabled) {
                                        return;
                                    }
                                    setRolling(true);
                                    rollDice({ variables: { dice: dice.id, situation } })
                                        .then(() => setRolling(false))
                                        .catch((e) => {
                                            console.log(e);
                                            setRolling(false);
                                        });
                                }}
                            />
                        ))}
                    {dicerollerSpecialRoll && (
                        <img
                            height={200}
                            src="./images/d13.png"
                            alt="special-dragon-dice"
                            className={classnames({ 'clickable': !diceDisabled }, 'center-ver')}
                            onClick={() => {
                                if (diceDisabled) {
                                    return;
                                }
                                setRolling(true);
                                rollDice({ variables: { dice: 'd13', situation } })
                                    .then(() => setRolling(false))
                                    .catch((e) => {
                                        console.log(e);
                                        setRolling(false);
                                    });
                            }}
                        />
                    )}
                </div>
                <div className="flex-row">
                    {currentUser?.id === userRolling?.id && (
                        <h5 className="color-gold center-hor">Hádžeš kockou!</h5>
                    )}
                    {(Dm || currentUser?.id === userRolling?.id) && (
                        <div className="flex-row m-l-20">
                            <Label className="center-hor m-r-20">Dôvod:</Label>
                            <Input
                                value={situation}
                                onChange={(e) => {
                                    if (e.target.value.length <= 15) {
                                        setSituation(e.target.value);
                                    }
                                }}
                            />
                        </div>
                    )}
                </div>
                <hr />
                <div className="flex-row">
                    <h5>Hody</h5>
                    <Button
                        color="link"
                        disabled={DicerollHistory.length !== 15 * (offset + 1)}
                        className="center-hor m-l-auto"
                        onClick={() => setOffset(offset + 1)}
                    >
                        Načítať viac hodov
                    </Button>
                </div>
                <div className="max-height-dice overflow-x">
                    {EntireHistory.map((Diceroll) =>
                        Diceroll === null ? (
                            <div key="startOfHistory" className="text-bolder font-17 text-center">
                                -----História----
                            </div>
                        ) : (
                            <div
                                key={Diceroll.id}
                                className="flex-row"
                                style={{
                                    border: `thick double ${
                                        Diceroll.user?.color ? Diceroll.user?.color : 'grey'
                                    }`,
                                }}
                            >
                                [{timestampToPracticalDate(Diceroll.createdAt, 'dd.MM. HH:mm')}]
                                {Diceroll.situation?.length > 0 && `[${Diceroll.situation}]`}
                                <span
                                    className="m-l-5 m-r-5 text-bolder"
                                    style={{
                                        color: Diceroll.user?.color ? Diceroll.user?.color : 'grey',
                                    }}
                                >
                                    {Diceroll.user?.character
                                        ? Diceroll.user?.character
                                        : 'Neznámy'}
                                </span>
                                hodil
                                {Diceroll.user?.gender > 1
                                    ? 'o '
                                    : Diceroll.user?.gender === 1
                                    ? ' '
                                    : 'a '}
                                kockou
                                <span
                                    className={classnames('m-l-5 text-bolder', {
                                        'color-rainbow': getDiceColor(Diceroll.dice) === null,
                                    })}
                                    style={
                                        getDiceColor(Diceroll.dice)
                                            ? { color: getDiceColor(Diceroll.dice) }
                                            : {}
                                    }
                                >
                                    {Diceroll.dice}
                                </span>
                                {`. Hod bol`}
                                <span className="text-bolder m-l-5 m-r-5">{Diceroll.roll}</span>z
                                <span className="text-bolder m-l-5">
                                    {Diceroll.dice.substring(1)}
                                </span>
                                .
                                {Dm && (
                                    <div className="m-l-auto">
                                        <i
                                            className="color-red fa fa-times m-r-5 clickable"
                                            onClick={() => {
                                                deleteDiceroll({ variables: { id: Diceroll.id } });
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        )
                    )}
                </div>
                {EntireHistory.length === 0 && <div>História hodov je prázdna</div>}
            </CardBody>
        </Card>
    );
}
