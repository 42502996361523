import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import Requests from './requests';
import Notifications from './notifications';
import Discover from './discover';
import Display from './display';
import CustomImage from './customImage';
import {
    GET_ALL_CRAFTINGS,
    CRAFTINGS_SUBSCRIPTION,
    GM_NOTIFICATIONS,
    GM_NOTIFICATIONS_SUBSCRIPTION,
    MAPS,
    MAPS_SUBSCRIPTION,
    PLACES,
    PLACES_SUBSCRIPTION,
    SPELLS,
    SPELLS_SUBSCRIPTION,
    TRADERS,
    TRADERS_SUBSCRIPTION,
    CREATURES,
    CREATURES_SUBSCRIPTION,
    RECIPES,
    RECIPES_SUBSCRIPTION,
    UPDATE_GAME_AREA,
    UPDATE_SETTINGS,
    BATTLEFIELDS_SUBSCRIPTION,
    BATTLEFIELDS,
} from 'queries';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { GetSettings } from 'helperFunctions';

export default function GameCommandBar() {
    const [dropdownOpen, setDropdownOpen] = React.useState(null);
    const [selectionOpen, setSelectionOpen] = React.useState(null);
    const [selectionFilter, setSelectionFilter] = React.useState('');
    const [updateGameArea] = useMutation(UPDATE_GAME_AREA);
    const [updateSettings] = useMutation(UPDATE_SETTINGS);

    const { gamePaused } = GetSettings();

    const {
        data: craftingData,
        loading: craftingLoading,
        refetch: craftingRefetch,
    } = useQuery(GET_ALL_CRAFTINGS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(CRAFTINGS_SUBSCRIPTION, {
        onData: () => {
            craftingRefetch();
        },
    });
    const Craftings = craftingLoading ? [] : craftingData.getAllCraftings;
    const {
        data: gmNotificationsData,
        loading: gmNotificationsLoading,
        refetch: gmNotificationsRefetch,
    } = useQuery(GM_NOTIFICATIONS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(GM_NOTIFICATIONS_SUBSCRIPTION, {
        onData: () => {
            gmNotificationsRefetch();
        },
    });
    const GMNotifications = gmNotificationsLoading ? [] : gmNotificationsData.GMNotifications;
    const {
        data: mapsData,
        loading: mapsLoading,
        refetch: mapsRefetch,
    } = useQuery(MAPS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(MAPS_SUBSCRIPTION, {
        onData: () => {
            mapsRefetch();
        },
    });

    const Maps = mapsLoading ? [] : mapsData.maps;

    const {
        data: placesData,
        loading: placesLoading,
        refetch: placesRefetch,
    } = useQuery(PLACES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(PLACES_SUBSCRIPTION, {
        onData: () => {
            placesRefetch();
        },
    });

    const Places = placesLoading ? [] : placesData.places;

    const {
        data: spellsData,
        loading: spellsLoading,
        refetch: spellsRefetch,
    } = useQuery(SPELLS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(SPELLS_SUBSCRIPTION, {
        onData: () => {
            spellsRefetch();
        },
    });

    const Spells = spellsLoading ? [] : spellsData.spells;

    const {
        data: tradersData,
        loading: tradersLoading,
        refetch: tradersRefetch,
    } = useQuery(TRADERS, {
        variables: {
            visibleOnly: false,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(TRADERS_SUBSCRIPTION, {
        onData: () => {
            tradersRefetch({ variables: { visibleOnly: false } });
        },
    });

    const Traders = tradersLoading ? [] : tradersData.traders;

    const {
        data: creaturesData,
        loading: creaturesLoading,
        refetch: creaturesRefetch,
    } = useQuery(CREATURES, {
        variables: {
            visibleOnly: false,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(CREATURES_SUBSCRIPTION, {
        onData: () => {
            creaturesRefetch({
                variables: {
                    visibleOnly: false,
                },
            });
        },
    });

    const Creatures = creaturesLoading ? [] : creaturesData.creatures;

    const {
        data: recipesData,
        loading: recipesLoading,
        refetch: recipesRefetch,
    } = useQuery(RECIPES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(RECIPES_SUBSCRIPTION, {
        onData: () => {
            recipesRefetch();
        },
    });

    const Recipes = recipesLoading ? [] : recipesData.recipes;

    const {
        data: battlefieldsData,
        loading: battlefieldsLoading,
        refetch: battlefieldsRefetch,
    } = useQuery(BATTLEFIELDS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(BATTLEFIELDS_SUBSCRIPTION, {
        onData: () => {
            battlefieldsRefetch();
        },
    });

    const Battlefields = battlefieldsLoading ? [] : battlefieldsData.battlefields;

    React.useEffect(() => {
        setSelectionFilter('');
    }, [selectionOpen]);

    const filterVisible = (Data, visibility = false) =>
        Data.filter((Item) => visibility === Item.visibility);

    const UnreadNotificationsCount = GMNotifications.filter(
        (Notification) => !Notification.read
    ).length;

    return (
        <div className="command-bar flex-row">
            <Dropdown
                isOpen={dropdownOpen === 1}
                toggle={() => {
                    if (dropdownOpen === 1) {
                        setDropdownOpen(null);
                    } else {
                        setDropdownOpen(1);
                    }
                }}
                className="commandbar-dropdown center-hor"
            >
                <DropdownToggle className="commandbar-dropdown-toggle">
                    <i className="fa fa-lightbulb" /> Objaviť
                </DropdownToggle>
                <DropdownMenu start="true">
                    <Discover
                        open={selectionOpen}
                        setOpen={setSelectionOpen}
                        dataFilter={selectionFilter}
                        setDataFilter={setSelectionFilter}
                        type="map"
                        title="Mapu"
                        data={filterVisible(Maps)}
                    />
                    <Discover
                        open={selectionOpen}
                        setOpen={setSelectionOpen}
                        dataFilter={selectionFilter}
                        setDataFilter={setSelectionFilter}
                        type="place"
                        title="Miesto"
                        data={filterVisible(Places)}
                        miniature
                    />
                    <Discover
                        open={selectionOpen}
                        setOpen={setSelectionOpen}
                        dataFilter={selectionFilter}
                        setDataFilter={setSelectionFilter}
                        type="spell"
                        title="Mágiu"
                        data={filterVisible(Spells)}
                    />
                    <Discover
                        open={selectionOpen}
                        setOpen={setSelectionOpen}
                        dataFilter={selectionFilter}
                        setDataFilter={setSelectionFilter}
                        type="trader"
                        title="Obchodníka"
                        data={filterVisible(Traders)}
                    />

                    <Discover
                        open={selectionOpen}
                        setOpen={setSelectionOpen}
                        dataFilter={selectionFilter}
                        setDataFilter={setSelectionFilter}
                        type="creature"
                        title="Bestiár"
                        data={filterVisible(Creatures)}
                    />

                    <Discover
                        open={selectionOpen}
                        setOpen={setSelectionOpen}
                        dataFilter={selectionFilter}
                        setDataFilter={setSelectionFilter}
                        type="recipe"
                        title="Recept"
                        data={filterVisible(Recipes)}
                    />
                </DropdownMenu>
            </Dropdown>
            <Dropdown
                isOpen={dropdownOpen === 2}
                toggle={() => {
                    if (dropdownOpen === 2) {
                        setDropdownOpen(null);
                    } else {
                        setDropdownOpen(2);
                    }
                }}
                className="commandbar-dropdown center-hor"
            >
                <DropdownToggle className="commandbar-dropdown-toggle">
                    <i className="fa fa-table-columns" /> Zobraziť
                </DropdownToggle>
                <DropdownMenu start="true">
                    <Display
                        open={selectionOpen}
                        setOpen={setSelectionOpen}
                        dataFilter={selectionFilter}
                        setDataFilter={setSelectionFilter}
                        type="map"
                        urlPath="map"
                        title="Mapu"
                        data={Maps}
                        miniatureZoom
                        imagePath="map"
                    />
                    <Display
                        open={selectionOpen}
                        setOpen={setSelectionOpen}
                        dataFilter={selectionFilter}
                        setDataFilter={setSelectionFilter}
                        type="place"
                        urlPath="place"
                        title="Miesto"
                        data={Places}
                        miniature
                        miniatureZoom
                        imagePath="place"
                    />
                    <Display
                        open={selectionOpen}
                        setOpen={setSelectionOpen}
                        dataFilter={selectionFilter}
                        setDataFilter={setSelectionFilter}
                        type="trader"
                        title="Obchodníka"
                        data={Traders}
                    />
                    <Display
                        open={selectionOpen}
                        setOpen={setSelectionOpen}
                        dataFilter={selectionFilter}
                        setDataFilter={setSelectionFilter}
                        type="creature"
                        title="Tvora z bestiáru"
                        data={Creatures}
                        miniatureZoom
                        imagePath="portrait"
                    />
                    <Display
                        open={selectionOpen}
                        setOpen={setSelectionOpen}
                        dataFilter={selectionFilter}
                        setDataFilter={setSelectionFilter}
                        type="battlefield"
                        title="Bojovisko"
                        data={Battlefields}
                        miniatureZoom
                        imagePath="battlefield"
                    />
                    <CustomImage
                        open={selectionOpen}
                        setOpen={setSelectionOpen}
                        dataFilter={selectionFilter}
                        setDataFilter={setSelectionFilter}
                    />
                    <DropdownItem
                        onClick={() => {
                            updateGameArea({ variables: { type: 'dicePoker', id: null } });
                        }}
                    >
                        Kockový poker
                    </DropdownItem>
                    <DropdownItem
                        onClick={() => {
                            updateGameArea({ variables: { type: 'combatSimulator', id: null } });
                        }}
                    >
                        Bojový simulátor
                    </DropdownItem>
                    <DropdownItem
                        onClick={() => {
                            updateGameArea({ variables: { type: 'dicerolls', id: null } });
                        }}
                    >
                        Hod kockami
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
            <Dropdown
                isOpen={dropdownOpen === 3}
                toggle={() => {
                    if (dropdownOpen === 3) {
                        setDropdownOpen(null);
                    } else {
                        setDropdownOpen(3);
                    }
                }}
                className="commandbar-dropdown center-hor"
            >
                <DropdownToggle className="commandbar-dropdown-toggle">
                    <i className="fa fa-envelope" /> Notifikácie
                    {UnreadNotificationsCount > 0 && (
                        <span className="color-gold text-bolder m-l-5">
                            {UnreadNotificationsCount}
                        </span>
                    )}
                </DropdownToggle>
                <DropdownMenu start="true">
                    <Notifications GMNotifications={GMNotifications} />
                </DropdownMenu>
            </Dropdown>
            <Dropdown
                isOpen={dropdownOpen === 4}
                toggle={() => {
                    if (dropdownOpen === 4) {
                        setDropdownOpen(null);
                    } else {
                        setDropdownOpen(4);
                    }
                }}
                className="commandbar-dropdown center-hor"
            >
                <DropdownToggle
                    className="commandbar-dropdown-toggle"
                    disabled={Craftings.length === 0}
                >
                    <i className="fa fa-question" /> Žiadosti
                    {Craftings.length > 0 && (
                        <i className="fa fa-exclamation-triangle color-gold m-l-5" />
                    )}
                </DropdownToggle>
                <DropdownMenu start="true">
                    <Requests Craftings={Craftings} />
                </DropdownMenu>
            </Dropdown>
            <div className="thin-buttons center-hor m-l-20">
                <Button
                    color={gamePaused ? 'success' : 'danger'}
                    onClick={() => updateSettings({ variables: { gamePaused: !gamePaused } })}
                >
                    {gamePaused ? 'Pokračovať v hre' : 'Pozastaviť hru'}
                </Button>
            </div>
        </div>
    );
}
