import { gql } from '@apollo/client';

export const SETTINGS = gql`
    query settings {
        settings {
            gamePaused
            calendar {
                hour
                day
                month
                year
                age
            }
            gameArea {
                type
                id
                url
                extra
            }
            userRolling {
                id
                color
                nickname
                character
            }
            testMode {
                active
                strict
                poetic
                advantage
                d4
                d6
                d8
                d10
                d100
            }
            dicerollerFixed
            dicerollerSpecialRoll
            lootManagerActive
        }
    }
`;

export const UPDATE_SETTINGS = gql`
    mutation updateSettings($gamePaused: Boolean, $registrationOpen: Boolean) {
        updateSettings(gamePaused: $gamePaused, registrationOpen: $registrationOpen) {
            id
        }
    }
`;

export const TEST_MODE = gql`
    mutation testMode(
        $active: Boolean
        $strict: Boolean
        $poetic: Boolean
        $advantage: Boolean
        $d4: Int
        $d6: Int
        $d8: Int
        $d10: Int
        $d100: Int
    ) {
        testMode(
            active: $active
            strict: $strict
            poetic: $poetic
            advantage: $advantage
            d4: $d4
            d6: $d6
            d8: $d8
            d10: $d10
            d100: $d100
        ) {
            id
        }
    }
`;

export const ADD_HOUR = gql`
    mutation addHour {
        addHour {
            id
        }
    }
`;
export const REMOVE_HOUR = gql`
    mutation removeHour {
        removeHour {
            id
        }
    }
`;
export const UPDATE_GAME_AREA = gql`
    mutation updateGameArea($type: String, $id: Int, $url: String) {
        updateGameArea(type: $type, id: $id, url: $url) {
            id
        }
    }
`;

export const DRAW_ON_IMAGE = gql`
    mutation drawOnImage($drawing: String!) {
        drawOnImage(drawing: $drawing)
    }
`;

export const CLEAR_MY_IMAGE_DRAWING = gql`
    mutation clearMyImageDrawing {
        clearMyImageDrawing
    }
`;

export const CLEAR_ALL_IMAGE_DRAWING = gql`
    mutation clearAllImageDrawing {
        clearAllImageDrawing
    }
`;

export const SET_LOOT_MANAGER_ACTIVE = gql`
    mutation setLootManagerActive {
        setLootManagerActive
    }
`;

export const SETTINGS_SUBSCRIPTION = gql`
    subscription settingsSubscription {
        settingsSubscription
    }
`;
