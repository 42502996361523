import React from 'react';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import {
    CLEAR_ALL_LOOT_MANAGER_ITEMS,
    LOOT_MANAGER_DISTRIBUTE_ITEMS,
    LOOT_MANAGER_ITEMS,
    LOOT_MANAGER_ITEMS_SUBSCRIPTION,
    REMOVE_LOOT_MANAGER_ITEM,
    SPLIT_LOOT_MANAGER_ITEM,
    UPDATE_LOOT_MANAGER_ITEM,
} from 'queries';
import { Button, Collapse, Table } from 'reactstrap';
import { GetMyRights, getItemDescription } from 'helperFunctions';
import { Empty, Tooltip } from 'components';
import AddLoot from './addLoot';
import AssignLoot from './assignLoot';
import classnames from 'classnames';

export default function LootList({ Users, participants }) {
    const {
        data: lootItemsData,
        loading: lootItemsLoading,
        refetch: lootItemsRefetch,
    } = useQuery(LOOT_MANAGER_ITEMS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LOOT_MANAGER_ITEMS_SUBSCRIPTION, {
        onData: () => {
            lootItemsRefetch();
        },
    });

    const [removeLootManagerItem] = useMutation(REMOVE_LOOT_MANAGER_ITEM);
    const [splitLootManagerItem] = useMutation(SPLIT_LOOT_MANAGER_ITEM);
    const [clearAllLootManagerItems] = useMutation(CLEAR_ALL_LOOT_MANAGER_ITEMS);
    const [lootManagerDistributeItems] = useMutation(LOOT_MANAGER_DISTRIBUTE_ITEMS);
    const [updateLootManagerItem] = useMutation(UPDATE_LOOT_MANAGER_ITEM);
    //id: $id, quantity: $quantity, sell: $sell, userId: $userId

    const [openAddLoot, setOpenAddLoot] = React.useState(false);
    const [openAssignLoot, setOpenAssignLoot] = React.useState(null);
    const [isOpen, setIsOpen] = React.useState(true);

    const { AdminOrDm } = GetMyRights();
    const Loot = lootItemsLoading ? [] : lootItemsData.lootManagerItems;

    return (
        <div className="m-t-20">
            <h4
                className="clickable"
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
            >
                Delenie lootu
            </h4>
            <Collapse isOpen={isOpen}>
                {AdminOrDm && (
                    <div className="thin-buttons">
                        <Button
                            className="p-l-10 p-r-10 m-r-10"
                            color="success"
                            onClick={() => {
                                setOpenAddLoot(!openAddLoot);
                            }}
                        >
                            <i className="m-r-5 fa fa-plus" id={`add-loot-btn`} />
                            Pridať loot
                        </Button>
                        <AddLoot
                            open={openAddLoot}
                            closeAddLoot={() => {
                                setOpenAddLoot(!openAddLoot);
                            }}
                        />
                        <Button
                            className="p-l-10 p-r-10 m-r-10"
                            color="danger"
                            onClick={() => {
                                if (window.confirm('Vymazať všetok loot?')) {
                                    clearAllLootManagerItems();
                                }
                            }}
                        >
                            <i className="m-r-5 fa fa-trash" />
                            Odstrániť všetok loot
                        </Button>
                        <Button
                            className="p-l-10 p-r-10 m-r-10"
                            color="success"
                            onClick={() => {
                                if (
                                    window.confirm(
                                        'Rozdeliť priradený loot? (Vytvorí dlhy a odstráni priradené veci)'
                                    )
                                ) {
                                    lootManagerDistributeItems();
                                }
                            }}
                        >
                            <i className="m-r-5 fa fa-hand-holding-hand" />
                            Rozdeliť loot
                        </Button>
                    </div>
                )}
                <Table striped className="bkg-half-white m-t-10">
                    <thead>
                        <tr>
                            <th width="50">ks</th>
                            <th>Názov</th>
                            <th width="150">Cena ks</th>
                            {AdminOrDm && <th width="150"></th>}
                        </tr>
                    </thead>
                    <tbody>
                        {Loot.map((Item) => (
                            <tr key={Item.id} className="text-bolder">
                                <td>{Item.quantity}</td>
                                <td>
                                    <span
                                        style={Item.user ? { color: Item.user.color } : {}}
                                        className={classnames({ 'text-bolder': Item.user })}
                                    >
                                        {Item.sell && (
                                            <Empty>
                                                <i
                                                    className="clickable fa fa-dollar-sign m-r-5 color-orange"
                                                    id={`for-sell-loot-tooltip-${Item.id}`}
                                                />
                                                <Tooltip
                                                    placement="top"
                                                    target={`for-sell-loot-tooltip-${Item.id}`}
                                                    text="Na predaj"
                                                />
                                            </Empty>
                                        )}
                                        {Item.user && (
                                            <Empty>
                                                <i
                                                    className="clickable fa fa-user m-r-5"
                                                    style={{ color: Item.user.color }}
                                                    id={`for-user-loot-tooltip-${Item.id}`}
                                                />
                                                <Tooltip
                                                    placement="top"
                                                    target={`for-user-loot-tooltip-${Item.id}`}
                                                    text={`Pre ${Item.user.character}`}
                                                />
                                            </Empty>
                                        )}
                                        <span
                                            className="clickable"
                                            id={`loot-item-title-tooltip-${Item.id}`}
                                        >
                                            {Item.item.title}
                                        </span>
                                    </span>
                                    {getItemDescription(Item.item) && (
                                        <Tooltip
                                            placement="top"
                                            target={`loot-item-title-tooltip-${Item.id}`}
                                            text={getItemDescription(Item.item)}
                                        />
                                    )}
                                </td>
                                <td>
                                    <div className="color-gold">
                                        {Item.item.price}g ({Math.round(0.6 * Item.item.price)}g)
                                    </div>
                                </td>
                                {AdminOrDm && (
                                    <td>
                                        <div>
                                            <i
                                                className="clickable fa fa-dollar-sign m-l-10 color-orange"
                                                onClick={() => {
                                                    updateLootManagerItem({
                                                        variables: {
                                                            id: Item.id,
                                                            sell: !Item.sell,
                                                        },
                                                    });
                                                }}
                                                id={`sell-loot-tooltip-${Item.id}`}
                                            />
                                            <Tooltip
                                                placement="top"
                                                target={`sell-loot-tooltip-${Item.id}`}
                                                text="Označiť/odznačiť na predaj"
                                            />

                                            <i
                                                className="clickable fa fa-user m-l-10 color-blue"
                                                onClick={() => {
                                                    if (openAssignLoot?.id === Item.id) {
                                                        setOpenAssignLoot(null);
                                                    } else {
                                                        setOpenAssignLoot(Item);
                                                    }
                                                }}
                                                id={`assign-loot-tooltip-${Item.id}`}
                                            />
                                            <Tooltip
                                                placement="top"
                                                target={`assign-loot-tooltip-${Item.id}`}
                                                text="Priradiť/odobrať hráčovi"
                                            />
                                            <i
                                                className="clickable fa fa-trash m-l-10 color-red"
                                                onClick={() => {
                                                    if (
                                                        window.confirm(
                                                            `Zmazať loot ${Item.item.title}?`
                                                        )
                                                    ) {
                                                        removeLootManagerItem({
                                                            variables: { id: Item.id },
                                                        });
                                                    }
                                                }}
                                                id={`destroy-loot-tooltip-${Item.id}`}
                                            />
                                            <Tooltip
                                                placement="top"
                                                target={`destroy-loot-tooltip-${Item.id}`}
                                                text="Odstrániť loot"
                                            />

                                            {Item.quantity > 1 && (
                                                <Empty>
                                                    <i
                                                        className="clickable fa fa-share-from-square m-l-10 color-blue"
                                                        onClick={() => {
                                                            if (
                                                                window.confirm(
                                                                    `Separovať 1 ${Item.item.title}?`
                                                                )
                                                            ) {
                                                                splitLootManagerItem({
                                                                    variables: { id: Item.id },
                                                                });
                                                            }
                                                        }}
                                                        id={`split-loot-tooltip-${Item.id}`}
                                                    />
                                                    <Tooltip
                                                        placement="top"
                                                        target={`split-loot-tooltip-${Item.id}`}
                                                        text="Oddeliť 1 z lootu"
                                                    />
                                                </Empty>
                                            )}
                                        </div>
                                    </td>
                                )}
                            </tr>
                        ))}
                        {Loot.some((loot) => loot.sell) && (
                            <tr style={{ borderTop: '3px solid grey' }}>
                                <td className="text-bolder" colSpan={2}>
                                    Spolu predaj
                                </td>
                                <td>
                                    <div className="color-gold text-bolder">
                                        {Loot.filter((loot) => loot.sell).reduce(
                                            (acc, cur) => acc + cur.item.price * cur.quantity,
                                            0
                                        )}
                                        g (
                                        {Loot.filter((loot) => loot.sell).reduce(
                                            (acc, cur) =>
                                                acc +
                                                Math.round(0.6 * cur.item.price) * cur.quantity,
                                            0
                                        )}
                                        g)
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                <AssignLoot
                    open={openAssignLoot}
                    closeAssignLoot={() => setOpenAssignLoot(null)}
                    Users={Users.filter((User) => participants.includes(User.id))}
                />
            </Collapse>
        </div>
    );
}
