import { sortBy } from 'helperFunctions';
import React from 'react';
import OpponentDisplay from './opponent';

export default function CombatSummary({ Opponents }) {
    return (
        <div>
            <div className="text-center text-bolder color-green font-20">Zhrnutie boja</div>
            <div className="flex-row-wrapped">
                {sortBy(Opponents, [
                    { key: 'user', asc: false },
                    { key: 'dead', asc: true },
                ]).map((Opponent, index) => (
                    <div className="p-10" key={index}>
                        <OpponentDisplay
                            type={Opponent.dead ? 'Mŕtvy' : 'Živý'}
                            Opponent={Opponent}
                        />
                        <div className="combat-message">
                            <div>
                                <span className="color-red text-bolder font-18">
                                    Celkové zranenie nepriateľov:
                                </span>
                                <span className="color-red m-l-10 font-20">{Opponent.damage}</span>
                            </div>
                            <div>
                                <span className="color-orange text-bolder font-18">
                                    Skóre za útok:
                                </span>
                                <span className="color-orange m-l-10 font-20">
                                    {Opponent.attackPoints}
                                </span>
                            </div>
                            <div>
                                <span className="color-blue text-bolder font-18">
                                    Skóre za obranu:
                                </span>
                                <span className="color-blue m-l-10 font-20">
                                    {Opponent.defencePoints}
                                </span>
                            </div>
                            <div>
                                <span className="color-gold text-bolder font-18">
                                    Celkové skóre:
                                </span>
                                <span className="color-gold m-l-10 font-20">{`${
                                    Opponent.score + Opponent.damage / 4
                                } (${Opponent.damage / 4} zo zranenia)`}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
