import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import { SketchPicker } from 'react-color';
import { USER_COLORS, USER_COLORS_SUBSCRIPTION, UPDATE_PROFILE } from 'queries';
import { Input, Button } from 'reactstrap';
import { GetMyData, sameColors } from 'helperFunctions';
import { useQuery, useSubscription, useMutation } from '@apollo/client';

export default function Profile() {
    const currentUser = GetMyData();
    const [nickname, setNickname] = React.useState(currentUser.nickname);
    const [character, setCharacter] = React.useState(currentUser.character);
    const [color, setColor] = React.useState(currentUser.color);
    const [newPass, setNewPass] = React.useState('');
    const [email, setEmail] = React.useState(currentUser.email);

    const [updateProfile] = useMutation(UPDATE_PROFILE);

    const {
        data: userColorsData,
        loading: userColorsLoading,
        refetch: userColorsRefetch,
    } = useQuery(USER_COLORS, {
        variables: {
            filterCurrent: true,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(USER_COLORS_SUBSCRIPTION, {
        onData: () => {
            userColorsRefetch();
        },
    });

    const setUserData = () => {
        setNickname(currentUser.nickname);
        setCharacter(currentUser.character);
        setColor(currentUser.color);
        setEmail(currentUser.email);
        setNewPass('');
    };

    const saveData = () => {
        let data = {
            nickname,
            character,
            color,
        };
        if (email !== currentUser.email) {
            data.email = email;
        }
        if (newPass.length > 5) {
            data.password = newPass;
        }
        updateProfile({
            variables: data,
        });
    };

    const userColors = userColorsLoading ? [] : userColorsData.userColors;
    const similiarColors = userColors.filter((User) => sameColors(currentUser.color, color));

    return (
        <div className="p-20 width-700">
            <FormGroup className="flex-row">
                <div className="width-150">
                    <Label for="nickname">Prezývka</Label>
                </div>
                <Input
                    id="nickname"
                    className="flex"
                    value={nickname}
                    placeholder="Zadaj prezývku"
                    onChange={(e) => setNickname(e.target.value)}
                />
            </FormGroup>
            <FormGroup className="flex-row">
                <div className="width-150">
                    <Label for="character">Meno charakteru</Label>
                </div>
                <Input
                    id="character"
                    className="flex"
                    value={character}
                    placeholder="Zadaj meno charakteru"
                    onChange={(e) => setCharacter(e.target.value)}
                />
            </FormGroup>
            <FormGroup className="flex-row">
                <div className="width-150">
                    <Label for="player-color">Farba hráča</Label>
                </div>
                <SketchPicker
                    id="player-color"
                    color={color}
                    onChangeComplete={(value) => setColor(value.hex)}
                />
                <div
                    className="height-300 width-100 p-10 text-bolder"
                    style={{ backgroundColor: color }}
                >
                    Hráčová farba
                </div>
                {similiarColors.length > 0 && (
                    <div className="height-300 width-400 text-bolder">
                        <div className="p-10">Táto farba je príliž podobná tymto hráčom!</div>
                        {similiarColors.map((User) => (
                            <div
                                className="p-10"
                                key={currentUser.color}
                                style={{
                                    height: Math.min(70, (300 - 24) / similiarColors.length),
                                    backgroundColor: currentUser.color,
                                }}
                            >{`${currentUser.nickname}(${currentUser.character})`}</div>
                        ))}
                    </div>
                )}
            </FormGroup>
            <FormGroup className="flex-row">
                <div className="width-150">
                    <Label for="email">E-mail</Label>
                </div>
                <Input
                    id="email"
                    className="flex"
                    value={email}
                    placeholder="Zmeniť e-mail - vyžaduje odhlásenie"
                    onChange={(e) => setEmail(e.target.value)}
                />
            </FormGroup>
            <FormGroup className="flex-row">
                <div className="width-150">
                    <Label for="newPass">Nové heslo</Label>
                </div>
                <Input
                    id="newPass"
                    type="password"
                    className="flex"
                    value={newPass}
                    placeholder="Zmeniť heslo - vyžaduje odhlásenie"
                    onChange={(e) => setNewPass(e.target.value)}
                />
            </FormGroup>
            <div className="flex-row">
                <Button
                    color="primary"
                    disabled={
                        similiarColors.length > 0 || (newPass.length > 0 && newPass.length < 5)
                    }
                    onClick={saveData}
                >
                    Uložiť
                </Button>
                <Button color="link" className="m-l-auto" onClick={setUserData}>
                    Obnoviť
                </Button>
            </div>
        </div>
    );
}
