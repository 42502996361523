import React from 'react';
import Select from 'react-select';
import { Input, Label, Button } from 'reactstrap';
import Textarea from 'react-textarea-autosize';
import { Image } from 'components';
import { pickSelectStyle } from 'configs/selectStyles';
import { DELETE_ITEM, UPDATE_ITEM } from 'queries';
import { useMutation } from '@apollo/client';
import { toBase64 } from 'helperFunctions';

export default function ItemEdit(props) {
    const { item, updateItem, removeFromTrader, deleteItem, itemTypes } = props;
    const { id } = item;
    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [type, setType] = React.useState(null);
    const [price, setPrice] = React.useState(0);
    const [weight, setWeight] = React.useState(0);
    const [order, setOrder] = React.useState(0);

    const [updateItemMutation] = useMutation(UPDATE_ITEM);
    const [deleteItemMutation] = useMutation(DELETE_ITEM);

    React.useEffect(() => {
        setTitle(item.title);
        setDescription(item.description);
        setType(item.type);
        setPrice(item.price);
        setWeight(item.weight ? item.weight.toString() : 0);
        setOrder(item.order ? item.order.toString() : 0);
        // eslint-disable-next-line
    }, []);

    const replaceIcon = async (newIcon) => {
        updateItemMutation({ variables: { id: item.id, icon: await toBase64(newIcon) } }).then(
            (response) => {
                updateItem({ ...item, icon: response.data.updateItem.icon });
            }
        );
    };

    const deleteFunc = async () => {
        if (window.confirm('Naozaj chceš zmazať túto vec z celej hry?')) {
            deleteItemMutation({ variables: { id } });
            deleteItem(id);
        }
    };

    return (
        <tr key={id}>
            <td>
                <Input
                    style={{ display: 'none' }}
                    type="file"
                    id={`icon-edit-upload${id}`}
                    accept="image/png, image/jpeg"
                    onChange={(e) => {
                        let files = e.target.files;
                        if (files.length === 1) {
                            replaceIcon(files[0]);
                        }
                    }}
                />
                <Label htmlFor={`icon-edit-upload${id}`}>
                    <Image height={35} path={item?.icon} />
                </Label>
            </td>
            <td>
                <Textarea
                    className="form-control hidden-input"
                    value={title}
                    onBlur={() => {
                        updateItemMutation({ variables: { id: item.id, title } });
                    }}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Zadaj názov"
                />
            </td>
            <td>
                <Textarea
                    className="form-control hidden-input"
                    value={description}
                    onBlur={() => {
                        updateItemMutation({ variables: { id: item.id, description } });
                    }}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Zadaj popis"
                />
            </td>
            <td>
                <Select
                    value={type}
                    onChange={(type) => {
                        setType(type);
                        updateItemMutation({ variables: { id: item.id, type: type.id } });
                        updateItem({ ...item, type });
                    }}
                    options={itemTypes}
                    styles={pickSelectStyle(['invisible'])}
                />
            </td>
            <td>
                <input
                    className="form-control hidden-input"
                    value={price}
                    onBlur={() => {
                        updateItemMutation({
                            variables: {
                                id: item.id,
                                price: isNaN(parseFloat(price)) ? 0 : parseFloat(price),
                            },
                        });
                        updateItem({
                            ...item,
                            price: isNaN(parseFloat(price)) ? 0 : parseFloat(price),
                        });
                    }}
                    onChange={(e) => setPrice(e.target.value)}
                />
            </td>
            <td>
                <input
                    className="form-control hidden-input"
                    value={weight}
                    onBlur={() => {
                        updateItem({
                            ...item,
                            weight: isNaN(parseFloat(weight)) ? 0 : parseFloat(weight),
                        });
                        updateItemMutation({
                            variables: {
                                id: item.id,
                                weight: isNaN(parseFloat(weight)) ? 0 : parseFloat(weight),
                            },
                        });
                    }}
                    onChange={(e) => setWeight(e.target.value)}
                />
            </td>
            <td>
                <input
                    className="form-control hidden-input"
                    value={order}
                    onBlur={() => {
                        updateItem({
                            ...item,
                            order: isNaN(parseInt(order)) ? 0 : parseInt(order),
                        });
                    }}
                    onChange={(e) => setOrder(e.target.value)}
                />
            </td>
            <td>
                <Button color="none" onClick={deleteFunc}>
                    <i className="fa fa-trash danger" />
                </Button>
                <Button
                    color="none"
                    onClick={() => {
                        if (window.confirm('Naozaj chceš odobrať túto vec obchodníkovi?')) {
                            removeFromTrader(id);
                        }
                    }}
                >
                    <i className="fa fa-times warning" />
                </Button>
            </td>
        </tr>
    );
}
