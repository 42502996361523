import { gql } from '@apollo/client';

export const OCCUPATION = gql`
    query occupation($id: Int!) {
        occupation(id: $id) {
            id
            createdAt
            updatedAt
            title
            description
            icon
            magic
        }
    }
`;

export const OCCUPATIONS = gql`
    query occupations {
        occupations {
            id
            createdAt
            updatedAt
            title
            description
            icon
            magic
        }
    }
`;
export const ADD_OCCUPATION = gql`
    mutation addOccupation($title: String!, $magic: Boolean!, $icon: String, $description: String) {
        addOccupation(title: $title, magic: $magic, icon: $icon, description: $description) {
            id
        }
    }
`;
export const UPDATE_OCCUPATION = gql`
    mutation updateOccupation(
        $id: Int!
        $title: String
        $description: String
        $icon: String
        $magic: Boolean
    ) {
        updateOccupation(
            id: $id
            title: $title
            description: $description
            icon: $icon
            magic: $magic
        ) {
            id
        }
    }
`;
export const DELETE_OCCUPATION = gql`
    mutation deleteOccupation($id: Int!) {
        deleteOccupation(id: $id) {
            id
        }
    }
`;

export const OCCUPATIONS_SUBSCRIPTION = gql`
    subscription occupationsSubscription {
        occupationsSubscription
    }
`;
