import React from 'react';
import classnames from 'classnames';
import { Empty, Tooltip, Image, Hide } from 'components';
import AddItem from './addItem';
import DeleteItem from './deleteItem';
import GiftItem from './giftItem';
import EquipItem from './equipItem';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Table } from 'reactstrap';
import { sortBy, getWeight, randomSimpleString, getItemDescription } from 'helperFunctions';
import { useQuery, useSubscription } from '@apollo/client';
import { INVENTORY, INVENTORY_SUBSCRIPTION, ITEMS_SUBSCRIPTION } from 'queries';

export default function CharacterInventory({ id, className, Users }) {
    const [tab, setTab] = React.useState(0);
    const [uniqueId] = React.useState(randomSimpleString() + id + randomSimpleString());

    const [openAddItem, setOpenAddItem] = React.useState(false);
    const [openDestroyItem, setOpenDestroyItem] = React.useState(null);
    const [openGiftItem, setOpenGiftItem] = React.useState(null);
    const [openEquipItem, setOpenEquipItem] = React.useState(null);

    const [dropdownTabOpen, setDropdownTabOpen] = React.useState(false);

    const [cleanupFunc, setCleanupFunc] = React.useState([
        {
            id: 'add-open',
            cleanup: () => setOpenAddItem(false),
        },
        { id: 'gift-open', cleanup: () => setOpenGiftItem(null) },
        { id: 'equip-open', cleanup: () => setOpenEquipItem(null) },
        { id: 'destroy-open', cleanup: () => setOpenDestroyItem(null) },
    ]);

    const {
        data: inventoryData,
        loading: inventoryLoading,
        refetch: inventoryRefetch,
    } = useQuery(INVENTORY, {
        fetchPolicy: 'network-only',
        variables: {
            id,
        },
    });

    useSubscription(INVENTORY_SUBSCRIPTION, {
        onData: () => {
            inventoryRefetch({ id });
        },
        variables: {
            id,
        },
    });
    useSubscription(ITEMS_SUBSCRIPTION, {
        onData: () => {
            inventoryRefetch({ id });
        },
    });
    const Inventory = inventoryLoading ? [] : inventoryData.inventory;

    const cleanup = (skipId = null) => {
        cleanupFunc.forEach((cleanupSec) => {
            if (cleanupSec.id !== skipId) {
                cleanupSec.cleanup();
            }
        });
    };

    React.useEffect(() => {
        cleanup();
        setOpenAddItem(false);
        setOpenDestroyItem(null);
        setOpenGiftItem(null);
        setOpenEquipItem(null);
        // eslint-disable-next-line
    }, [id]);

    React.useEffect(() => {
        if (openDestroyItem) {
            setOpenDestroyItem(Inventory.find((Item) => Item.id === openDestroyItem.id));
        }
        if (openGiftItem) {
            setOpenGiftItem(Inventory.find((Item) => Item.id === openGiftItem.id));
        }
        if (openEquipItem) {
            setOpenEquipItem(Inventory.find((Item) => Item.id === openEquipItem.id));
        }
        // eslint-disable-next-line
    }, [Inventory]);

    //processing
    const filterCategory = (items) => {
        if (tab === 0) {
            return items.filter((Item) => Item.type.inventoryTab !== -1);
        }
        if (tab === 10) {
            return items.filter((item) => [6, 7, 8, 9, 11].includes(item.type.inventoryTab));
        }
        return items.filter((item) => item.type.inventoryTab === tab);
    };

    const getCategoryEmpty = () => {
        switch (tab) {
            case 0:
                return 'Inventár je prázdny';
            case 1:
                return 'Nevlastníš žiadne zbraňe';
            case 2:
                return 'Nevlastníš žiadne brnenia';
            case 3:
                return 'Nevlastníš žiadne runy alebo elixíre';
            case 4:
                return 'Nevlastníš žiadne ingrediencie';
            case 5:
                return 'Nevlastníš žiadne jedlo';
            case 6:
                return 'Nevlastníš žiadne iné veci';
            case 7:
                return 'Niesú problémové veci';
            default:
                return '';
        }
    };

    const addToCleanup = (cleanupSec) => {
        setCleanupFunc([
            cleanupSec,
            ...cleanupFunc.filter((cleanupSec2) => cleanupSec2.id !== cleanupSec),
        ]);
    };

    const FilteredInventory = sortBy(filterCategory(Inventory), [
        { key: 'type.inventoryOrder', asc: true },
        { key: 'title', asc: true },
    ]);

    return (
        <div className={`char-inventory flex bkg-paper ${className ? className : ''}`}>
            <div className="tabs noselect">
                <div className={classnames({ active: tab === 0 }, 'tab')} onClick={() => setTab(0)}>
                    Všetko
                </div>
                <div className={classnames({ active: tab === 1 }, 'tab')} onClick={() => setTab(1)}>
                    Zbrane
                </div>
                <div className={classnames({ active: tab === 2 }, 'tab')} onClick={() => setTab(2)}>
                    Brnenie
                </div>
                <div className={classnames({ active: tab === 3 }, 'tab')} onClick={() => setTab(3)}>
                    Spotrebné
                </div>
                <div className={classnames({ active: tab === 4 }, 'tab')} onClick={() => setTab(4)}>
                    Ingrediencie
                </div>
                <div className={classnames({ active: tab === 5 }, 'tab')} onClick={() => setTab(5)}>
                    Jedlo
                </div>
                <div
                    className={classnames(
                        { active: [6, 7, 8, 9, 10, 11].includes(tab) },
                        'tab-dropdown'
                    )}
                >
                    <Dropdown
                        isOpen={dropdownTabOpen}
                        toggle={() => {
                            if (dropdownTabOpen) {
                                setDropdownTabOpen(false);
                            } else {
                                setDropdownTabOpen(true);
                            }
                        }}
                        className="center-hor"
                    >
                        <DropdownToggle>Iné</DropdownToggle>
                        <DropdownMenu start="true">
                            <DropdownItem
                                active={tab === 10}
                                onClick={() => {
                                    setTab(10);
                                }}
                            >
                                Všetky
                            </DropdownItem>
                            <DropdownItem
                                active={tab === 11}
                                onClick={() => {
                                    setTab(11);
                                }}
                            >
                                Ruksákové
                            </DropdownItem>
                            <DropdownItem
                                active={tab === 9}
                                onClick={() => {
                                    setTab(9);
                                }}
                            >
                                Iné
                            </DropdownItem>
                            <DropdownItem
                                active={tab === 6}
                                onClick={() => {
                                    setTab(6);
                                }}
                            >
                                Doplnky
                            </DropdownItem>
                            <DropdownItem
                                active={tab === 7}
                                onClick={() => {
                                    setTab(7);
                                }}
                            >
                                Nástroje
                            </DropdownItem>
                            <DropdownItem
                                active={tab === 8}
                                onClick={() => {
                                    setTab(8);
                                }}
                            >
                                Dokumenty a kľúče
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <Hide gamePaused adminOrDm currentUser id={id}>
                    <div className="tab green m-l-auto">
                        <i
                            className="fa fa-plus"
                            onClick={() => {
                                cleanup('add-open');
                                setOpenAddItem(!openAddItem);
                            }}
                            id={`add-item-btn-${id}`}
                        />
                    </div>
                    <AddItem
                        cleanup={cleanup}
                        addToCleanup={addToCleanup}
                        open={openAddItem}
                        id={id}
                    />
                </Hide>
            </div>
            <div className="inventory-table" id={`inventory-table-${uniqueId}`}>
                <Table striped>
                    <thead>
                        <tr>
                            <th colSpan={2}>Názov</th>
                            {[0, 10].includes(tab) && <th width="50">Kategória</th>}
                            <th width="50">Váha</th>
                            <th width="50">Ks</th>
                            <Hide adminOrDm currentUser gamePaused id={id}>
                                <th width="86">Akcie</th>
                            </Hide>
                        </tr>
                    </thead>
                    <tbody>
                        {FilteredInventory.map((item) => (
                            <tr key={item.id}>
                                <td width="30">
                                    <Image
                                        path={item.icon}
                                        width={30}
                                        height={30}
                                        focus
                                        fillEmpty
                                    />
                                </td>
                                <td>
                                    {item.equipped && (
                                        <Empty>
                                            <i
                                                className="fa fa-eye m-r-5 color-green"
                                                id={`equipped-tooltip-${item.itemId}-${id}`}
                                            />
                                            <Tooltip
                                                placement="top"
                                                target={`equipped-tooltip-${item.itemId}-${id}`}
                                                text="Vybavené"
                                            />
                                        </Empty>
                                    )}
                                    {item.type.id === 2 && item?.variants.length > 0 && (
                                        <Empty>
                                            <i
                                                className="fa fa-indian-rupee-sign m-r-5 color-green"
                                                id={`has-runes-tooltip-${item.itemId}-${id}`}
                                            />
                                            <Tooltip
                                                placement="top"
                                                target={`has-runes-tooltip-${item.itemId}-${id}`}
                                                text="Má runy"
                                            />
                                        </Empty>
                                    )}
                                    <span id={`item-title-tooltip-${item.itemId}-${id}`}>
                                        {item.title}
                                    </span>
                                    {getItemDescription(item, false, false, true) && (
                                        <Tooltip
                                            placement="top"
                                            target={`item-title-tooltip-${item.itemId}-${id}`}
                                            text={getItemDescription(item, false, false, true)}
                                        />
                                    )}
                                </td>
                                {[0, 10].includes(tab) && (
                                    <th
                                        style={{ color: item.type.color }}
                                        className={classnames({
                                            'font-13': item.type.inventoryLabel.length > 11,
                                        })}
                                    >
                                        {item.type.inventoryLabel}
                                    </th>
                                )}
                                <td>{getWeight(item.weight)}</td>
                                <td>{item.quantity}</td>
                                <Hide gamePaused adminOrDm currentUser id={id}>
                                    <td className="p-l-0 p-r-0">
                                        <Hide adminOrDm currentUser id={id}>
                                            <div>
                                                {[2, 3, 5, 6, 7, 8, 9, 12].includes(
                                                    item.type.id
                                                ) && (
                                                    <Empty>
                                                        <i
                                                            className="clickable fa-solid fa-file-arrow-up m-l-10 color-blue"
                                                            id={`equip-tooltip-${item.itemId}-${id}`}
                                                            onClick={() => {
                                                                cleanup('equip-open');
                                                                if (
                                                                    openEquipItem?.itemId ===
                                                                    item.itemId
                                                                ) {
                                                                    setOpenEquipItem(null);
                                                                } else {
                                                                    setOpenEquipItem(item);
                                                                }
                                                            }}
                                                        />
                                                        <Tooltip
                                                            placement="top"
                                                            target={`equip-tooltip-${item.itemId}-${id}`}
                                                            text="Vybaviť vec"
                                                        />
                                                    </Empty>
                                                )}
                                                <i
                                                    className="clickable fa fa-retweet m-l-10 color-green"
                                                    onClick={() => {
                                                        cleanup('gift-open');
                                                        if (openGiftItem?.itemId === item.itemId) {
                                                            setOpenGiftItem(null);
                                                        } else {
                                                            setOpenGiftItem(item);
                                                        }
                                                    }}
                                                    id={`give-item-tooltip-${item.itemId}-${id}`}
                                                />
                                                <Tooltip
                                                    placement="top"
                                                    target={`give-item-tooltip-${item.itemId}-${id}`}
                                                    text="Darovať vec"
                                                />
                                                <i
                                                    className="clickable fa fa-trash m-l-10 color-red"
                                                    onClick={() => {
                                                        cleanup('destroy-open');
                                                        if (
                                                            openDestroyItem?.itemId === item.itemId
                                                        ) {
                                                            setOpenDestroyItem(null);
                                                        } else {
                                                            setOpenDestroyItem(item);
                                                        }
                                                    }}
                                                    id={`destroy-item-tooltip-${item.itemId}-${id}`}
                                                />
                                                <Tooltip
                                                    placement="top"
                                                    target={`destroy-item-tooltip-${item.itemId}-${id}`}
                                                    text="Odobrať vec"
                                                />
                                            </div>
                                        </Hide>
                                    </td>
                                </Hide>
                            </tr>
                        ))}
                        {FilteredInventory.length === 0 && (
                            <tr>
                                <td colSpan={tab === 0 || tab === 7 ? 6 : 5}>
                                    {getCategoryEmpty()}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                <DeleteItem
                    id={id}
                    cleanup={cleanup}
                    addToCleanup={addToCleanup}
                    Item={openDestroyItem}
                    uniqueId={uniqueId}
                />
                <GiftItem
                    cleanup={cleanup}
                    addToCleanup={addToCleanup}
                    Item={openGiftItem}
                    uniqueId={uniqueId}
                    Users={Users.filter((User) => User.id !== id)}
                />
                <EquipItem
                    cleanup={cleanup}
                    addToCleanup={addToCleanup}
                    Item={openEquipItem}
                    uniqueId={uniqueId}
                    Inventory={Inventory}
                />
            </div>
        </div>
    );
}
