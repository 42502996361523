import { useApolloClient, useQuery } from '@apollo/client';
import { GET_MY_DATA, GET_RIGHTS, GET_SETTINGS } from 'apollo/queries';

export const GetMyData = () => {
    const client = useApolloClient();
    const myData = client.readQuery({
        query: GET_MY_DATA,
    });
    if (myData) {
        return myData.getMyData;
    }
    return null;
};

export const GetMyRights = () => {
    const { data } = useQuery(GET_RIGHTS);
    return data.getRights;
};

export const GetSettings = () => {
    let { data } = useQuery(GET_SETTINGS);
    if (data.Settings.userRolling === null) {
        return {
            ...data.Settings,
            userRolling: { label: 'Dungeon Master', value: null, id: null },
        };
    }
    return data.Settings;
};
