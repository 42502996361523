import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from 'reactstrap';
import { DISCOVER } from 'queries/dmExtra';
import { useMutation } from '@apollo/client';
import { Image, Tooltip } from 'components';

export default function Discover(props) {
    const { open, setOpen, dataFilter, setDataFilter, type, title, data, miniature } = props;
    const [discover] = useMutation(DISCOVER);

    return (
        <Dropdown
            isOpen={open === `discover-${type}`}
            toggle={() => {
                if (open === `discover-${type}`) {
                    setOpen(null);
                } else {
                    setOpen(`discover-${type}`);
                }
            }}
            className="commandbar-dropdown-inner center-hor"
            direction="end"
        >
            <DropdownToggle
                className="commandbar-dropdown-inner-toggle full-width text-left"
                disabled={data.length === 0}
            >
                {title}
            </DropdownToggle>
            <DropdownMenu start="true" className="max-height-50vh overflow-x">
                <div className="p-10">
                    <Input
                        value={dataFilter}
                        placeholder="Hľadať"
                        onChange={(e) => setDataFilter(e.target.value)}
                    />
                </div>
                {data
                    .filter((Item) => Item.title.toLowerCase().includes(dataFilter.toLowerCase()))
                    .map((Item) => (
                        <DropdownItem
                            key={Item.id}
                            onClick={() => {
                                discover({ variables: { id: Item.id, type } });
                            }}
                            id={`show-${type}-miniature-${Item.id}`}
                        >
                            {Item.title}
                            {miniature && (
                                <Tooltip
                                    placement="right"
                                    target={`show-${type}-miniature-${Item.id}`}
                                    text={<Image path={Item[type]} small />}
                                    style={{ backgroundColor: 'transparent' }}
                                />
                            )}
                        </DropdownItem>
                    ))}
            </DropdownMenu>
        </Dropdown>
    );
}
