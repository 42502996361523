import { gql } from '@apollo/client';

export const CREATURE = gql`
    query creature($id: Int!) {
        creature(id: $id) {
            id
            createdAt
            updatedAt
            title
            level
            visibility
            important

            portrait
            capacity
            energy
            defence
            dexterity
            speed
            strength
            vitality
            endurance
        }
    }
`;

export const CREATURES = gql`
    query creatures($visibleOnly: Boolean!) {
        creatures(visibleOnly: $visibleOnly) {
            id
            title
            visibility
            important
            portrait
        }
    }
`;
export const ADD_CREATURE = gql`
    mutation addCreature(
        $title: String!
        $level: Int!
        $portrait: String!
        $visibility: Boolean
        $important: Boolean
        $capacity: Int
        $energy: Int
        $defence: Int
        $dexterity: Int
        $speed: Int
        $strength: Int
        $vitality: Int
        $endurance: Int
    ) {
        addCreature(
            title: $title
            level: $level
            portrait: $portrait
            visibility: $visibility
            important: $important
            capacity: $capacity
            energy: $energy
            defence: $defence
            dexterity: $dexterity
            speed: $speed
            strength: $strength
            vitality: $vitality
            endurance: $endurance
        ) {
            id
        }
    }
`;
export const UPDATE_CREATURE = gql`
    mutation updateCreature(
        $id: Int!
        $title: String
        $level: Int
        $visibility: Boolean
        $important: Boolean
        $portrait: String
        $capacity: Int
        $energy: Int
        $defence: Int
        $dexterity: Int
        $speed: Int
        $strength: Int
        $vitality: Int
        $endurance: Int
    ) {
        updateCreature(
            id: $id
            title: $title
            level: $level
            visibility: $visibility
            important: $important
            portrait: $portrait
            capacity: $capacity
            energy: $energy
            defence: $defence
            dexterity: $dexterity
            speed: $speed
            strength: $strength
            vitality: $vitality
            endurance: $endurance
        ) {
            id
        }
    }
`;
export const DELETE_CREATURE = gql`
    mutation deleteCreature($id: Int!) {
        deleteCreature(id: $id) {
            id
        }
    }
`;

export const CREATURES_SUBSCRIPTION = gql`
    subscription creaturesSubscription {
        creaturesSubscription
    }
`;
