import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { useNavigate } from 'react-router';

export default function MapList({ fixedShow, setFixedShow, type, id, Maps }) {
    const navigate = useNavigate();

    return (
        <ListGroup className="library-list-categories">
            <ListGroupItem
                active={fixedShow.includes('maps')}
                action
                className="clickable m-t-10"
                onClick={() => {
                    if (fixedShow.includes('maps')) {
                        setFixedShow(fixedShow.filter((title) => title !== 'maps'));
                    } else {
                        setFixedShow([...fixedShow, 'maps']);
                    }
                }}
            >
                Mapy
            </ListGroupItem>
            {fixedShow.includes('maps') && (
                <ListGroup className="m-l-20 library-list-entries">
                    {Maps.map((Entry) => (
                        <ListGroupItem
                            key={Entry.id}
                            active={type === 'maps' && id === Entry.id}
                            action
                            className="clickable"
                            onClick={() => {
                                navigate(`/library/maps/${Entry.id}`);
                            }}
                        >
                            {Entry.title}
                        </ListGroupItem>
                    ))}
                    {Maps.length === 0 && (
                        <ListGroupItem className="noselect">Polička prázdna :c</ListGroupItem>
                    )}
                </ListGroup>
            )}
        </ListGroup>
    );
}
