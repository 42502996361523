import { Empty } from 'components';
import { formattedMessage } from 'helperFunctions';
import React from 'react';
import RenderFight from './renderFight';
import OpponentDisplay from './opponent';

/*
effect-combat-history-message
roll-combat-history-message
generic-combat-history-message
death-combat-history-message
*/

export default function ShowHistory({ History }) {
    const processHistory = () => {
        let round = 1;
        return History.filter(
            (Entry, index) => index !== History.length - 1 || !Entry.endOfRound
        ).map((Entry) => {
            if (Entry.endOfRound) {
                round = round + 1;
            }
            return {
                ...Entry,
                round,
            };
        });
    };

    const RenderEntry = (Entry, index) => {
        if (Entry.endOfRound) {
            return (
                <h4 key={index}>
                    Koniec kola {Entry.round - 1}! Začína kolo: {Entry.round}
                </h4>
            );
        } else if (Entry.skippedTurn) {
            return (
                <Empty key={index}>
                    <div className="flex-row">
                        <OpponentDisplay
                            type="Útočník"
                            className="center-ver"
                            Opponent={Entry.Attacker}
                        />
                    </div>
                    <div key={index} className="effect-combat-history-message">
                        {formattedMessage([
                            'Bojovník ',
                            {
                                className: 'text-bolder',
                                style: { color: Entry.Attacker.color },
                                text: Entry.Attacker.nickname,
                            },
                            ' prišiel o kolo! ',
                            Entry.Attacker.stunned ? 'Bol paralyzovaný!' : null,
                        ])}
                    </div>
                    <hr />
                </Empty>
            );
        }
        return (
            <Empty key={index}>
                <RenderFight key={index} Fight={Entry} />
            </Empty>
        );
    };

    return (
        <div className="max-width-1000">
            <h4>Začína kolo: 1</h4>
            {processHistory().map((Entry, index) => RenderEntry(Entry, index))}
        </div>
    );
}
