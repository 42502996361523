import React from 'react';
import { Button } from 'reactstrap';
import { Image } from 'components';
import { DELETE_RECIPE } from 'queries';
import { useMutation } from '@apollo/client';

export default function RecipeEdit(props) {
    const { recipe, removeFromTrader, updateOrder } = props;
    const { id } = recipe;
    const [order, setOrder] = React.useState(0);

    const [deleteRecipeMutation] = useMutation(DELETE_RECIPE);

    React.useEffect(() => {
        setOrder(recipe.order ? recipe.order.toString() : 0);
        // eslint-disable-next-line
    }, []);

    const deleteFunc = async () => {
        if (window.confirm('Naozaj chceš zmazať túto vec z celej hry?')) {
            deleteRecipeMutation({ variables: { id } });
            removeFromTrader();
        }
    };

    return (
        <tr key={id}>
            <td>
                <Image height={35} path={recipe?.icon} />
            </td>
            <td className="text-hor-middle p-l-20">{recipe.title}</td>
            <td className="text-hor-middle p-l-20">{recipe.description}</td>
            <td className="text-hor-middle p-l-10">Recept</td>
            <td colSpan={2} className="text-hor-middle p-l-20">
                {recipe.price}
            </td>
            <td>
                <input
                    className="form-control hidden-input"
                    value={order}
                    onBlur={() => {
                        updateOrder(isNaN(parseInt(order)) ? 0 : parseInt(order));
                    }}
                    onChange={(e) => setOrder(e.target.value)}
                />
            </td>
            <td>
                <Button color="none" onClick={deleteFunc}>
                    <i className="fa fa-trash danger" />
                </Button>
                <Button
                    color="none"
                    onClick={() => {
                        if (window.confirm('Naozaj chceš odobrať tento recept obchodníkovi?')) {
                            removeFromTrader(id);
                        }
                    }}
                >
                    <i className="fa fa-times warning" />
                </Button>
            </td>
        </tr>
    );
}
