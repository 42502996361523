import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import teams from 'configs/teams';
import Attributes from './attributes';
import { Image } from 'components';

export default function OpponentDisplay({ Opponent, type, className }) {
    const team = teams.find((team) => team.value === Opponent.team);

    return (
        <div className={`p-t-10 p-l-5 p-r-5 p-b-5 width-470 ${className ? className : ''}`}>
            <h4 className="text-bolder">
                {type}:
                <span className="m-l-5" style={{ color: Opponent.color }}>
                    {Opponent.nickname}
                </span>
            </h4>
            <div className="flex-row m-t-10 m-b-10">
                <Image className="flex" path={Opponent.icon} bottom height={250} />
                <Attributes
                    Opponent={Opponent}
                    extraDiv={
                        <div>
                            <div className="text-bolder">Level: {Opponent.level}</div>
                            <div
                                style={{ backgroundColor: team.color, color: 'white' }}
                                className="text-bolder text-hor-middle"
                            >
                                {team.label}
                            </div>
                        </div>
                    }
                />
            </div>

            {Opponent.type === 'creature' && (
                <FormGroup className="flex-row m-t-5">
                    <Label className="width-70 center-hor">Meno:</Label>
                    {Opponent.nickname}
                </FormGroup>
            )}
        </div>
    );
}
