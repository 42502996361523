import React from 'react';
import { Trader } from 'components/trader';

export default function TraderEntry({ id }) {
    return (
        <div className="library-right">
            <Trader id={id} inLibrary />
        </div>
    );
}
