import { gql } from '@apollo/client';

export const RACE = gql`
    query Race($id: Int!) {
        race(id: $id) {
            id
            createdAt
            updatedAt
            title
            description
            size
            icon
            portrait

            capacity
            energy
            defence
            dexterity
            speed
            strength
            vitality
            endurance
        }
    }
`;

export const RACES = gql`
    query races {
        races {
            id
            title
            size
        }
    }
`;
export const ADD_RACE = gql`
    mutation addRace(
        $title: String
        $description: String
        $size: Int
        $icon: String
        $portrait: String
        $capacity: Int
        $energy: Int
        $defence: Int
        $dexterity: Int
        $speed: Int
        $strength: Int
        $vitality: Int
        $endurance: Int
    ) {
        addRace(
            title: $title
            description: $description
            size: $size
            icon: $icon
            portrait: $portrait
            capacity: $capacity
            energy: $energy
            defence: $defence
            dexterity: $dexterity
            speed: $speed
            strength: $strength
            vitality: $vitality
            endurance: $endurance
        ) {
            id
        }
    }
`;
export const UPDATE_RACE = gql`
    mutation updateRace(
        $id: Int!
        $endurance: Int
        $icon: String
        $dexterity: Int
        $vitality: Int
        $strength: Int
        $speed: Int
        $defence: Int
        $energy: Int
        $capacity: Int
        $removePortrait: Boolean
        $portrait: String
        $removeIcon: Boolean
        $size: Int
        $description: String
        $title: String
    ) {
        updateRace(
            id: $id
            endurance: $endurance
            icon: $icon
            dexterity: $dexterity
            vitality: $vitality
            strength: $strength
            speed: $speed
            defence: $defence
            energy: $energy
            capacity: $capacity
            removePortrait: $removePortrait
            portrait: $portrait
            removeIcon: $removeIcon
            size: $size
            description: $description
            title: $title
        ) {
            id
        }
    }
`;
export const DELETE_RACE = gql`
    mutation deleteRace($id: Int!) {
        deleteRace(id: $id) {
            id
        }
    }
`;

export const RACES_SUBSCRIPTION = gql`
    subscription racesSubscription {
        racesSubscription
    }
`;
