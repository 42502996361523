import React from 'react';
import { Image, Tooltip } from 'components';
import { Button, Card, CardHeader, CardBody } from 'reactstrap';
import classnames from 'classnames';
import { stringToDiv } from 'helperFunctions';
import { RACE, RACES_SUBSCRIPTION } from 'queries';
import { useQuery, useSubscription } from '@apollo/client';
import { GET_LIBRARY_OPEN } from 'apollo/queries';
import { setShowLibraryList } from 'apollo/localSchema/actions';

export default function RaceEntry({ id }) {
    const { data: libraryOpenData } = useQuery(GET_LIBRARY_OPEN);
    const ShowLibraryList = libraryOpenData.ShowLibraryList;
    const {
        data: raceData,
        loading: raceLoading,
        refetch: raceRefetch,
    } = useQuery(RACE, {
        fetchPolicy: 'network-only',
        variables: {
            id,
        },
    });

    useSubscription(RACES_SUBSCRIPTION, {
        onData: () => {
            raceRefetch({ variables: { id } });
        },
    });

    const Race = raceLoading ? null : raceData.race;
    if (!Race) {
        return <div>Načítava sa...</div>;
    }

    return (
        <Card className="library-right">
            <CardHeader>
                <Button color="link" onClick={setShowLibraryList} id="show-hide-library-list">
                    <i
                        className={classnames({
                            'fa fa-arrow-left color-blue': ShowLibraryList,
                            'fa fa-arrow-right color-blue': !ShowLibraryList,
                        })}
                    />
                    <i className="m-l-2 fa fa-book-atlas color-blue" />
                </Button>
                <Tooltip
                    placement="top"
                    target="show-hide-library-list"
                    text={ShowLibraryList ? 'Schovať knižnicu' : 'Zobraziť knižnicu'}
                />
                {`${Race.title} (Rasy)`}
            </CardHeader>
            <CardBody className="p-20">
                <h4 className="flex-row">
                    <Image height={50} path={Race.icon} hideEmpty focus />
                    <span className="m-l-20 center-hor">Názov: {Race.title}</span>
                </h4>
                <div className="text-italics max-width-500"> {stringToDiv(Race.description)}</div>
                <div className="flex-row m-t-20">
                    <Image width={400} height={400} path={Race.portrait} hideEmpty showFull />
                    <div className="ancient-font font-25 m-l-20">
                        <div className="flex-row">
                            <div className="width-150">Vitalita</div>
                            <div>{Race.vitality}</div>
                        </div>
                        <div className="flex-row">
                            <div className="width-150">Výdrž</div>
                            <div>{Race.endurance}</div>
                        </div>
                        <div className="flex-row">
                            <div className="width-150">Sila</div>
                            <div>{Race.strength}</div>
                        </div>
                        <div className="flex-row">
                            <div className="width-150">Rýchlosť</div>
                            <div>{Race.speed}</div>
                        </div>
                        <div className="flex-row">
                            <div className="width-150">Obratnosť</div>
                            <div>{Race.dexterity}</div>
                        </div>
                        <div className="flex-row">
                            <div className="width-150">Obrana</div>
                            <div>{Race.defence}</div>
                        </div>
                        <div className="flex-row">
                            <div className="width-150">Energia</div>
                            <div>{Race.energy}</div>
                        </div>
                        <div className="flex-row">
                            <div className="width-150">Nosnosť</div>
                            <div>{Race.capacity} kg</div>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}
