import React from 'react';
import { Card, CardHeader, CardBody, Button } from 'reactstrap';
import { Tooltip } from 'components';
//import Attributes from './attributes';
import classnames from 'classnames';
import { COMBAT_HISTORY, DELETE_COMBAT_HISTORY } from 'queries';
import { useMutation, useQuery } from '@apollo/client';
import { GET_LIBRARY_OPEN } from 'apollo/queries';
import { setShowLibraryList } from 'apollo/localSchema/actions';
import { GetMyRights, timestampToPracticalDate } from 'helperFunctions';
import CombatSummary from './summary';
import ShowHistory from './history';

export default function CombatEntry({ id }) {
    const { AdminOrDm } = GetMyRights();

    const [tab, setTab] = React.useState(0);
    const [deleteCombatHistory] = useMutation(DELETE_COMBAT_HISTORY);

    const { data: libraryOpenData } = useQuery(GET_LIBRARY_OPEN);
    const ShowLibraryList = libraryOpenData.ShowLibraryList;

    const { data: combatHistoryData, loading: combatHistoryLoading } = useQuery(COMBAT_HISTORY, {
        fetchPolicy: 'network-only',
        variables: {
            id,
        },
    });
    if (combatHistoryLoading) {
        return (
            <Card className="library-right">
                <CardHeader className="thin-buttons flex-row text-hor-middle">
                    <CardBody className="p-20 width-700">Načítava sa...</CardBody>
                </CardHeader>
            </Card>
        );
    }

    const CombatHistory = combatHistoryData.combatHistory;
    const History = JSON.parse(CombatHistory.history);
    const Opponents = JSON.parse(CombatHistory.opponents);

    return (
        <Card className="library-right">
            <CardHeader className="thin-buttons flex-row text-hor-middle">
                <Button color="link" onClick={setShowLibraryList} id="show-hide-library-list">
                    <i
                        className={classnames({
                            'fa fa-arrow-left color-blue': ShowLibraryList,
                            'fa fa-arrow-right color-blue': !ShowLibraryList,
                        })}
                    />
                    <i className="m-l-2 fa fa-book-atlas color-blue" />
                </Button>
                <Tooltip
                    placement="top"
                    target="show-hide-library-list"
                    text={ShowLibraryList ? 'Schovať knižnicu' : 'Zobraziť knižnicu'}
                />
                {`[${timestampToPracticalDate(CombatHistory.createdAt)}] ${
                    CombatHistory.title
                } (História boja)`}
                {AdminOrDm && (
                    <Button
                        color="danger"
                        className="m-l-auto"
                        onClick={() => {
                            if (window.confirm('Si si istý že chceš zmazať tento záznam pokru?')) {
                                deleteCombatHistory({ variables: { id } });
                            }
                        }}
                    >
                        Zmazať záznam
                    </Button>
                )}
            </CardHeader>
            <CardBody className="p-t-0">
                <div className="tabs noselect">
                    <div
                        className={classnames({ active: tab === 0 }, 'tab')}
                        onClick={() => setTab(0)}
                    >
                        Boj
                    </div>
                    <div
                        className={classnames({ active: tab === 1 }, 'tab')}
                        onClick={() => setTab(1)}
                    >
                        Zhrnutie
                    </div>
                </div>
                {tab === 0 && <ShowHistory History={History} />}
                {tab === 1 && <CombatSummary Opponents={Opponents} />}
            </CardBody>
        </Card>
    );
}
