import React from 'react';
import { Card, CardHeader, CardBody, Button, FormGroup, Label, Col, Input } from 'reactstrap';
import Select from 'react-select';
import Textarea from 'react-textarea-autosize';
import { DELETE_ITEM, ITEM, ITEMS_SUBSCRIPTION, ITEM_TYPES, UPDATE_ITEM } from 'queries';
import { ImageInput, Empty } from 'components';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { toBase64 } from 'helperFunctions';

const noReplace = {
    value: null,
    id: null,
    label: 'Nič nenahrádza',
};

export default function ItemEdit(props) {
    const { itemID } = props;

    const [icon, setIcon] = React.useState(null);
    const [deleteIcon, setDeleteIcon] = React.useState(false);
    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [type, setType] = React.useState(null);
    const [price, setPrice] = React.useState(0);
    const [weight, setWeight] = React.useState(0);
    const [replaces, setReplaces] = React.useState(noReplace);

    const [vitality, setVitality] = React.useState(0);
    const [endurance, setEndurance] = React.useState(0);
    const [strength, setStrength] = React.useState(0);
    const [speed, setSpeed] = React.useState(0);
    const [dexterity, setDexterity] = React.useState(0);
    const [defence, setDefence] = React.useState(0);
    const [energy, setEnergy] = React.useState(0);
    const [capacity, setCapacity] = React.useState(0);

    const [saving, setSaving] = React.useState(false);

    const [updateItem] = useMutation(UPDATE_ITEM);
    const [deleteItem] = useMutation(DELETE_ITEM);

    const {
        data: itemData,
        loading: itemLoading,
        refetch: itemRefetch,
    } = useQuery(ITEM, {
        fetchPolicy: 'network-only',
        variables: {
            id: itemID,
        },
    });
    const Item = itemLoading ? null : itemData.item;

    const { data: itemTypesData, loading: itemTypesLoading } = useQuery(ITEM_TYPES, {
        fetchPolicy: 'network-only',
    });
    useSubscription(ITEMS_SUBSCRIPTION, {
        onData: () => {
            itemRefetch();
        },
    });

    const itemTypes = itemTypesLoading ? [] : itemTypesData.itemTypes;

    React.useEffect(() => {
        itemRefetch({ id: itemID });
        // eslint-disable-next-line
    }, [itemID]);

    React.useEffect(() => {
        if (!itemLoading && !itemTypesLoading && Item) {
            setIcon(null);
            setDeleteIcon(false);
            setTitle(Item.title);
            setDescription(Item.description);
            setType(Item.type);
            setPrice(Item.price);
            setWeight(Item.weight);
            setVitality(Item.vitality);
            setEndurance(Item.endurance);
            setStrength(Item.strength);
            setSpeed(Item.speed);
            setDexterity(Item.dexterity);
            setDefence(Item.defence);
            setEnergy(Item.energy);
            setCapacity(Item.capacity);
            setReplaces([noReplace, ...itemTypes].find((type) => type.id === Item.replaces));
        }
        // eslint-disable-next-line
    }, [Item, itemTypes]);

    const save = async () => {
        setSaving(true);
        let body = {
            id: itemID,
            title,
            description,
            type: type.id,
            price: isNaN(parseFloat(price)) ? Item.price : parseFloat(price),
            removeIcon: deleteIcon,
            weight: isNaN(parseFloat(weight)) ? 0 : parseFloat(weight),

            vitality: vitality === '' ? 0 : parseInt(vitality),
            endurance: endurance === '' ? 0 : parseInt(endurance),
            strength: strength === '' ? 0 : parseInt(strength),
            speed: speed === '' ? 0 : parseInt(speed),
            dexterity: dexterity === '' ? 0 : parseInt(dexterity),
            defence: defence === '' ? 0 : parseInt(defence),
            energy: energy === '' ? 0 : parseInt(energy),
            capacity: capacity === '' ? 0 : parseInt(capacity),
        };

        if (type.id === 12) {
            body.replaces = replaces.id;
        }

        if (icon) {
            body.icon = await toBase64(icon);
        }
        updateItem({ variables: body })
            .then(() => {
                //update STATE images
                setSaving(false);
                setIcon(null);
                setDeleteIcon(false);
            })
            .catch((e) => {
                setSaving(false);
                console.log(e);
            });
    };

    const deleteFunc = async () => {
        if (window.confirm(`Si si istý že chceš zmazať vec ${title}?`)) {
            deleteItem({ variables: { id: itemID } });
        }
    };

    let disabled =
        title === '' || type === null || price === '' || isNaN(parseFloat(weight)) || saving;
    return (
        <div className="settings-right">
            <Card>
                <CardHeader className="flex-row">
                    <Textarea
                        id="title"
                        className="form-control"
                        value={title}
                        placeholder="Zadaj názov veci"
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </CardHeader>
                <CardBody>
                    <FormGroup className="flex-row">
                        <Label for="description" sm={2}>
                            Popis
                        </Label>
                        <Textarea
                            id="description"
                            className="form-control"
                            value={description}
                            placeholder="Zadaj popis"
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="price" sm={2}>
                            Cena
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="price"
                                value={price}
                                placeholder="Zadaj cenu"
                                onChange={(e) => setPrice(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="weight" sm={2}>
                            Váha
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="weight"
                                value={weight}
                                placeholder="Zadaj váhu"
                                onChange={(e) => setWeight(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <ImageInput
                        setImage={setIcon}
                        image={icon}
                        id={`itemIcon${itemID}`}
                        label="Ikona"
                        placeholder="Vyberte ikonu"
                        original={Item?.icon}
                        height={150}
                        setDeleteImage={setDeleteIcon}
                        deleteImage={deleteIcon}
                    />
                    <FormGroup className="flex-row">
                        <Label for="icon" sm={2}>
                            Typ
                        </Label>
                        <Col sm={10}>
                            <Select
                                value={type}
                                options={itemTypes}
                                placeholder="Vyber typ"
                                onChange={(type) => setType(type)}
                            />
                        </Col>
                    </FormGroup>
                    {type?.id === 12 && (
                        <FormGroup className="flex-row">
                            <Label for="icon" sm={2}>
                                Nahrádza vzhľad:
                            </Label>
                            <Col sm={10}>
                                <Select
                                    value={replaces}
                                    options={[
                                        noReplace,
                                        ...itemTypes.filter((type) =>
                                            [2, 5, 6, 7, 8, 9].includes(type.id)
                                        ),
                                    ]}
                                    onChange={(replaces) => setReplaces(replaces)}
                                />
                            </Col>
                        </FormGroup>
                    )}
                    {[2, 4, 5, 6, 7, 8, 9, 12].includes(type?.id) && (
                        <Empty>
                            <hr />
                            <h5>Statusy</h5>
                            <FormGroup className="flex-row">
                                <Label for="vitality" sm={2}>
                                    Vitalita
                                </Label>
                                <Col sm={10}>
                                    <Input
                                        type="number"
                                        id="vitality"
                                        value={vitality}
                                        placeholder="Zadaj vitalitu"
                                        onChange={(e) => setVitality(e.target.value)}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="flex-row">
                                <Label for="endurance" sm={2}>
                                    Výdrž
                                </Label>
                                <Col sm={10}>
                                    <Input
                                        type="number"
                                        id="endurance"
                                        value={endurance}
                                        placeholder="Zadaj výdrž"
                                        onChange={(e) => setEndurance(e.target.value)}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="flex-row">
                                <Label for="strength" sm={2}>
                                    Sila
                                </Label>
                                <Col sm={10}>
                                    <Input
                                        type="number"
                                        id="strength"
                                        value={strength}
                                        placeholder="Zadaj silu"
                                        onChange={(e) => setStrength(e.target.value)}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="flex-row">
                                <Label for="speed" sm={2}>
                                    Rýchlosť
                                </Label>
                                <Col sm={10}>
                                    <Input
                                        type="number"
                                        id="speed"
                                        value={speed}
                                        placeholder="Zadaj rýchlosť"
                                        onChange={(e) => setSpeed(e.target.value)}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="flex-row">
                                <Label for="dexterity" sm={2}>
                                    Obratnosť
                                </Label>
                                <Col sm={10}>
                                    <Input
                                        type="number"
                                        id="dexterity"
                                        value={dexterity}
                                        placeholder="Zadaj obratnosť"
                                        onChange={(e) => setDexterity(e.target.value)}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="flex-row">
                                <Label for="defence" sm={2}>
                                    Obrana
                                </Label>
                                <Col sm={10}>
                                    <Input
                                        type="number"
                                        id="defence"
                                        value={defence}
                                        placeholder="Zadaj obranu"
                                        onChange={(e) => setDefence(e.target.value)}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="flex-row">
                                <Label for="energy" sm={2}>
                                    Energia
                                </Label>
                                <Col sm={10}>
                                    <Input
                                        type="number"
                                        id="energy"
                                        value={energy}
                                        placeholder="Zadaj energiu"
                                        onChange={(e) => setEnergy(e.target.value)}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="flex-row">
                                <Label for="capacity" sm={2}>
                                    Nosnosť
                                </Label>
                                <Col sm={10}>
                                    <Input
                                        type="number"
                                        id="capacity"
                                        value={capacity}
                                        placeholder="Zadaj nosnosť"
                                        onChange={(e) => setCapacity(e.target.value)}
                                    />
                                </Col>
                            </FormGroup>
                        </Empty>
                    )}
                    <div className="flex-row">
                        <Button color="success" disabled={disabled} onClick={save}>
                            {saving ? 'Ukladá sa...' : 'Uložiť vec'}
                        </Button>
                        <Button color="danger" className="m-l-auto" onClick={deleteFunc}>
                            Vymazať
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
