import React from 'react';
import { Button, Label, ListGroup, ListGroupItem } from 'reactstrap';
import RaceEdit from './edit';
import RaceAdd from './add';
import { Tooltip } from 'components';
import { useParams, useNavigate } from 'react-router';
import { RACES, RACES_SUBSCRIPTION } from 'queries';
import { useQuery, useSubscription } from '@apollo/client';

export default function RaceList() {
    const { id } = useParams();
    const raceID = parseInt(id);
    const navigate = useNavigate();
    const {
        data: racesData,
        loading: racesLoading,
        refetch: racesRefetch,
    } = useQuery(RACES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(RACES_SUBSCRIPTION, {
        onData: () => {
            racesRefetch();
        },
    });

    const Races = racesLoading ? [] : racesData.races;

    return (
        <div className="flex-row">
            <div className="settings-left">
                <div className="flex-row">
                    <Label className="m-t-5 color-white">Rasy</Label>
                    <Button
                        color="link"
                        id="add-race"
                        className="center-hor m-l-auto m-b-5"
                        onClick={() => navigate(`/nastavenia_hry/rasy/add`)}
                    >
                        <i className="fa fa-plus" />
                    </Button>
                    <Tooltip placement="right" target="add-race" text="Vytvoriť novú rasu" />
                </div>
                <ListGroup>
                    {Races.map((Race) => (
                        <ListGroupItem
                            key={Race.id}
                            active={raceID === Race.id}
                            action
                            className="clickable"
                            onClick={() => {
                                navigate(`/nastavenia_hry/rasy/${Race.id}`);
                            }}
                        >
                            {Race.title}
                            <span className="center-right-absolute highlighted-text">
                                {Race.size}
                            </span>
                        </ListGroupItem>
                    ))}
                </ListGroup>
            </div>
            {!isNaN(raceID) && Races.some((Race) => Race.id === raceID) && (
                <RaceEdit raceID={raceID} />
            )}
            {id === 'add' && <RaceAdd />}
        </div>
    );
}
