import React from 'react';
import { Popover, PopoverHeader, FormGroup, Label, Button, Input } from 'reactstrap';
import Select from 'react-select';
import { GIFT_ITEM } from 'queries';
import { toSelArr } from 'helperFunctions';
import { useMutation } from '@apollo/client';

export default function GiftItem(props) {
    const { cleanup, addToCleanup, Item, uniqueId, Users } = props;

    const [toCharacter, setToCharacter] = React.useState(null);
    const [quantity, setQuantity] = React.useState(1);
    const [processing, setProcessing] = React.useState(false);
    const [variant, setVariant] = React.useState(null);
    const [giftItemMutation] = useMutation(GIFT_ITEM);

    React.useEffect(() => {
        addToCleanup({
            id: 'gift',
            cleanup: () => {
                setToCharacter(null);
                setQuantity(1);
                setVariant(null);
            },
        });
        // eslint-disable-next-line
    }, []);

    if (Item === null) {
        return null;
    }

    const giftItem = async () => {
        setProcessing(true);
        let body = {
            id: Item.id,
            toChar: toCharacter.id,
            quantity: isNaN(parseInt(quantity)) ? 1 : parseInt(quantity),
        };
        if (variant !== null && variant.id !== null) {
            body.variantId = variant.id;
        }
        giftItemMutation({ variables: body })
            .then(() => {
                setProcessing(false);
                cleanup();
            })
            .catch((e) => {
                console.log(e);
                setProcessing(false);
            });
    };

    const getVariantsSelect = () => {
        let variants = [];
        let equippedVariant = Item.equipped
            ? Item.variants.find((equippedVariant) => equippedVariant.equipped)
            : null;
        if (Item.quantity > Item.variants.length) {
            variants.push({
                id: null,
                value: null,
                label:
                    Item.equipped && !equippedVariant
                        ? `Základná zbraň (vybavená)`
                        : `Základná zbraň`,
            });
        }
        Item.variants.forEach((Variant) => {
            const variantTitle = `${Variant.rune1 ? Variant.rune1.title : 'Bez runy'},${
                Variant.rune2 ? Variant.rune2.title : 'bez runy'
            },${Variant.rune3 ? Variant.rune3.title : 'bez runy'}`;
            variants.push({
                id: Variant.id,
                value: Variant.id,
                label: Variant.equipped ? `${variantTitle} (vybavená)` : variantTitle,
            });
        });
        return variants;
    };

    return (
        <Popover
            className="popover-400"
            placement="right"
            isOpen={![null, undefined].includes(Item)}
            target={`inventory-table-${uniqueId}`}
            toggle={() => {}}
        >
            <PopoverHeader>{`Darovanie ${Item ? Item.title : ''}`}</PopoverHeader>
            {Item && (
                <div className="p-20">
                    {Item.type.id === 2 && (
                        <FormGroup>
                            <Label>Variant</Label>
                            <Select
                                value={variant}
                                options={getVariantsSelect()}
                                placeholder="Variant zbrane"
                                onChange={(variant) => {
                                    setVariant(variant);
                                }}
                            />
                        </FormGroup>
                    )}
                    {Item.type.id !== 2 && (
                        <FormGroup>
                            <Label htmlFor="gift-item-quantity">
                                {`Množstvo (${Item.quantity})`}
                            </Label>
                            <Input
                                type="number"
                                id="gift-item-quantity"
                                value={quantity}
                                placeholder="Zadaj množstvo"
                                onChange={(e) => setQuantity(e.target.value)}
                            />
                        </FormGroup>
                    )}
                    <FormGroup>
                        <Label>Hráčovi</Label>
                        <Select
                            value={toCharacter}
                            options={toSelArr(Users, 'character', 'id')}
                            placeholder="Darovať hráčovi"
                            onChange={(character) => {
                                setToCharacter(character);
                            }}
                        />
                    </FormGroup>
                    <div className="flex-row">
                        <Button
                            color="success"
                            disabled={
                                processing ||
                                isNaN(parseInt(quantity)) ||
                                parseInt(quantity) < 1 ||
                                !Item ||
                                parseInt(quantity) > Item.quantity ||
                                toCharacter === null ||
                                (Item.type.id === 2 && variant === null)
                            }
                            onClick={giftItem}
                        >
                            {processing ? 'Daruje sa...' : 'Darovať'}
                        </Button>

                        <Button color="warning" className="m-l-auto" onClick={cleanup}>
                            Zavrieť
                        </Button>
                    </div>
                </div>
            )}
        </Popover>
    );
}
