import React from 'react';
import { Card } from 'reactstrap';
import {
    BATTLEFIELDS,
    BATTLEFIELDS_SUBSCRIPTION,
    CHANGE_ATTACKER,
    COMBAT_SIMULATOR,
    COMBAT_SIMULATOR_SUBSCRIPTION,
    COMBAT_USERS_AND_CREATURES,
    INVENTORY_SUBSCRIPTION,
    USERS,
    USERS_SUBSCRIPTION,
} from 'queries';
import CombatOpponents from './opponents';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import CombatPreparation from './preparation';
import PreFight from './preFight';
import Fight from './fight';
import Summary from './summary';

export default function CombatSimulator() {
    const [selectedOpponent, setSelectedOpponent] = React.useState(null);

    const [changeAttacker] = useMutation(CHANGE_ATTACKER);

    const {
        data: combatSimulatorData,
        loading: combatSimulatorLoading,
        refetch: combatSimulatorRefetch,
    } = useQuery(COMBAT_SIMULATOR, {
        fetchPolicy: 'network-only',
    });

    useSubscription(COMBAT_SIMULATOR_SUBSCRIPTION, {
        onData: () => {
            combatSimulatorRefetch();
        },
    });

    const {
        data: battlefieldsData,
        loading: battlefieldsLoading,
        refetch: battlefieldsRefetch,
    } = useQuery(BATTLEFIELDS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(BATTLEFIELDS_SUBSCRIPTION, {
        onData: () => {
            battlefieldsRefetch();
            combatSimulatorRefetch();
        },
    });

    const {
        data: combatUsersAndCreaturesData,
        loading: combatUsersAndCreaturesLoading,
        refetch: combatUsersAndCreaturesRefetch,
    } = useQuery(COMBAT_USERS_AND_CREATURES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(INVENTORY_SUBSCRIPTION, {
        onData: () => {
            combatUsersAndCreaturesRefetch();
            combatSimulatorRefetch();
        },
    });

    useSubscription(USERS_SUBSCRIPTION, {
        onData: () => {
            combatUsersAndCreaturesRefetch();
            combatSimulatorRefetch();
        },
    });

    const {
        data: usersData,
        loading: usersLoading,
        refetch: usersRefetch,
    } = useQuery(USERS, {
        fetchPolicy: 'network-only',
        variables: {
            dm: false,
            includeInactive: false,
        },
    });

    useSubscription(USERS_SUBSCRIPTION, {
        onData: () => {
            usersRefetch({
                dm: false,
                includeInactive: false,
            });
        },
    });

    const Users = usersLoading ? [] : usersData.users;

    const CombatUsersAndCreatures = combatUsersAndCreaturesLoading
        ? []
        : combatUsersAndCreaturesData.combatUsersAndCreatures;

    const CombatSimulator = combatSimulatorLoading ? null : combatSimulatorData.combatSimulator;
    const Battlefields = battlefieldsLoading ? [] : battlefieldsData.battlefields;

    if (combatSimulatorLoading) {
        return (
            <Card className="flex center-hor m-20 p-20">
                <div className="flex-row">
                    <div className="center-ver flex-row">Načítava sa bojový simulátor</div>
                </div>
            </Card>
        );
    }

    const progress = CombatSimulator.progress;

    return (
        <Card className="flex center-hor m-20 p-20 overflow-x max-height-combat-sim">
            {progress !== -2 && (
                <CombatOpponents
                    Opponents={CombatSimulator.opponents}
                    CombatUsersAndCreatures={CombatUsersAndCreatures}
                    selectOpponent={
                        progress === -1
                            ? setSelectedOpponent
                            : (id) => changeAttacker({ variables: { id } })
                    }
                    selectedOpponent={
                        progress === -1 ? selectedOpponent : CombatSimulator.currentAttacker
                    }
                    progress={progress}
                    CombatSimulator={CombatSimulator}
                    dataLoaded={!battlefieldsLoading && !combatSimulatorLoading && !usersLoading}
                    Battlefields={Battlefields}
                    Users={Users}
                />
            )}
            <hr className="m-t-0 m-b-0" />
            {progress === -1 && (
                <CombatPreparation
                    CombatSimulator={CombatSimulator}
                    selectedOpponent={selectedOpponent}
                    Battlefields={Battlefields}
                />
            )}
            {progress === 0 && <PreFight CombatSimulator={CombatSimulator} />}
            {progress === 1 && <Fight CombatSimulator={CombatSimulator} />}
            {progress === -2 && <Summary CombatSimulator={CombatSimulator} />}
        </Card>
    );
}
