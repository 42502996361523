import { gql } from '@apollo/client';

export const DEBTS = gql`
    query debts {
        debts {
            id
            createdAt
            updatedAt
            debt
            creditor {
                id
                color
                character
                gold
            }
            debtor {
                id
                color
                character
                gold
            }
        }
    }
`;

export const MODIFY_DEBT = gql`
    mutation modifyDebt($debt: Int!, $from: Int!, $to: Int!) {
        modifyDebt(debt: $debt, from: $from, to: $to)
    }
`;

export const DEBTS_SUBSCRIPTION = gql`
    subscription debtsSubscription {
        debtsSubscription
    }
`;
