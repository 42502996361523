import React from 'react';
import { Card, CardHeader, CardBody, Button, FormGroup, Label, Col, Input } from 'reactstrap';
import { ImageInput } from 'components';
import { toBase64 } from 'helperFunctions';
import { ADD_RACE } from 'queries';
import { useMutation } from '@apollo/client';

export default function RaceAdd() {
    const [description, setDescription] = React.useState('');
    const [title, setTitle] = React.useState('');
    const [icon, setIcon] = React.useState(null);
    const [portrait, setPortrait] = React.useState(null);
    const [size, setSize] = React.useState(0);

    const [vitality, setVitality] = React.useState(0);
    const [endurance, setEndurance] = React.useState(0);
    const [strength, setStrength] = React.useState(0);
    const [speed, setSpeed] = React.useState(0);
    const [dexterity, setDexterity] = React.useState(0);
    const [defence, setDefence] = React.useState(0);
    const [energy, setEnergy] = React.useState(0);
    const [capacity, setCapacity] = React.useState(0);
    const [adding, setAdding] = React.useState(false);

    const [addRace] = useMutation(ADD_RACE);

    const add = async () => {
        setAdding(true);
        let body = {
            description,
            title,
            size: size === '' ? 0 : parseInt(size),

            vitality: vitality === '' ? 0 : parseInt(vitality),
            endurance: endurance === '' ? 0 : parseInt(endurance),
            strength: strength === '' ? 0 : parseInt(strength),
            speed: speed === '' ? 0 : parseInt(speed),
            dexterity: dexterity === '' ? 0 : parseInt(dexterity),
            defence: defence === '' ? 0 : parseInt(defence),
            energy: energy === '' ? 0 : parseInt(energy),
            capacity: capacity === '' ? 0 : parseInt(capacity),
        };
        if (icon) {
            body.icon = await toBase64(icon);
        }
        if (portrait) {
            body.portrait = await toBase64(portrait);
        }

        addRace({ variables: body })
            .then(() => {
                //update STATE images
                setDescription('');
                setTitle('');
                setIcon(null);
                setPortrait(null);
                setSize(0);
                setAdding(false);

                setVitality(0);
                setEndurance(0);
                setStrength(0);
                setSpeed(0);
                setDexterity(0);
                setDefence(0);
                setEnergy(0);
                setCapacity(0);
            })
            .catch((e) => {
                setAdding(false);
                console.log(e);
            });
    };

    return (
        <div className="settings-right">
            <Card>
                <CardHeader className="flex-row">Nova rasa</CardHeader>
                <CardBody>
                    <FormGroup className="flex-row">
                        <Label for="race" sm={2}>
                            Názov
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="race"
                                value={title}
                                placeholder="Zadaj názov rasy"
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="description" sm={2}>
                            Popis
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="textarea"
                                id="description"
                                value={description}
                                placeholder="Zadaj popis rasy"
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <ImageInput
                        label="Ikona rasy"
                        id="raceIcon-new"
                        setImage={setIcon}
                        image={icon}
                        placeholder="Vyberte ikonu rasy"
                        height={150}
                    />
                    <ImageInput
                        setImage={setPortrait}
                        image={portrait}
                        id="racePortrait-new"
                        label="Portrét rasy"
                        placeholder="Vyberte portrét rasy"
                        height={400}
                    />
                    <FormGroup className="flex-row">
                        <Label for="size" sm={2}>
                            Veľkosť (poradie)
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="size"
                                value={size}
                                placeholder="Zadaj veľkosť"
                                onChange={(e) => setSize(e.target.value)}
                            />
                        </Col>
                    </FormGroup>

                    <h4>Základné atribúty</h4>
                    <FormGroup className="flex-row">
                        <Label for="vitality" sm={2}>
                            Vitalita
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="vitality"
                                value={vitality}
                                placeholder="Zadaj vitalitu"
                                onChange={(e) => setVitality(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="endurance" sm={2}>
                            Výdrž
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="endurance"
                                value={endurance}
                                placeholder="Zadaj výdrž"
                                onChange={(e) => setEndurance(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="strength" sm={2}>
                            Sila
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="strength"
                                value={strength}
                                placeholder="Zadaj silu"
                                onChange={(e) => setStrength(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="speed" sm={2}>
                            Rýchlosť
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="speed"
                                value={speed}
                                placeholder="Zadaj rýchlosť"
                                onChange={(e) => setSpeed(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="dexterity" sm={2}>
                            Obratnosť
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="dexterity"
                                value={dexterity}
                                placeholder="Zadaj obratnosť"
                                onChange={(e) => setDexterity(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="defence" sm={2}>
                            Obrana
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="defence"
                                value={defence}
                                placeholder="Zadaj obranu"
                                onChange={(e) => setDefence(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="energy" sm={2}>
                            Energia
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="energy"
                                value={energy}
                                placeholder="Zadaj energiu"
                                onChange={(e) => setEnergy(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="capacity" sm={2}>
                            Nosnosť
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="capacity"
                                value={capacity}
                                placeholder="Zadaj nosnosť"
                                onChange={(e) => setCapacity(e.target.value)}
                            />
                        </Col>
                    </FormGroup>

                    <div className="flex-row">
                        <Button
                            color="success"
                            disabled={adding || portrait === null || icon === null}
                            onClick={add}
                        >
                            {adding ? 'Pridáva sa...' : 'Pridať rasu'}
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
