import React from 'react';
import { Card, CardHeader, CardBody, Button, FormGroup, Label, Col, Input } from 'reactstrap';
import { Checkbox, ImageInput } from 'components';
import Textarea from 'react-textarea-autosize';
import { toBase64 } from 'helperFunctions';
import { DELETE_MAP, MAP, MAPS_SUBSCRIPTION, UPDATE_MAP } from 'queries';
import { useMutation, useQuery, useSubscription } from '@apollo/client';

export default function MapEdit(props) {
    const { mapID } = props;

    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [visibility, setVisibility] = React.useState(false);
    const [map, setMap] = React.useState(null);
    const [important, setImportant] = React.useState(false);
    const [saving, setSaving] = React.useState(false);

    const [updateMap] = useMutation(UPDATE_MAP);
    const [deleteMap] = useMutation(DELETE_MAP);

    const {
        data: mapData,
        loading: mapLoading,
        refetch: mapRefetch,
    } = useQuery(MAP, {
        fetchPolicy: 'network-only',
        variables: {
            id: mapID,
        },
    });

    useSubscription(MAPS_SUBSCRIPTION, {
        onData: () => {
            mapRefetch({ variables: { id: mapID } });
        },
    });

    const Map = mapLoading ? null : mapData.map;

    React.useEffect(() => {
        mapRefetch({ id: mapID });
        // eslint-disable-next-line
    }, [mapID]);

    React.useEffect(() => {
        if (!mapLoading) {
            setTitle(Map.title);
            setDescription(Map.description);
            setVisibility(Map.visibility);
            setImportant(Map.important);
        }
        // eslint-disable-next-line
    }, [Map]);

    const save = async () => {
        setSaving(true);
        //save images
        let body = {
            id: mapID,
            title,
            description,
            visibility,
            important,
        };
        if (map) {
            body.map = await toBase64(map);
        }
        updateMap({ variables: body })
            .then(() => {
                //update STATE images
                setSaving(false);
                setMap(null);
            })
            .catch((e) => {
                setSaving(false);
                console.log(e);
            });
    };

    const deleteFunc = async () => {
        if (window.confirm(`Si si istý že chceš zmazať miesto ${title}?`)) {
            deleteMap({ variables: { id: mapID } });
        }
    };

    return (
        <div className="settings-right">
            <Card>
                <CardHeader className="flex-row">
                    <Input
                        id="map"
                        value={title}
                        className="invisible-input"
                        mapholder="Názov mapy"
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </CardHeader>
                <CardBody>
                    <FormGroup>
                        <Checkbox
                            className="form-control-checkbox"
                            label="Spoznali mapu"
                            value={visibility}
                            right
                            centerHor
                            onChange={() => setVisibility(!visibility)}
                        />
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="description" sm={2}>
                            Popis
                        </Label>
                        <Col sm={10}>
                            <Textarea
                                className="form-control"
                                id="description"
                                mapholder="Zadaj popis oblasti"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <ImageInput
                        setImage={setMap}
                        image={map}
                        id={`map${mapID}`}
                        label="Mapa"
                        mapholder="Vyberte mapu"
                        original={Map?.map}
                        height={400}
                    />
                    <div className="flex-row">
                        <Button color="primary" disabled={saving} onClick={save}>
                            {saving ? 'Ukladá sa...' : 'Uložiť mapu'}
                        </Button>
                        <Button color="danger" className="m-l-auto" onClick={deleteFunc}>
                            Vymazať
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
