import React from 'react';
import { Button, Label, ListGroup, ListGroupItem } from 'reactstrap';
import BattlefieldEdit from './edit';
import BattlefieldAdd from './add';
import { Tooltip } from 'components';
import { useParams, useNavigate } from 'react-router';
import { BATTLEFIELDS, BATTLEFIELDS_SUBSCRIPTION } from 'queries';
import { useQuery, useSubscription } from '@apollo/client';

export default function BattlefieldList() {
    const { id } = useParams();
    const battlefieldID = parseInt(id);
    const navigate = useNavigate();
    const {
        data: battlefieldsData,
        loading: battlefieldsLoading,
        refetch: battlefieldsRefetch,
    } = useQuery(BATTLEFIELDS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(BATTLEFIELDS_SUBSCRIPTION, {
        onData: () => {
            battlefieldsRefetch();
        },
    });

    const Battlefields = battlefieldsLoading ? [] : battlefieldsData.battlefields;

    return (
        <div className="flex-row">
            <div className="settings-left">
                <div className="flex-row">
                    <Label className="color-white">Bojoviská</Label>
                    <Button
                        color="link"
                        id="add-battlefield"
                        className="center-hor m-l-auto m-b-5"
                        onClick={() => navigate('/nastavenia_hry/bojoviska/add')}
                    >
                        <i className="fa fa-plus" />
                    </Button>
                </div>
                <Tooltip
                    battlefieldment="right"
                    target="add-battlefield"
                    text="Vytvoriť nové bojovisko"
                />
                <ListGroup>
                    {Battlefields.map((battlefield) => (
                        <ListGroupItem
                            key={battlefield.id}
                            active={battlefieldID === battlefield.id}
                            action
                            className="clickable"
                            onClick={() => {
                                navigate(`/nastavenia_hry/bojoviska/${battlefield.id}`);
                            }}
                        >
                            {battlefield.title}
                        </ListGroupItem>
                    ))}
                </ListGroup>
            </div>
            {!isNaN(battlefieldID) &&
                Battlefields.some((battlefield) => battlefield.id === battlefieldID) && (
                    <BattlefieldEdit battlefieldID={battlefieldID} />
                )}
            {id === 'add' && <BattlefieldAdd className="settings-right" />}
        </div>
    );
}
