import { gql } from '@apollo/client';

export const LOOT_MANAGER = gql`
    query lootManager {
        lootManager {
            participants
        }
    }
`;

export const ADD_OR_REMOVE_PARTICIPANT = gql`
    mutation addOrRemoveParticipant($id: Int!) {
        addOrRemoveParticipant(id: $id) {
            participants
        }
    }
`;

export const LOOT_MANAGER_SUBSCRIPTION = gql`
    subscription lootManagerSubscription {
        lootManagerSubscription
    }
`;
