import { gql } from '@apollo/client';

export const TRADER = gql`
    query trader($id: Int!) {
        trader(id: $id) {
            id
            title
            visibility
            portrait
            recipes {
                order
                recipeId
                description
                icon
                price
                title
                id
                visibility
            }
            items {
                order
                itemId
                description
                icon
                title
                price
                type
                weight
                energy
                defence
                dexterity
                speed
                strength
                vitality
                endurance
                capacity
            }
        }
    }
`;

export const TRADERS = gql`
    query traders($visibleOnly: Boolean) {
        traders(visibleOnly: $visibleOnly) {
            id
            title
            visibility
        }
    }
`;
export const ADD_TRADER = gql`
    mutation addTrader(
        $title: String
        $visibility: Boolean
        $portrait: String
        $recipes: [TraderRecipeInput]
        $items: [TraderItemInput]
    ) {
        addTrader(
            title: $title
            visibility: $visibility
            portrait: $portrait
            recipes: $recipes
            items: $items
        ) {
            id
        }
    }
`;
export const UPDATE_TRADER = gql`
    mutation updateTrader(
        $id: Int!
        $title: String
        $portrait: String
        $visibility: Boolean
        $removePortrait: Boolean
        $items: [TraderItemInput]
        $recipes: [TraderRecipeInput]
    ) {
        updateTrader(
            id: $id
            title: $title
            portrait: $portrait
            visibility: $visibility
            removePortrait: $removePortrait
            items: $items
            recipes: $recipes
        ) {
            id
        }
    }
`;
export const DELETE_TRADER = gql`
    mutation deleteTrader($id: Int!) {
        deleteTrader(id: $id) {
            id
        }
    }
`;

export const TRADERS_SUBSCRIPTION = gql`
    subscription tradersSubscription {
        tradersSubscription
    }
`;
