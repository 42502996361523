import React from 'react';
import { Button, Label, ListGroup, ListGroupItem } from 'reactstrap';
import LibraryEdit from './edit';
import LibraryAdd from './add';
import { Empty, Tooltip } from 'components';
import { useParams, useNavigate } from 'react-router';
import { LIBRARY_ENTRIES, LIBRARY_ENTRIES_SUBSCRIPTION } from 'queries';
import { useQuery, useSubscription } from '@apollo/client';
import { sortBy } from 'helperFunctions';

export default function LibraryList() {
    const { id } = useParams();
    const libraryID = parseInt(id);
    const navigate = useNavigate();
    const [show, setShow] = React.useState([]);

    const {
        data: libraryEntriesData,
        loading: libraryEntriesLoading,
        refetch: libraryEntriesRefetch,
    } = useQuery(LIBRARY_ENTRIES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LIBRARY_ENTRIES_SUBSCRIPTION, {
        onData: () => {
            libraryEntriesRefetch();
        },
    });

    const groupLibraryEntries = (LibraryEntries) => {
        let GroupedLibraryEntries = [];
        LibraryEntries.forEach((LibraryEntry) => {
            const Index = GroupedLibraryEntries.findIndex(
                (Group) => Group.id === LibraryEntry.category.id
            );
            if (Index === -1) {
                GroupedLibraryEntries.push({
                    ...LibraryEntry.category,
                    LibraryEntries: [LibraryEntry],
                });
            } else {
                GroupedLibraryEntries[Index].LibraryEntries.push(LibraryEntry);
            }
        });
        return sortBy(GroupedLibraryEntries, [{ asc: true, key: 'order' }]);
    };

    const LibraryEntries = libraryEntriesLoading ? [] : libraryEntriesData.libraryEntries;
    const GroupedLibraryEntries = groupLibraryEntries(LibraryEntries);
    return (
        <div className="flex-row">
            <div className="settings-left">
                <div className="flex-row">
                    <Label className="m-t-5 color-white">Knižnica</Label>
                    <Button
                        color="link"
                        id="add-library-entry"
                        className="center-hor m-l-auto m-b-5"
                        onClick={() => navigate('/nastavenia_hry/kniznica/add')}
                    >
                        <i className="fa fa-plus" />
                    </Button>
                    <Tooltip
                        placement="right"
                        target="add-library-entry"
                        text="Vytvoriť nový záznam"
                    />
                </div>
                <ListGroup className="library-list-categories">
                    {GroupedLibraryEntries.map((Category) => (
                        <Empty key={Category.id}>
                            <ListGroupItem
                                key={Category.id}
                                active={show.includes(Category.id)}
                                action
                                className="clickable m-t-10"
                                onClick={() => {
                                    if (show.includes(Category.id)) {
                                        setShow(show.filter((id) => id !== Category.id));
                                    } else {
                                        setShow([...show, Category.id]);
                                    }
                                }}
                            >
                                {Category.title}
                                <span className="center-right-absolute ">{Category.order}</span>
                            </ListGroupItem>
                            {show.includes(Category.id) && (
                                <ListGroup className="m-l-20 library-list-entries">
                                    {Category.LibraryEntries.map((LibraryEntry) => (
                                        <ListGroupItem
                                            key={LibraryEntry.id}
                                            active={libraryID === LibraryEntry.id}
                                            action
                                            className="clickable"
                                            onClick={() => {
                                                navigate(
                                                    `/nastavenia_hry/kniznica/${LibraryEntry.id}`
                                                );
                                            }}
                                        >
                                            {LibraryEntry.title}
                                            <span className="center-right-absolute ">
                                                {LibraryEntry.order}
                                            </span>
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            )}
                        </Empty>
                    ))}
                </ListGroup>
            </div>
            {!isNaN(libraryID) && LibraryEntries.some((Library) => Library.id === libraryID) && (
                <LibraryEdit libraryID={libraryID} />
            )}
            {id === 'add' && <LibraryAdd />}
        </div>
    );
}
