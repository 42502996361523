import ReactDOM from 'react-dom/client';
import Navigation from 'navigation';
import Login from 'pages/login';
import { ApolloProvider, useQuery } from '@apollo/client';
import createClient from 'apollo/createClient';
import { GET_IS_LOGGED_IN } from 'apollo/queries';

import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'scss/index.scss';

const client = createClient();
const IsLoggedIn = () => {
    const { data } = useQuery(GET_IS_LOGGED_IN);
    return data.isLoggedIn ? <Navigation /> : <Login />;
};
document.getElementById('root').style.backgroundImage = 'url("/images/bkg-dark.png")';
document.getElementById('root').style.backgroundSize = 'cover';
document.getElementById('root').style.backgroundRepeat = 'no-repeat';
document.getElementById('root').style.backgroundSize = '100% auto';
document.getElementById('root').style.backgroundAttachment = 'fixed';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <ApolloProvider client={client}>
        <IsLoggedIn />
    </ApolloProvider>
);
