import { gql } from '@apollo/client';

export const COMBAT_HISTORY = gql`
    query combatHistory($id: Int!) {
        combatHistory(id: $id) {
            id
            createdAt
            title
            history
            opponents
        }
    }
`;

export const COMBAT_HISTORIES = gql`
    query combatHistories {
        combatHistories {
            id
            createdAt
            title
        }
    }
`;

export const DELETE_COMBAT_HISTORY = gql`
    mutation deleteCombatHistory($id: Int!) {
        deleteCombatHistory(id: $id)
    }
`;

export const COMBAT_HISTORIES_SUBSCRIPTION = gql`
    subscription combatHistoriesSubscription {
        combatHistoriesSubscription
    }
`;
