import { gql } from '@apollo/client';

export const GET_ALL_CRAFTINGS = gql`
    query getAllCraftings {
        getAllCraftings {
            createdAt
            id
            quantity
            recipe {
                ingredients {
                    id
                    itemId
                    title
                    quantity
                    icon
                }
                tools {
                    id
                    itemId
                    title
                    quantity
                    icon
                }
                results {
                    id
                    itemId
                    title
                    quantity
                    icon
                }
                title
            }
            user {
                id
                nickname
                color
                character
                inventory {
                    id
                    quantity
                    itemId
                }
            }
        }
    }
`;

export const REQUEST_CRAFTING = gql`
    mutation RequestCrafting($recipeId: Int!, $quantity: Int!) {
        requestCrafting(recipeId: $recipeId, quantity: $quantity) {
            id
        }
    }
`;
export const DENY_CRAFTING = gql`
    mutation DenyCrafting($id: Int!) {
        denyCrafting(id: $id) {
            id
        }
    }
`;
export const FINISH_CRAFTING = gql`
    mutation FinishCrafting($id: Int!) {
        finishCrafting(id: $id) {
            id
        }
    }
`;
export const DELETE_CRAFTING = gql`
    mutation DeleteCrafting($id: Int!) {
        deleteCrafting(id: $id) {
            id
        }
    }
`;

export const CRAFTINGS_SUBSCRIPTION = gql`
    subscription craftingsSubscription {
        craftingsSubscription
    }
`;
