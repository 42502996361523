import React from 'react';
import { Card, CardBody, CardFooter, CardHeader, Label, Button, Input } from 'reactstrap';
import {
    GetMyData,
    GetMyRights,
    replaceFromString,
    timestampToPracticalDate,
} from 'helperFunctions';
import Select from 'react-select';
import { Empty, Tooltip } from 'components';
import classnames from 'classnames';
import { pickSelectStyle } from 'configs/selectStyles';
import {
    DELETE_NOTE,
    SET_NOTE_IGNORED,
    SET_NOTE_PRIVATE,
    SET_NOTE_IMPORTANT,
    SET_NOTE_SUPER_PRIVATE,
    NOTES,
    NOTES_SUBSCRIPTION,
} from 'queries';
import NewNote from './newNote';
import EditNote from './editNote';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
const allUsers = {
    id: null,
    value: null,
    label: 'Všetci',
};
const allCategories = {
    id: null,
    value: null,
    label: 'Všetky',
};

export function Notes() {
    const currentUser = GetMyData();
    const Admin = currentUser.admin;
    const { AdminOrDm } = GetMyRights();
    const [filterUser, setFilterUser] = React.useState(allUsers);
    const [filterCategory, setFilterCategory] = React.useState(allCategories);
    const [filterIgnored, setFilterIgnored] = React.useState(true);
    const [openMessage, setOpenMessage] = React.useState(false);
    const [filterImportant, setFilterImportant] = React.useState(false);
    const [editedNote, setEditedNote] = React.useState(null);
    const [search, setSearch] = React.useState('');

    const [deleteNote] = useMutation(DELETE_NOTE);
    const [setNoteIgnored] = useMutation(SET_NOTE_IGNORED);
    const [setNotePrivate] = useMutation(SET_NOTE_PRIVATE);
    const [setNoteSuperPrivate] = useMutation(SET_NOTE_SUPER_PRIVATE);
    const [setNoteImportant] = useMutation(SET_NOTE_IMPORTANT);

    const {
        data: notesData,
        loading: notesLoading,
        refetch: notesRefetch,
    } = useQuery(NOTES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(NOTES_SUBSCRIPTION, {
        onData: () => {
            notesRefetch();
        },
    });

    const Notes = notesLoading ? [] : notesData.notes;
    const filterUserId = filterUser.value;
    const filterCategoryId = filterCategory.value;

    const filterNotes = () => {
        let currentSearch = replaceFromString(search, [' '], '')
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase();
        return Notes.filter(
            (Note) =>
                (!Note.isPrivate || Note.user?.id === currentUser.id || AdminOrDm) &&
                (!filterImportant || !Note.importantList.includes(currentUser.id)) &&
                (!filterIgnored || !Note.ignoreList.includes(currentUser.id)) &&
                (filterUserId === null || Note.user?.id === filterUserId) &&
                (filterCategoryId === null || Note.category === filterCategoryId) &&
                (currentSearch.length === 0 ||
                    replaceFromString(
                        `${Note.category}${Note.user?.nickname}${Note.user?.character}${
                            Note.category
                        }${Note.message.replace(/<[^>]+>/g, '')}`,
                        [' '],
                        ''
                    )
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .toLowerCase()
                        .includes(currentSearch))
        ).sort((Note1, Note2) => {
            if (
                Note1.importantList.includes(currentUser.id) &&
                !Note2.importantList.includes(currentUser.id)
            ) {
                return -1;
            } else {
                return parseInt(Note2.createdAt) - parseInt(Note1.createdAt);
            }
        });
    };
    const FilteredNotes = filterNotes();
    const getCategories = (priv = false) => {
        let categoryOptions = [allCategories];
        Notes.forEach((Note) => {
            if (
                (priv || !Note.isPrivate || currentUser.id === Note.user?.id) &&
                !categoryOptions.some((option) => option.id === Note.category)
            ) {
                categoryOptions.push({
                    id: Note.category,
                    value: Note.category,
                    label: Note.category,
                });
            }
        });
        return categoryOptions;
    };
    const getUsers = () => {
        let userOptions = [allUsers];
        FilteredNotes.forEach((Note) => {
            if (Note.user !== null && !userOptions.some((option) => option.id === Note.user.id)) {
                if (currentUser.id === Note.user.id) {
                    userOptions.push({
                        id: currentUser.id,
                        value: currentUser.id,
                        label: `Ja (${currentUser.nickname})`,
                    });
                } else {
                    userOptions.push({
                        id: Note.user.id,
                        value: Note.user.id,
                        label: Note.user.nickname,
                    });
                }
            }
        });
        return userOptions;
    };

    if (notesLoading || !currentUser) {
        return (
            <Card className="small-card width-500">
                <CardHeader>Poznámky</CardHeader>
                <CardBody>Načítavajú sa....</CardBody>
                <CardFooter></CardFooter>
            </Card>
        );
    }

    return (
        <Card className="small-card notes flex">
            <CardHeader>
                <div className="flex-row">
                    <span className="center-hor">Poznámky</span>
                    <div className="m-l-auto center-hor">
                        <Input
                            className="p-0"
                            placeholder="Prehľadávať..."
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                    <Button
                        color="link"
                        className="font-12 "
                        onClick={() => setFilterImportant(!filterImportant)}
                    >
                        <i
                            id={`icon-important-only-${currentUser.id}`}
                            className={classnames(
                                {
                                    'fa-solid fa-star color-gold': !filterImportant,
                                    'fa-solid fa-star-half color-grey': filterImportant,
                                },
                                'clickable'
                            )}
                        />
                        <Tooltip
                            placement="top"
                            target={`icon-important-only-${currentUser.id}`}
                            text="Schovať doležité?"
                        />
                    </Button>
                    <Button
                        color="link"
                        className=" font-12"
                        onClick={() => setFilterIgnored(!filterIgnored)}
                    >
                        {filterIgnored ? 'Zobraziť ignorované' : 'Schovať ignorované'}
                    </Button>
                </div>
            </CardHeader>
            <div className="note-control">
                <div className="flex-row m-t-5">
                    <Label className="center-hor m-l-20 m-r-5">Kategória</Label>
                    <div className="flex">
                        <Select
                            value={filterCategory}
                            options={getCategories(true)}
                            onChange={(category) => setFilterCategory(category)}
                            styles={pickSelectStyle(['noPadding'])}
                        />
                    </div>
                    <Label className="center-hor m-l-20 m-r-5">Charakter</Label>
                    <div className="flex m-r-20">
                        <Select
                            value={filterUser}
                            options={getUsers()}
                            onChange={(character) => setFilterUser(character)}
                            styles={pickSelectStyle(['noPadding'])}
                        />
                    </div>
                </div>
                <hr className="p-0 m-t-5 m-b-5" />
            </div>
            <CardBody>
                <div className="notes-container">
                    {FilteredNotes.map((Note) => (
                        <div
                            key={Note.id}
                            id={`note-${Note.id}`}
                            className={classnames(
                                { 'private-note': Note.isPrivate },
                                'double-border p-5 m-t-10 font-17'
                            )}
                        >
                            <div className="flex-row font-17">
                                <div>
                                    {timestampToPracticalDate(Note.createdAt)}
                                    {Note.user?.id === currentUser.id && (
                                        <Empty>
                                            <i
                                                id={`icon-delete-note-${Note.id}`}
                                                className={classnames(
                                                    {
                                                        'clickable':
                                                            Note.user?.id === currentUser.id,
                                                    },
                                                    'fa fa-trash m-l-5 m-r-5 color-red'
                                                )}
                                                onClick={() => {
                                                    if (Note.user?.id === currentUser.id) {
                                                        if (
                                                            window.confirm(
                                                                `Naozaj zmazať poznámku "\n${replaceFromString(
                                                                    Note.message,
                                                                    ['</p>'],
                                                                    '\n'
                                                                ).replace(/<[^>]+>/g, '')}" ?`
                                                            )
                                                        ) {
                                                            deleteNote({
                                                                variables: { id: Note.id },
                                                            });
                                                        }
                                                        setEditedNote(null);
                                                    }
                                                }}
                                            />
                                            <Tooltip
                                                placement="top"
                                                target={`icon-delete-note-${Note.id}`}
                                                text="Vymazať"
                                            />
                                            <i
                                                id={`icon-edit-note-${Note.id}`}
                                                className={classnames(
                                                    {
                                                        'clickable':
                                                            Note.user?.id === currentUser.id,
                                                    },
                                                    'fa fa-pen m-l-5 m-r-5 color-blue'
                                                )}
                                                onClick={() => {
                                                    setEditedNote(Note);
                                                }}
                                            />
                                            <Tooltip
                                                placement="top"
                                                target={`icon-edit-note-${Note.id}`}
                                                text="Upraviť"
                                            />
                                        </Empty>
                                    )}
                                </div>
                                <div className="m-l-auto">
                                    <i
                                        id={`icon-ignore-note-${Note.id}`}
                                        className={classnames(
                                            {
                                                'fa-eye color-green': !Note.ignoreList.includes(
                                                    currentUser.id
                                                ),
                                                'fa-eye-slash color-red': Note.ignoreList.includes(
                                                    currentUser.id
                                                ),
                                            },
                                            'fa clickable m-r-5'
                                        )}
                                        onClick={() => {
                                            setNoteIgnored({ variables: { id: Note.id } });
                                        }}
                                    />
                                    <Tooltip
                                        placement="top"
                                        target={`icon-ignore-note-${Note.id}`}
                                        text={
                                            Note.ignoreList.includes(currentUser.id)
                                                ? 'Zviditeľniť'
                                                : 'Ignorovať'
                                        }
                                    />
                                    <i
                                        id={`icon-important-note-${Note.id}`}
                                        className={classnames(
                                            {
                                                'fa-regular fa-star color-gold':
                                                    !Note.importantList.includes(currentUser.id),
                                                'fa-solid fa-star color-gold':
                                                    Note.importantList.includes(currentUser.id),
                                            },
                                            'clickable'
                                        )}
                                        onClick={() => {
                                            setNoteImportant({ variables: { id: Note.id } });
                                        }}
                                    />
                                    <Tooltip
                                        placement="top"
                                        target={`icon-important-note-${Note.id}`}
                                        text="Dôležitá?"
                                    />
                                </div>
                                <div className="flex-row m-l-auto">
                                    <div className="text-bolder m-r-5 width-60 overflow-cut">
                                        {Note.category}
                                    </div>
                                    {Note.user?.id === currentUser.id && Admin && (
                                        <i
                                            className={classnames(
                                                {
                                                    'color-red fa-key': Note.isSuperPrivate,
                                                    'color-green fa-key': !Note.isSuperPrivate,
                                                    'clickable': Note.user?.id === currentUser.id,
                                                },
                                                'fa-solid m-r-5 center-hor'
                                            )}
                                            onClick={() =>
                                                setNoteSuperPrivate({ variables: { id: Note.id } })
                                            }
                                        />
                                    )}

                                    <i
                                        id={`icon-private-note-${Note.id}`}
                                        className={classnames(
                                            {
                                                'color-red fa-lock': Note.isPrivate,
                                                'color-green fa-unlock': !Note.isPrivate,
                                                'clickable': Note.user?.id === currentUser.id,
                                            },
                                            'fa-solid m-r-5 center-hor'
                                        )}
                                        onClick={() => {
                                            if (
                                                Note.user?.id === currentUser.id ||
                                                (Note.user === null && currentUser.admin)
                                            ) {
                                                setNotePrivate({ variables: { id: Note.id } });
                                            }
                                        }}
                                    />
                                    <Tooltip
                                        placement="top"
                                        target={`icon-private-note-${Note.id}`}
                                        text={Note.isPrivate ? 'Súkromná' : 'Verejná'}
                                    />
                                    <div
                                        className="text-bolder"
                                        style={{ color: Note.user ? Note.user.color : 'grey' }}
                                    >
                                        {Note.user ? Note.user.character : 'Neexistuje'}
                                    </div>
                                </div>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: Note.message }} />
                        </div>
                    ))}
                    {FilteredNotes.length === 0 && <div>Bez poznámok</div>}
                </div>
            </CardBody>
            <CardFooter className="p-10 flex-row" id="new-note-footer">
                <Button
                    color="success"
                    className="center-ver"
                    onClick={() => setOpenMessage(!openMessage)}
                >
                    Nová poznámka
                </Button>
                <NewNote open={openMessage} setOpen={setOpenMessage} categories={getCategories()} />
                <EditNote
                    open={editedNote && FilteredNotes.some((Note) => Note.id === editedNote?.id)}
                    close={() => setEditedNote(null)}
                    note={editedNote}
                    categories={getCategories()}
                />
            </CardFooter>
        </Card>
    );
}
