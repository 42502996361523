import React from 'react';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import {
    LOOT_MANAGER_LOGS,
    LOOT_MANAGER_LOGS_SUBSCRIPTION,
    CLEAR_ALL_LOOT_MANAGER_LOGS,
} from 'queries';
import { Button, Collapse } from 'reactstrap';
import { GetMyRights, formattedMessage, sortBy, timestampToPracticalDate } from 'helperFunctions';

export default function LootManagerLogger() {
    const {
        data: lootManagerLogsData,
        loading: lootManagerLogsLoading,
        refetch: lootManagerLogsRefetch,
    } = useQuery(LOOT_MANAGER_LOGS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LOOT_MANAGER_LOGS_SUBSCRIPTION, {
        onData: () => {
            lootManagerLogsRefetch();
        },
    });

    const [clearAllLootManagerLogs] = useMutation(CLEAR_ALL_LOOT_MANAGER_LOGS);

    const [isOpen, setIsOpen] = React.useState(false);
    const { Admin } = GetMyRights();

    const Logs = lootManagerLogsLoading
        ? []
        : sortBy(lootManagerLogsData.lootManagerLogs, [{ key: 'createdAt', asc: false }]);

    return (
        <div className="m-t-20">
            <h4
                className="clickable"
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
            >
                Logy
            </h4>
            <Collapse isOpen={isOpen}>
                {Admin && (
                    <div className="thin-buttons m-b-10">
                        <Button
                            className="p-l-10 p-r-10 m-r-10"
                            color="danger"
                            onClick={() => {
                                if (window.confirm('Si si istý?')) {
                                    clearAllLootManagerLogs();
                                }
                            }}
                        >
                            Vyčistiť logy
                        </Button>
                    </div>
                )}
                {Logs.map((Log) => (
                    <div className="double-border border-green p-10 m-b-20" key={Log.id}>
                        <div className="text-bolder text-center">
                            Vytvorený {timestampToPracticalDate(Log.createdAt)}
                        </div>
                        {JSON.parse(Log.message).map((Message, index) => (
                            <div className="double-border m-b-10 bkg-half-white p-5" key={index}>
                                {formattedMessage(Message)}
                            </div>
                        ))}
                    </div>
                ))}
            </Collapse>
        </div>
    );
}
