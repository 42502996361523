import { gql } from '@apollo/client';
import { userData } from 'queries';

export const USER_DATA_SUBSCRIPTION = gql`
    subscription userDataSubscription {
        userDataSubscription
    }
`;

export const GET_MY_DATA = gql`
    query {
        getMyData {
            ${userData}
        }
    }
`;
