import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, Button } from 'reactstrap';
import { Image } from 'components';
import axios from 'axios';
import {
    ADD_CUSTOM_IMAGE,
    CUSTOM_IMAGES,
    CUSTOM_IMAGE_SUBSCRIPTION,
    UPDATE_GAME_AREA,
} from 'queries';
import { useMutation, useQuery, useSubscription } from '@apollo/client';

export default function CustomImagePopover(props) {
    const { open, setOpen, dataFilter, setDataFilter } = props;

    const [newImage, setNewImage] = React.useState(null);
    const [newImageTitle, setNewImageTitle] = React.useState('');
    const [uploadingImage, setUploadingImage] = React.useState(false);

    const [addCustomImage] = useMutation(ADD_CUSTOM_IMAGE);
    const [updateGameArea] = useMutation(UPDATE_GAME_AREA);

    const {
        data: customImagesData,
        loading: customImagesLoading,
        refetch: customImagesRefetch,
    } = useQuery(CUSTOM_IMAGES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(CUSTOM_IMAGE_SUBSCRIPTION, {
        onData: () => {
            customImagesRefetch();
        },
    });

    const CustomImages = customImagesLoading ? [] : customImagesData.customImages;

    const uploadNewImage = () => {
        setUploadingImage(true);
        const formData = new FormData();

        formData.append('image', newImage);
        formData.append('key', '1217914f1a64f4e89067fff45c138f85');
        axios
            .post(`https://api.imgbb.com/1/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                mode: 'no-cors',
                withCredentials: false,
            })
            .then((response) => {
                addCustomImage({
                    variables: {
                        url: response.data.data.url,
                        title: newImageTitle,
                    },
                }).then((response) => {
                    updateGameArea({
                        variables: {
                            type: 'customImage',
                            id: null,
                            url: response.data.data.url,
                        },
                    });
                });
                setUploadingImage(false);
                setNewImage(null);
                setNewImageTitle('');
            })
            .catch((err) => {
                setUploadingImage(false);
                console.log('API error ↓');
                console.log(err);

                if (err.response.data.error) {
                    console.log(err.response.data.error);
                    //When trouble shooting, simple informations about the error can be found in err.response.data.error so it's good to display it
                }
            });
    };

    return (
        <Dropdown
            isOpen={open === 'custom-image'}
            toggle={() => {
                if (open === 'custom-image') {
                    setOpen(null);
                } else {
                    setOpen('custom-image');
                }
            }}
            className="commandbar-dropdown-inner center-hor"
            direction="end"
        >
            <DropdownToggle className="commandbar-dropdown-inner-toggle full-width text-left">
                Vlastný obrázok
            </DropdownToggle>
            <DropdownMenu start="true" className="width-500 p-10 max-height-50vh overflow-x">
                <div>
                    <Input
                        value={dataFilter}
                        placeholder="Hľadať"
                        onChange={(e) => setDataFilter(e.target.value)}
                    />
                </div>
                <div className="p-t-10">
                    <Input
                        type="file"
                        id="map"
                        accept="image/png, image/jpeg"
                        placeholder="Vyberte nový obrázok"
                        onChange={(e) => {
                            let files = e.target.files;
                            if (files.length === 1) {
                                setNewImage(files[0]);
                            }
                        }}
                    />
                </div>
                {newImage !== null && (
                    <div className="p-t-10 flex-row">
                        <Input
                            value={newImageTitle}
                            placeholder="Názov k novému obrázku"
                            onChange={(e) => setNewImageTitle(e.target.value)}
                        />
                        {newImage !== null && newImageTitle.length > 2 && (
                            <Button
                                color="success"
                                className="m-l-10"
                                disabled={uploadingImage}
                                onClick={uploadNewImage}
                            >
                                Nahrať
                            </Button>
                        )}
                    </div>
                )}
                <hr />
                {CustomImages.filter((CustomImage) =>
                    CustomImage.title.toLowerCase().includes(dataFilter.toLowerCase())
                ).map((CustomImage) => (
                    <DropdownItem key={CustomImage.id}>
                        <div
                            className="flex-row clickable"
                            onClick={() => {
                                updateGameArea({
                                    variables: {
                                        type: 'customImage',
                                        id: null,
                                        url: CustomImage.url,
                                    },
                                });
                            }}
                        >
                            <div className="center-hor">{CustomImage.title}</div>
                            <Image
                                url={CustomImage.url}
                                height={70}
                                focus
                                focusDimensions={{ width: 500 }}
                                focusPlacement="right"
                                className="m-l-auto"
                            />
                        </div>
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
}
