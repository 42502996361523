import React from 'react';
import GeneralNotes from '../notes';
import { Crafting } from 'components';

export default function RecipeEntry({ id }) {
    return (
        <div className="flex-row flex">
            <Crafting recipeID={id} inLibrary />
            <div className="flex">
                <GeneralNotes id={id} type="recipe" className="separate" />
            </div>
        </div>
    );
}
