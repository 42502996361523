import React from 'react';
import { noItemImage } from 'configs/constants';
import Textarea from 'react-textarea-autosize';
import Select from 'react-select';
import { Input, Label, Button } from 'reactstrap';
import classnames from 'classnames';
import { toBase64 } from 'helperFunctions';
import { useMutation } from '@apollo/client';
import { ADD_ITEM } from 'queries';

export default function ItemAdd(props) {
    const { onSubmit, itemTypes } = props;
    const [icon, setIcon] = React.useState(null);
    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [type, setType] = React.useState(null);
    const [price, setPrice] = React.useState(0);
    const [weight, setWeight] = React.useState(0);
    const [order, setOrder] = React.useState(0);
    const [adding, setAdding] = React.useState(false);

    const [addItem] = useMutation(ADD_ITEM);

    const add = async () => {
        const body = {
            title,
            description,
            type: type.id,
            price: isNaN(parseInt(price)) ? 1 : parseInt(price),
            weight: isNaN(parseFloat(weight)) ? 0 : parseFloat(weight),
        };
        setAdding(true);

        if (icon) {
            body.icon = await toBase64(icon);
        }
        addItem({ variables: body })
            .then((response) => {
                onSubmit({
                    id: response.data.addItem.id,
                    title,
                    description,
                    type: type,
                    order: isNaN(parseInt(order)) ? 1 : parseInt(order),
                    price: isNaN(parseInt(price)) ? 1 : parseInt(price),
                    weight: isNaN(parseFloat(weight)) ? 0 : parseFloat(weight),
                    icon: response.data.addItem.icon,
                });
                setTitle('');
                setDescription('');
                setPrice(0);
                setWeight(0);
                setOrder(0);
                setAdding(false);
            })
            .catch((e) => {
                console.log(e);
                setAdding(false);
            });
    };

    const disabled = title === '' || type === null || price === '' || adding;
    return (
        <tr key="add">
            <td>
                <Input
                    style={{
                        display: 'none',
                    }}
                    type="file"
                    id="icon-add-upload"
                    accept="image/png, image/jpeg"
                    onChange={(e) => {
                        let files = e.target.files;
                        if (files.length === 1) {
                            setIcon(files[0]);
                        }
                    }}
                />
                <Label htmlFor="icon-add-upload">
                    <img
                        height={35}
                        alt="none"
                        src={icon === null ? noItemImage : URL.createObjectURL(icon)}
                    />
                </Label>
            </td>
            <td>
                <Textarea
                    className="form-control"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Zadaj názov"
                />
            </td>
            <td>
                <Textarea
                    className="form-control"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Zadaj popis"
                />
            </td>
            <td>
                <Select
                    value={type}
                    options={itemTypes}
                    placeholder="Vyber typ"
                    onChange={(type) => setType(type)}
                />
            </td>
            <td>
                <Input
                    value={price}
                    type="number"
                    onChange={(e) => setPrice(e.target.value)}
                    label="Zadaj cenu"
                />
            </td>
            <td>
                <Input
                    value={weight}
                    type="number"
                    onChange={(e) => setWeight(e.target.value)}
                    label="Zadaj váhu"
                />
            </td>
            <td>
                <Input
                    value={order}
                    type="number"
                    onChange={(e) => setOrder(e.target.value)}
                    label="Zadaj poradie"
                />
            </td>
            <td>
                <Button disabled={disabled} color="none" onClick={add}>
                    <i
                        className={classnames('fa fa-plus', {
                            'success': !disabled,
                        })}
                    />
                </Button>
            </td>
        </tr>
    );
}
