import React from 'react';
import { Label, FormGroup, Button } from 'reactstrap';
import { Popover } from 'reactstrap';
import Select from 'react-select/creatable';
import { pickSelectStyle } from 'configs/selectStyles';
import { Checkbox, Tooltip, TextEditor } from 'components';
import { UPDATE_NOTE } from 'queries';
import { useMutation } from '@apollo/client';

export default function EditNote(props) {
    const { open, close, note, categories } = props;

    const [message, setMessage] = React.useState('');
    const [category, setCategory] = React.useState(null);
    const [priv, setPriv] = React.useState(false);
    const [savingNote, setSavingNote] = React.useState(false);
    const [updateNoteMutation] = useMutation(UPDATE_NOTE);

    React.useEffect(() => {
        if (open) {
            const noteCategory = categories.find((Category) => Category.value === note.category);
            setMessage(note.message);
            setCategory(noteCategory ? noteCategory : null);
            setPriv(note.private);
            setSavingNote(false);
        }
        // eslint-disable-next-line
    }, [open, note?.id]);

    if (!open) {
        return null;
    }

    const updateNote = async () => {
        setSavingNote(true);
        updateNoteMutation({
            variables: {
                id: note.id,
                message,
                isPrivate: priv,
                category: category.value,
            },
        })
            .then(() => {
                setSavingNote(false);
                close();
            })
            .catch(() => {
                setSavingNote(false);
            });
    };

    return (
        <Popover
            className="popover-note-message"
            placement="left"
            isOpen={open}
            target={`note-${note.id}`}
            toggle={close}
        >
            <div className="flex-row">
                <FormGroup className="flex">
                    <Label>Kategória</Label>
                    <Select
                        value={category}
                        options={categories}
                        onChange={(category) => setCategory(category)}
                        styles={pickSelectStyle(['noPadding'])}
                    />
                </FormGroup>
                <Checkbox label="Súkromná" value={priv} onChange={() => setPriv(!priv)} centerHor />
                <Button
                    color="link"
                    className="m-l-auto"
                    disabled={message.length < 2 || savingNote || category === null}
                    onClick={updateNote}
                >
                    <i className="fa fa-save color-blue" id="edit-note-button" />
                </Button>
                <Tooltip placement="top" target="edit-note-button" text="Upraviť poznámku" />
                <Button
                    color="link"
                    onClick={() => {
                        if (
                            (note.category === category.value &&
                                note.message === message &&
                                note.private === priv) ||
                            window.confirm('Zrušiť editáciu? Stratíš zmeny.')
                        ) {
                            close();
                        }
                    }}
                >
                    <i className="fa fa-times color-red" id="cancel-edit-note-button" />
                </Button>
                <Tooltip
                    placement="top"
                    target="cancel-edit-note-button"
                    text="Zrušiť úpravu poznámky"
                />
            </div>
            <FormGroup className="note-editor min-height-300">
                <TextEditor
                    className="flex min-height-250"
                    value={message}
                    onChange={(text) => {
                        setMessage(text);
                    }}
                />
            </FormGroup>
        </Popover>
    );
}
