import React from 'react';
import { Tooltip as Tooltips } from 'reactstrap';
import { Empty } from './Empty';

export function Tooltip(props) {
    const { placement, target, text, ...rest } = props;
    const [tooltip, setTooltip] = React.useState(false);

    return (
        <Empty>
            {document.getElementById(target) === null && <span id={target}></span>}
            <Tooltips
                placement={`${placement}`}
                target={`${target}`}
                isOpen={tooltip}
                toggle={() => setTooltip(!tooltip)}
                {...rest}
            >
                {text}
            </Tooltips>
        </Empty>
    );
}
