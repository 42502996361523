import React from 'react';
import { Button, Label, ListGroup, ListGroupItem } from 'reactstrap';
import SpellEdit from './edit';
import SpellAdd from './add';
import { Tooltip } from 'components';
import { useParams, useNavigate } from 'react-router';
import { SPELLS, SPELLS_SUBSCRIPTION } from 'queries';
import { useQuery, useSubscription } from '@apollo/client';

export default function SpellList() {
    const { id } = useParams();
    const spellID = parseInt(id);
    const navigate = useNavigate();

    const {
        data: spellsData,
        loading: spellsLoading,
        refetch: spellsRefetch,
    } = useQuery(SPELLS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(SPELLS_SUBSCRIPTION, {
        onData: () => {
            spellsRefetch();
        },
    });

    const groupSpells = (Spells) => {
        let GroupedSpells = [];
        Spells.forEach((Spell) => {
            const Index = GroupedSpells.findIndex((Group) => Group.id === Spell.mageClass.id);
            if (Index === -1) {
                GroupedSpells.push({
                    ...Spell.mageClass,
                    Spells: [Spell],
                });
            } else {
                GroupedSpells[Index].Spells.push(Spell);
            }
        });
        return GroupedSpells;
    };

    const Spells = spellsLoading ? [] : spellsData.spells;
    const GroupedSpells = groupSpells(Spells);

    return (
        <div className="flex-row">
            <div className="settings-left">
                <div className="flex-row">
                    <Label className="m-t-5 color-white">Mágia</Label>
                    <Button
                        color="link"
                        id="add-spell"
                        className="center-hor m-l-auto m-b-5"
                        onClick={() => navigate('/nastavenia_hry/magia/add')}
                    >
                        <i className="fa fa-plus" />
                    </Button>
                    <Tooltip placement="right" target="add-spell" text="Vytvoriť nové kúzlo" />
                </div>
                <ListGroup>
                    {GroupedSpells.map((SpellGroup) => (
                        <div key={SpellGroup.id}>
                            <Label className="color-grey m-t-10">{SpellGroup.label}</Label>
                            {SpellGroup.Spells.map((spell) => (
                                <ListGroupItem
                                    key={spell.id}
                                    active={spellID === spell.id}
                                    action
                                    className="clickable"
                                    onClick={() => {
                                        navigate(`/nastavenia_hry/magia/${spell.id}`);
                                    }}
                                >
                                    {spell.title}
                                    <span className="center-right-absolute">{spell.level}</span>
                                </ListGroupItem>
                            ))}
                        </div>
                    ))}
                </ListGroup>
            </div>
            {!isNaN(spellID) && Spells.some((Spell) => Spell.id === spellID) && (
                <SpellEdit spellID={spellID} />
            )}
            {id === 'add' && <SpellAdd />}
        </div>
    );
}
