import React from 'react';
import { Image, Tooltip } from 'components';
import { Button, Card, CardHeader, CardBody } from 'reactstrap';
import classnames from 'classnames';
import GeneralNotes from '../notes';
import { PLACE, PLACES_SUBSCRIPTION } from 'queries';
import { useQuery, useSubscription } from '@apollo/client';
import { GET_LIBRARY_OPEN } from 'apollo/queries';
import { setShowLibraryList } from 'apollo/localSchema/actions';

export default function PlaceEntry({ id }) {
    const { data: libraryOpenData } = useQuery(GET_LIBRARY_OPEN);
    const ShowLibraryList = libraryOpenData.ShowLibraryList;
    const {
        data: placeData,
        loading: placeLoading,
        refetch: placeRefetch,
    } = useQuery(PLACE, {
        fetchPolicy: 'network-only',
        variables: {
            id,
        },
    });

    useSubscription(PLACES_SUBSCRIPTION, {
        onData: () => {
            placeRefetch({ variables: { id } });
        },
    });

    const Place = placeLoading ? null : placeData.place;
    if (!Place) {
        return <div>Načítava sa...</div>;
    }

    return (
        <Card className="library-right">
            <CardHeader>
                <Button color="link" onClick={setShowLibraryList} id="show-hide-library-list">
                    <i
                        className={classnames({
                            'fa fa-arrow-left color-blue': ShowLibraryList,
                            'fa fa-arrow-right color-blue': !ShowLibraryList,
                        })}
                    />
                    <i className="m-l-2 fa fa-book-atlas color-blue" />
                </Button>
                <Tooltip
                    placement="top"
                    target="show-hide-library-list"
                    text={ShowLibraryList ? 'Schovať knižnicu' : 'Zobraziť knižnicu'}
                />
                {`${Place.title} (Miesta)`}
            </CardHeader>
            <CardBody className="p-20 flex-row">
                <div style={{ width: 'calc( 100% - 500px)' }}>
                    <h4>{Place.title}</h4>
                    <div className="text-italics m-b-10">{Place.description}</div>
                    <Image path={Place.place} hideEmpty showFull height={500} />
                </div>
                <div className="flex m-l-20">
                    <GeneralNotes id={id} type="place" />
                </div>
            </CardBody>
        </Card>
    );
}
