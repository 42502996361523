import chroma from 'chroma-js';

export const pickSelectStyle = (wantedAttributes = []) => {
    const invisible = wantedAttributes.includes('invisible');
    const noArrow = wantedAttributes.includes('noArrow');
    const whiteText = wantedAttributes.includes('whiteText');
    const noPadding = wantedAttributes.includes('noPadding');
    const colored = wantedAttributes.includes('colored');
    const fullColor = wantedAttributes.includes('fullColor');
    const required = wantedAttributes.includes('required');
    const highlight = wantedAttributes.includes('highlight');
    // const disabled = wantedAttributes.includes('disabled');
    const bordered = wantedAttributes.includes('bordered');
    // const sidebar = wantedAttributes.includes('sidebar');
    const right = wantedAttributes.includes('right');
    const bolder = wantedAttributes.includes('bolder');
    const basic = wantedAttributes.includes('basic');
    const flex = wantedAttributes.includes('flex');
    const size12 = wantedAttributes.includes('size12');
    const size14 = wantedAttributes.includes('size14');
    const size16 = wantedAttributes.includes('size16');
    const inputSize = wantedAttributes.includes('inputSize');
    const iconNoLeftPadding = wantedAttributes.includes('iconNoLeftPadding');

    return {
        container: (base) => {
            let newStyle = {
                ...base,
            };
            if (flex) {
                newStyle = {
                    ...newStyle,
                    flex: 1,
                };
            }

            return newStyle;
        },
        control: (base, state) => {
            let newStyle = {
                ...base,
            };
            if (noPadding) {
                newStyle = {
                    ...newStyle,
                    padding: '0px 0px !important',
                    minHeight: 0,
                };
            } else {
                newStyle = {
                    ...newStyle,
                    minHeight: 30,
                };
            }
            if (invisible) {
                newStyle = {
                    ...newStyle,
                    'border-width': 0,
                    backgroundColor: 'inherit',
                };
            } else {
                newStyle = {
                    ...newStyle,
                    backgroundColor: 'white',
                };
            }

            const data = state.getValue();
            if (fullColor && data[0].color) {
                newStyle = {
                    ...newStyle,
                    backgroundColor: data[0].color,
                };
            }

            if (noArrow) {
                newStyle = {
                    ...newStyle,
                    minHeight: 32,
                };
            }

            if (
                required &&
                (state.getValue().length === 0 ||
                    state.getValue().some((item) => item.value === null))
            ) {
                newStyle = {
                    ...newStyle,
                    borderColor: 'red',
                    borderWidth: 1,
                    color: 'red',
                };
            }

            if (noPadding) {
                newStyle = {
                    ...newStyle,
                    //  borderWidth: base.borderWidth,
                    padding: '0px 0px !important',
                };
            }

            if (size12) {
                newStyle = {
                    ...newStyle,
                    fontSize: '12px',
                };
            }

            if (size14) {
                newStyle = {
                    ...newStyle,
                    fontSize: '14px',
                };
            }

            if (size16) {
                newStyle = {
                    ...newStyle,
                    fontSize: '16px',
                };
            }

            if (inputSize) {
                newStyle = {
                    ...newStyle,
                    minHeight: 30,
                };
            }

            if (bordered) {
                newStyle = {
                    ...newStyle,
                    borderWidth: 1,
                    borderRadius: 1,
                };
            }
            return newStyle;
        },
        dropdownIndicator: (base) => {
            let newStyle = {
                ...base,
                padding: 4,
                width: 22,
            };
            if (noArrow) {
                newStyle = {
                    ...newStyle,
                    color: 'transparent',
                    width: 0,
                    padding: 0,
                };
            }
            if (iconNoLeftPadding) {
                newStyle = {
                    ...newStyle,
                    padding: '0px !important',
                };
            }
            return newStyle;
        },
        clearIndicator: (base) => {
            return {
                ...base,
                padding: 4,
            };
        },
        multiValue: (base, aditionalParams) => {
            const { data } = aditionalParams;
            let newStyle = {
                ...base,
                borderRadius: 0,
            };
            if (highlight) {
                newStyle = {
                    ...newStyle,
                    marginLeft: 0,
                    marginRight: 4,
                };
            }

            if (colored && data.color) {
                const color = chroma(data.color);
                newStyle = {
                    ...newStyle,
                    color: 'white',
                    padding: '0px 5px',
                    backgroundColor: color.alpha(0.1).css(),
                };
            }
            return newStyle;
        },
        multiValueLabel: (base, aditionalParams) => {
            const { data } = aditionalParams;
            let newStyle = {
                ...base,
            };
            if (colored && data.color) {
                newStyle = {
                    ...newStyle,
                    color: data.color,
                };
            }
            return newStyle;
        },
        multiValueRemove: (base, aditionalParams) => {
            const { data } = aditionalParams;
            let newStyle = {
                ...base,
                color: 'black',
                backgroundColor: '#F2F1F1',
                ':hover': {
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    color: 'white',
                },
            };
            if (highlight) {
                newStyle = {
                    ...newStyle,
                    margin: 0,
                };
            }
            if (colored && data.color) {
                newStyle = {
                    ...newStyle,
                    color: data.color,
                    backgroundColor: 'transparent',
                    ':hover': {
                        backgroundColor: data.color,
                        color: 'white',
                    },
                };
            }
            return newStyle;
        },
        valueContainer: (base) => {
            let newStyle = {
                ...base,
                padding: '0px 5px',
                borderRadius: 0,
                paddingLeft: 0, //all selects no left padding
            };
            if (!colored) {
                newStyle = {
                    ...newStyle,
                };
            }

            if (invisible || noArrow || colored || highlight) {
                newStyle = {
                    ...newStyle,
                    padding: '0px 6px',
                };
            }
            if (size12) {
                newStyle = {
                    ...newStyle,
                    fontSize: '12px',
                };
            }

            if (size14) {
                newStyle = {
                    ...newStyle,
                    fontSize: '14px',
                };
            }

            if (size16) {
                newStyle = {
                    ...newStyle,
                    fontSize: '16px',
                };
            }

            if (highlight) {
                newStyle = {
                    ...newStyle,
                    margin: 0,
                };
            }
            return newStyle;
        },
        input: (base) => {
            let newStyle = {
                ...base,
                margin: 0,
                padding: 0,
                backgroundColor: 'white',
                borderRadius: 0,
            };
            if (invisible || colored) {
                newStyle = {
                    ...newStyle,
                    backgroundColor: 'inherit',
                };
            }

            return newStyle;
        },
        indicatorSeparator: (base) => {
            let newStyle = {
                ...base,
                width: 0,
            };

            return newStyle;
        },
        placeholder: (base) => {
            let newStyle = {
                ...base,
                display: 'block',
                gridArea: '1 / 1 / 2 / 3',
                gridTemplateColumns: '0px min-content',
                order: 1,
            };
            if (invisible || noArrow) {
                newStyle = {
                    ...newStyle,
                };
            } else {
                newStyle = {
                    ...newStyle,
                };
            }
            if (size12) {
                newStyle = {
                    ...newStyle,
                    fontSize: '12px',
                };
            }

            if (size14) {
                newStyle = {
                    ...newStyle,
                    fontSize: '14px',
                };
            }

            if (size16) {
                newStyle = {
                    ...newStyle,
                    fontSize: '16px',
                };
            }

            if (required) {
                newStyle = {
                    ...newStyle,
                    color: 'red',
                };
            }

            return newStyle;
        },
        menu: (base) => {
            let newStyle = {
                ...base,
                zIndex: 99999,
            };

            return newStyle;
        },
        option: (base, aditionalParams) => {
            const { data, isDisabled, isFocused, isSelected } = aditionalParams;
            let newStyle = {
                ...base,
            };
            if (colored && data.color) {
                const color = chroma(data.color);
                newStyle = {
                    ...newStyle,
                    backgroundColor: isSelected
                        ? data.color
                        : isFocused
                        ? color.alpha(0.1).css()
                        : null,
                    color: isSelected
                        ? chroma.contrast(color, 'white') > 2
                            ? 'white'
                            : 'black'
                        : data.color,

                    ':active': {
                        ...newStyle[':active'],
                        backgroundColor:
                            !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
                    },
                };
            }
            if (right) {
                newStyle = {
                    ...newStyle,
                    textAlign: 'right',
                };
            }
            if (basic) {
                newStyle = {
                    ...newStyle,
                    padding: '2px 5px',
                };
            }

            if (bolder) {
                newStyle = {
                    ...newStyle,
                    fontWeight: 'bolder',
                };
            }
            return newStyle;
        },
        singleValue: (base, aditionalParams) => {
            const { data } = aditionalParams;
            let newStyle = {
                ...base,
                gridArea: 1,
                gridRowStart: 1,
                gridRowEnd: 1,
                gridColumnEnd: 1,
            };
            if (colored && data.color) {
                newStyle = {
                    ...newStyle,
                    color: 'white',
                    padding: '0px 5px',
                    minHeight: '19px', //potencially breaking
                    backgroundColor: data.color,
                };
            }
            if (right) {
                newStyle = {
                    ...newStyle,
                    textAlign: 'right',
                    width: '100%',
                    paddingRight: 5,
                };
            }
            if (bolder) {
                newStyle = {
                    ...newStyle,
                    fontWeight: 'bolder',
                };
            }

            if (whiteText) {
                newStyle = {
                    ...newStyle,
                    color: 'white',
                };
            }

            if (invisible) {
                newStyle = {
                    ...newStyle,
                    backgroundColor: 'inherit',
                };
            }

            return newStyle;
        },
    };
};
