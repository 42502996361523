import React from 'react';
import { getItemDescription, getWeight, sortBy } from 'helperFunctions';
import { Empty, Image, Tooltip } from 'components';
import { Table, Button } from 'reactstrap';
import classnames from 'classnames';
import GeneralNotes from 'pages/library/notes';
import { TRADER, TRADERS_SUBSCRIPTION } from 'queries';
import { setShowLibraryList } from 'apollo/localSchema/actions';
import { GET_LIBRARY_OPEN } from 'apollo/queries';
import { useQuery, useSubscription } from '@apollo/client';

export function Trader(props) {
    const { id, inLibrary } = props;

    const { data: libraryOpenData } = useQuery(GET_LIBRARY_OPEN);
    const ShowLibraryList = libraryOpenData.ShowLibraryList;
    const {
        data: traderData,
        loading: traderLoading,
        refetch: traderRefetch,
    } = useQuery(TRADER, {
        fetchPolicy: 'network-only',
        variables: {
            id,
        },
    });

    useSubscription(TRADERS_SUBSCRIPTION, {
        onData: () => {
            traderRefetch({ id });
        },
    });

    const Trader = traderLoading ? null : traderData.trader;

    if (!Trader) {
        return <div>Načítava sa...</div>;
    }

    const TraderItems = sortBy(
        [
            ...Trader.recipes.map((recipe) => ({ ...recipe, dataType: 'recipe' })),
            ...Trader.items.map((recipe) => ({ ...recipe, dataType: 'item' })),
        ],
        [{ key: 'order', asc: true }]
    );

    return (
        <div className="ancient-font p-20">
            <div className="flex-row">
                {inLibrary && (
                    <Empty>
                        <Button
                            color="link"
                            onClick={setShowLibraryList}
                            id="show-hide-library-list"
                        >
                            <i
                                className={classnames({
                                    'fa fa-arrow-left color-blue': ShowLibraryList,
                                    'fa fa-arrow-right color-blue': !ShowLibraryList,
                                })}
                            />
                            <i className="m-l-2 fa fa-book-atlas color-blue" />
                        </Button>
                        <Tooltip
                            placement="top"
                            target="show-hide-library-list"
                            text={ShowLibraryList ? 'Schovať knižnicu' : 'Zobraziť knižnicu'}
                        />
                    </Empty>
                )}
                <h1 className="text-center color-white">{Trader.title}</h1>
            </div>
            <div className="flex-row">
                <Image height={500} maxWidth={250} path={Trader.portrait} hideEmpty showFull />
                <div className="m-l-20">
                    <Table borderless className="flex-row m-t-10">
                        <tbody className="color-grey">
                            {TraderItems.map((Item, index) => (
                                <tr
                                    key={Item.dataType + Item.itemId}
                                    style={
                                        index === 0 ||
                                        Item.order - TraderItems[index - 1].order <= 1
                                            ? {}
                                            : { borderTop: 'solid transparent 20px' }
                                    }
                                >
                                    <td>
                                        <Image height={50} path={Item.icon} hideEmpty showFull />
                                    </td>
                                    <td className="font-20 m-l-10 text-hor-middle text-right p-r-0 text-bolder">
                                        {Item.price}
                                    </td>
                                    <td className="font-20 text-hor-middle p-l-2">
                                        <i className="fa fa-coins color-gold" />
                                    </td>
                                    <td
                                        className={classnames(
                                            'clickable text-hor-middle font-25 text-bolder'
                                        )}
                                        id={`trader-${id}-item-${Item.dataType + Item.itemId}`}
                                    >
                                        {Item.title}

                                        <Tooltip
                                            placement="top"
                                            target={`trader-${id}-item-${
                                                Item.dataType + Item.itemId
                                            }`}
                                            text={getItemDescription(Item, false, true)}
                                        />
                                        {console.log(getItemDescription(Item, false, true))}
                                    </td>
                                    <td className="text-hor-middle font-25">
                                        {Item.dataType === 'recipe'
                                            ? 'Recept'
                                            : getWeight(Item.weight)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <div className="flex">{inLibrary && <GeneralNotes id={id} type="trader" />}</div>
            </div>
        </div>
    );
}
