import { dmVar, adminVar } from './variables';

export function setAsDm() {
    dmVar(true);
    adminVar(true);
}

export function setAsAdmin() {
    dmVar(false);
    adminVar(true);
}

export function setAsUser() {
    dmVar(false);
    adminVar(false);
}
