import { gql } from '@apollo/client';

export const LIBRARY_NOTES = gql`
    query libraryNotes($libraryId: Int!, $type: NoteTypeEnum!) {
        libraryNotes(libraryId: $libraryId, type: $type) {
            id
            createdAt
            updatedAt
            message
            isPrivate
            ignoreList
            user {
                id
                nickname
                color
                character
            }
        }
    }
`;
export const ADD_LIBRARY_NOTE = gql`
    mutation addLibraryNote(
        $targetId: Int!
        $type: NoteTypeEnum!
        $message: String!
        $isPrivate: Boolean!
    ) {
        addLibraryNote(targetId: $targetId, type: $type, message: $message, isPrivate: $isPrivate) {
            id
        }
    }
`;
export const UPDATE_LIBRARY_NOTE = gql`
    mutation updateLibraryNote($id: Int!, $message: String, $isPrivate: Boolean) {
        updateLibraryNote(id: $id, message: $message, isPrivate: $isPrivate) {
            id
        }
    }
`;
export const DELETE_LIBRARY_NOTE = gql`
    mutation deleteLibraryNote($id: Int!) {
        deleteLibraryNote(id: $id) {
            id
        }
    }
`;
export const SET_LIBRARY_NOTE_PRIVATE = gql`
    mutation setLibraryNotePrivate($id: Int!) {
        setLibraryNotePrivate(id: $id) {
            id
        }
    }
`;
export const SET_LIBRARY_NOTE_IGNORED = gql`
    mutation setLibraryNoteIgnored($id: Int!) {
        setLibraryNoteIgnored(id: $id) {
            id
        }
    }
`;

export const LIBRARY_NOTES_SUBSCRIPTION = gql`
    subscription libraryNotesSubscription($libraryId: Int!, $type: NoteTypeEnum!) {
        libraryNotesSubscription(libraryId: $libraryId, type: $type)
    }
`;
