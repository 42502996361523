import { gql } from '@apollo/client';

export const GOLD_HISTORY = gql`
    query goldHistory($userId: Int!, $today: Boolean!, $offset: Int) {
        goldHistory(userId: $userId, today: $today, offset: $offset) {
            id
            createdAt
            updatedAt
            stolen
            transfer
            recieved
            quantity
            reason
            transferedFrom {
                id
                nickname
                color
                character
            }
            transferedTo {
                id
                nickname
                color
                character
            }
        }
    }
`;
export const GOLD_CHANGE = gql`
    mutation goldChange($userId: Int, $quantity: Int, $reason: String) {
        goldChange(userId: $userId, quantity: $quantity, reason: $reason) {
            id
        }
    }
`;
export const GOLD_TRANSFER = gql`
    mutation goldTransfer($quantity: Int, $transferedFrom: Int, $transferedTo: Int) {
        goldTransfer(
            quantity: $quantity
            transferedFrom: $transferedFrom
            transferedTo: $transferedTo
        ) {
            id
        }
    }
`;
export const GOLD_STEAL = gql`
    mutation goldSteal($quantity: Int, $reason: String, $userId: Int) {
        goldSteal(quantity: $quantity, reason: $reason, userId: $userId) {
            id
        }
    }
`;
export const GOLD_HISTORY_SUBSCRIPTION = gql`
    subscription goldHistorySubscription($userId: Int!) {
        goldHistorySubscription(userId: $userId)
    }
`;
