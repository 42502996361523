import { makeVar } from '@apollo/client';
import { imageChangeIds, updateImageChangeIds } from './variables';

export function updateImageId(path) {
    if ([null, undefined].includes(path)) {
        return;
    }
    if ([null, undefined].includes(imageChangeIds[path])) {
        updateImageChangeIds({ ...imageChangeIds, [path]: makeVar(1) });
    } else {
        imageChangeIds[path](imageChangeIds[path]() + 1);
    }
}
