import React from 'react';
import { Button, Label, Popover, PopoverHeader } from 'reactstrap';
import { Tooltip } from 'components';
import classnames from 'classnames';
import { useMutation } from '@apollo/client';
import { UPDATE_ATTRIBUTES, UPDATE_ATTRIBUTE_SIMPLE } from 'queries';

const valueBaseClassName = 'center-hor min-width-20 text-center';

export default function AttributeEdit({ User, attribute, close, uniqueId }) {
    const [updateAttributes] = useMutation(UPDATE_ATTRIBUTES);
    const [updateAttributeSimple] = useMutation(UPDATE_ATTRIBUTE_SIMPLE);
    if (attribute === null) {
        return null;
    }
    const { stat, title } = attribute;
    const Attribute = User.attributes[stat];

    const increaseAttribute = (field) => {
        updateAttributeSimple({
            variables: {
                id: User.id,
                type: stat,
                modifier: field,
                increment: true,
            },
        });
    };

    const decreaseAttribute = (field, reset = false, strangeName) => {
        if (reset) {
            strangeName = strangeName ? strangeName : field;
            updateAttributes({
                variables: {
                    id: User.id,
                    type: stat,
                    modifier: field,
                    value: 0,
                },
            });
        } else {
            updateAttributeSimple({
                variables: {
                    id: User.id,
                    type: stat,
                    modifier: field,
                    increment: false,
                },
            });
        }
    };

    return (
        <Popover
            className="popover-400"
            placement="right"
            isOpen={attribute !== null}
            target={`character-attribute-${stat}-edit-${uniqueId}`}
            toggle={close}
        >
            <PopoverHeader>
                <div className="flex-row">
                    {`Úprava atribútu ${title}`}
                    <i
                        className="m-l-auto fa fa-times color-red clickable font-20 center-hor"
                        onClick={close}
                    />
                </div>
            </PopoverHeader>
            <div className="p-10">
                <div className="flex-row">
                    <Button color="link" onClick={() => decreaseAttribute('lost')}>
                        <i className="fa fa-minus" />
                    </Button>
                    <Label
                        className={classnames(
                            { 'color-green': Attribute.lost > 0, 'color-red': Attribute.lost < 0 },
                            valueBaseClassName
                        )}
                    >
                        {Attribute.lost}
                    </Label>
                    <Button color="link" onClick={() => increaseAttribute('lost')}>
                        <i className="fa fa-plus" />
                    </Button>
                    <Label className="center-hor m-r-10">Stratil</Label>
                    <Button
                        color="link"
                        className="m-l-auto"
                        onClick={() => decreaseAttribute('lost', true)}
                    >
                        <i className="fa fa-sync" id={`reset-lost-${uniqueId}`} />
                        <Tooltip
                            placement="top"
                            target={`reset-lost-${uniqueId}`}
                            text="Nastaviť na 0"
                        />
                    </Button>
                </div>
                <div className="flex-row">
                    <Button color="link" onClick={() => decreaseAttribute('extra')}>
                        <i className="fa fa-minus" />
                    </Button>
                    <Label
                        className={classnames(
                            {
                                'color-green': Attribute.extra > 0,
                                'color-red': Attribute.extra < 0,
                            },
                            valueBaseClassName
                        )}
                    >
                        {Attribute.extra}
                    </Label>
                    <Button color="link" onClick={() => increaseAttribute('extra')}>
                        <i className="fa fa-plus" />
                    </Button>
                    <Label className="center-hor m-r-10">Extra</Label>
                </div>
                <div className="flex-row">
                    <Button color="link" onClick={() => decreaseAttribute('levelup')}>
                        <i className="fa fa-minus" />
                    </Button>
                    <Label
                        className={classnames(
                            {
                                'color-green': Attribute.levelup > 0,
                                'color-red': Attribute.levelup < 0,
                            },
                            valueBaseClassName
                        )}
                    >
                        {Attribute.levelup}
                    </Label>
                    <Button color="link" onClick={() => increaseAttribute('levelUp')}>
                        <i className="fa fa-plus" />
                    </Button>
                    <Label className="center-hor m-r-10">Nový level</Label>
                </div>
            </div>
        </Popover>
    );
}
