import React from 'react';
import BestiaryCreature from 'components/bestiaryCreature';

export default function BestiaryEntry({ id }) {
    return (
        <div className="library-right">
            <BestiaryCreature id={id} inLibrary />
        </div>
    );
}
