import React from 'react';
import { Tooltip } from 'components/tooltip';
import classnames from 'classnames';
import { GetMyRights } from 'helperFunctions';

export default function Attribute(props) {
    const {
        fromEquipment,
        attribute,
        title,
        User,
        uniqueId,
        setEditing,
        editing,
        inventoryWeight,
    } = props;
    const { AdminOrDm } = GetMyRights();

    const Attribute = User.attributes[attribute];
    let multiplier = 1;
    if (attribute === 'capacity') {
        multiplier = 2;
    }

    const usefullStats = [
        { id: 'lost', value: Attribute.lost * -1, title: 'Stratil' },
        { id: 'base', value: User.race[attribute], title: 'Základ' },
        { id: 'extra', value: Attribute.extra, title: 'Extra' },
        { id: 'levelup', value: Attribute.levelup * multiplier, title: 'Nový level' },
        {
            id: 'equip',
            value: fromEquipment.reduce((acc, equip) => acc + equip.value, 0),
            title: 'Z vybavenia',
        },
    ].filter((breakdown) => breakdown.value !== 0);

    const getRemainingColor = (value, maxValue, flipValue = true) => {
        let useValue = value / maxValue;
        if (useValue > 1) {
            useValue = -0.5;
        } else if (useValue < 0) {
            useValue = -1.5;
        } else if (flipValue) {
            useValue = Math.abs(useValue - 1);
        }

        const hue = ((1 - useValue) * 120).toString(10);
        return `hsl(${hue},100%,50%)`;
    };
    const total = usefullStats
        .filter(
            (stat) =>
                ['strength', 'speed', 'dexterity', 'defence', 'capacity'].includes(attribute) ||
                stat.id !== 'lost'
        )
        .reduce((acc, stat) => acc + stat.value, 0);

    const getCurrentStat = () => {
        if (attribute === 'capacity') {
            return inventoryWeight.toFixed(2);
        } else if (['vitality', 'energy', 'endurance'].includes(attribute)) {
            return total - Attribute.lost;
        }
        return null;
    };

    return (
        <div className="flex-row m-t-3">
            <div
                className="width-100 clickable"
                id={`character-attribute-${attribute}-${uniqueId}`}
            >
                {title}
            </div>
            <div className="width-120">
                {getCurrentStat() !== null && (
                    <span
                        style={{
                            color: getRemainingColor(
                                getCurrentStat(),
                                total,
                                ['vitality', 'energy', 'endurance'].includes(attribute)
                            ),
                        }}
                    >
                        {getCurrentStat()}
                    </span>
                )}
                {`${getCurrentStat() !== null ? '/' : ''}${total}`}
                {}
                {attribute === 'capacity' ? ' kg' : ''}
            </div>
            {AdminOrDm && (
                <i
                    className="fa fa-edit clickable"
                    id={`character-attribute-${attribute}-edit-${uniqueId}`}
                    onClick={() =>
                        setEditing(
                            editing === null || editing.stat !== attribute
                                ? { stat: attribute, title }
                                : null
                        )
                    }
                />
            )}
            <Tooltip
                placement="left"
                target={`character-attribute-${attribute}-${uniqueId}`}
                text={
                    <div className="font-17 text-left">
                        {usefullStats.map((attribute) => (
                            <div key={attribute.id}>
                                <span className="text-bolder m-r-5">{attribute.title}:</span>
                                <span
                                    className={classnames(
                                        {
                                            'color-green': attribute.value > 0,
                                            'color-red': attribute.value < 0,
                                        },
                                        'text-bolder'
                                    )}
                                >
                                    {attribute.value}
                                </span>
                            </div>
                        ))}
                        {usefullStats.some((attribute) => attribute.id === 'equip') && (
                            <div>
                                <div>------------------</div>
                                <div>Detaily vybavenia:</div>
                                {fromEquipment.map((equip, index) => (
                                    <div key={index}>
                                        <span className="text-bolder m-r-5">{equip.title}:</span>
                                        <span
                                            className={classnames(
                                                {
                                                    'color-green': equip.value > 0,
                                                    'color-red': equip.value < 0,
                                                },
                                                'text-bolder'
                                            )}
                                        >
                                            {equip.value}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                }
            />
        </div>
    );
}
