import React from 'react';
import { Button, Label, ListGroup, ListGroupItem } from 'reactstrap';
import { useParams, useNavigate } from 'react-router';
import { Tooltip } from 'components';
import UserEdit from './edit';
import {
    REGISTRATION_OPEN,
    REGISTRATION_SUBSCRIPTION,
    UPDATE_SETTINGS,
    UPDATE_USER,
    USERS,
    USERS_SUBSCRIPTION,
} from 'queries';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import classnames from 'classnames';

export default function UserList() {
    const { id } = useParams();
    const userID = parseInt(id);
    const navigate = useNavigate();

    const [updateSettings] = useMutation(UPDATE_SETTINGS);
    const [updateUser] = useMutation(UPDATE_USER);
    const {
        data: usersData,
        loading: usersLoading,
        refetch: usersRefetch,
    } = useQuery(USERS, {
        fetchPolicy: 'network-only',
        variables: {
            dm: true,
            includeInactive: true,
        },
    });

    useSubscription(USERS_SUBSCRIPTION, {
        onData: () => {
            usersRefetch({
                dm: true,
                includeInactive: true,
            });
        },
    });
    const Users = usersLoading ? [] : usersData.users;

    const {
        data: registrationOpenData,
        loading: registrationOpenLoading,
        refetch: registrationOpenRefetch,
    } = useQuery(REGISTRATION_OPEN, {
        fetchPolicy: 'network-only',
    });

    useSubscription(REGISTRATION_SUBSCRIPTION, {
        onData: () => {
            registrationOpenRefetch();
        },
    });
    const registrationOpen = !registrationOpenLoading && registrationOpenData.registrationOpen;

    return (
        <div className="flex-row">
            <div className="settings-left">
                <div className="flex-row">
                    <Label className="m-t-5">Používatelia</Label>
                    <Button
                        color="link"
                        className="center-hor m-l-auto m-b-5"
                        onClick={() => {
                            updateSettings({ variables: { registrationOpen: !registrationOpen } });
                        }}
                    >
                        {registrationOpen ? 'Uzavrieť registráciu' : 'Otvoriť registráciu'}
                    </Button>
                </div>

                <ListGroup>
                    {Users.map((User) => (
                        <ListGroupItem
                            key={User.id}
                            active={id && id === User.id}
                            action
                            className="clickable"
                            onClick={() => {
                                navigate(`/nastavenia_pouzivatelov/${User.id}`);
                            }}
                        >
                            {`${User.nickname} (${User.character})`}
                            {!User.admin && !User.dm && (
                                <span>
                                    <Button
                                        color={User.activated ? 'success' : 'danger'}
                                        className="list-tooltip center-right-absolute"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            updateUser({
                                                variables: {
                                                    id: User.id,
                                                    activated: !User.activated,
                                                },
                                            });
                                        }}
                                        id={`user-activated-${User.id}`}
                                    >
                                        <i
                                            className={classnames(
                                                {
                                                    'fa-check': User.activated,
                                                    'fa-times p-l-3 p-r-3': !User.activated,
                                                },
                                                'fa color-white'
                                            )}
                                        />
                                    </Button>
                                    <Tooltip
                                        placement="top"
                                        target={`user-activated-${User.id}`}
                                        text={
                                            User.activated
                                                ? 'Dekativovať používateľa'
                                                : 'Aktivovať používateľa'
                                        }
                                    />
                                </span>
                            )}
                        </ListGroupItem>
                    ))}
                </ListGroup>
            </div>
            {userID && Users.some((User) => User.id === userID) && <UserEdit userID={userID} />}
        </div>
    );
}
