import React from 'react';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import {
    DEBTS,
    MODIFY_DEBT, //debt: Int!, $from: Int!, $to: Int!
    DEBTS_SUBSCRIPTION,
} from 'queries';
import Select from 'react-select';
import { Button, Collapse, FormGroup, Input, Label, Popover, Table } from 'reactstrap';
import { GetMyData, GetMyRights, toSelArr } from 'helperFunctions';
import { pickSelectStyle } from 'configs/selectStyles';

export default function DebtsList({ Users }) {
    const {
        data: debtsData,
        loading: debtsLoading,
        refetch: debtsRefetch,
    } = useQuery(DEBTS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(DEBTS_SUBSCRIPTION, {
        onData: () => {
            debtsRefetch();
        },
    });

    const [modifyDebt] = useMutation(MODIFY_DEBT);

    const [openModifyDebt, setOpenModifyDebt] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(true);

    const [debtFrom, setDebtFrom] = React.useState(null);
    const [debtTo, setDebtTo] = React.useState(null);
    const [debt, setDebt] = React.useState('0');
    const [processing, setProcessing] = React.useState(false);

    const updateDebt = () => {
        setProcessing(true);
        modifyDebt({
            variables: {
                from: debtFrom.id,
                to: debtTo.id,
                debt: parseInt(debt),
            },
        })
            .then(() => {
                setDebtFrom(null);
                setDebtTo(null);
                setDebt('0');
                setProcessing(false);
            })
            .catch((e) => {
                console.log(e);
                setProcessing(false);
            });
    };

    const { Admin } = GetMyRights();
    const currentUser = GetMyData();
    const Debts = debtsLoading
        ? []
        : debtsData.debts
              .filter((Debt) => Debt.debt !== 0)
              .sort((Debt1, Debt2) => {
                  let data1 = {
                      cr: Debt1.debtor,
                      db: Debt1.creditor,
                  };
                  if (Debt1.debt > 0) {
                      data1 = {
                          cr: Debt1.creditor,
                          db: Debt1.debtor,
                      };
                  }
                  let data2 = {
                      cr: Debt2.debtor,
                      db: Debt2.creditor,
                  };
                  if (Debt2.debt > 0) {
                      data2 = {
                          cr: Debt2.creditor,
                          db: Debt2.debtor,
                      };
                  }
                  if (data1.db.id === currentUser.id && data2.db.id === currentUser.id) {
                      return data1.cr.character < data2.cr.character ? -1 : 1;
                  }
                  if (data1.db.id === currentUser.id) {
                      return -1;
                  }
                  if (data2.db.id === currentUser.id) {
                      return 1;
                  }
                  if (data1.cr.id === currentUser.id && data2.cr.id === currentUser.id) {
                      return data1.db.character < data2.db.character ? -1 : 1;
                  }
                  if (data1.cr.id === currentUser.id) {
                      return -1;
                  }
                  if (data2.cr.id === currentUser.id) {
                      return 1;
                  }

                  if (data1.db.character < data2.db.character) {
                      return -1;
                  }
                  if (data2.db.character < data1.db.character) {
                      return 1;
                  }
                  if (data1.cr.character < data2.cr.character) {
                      return -1;
                  }
                  if (data2.cr.character < data1.cr.character) {
                      return 1;
                  }
                  return 0;
              });

    return (
        <div className="m-t-20 max-width-500">
            <h4
                className="clickable"
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
            >
                Dlhy
            </h4>
            <Collapse isOpen={isOpen}>
                {Admin && (
                    <div className="thin-buttons">
                        <Button
                            className="p-l-10 p-r-10 m-r-10"
                            color="success"
                            onClick={() => {
                                setOpenModifyDebt(!openModifyDebt);
                            }}
                        >
                            <i className="m-r-5 fa fa-plus" id={`modify-debt-btn`} />
                            Upraviť dlh
                        </Button>
                    </div>
                )}
                <Table striped className="bkg-half-white m-t-10">
                    <thead>
                        <tr>
                            <th>Kto</th>
                            <th>Komu</th>
                            <th>Dlh</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Debts.map((Debt) => {
                            const Creditor = Debt.debt > 0 ? Debt.creditor : Debt.debtor;
                            const Debtor = Debt.debt < 0 ? Debt.creditor : Debt.debtor;
                            return (
                                <tr key={Debt.id}>
                                    <td>
                                        <span
                                            style={{ color: Debtor.color }}
                                            className="text-bolder"
                                        >
                                            {Debtor.character}
                                        </span>
                                    </td>
                                    <td>
                                        <span
                                            style={{ color: Creditor.color }}
                                            className="text-bolder"
                                        >
                                            {Creditor.character}
                                        </span>
                                    </td>
                                    <td>
                                        <span className="text-bolder color-gold">
                                            {Math.abs(Debt.debt)}g
                                        </span>
                                    </td>
                                </tr>
                            );
                        })}
                        {Debts.length === 0 && (
                            <tr>
                                <td colSpan={3} className="text-bolder">
                                    Hra je bez dlhov!
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                {Admin && (
                    <Popover
                        className="popover-400"
                        placement="top"
                        isOpen={openModifyDebt}
                        target={`modify-debt-btn`}
                        toggle={() => setOpenModifyDebt(!openModifyDebt)}
                    >
                        <div className="p-20">
                            <FormGroup>
                                <Label>Kto</Label>
                                <Select
                                    value={debtFrom}
                                    options={toSelArr(Users, 'character').filter(
                                        (User) => User.id !== debtTo?.id
                                    )}
                                    onChange={(user) => {
                                        setDebtFrom(user);
                                    }}
                                    styles={pickSelectStyle(['colored'])}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Komu</Label>
                                <Select
                                    value={debtTo}
                                    options={toSelArr(Users, 'character').filter(
                                        (User) => User.id !== debtFrom?.id
                                    )}
                                    onChange={(user) => {
                                        setDebtTo(user);
                                    }}
                                    styles={pickSelectStyle(['colored'])}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="debtQuantity">Zmena</Label>
                                <Input
                                    type="number"
                                    id="debtQuantity"
                                    value={debt}
                                    placeholder="Ako veľa dal hráč hráčovi"
                                    onChange={(e) => setDebt(e.target.value)}
                                />
                            </FormGroup>
                            <div className="flex-row">
                                <Button
                                    color="success"
                                    disabled={
                                        processing ||
                                        debtFrom === null ||
                                        debtTo === null ||
                                        isNaN(parseInt(debt)) ||
                                        parseInt(debt) === 0
                                    }
                                    onClick={updateDebt}
                                >
                                    {processing ? 'Upravuje sa dlh...' : 'Upraviť dlh'}
                                </Button>
                                <Button
                                    color="warning"
                                    className="m-l-auto"
                                    onClick={() => setOpenModifyDebt(!openModifyDebt)}
                                >
                                    Zavrieť
                                </Button>
                            </div>
                        </div>
                    </Popover>
                )}
            </Collapse>
        </div>
    );
}
