import React from 'react';
import Select from 'react-select';
import { pickSelectStyle } from 'configs/selectStyles';
import { Button, FormGroup, Input, Label, PopoverHeader } from 'reactstrap';
import { Popover } from 'components/Popover';
import { GetMyRights } from 'helperFunctions';
import { ADD_OPPONENT, END_COMBAT, RESET_COMBAT } from 'queries';
import { useMutation } from '@apollo/client';
import classnames from 'classnames';
import Attributes from './attributes';
import { Image } from 'components/Image';
import { Empty } from 'components/Empty';
import { Tooltip } from 'components/tooltip';
import teams from 'configs/teams';
import Battlefield from './battlefield';

export default function CombatOpponents({
    Opponents,
    CombatUsersAndCreatures,
    selectOpponent,
    selectedOpponent,
    progress,
    CombatSimulator,
    dataLoaded,
    Battlefields,
    Users,
}) {
    const { Dm } = GetMyRights();

    const [openAddOpponent, setOpenAddOpponent] = React.useState(false);
    const [newOpponent, setNewOpponent] = React.useState(null);
    const [newOpponentTeam, setNewOpponentTeam] = React.useState(teams[0]);
    const [newOpponentNickname, setNewOpponentNickname] = React.useState('');

    const [addOpponentMutation] = useMutation(ADD_OPPONENT);
    const [resetCombatMutation] = useMutation(RESET_COMBAT);
    const [endCombat] = useMutation(END_COMBAT);

    React.useEffect(() => {
        if (newOpponent) {
            const replacement = CombatUsersAndCreatures.find(
                (Opponent) => Opponent.id === newOpponent.id
            );
            if (replacement) {
                setNewOpponent({
                    ...replacement,
                    value: replacement.id,
                    uniqueSpeed: Math.floor(Math.random() * 1000),
                });
                setNewOpponentNickname(replacement.label);
            }
        }
        // eslint-disable-next-line
    }, [CombatUsersAndCreatures]);

    const AvailableOpponents = CombatUsersAndCreatures.filter(
        (PotentialOpponent) =>
            PotentialOpponent.type !== 'user' ||
            !Opponents.some((Opponent) => Opponent?.user?.id === PotentialOpponent.userId)
    ).map((PotentialOpponent) => ({
        ...PotentialOpponent,
        value: PotentialOpponent.id,
    }));

    const addOpponent = () => {
        addOpponentMutation({
            variables: {
                uniqueSpeed: newOpponent.uniqueSpeed,
                team: newOpponentTeam.value,
                creatureId: newOpponent.creatureId,
                userId: newOpponent.userId,
                nickname: newOpponentNickname,
                defenceExtra: newOpponent.attributes.defence.extra,
                dexterityExtra: newOpponent.attributes.dexterity.extra,
                enduranceExtra: newOpponent.attributes.endurance.extra,
                energyExtra: newOpponent.attributes.energy.extra,
                speedExtra: newOpponent.attributes.speed.extra,
                strengthExtra: newOpponent.attributes.strength.extra,
                vitalityExtra: newOpponent.attributes.vitality.extra,
                defenceLost: newOpponent.attributes.defence.lost,
                dexterityLost: newOpponent.attributes.dexterity.lost,
                enduranceLost: newOpponent.attributes.endurance.lost,
                energyLost: newOpponent.attributes.energy.lost,
                speedLost: newOpponent.attributes.speed.lost,
                strengthLost: newOpponent.attributes.strength.lost,
                vitalityLost: newOpponent.attributes.vitality.lost,
            },
        }).then(() => setNewOpponent(null));
    };

    return (
        <div className="flex-row">
            {Dm && (
                <div>
                    <Button id="add-opponent" color="link" onClick={() => setOpenAddOpponent(true)}>
                        <i className="fa fa-plus color-green" />
                    </Button>
                    <Popover
                        className="popover-500"
                        placement="left"
                        isOpen={openAddOpponent}
                        target={`add-opponent`}
                        toggle={() => {
                            setOpenAddOpponent(false);
                        }}
                    >
                        <PopoverHeader>Pridanie oponenta do boja</PopoverHeader>
                        <div className="p-20">
                            <Select
                                value={newOpponent}
                                options={AvailableOpponents}
                                placeholder="Nový oponent"
                                onChange={(Opponent) => {
                                    setNewOpponent({
                                        ...Opponent,
                                        uniqueSpeed: Math.floor(Math.random() * 1000),
                                    });
                                    setNewOpponentNickname(Opponent.label);
                                }}
                                styles={pickSelectStyle(['colored'])}
                            />
                            {newOpponent !== null && (
                                <Empty>
                                    <div className="flex-row m-t-10 m-b-10">
                                        <Image
                                            className="flex"
                                            path={newOpponent.portrait}
                                            bottom
                                            height={250}
                                        />
                                        <Attributes
                                            Opponent={newOpponent}
                                            updateAttribute={(attribute, type, value) => {
                                                let newData = { ...newOpponent };
                                                newData.attributes[attribute][type] = value;
                                                setNewOpponent(newData);
                                            }}
                                            updateUniqueSpeed={(newSpeed) => {
                                                setNewOpponent({
                                                    ...newOpponent,
                                                    uniqueSpeed: newSpeed,
                                                });
                                            }}
                                        />
                                    </div>

                                    <Select
                                        value={newOpponentTeam}
                                        options={teams}
                                        placeholder="Tým"
                                        onChange={(Team) => {
                                            setNewOpponentTeam(Team);
                                        }}
                                        styles={pickSelectStyle(['colored'])}
                                    />
                                    {newOpponent.type === 'creature' && (
                                        <FormGroup className="flex-row m-t-5">
                                            <Label className="width-70 center-hor">Meno:</Label>
                                            <Input
                                                value={newOpponentNickname}
                                                onChange={(e) =>
                                                    setNewOpponentNickname(e.target.value)
                                                }
                                            />
                                        </FormGroup>
                                    )}

                                    <div className="flex-row m-t-5 m-b-5">
                                        <Button
                                            color="success center-ver"
                                            disabled={
                                                !Dm ||
                                                (newOpponent.type === 'creature' &&
                                                    newOpponent.label === newOpponentNickname)
                                            }
                                            onClick={addOpponent}
                                        >
                                            Pridať oponenta
                                        </Button>
                                    </div>
                                </Empty>
                            )}
                        </div>
                    </Popover>
                </div>
            )}
            <div className="flex-row-wrapped">
                {progress > -1 && (
                    <div className="text-bolder no-select center-hor">
                        Kolo: {CombatSimulator.round}
                        <span className="m-l-5 m-r-5">|</span>
                    </div>
                )}
                {Opponents.filter((Opponent) => !Opponent.dead && !Opponent.attacked)
                    .sort((Opponent1, Opponent2) => {
                        const exhausted1 =
                            Opponent1.attributes.endurance.base +
                                Opponent1.attributes.endurance.extra -
                                Opponent1.attributes.endurance.lost <=
                            0;
                        const exhausted2 =
                            Opponent2.attributes.endurance.base +
                                Opponent2.attributes.endurance.extra -
                                Opponent2.attributes.endurance.lost <=
                            0;
                        if (exhausted1 && !exhausted2) {
                            return 1;
                        }
                        if (!exhausted1 && exhausted2) {
                            return -1;
                        }
                        const Speed1 =
                            Opponent1.attributes.speed.base +
                            Opponent1.attributes.speed.extra -
                            Opponent1.attributes.speed.lost +
                            Opponent1.uniqueSpeed / 1000;
                        const Speed2 =
                            Opponent2.attributes.speed.base +
                            Opponent2.attributes.speed.extra -
                            Opponent2.attributes.speed.lost +
                            Opponent2.uniqueSpeed / 1000;
                        return Speed2 - Speed1;
                    })
                    .map((Opponent) => (
                        <div key={Opponent.id} className="clickable text-bolder center-hor">
                            <span
                                className={classnames({
                                    'underline-selected': selectedOpponent === Opponent.id,
                                    'text-crossed':
                                        Opponent.attributes.endurance.base +
                                            Opponent.attributes.endurance.extra -
                                            Opponent.attributes.endurance.lost <=
                                        0,
                                })}
                                style={{ color: Opponent.user ? Opponent.user.color : 'grey' }}
                                onClick={() => {
                                    if ((Dm || progress === -1) && progress <= 0) {
                                        selectOpponent(Opponent.id);
                                    }
                                }}
                            >
                                {Opponent.stunned && <i className="fa fa-bolt color-gold" />}
                                {Opponent.nickname}
                            </span>
                            <span className="m-l-5 m-r-5">|</span>
                        </div>
                    ))}
                {progress > -1 && (
                    <div className="text-bolder no-select center-hor color-orange">
                        Kolo: {CombatSimulator.round + 1}
                        <span className="m-l-5 m-r-5">|</span>
                    </div>
                )}
                {Opponents.filter((Opponent) => !Opponent.dead && Opponent.attacked)
                    .sort((Opponent1, Opponent2) => {
                        const exhausted1 =
                            Opponent1.attributes.endurance.base +
                                Opponent1.attributes.endurance.extra -
                                Opponent1.attributes.endurance.lost <=
                            0;
                        const exhausted2 =
                            Opponent2.attributes.endurance.base +
                                Opponent2.attributes.endurance.extra -
                                Opponent2.attributes.endurance.lost <=
                            0;
                        if (exhausted1 && !exhausted2) {
                            return 1;
                        }
                        if (!exhausted1 && exhausted2) {
                            return -1;
                        }
                        const Speed1 =
                            Opponent1.attributes.speed.base +
                            Opponent1.attributes.speed.extra -
                            Opponent1.attributes.speed.lost +
                            Opponent1.uniqueSpeed / 1000;
                        const Speed2 =
                            Opponent2.attributes.speed.base +
                            Opponent2.attributes.speed.extra -
                            Opponent2.attributes.speed.lost +
                            Opponent2.uniqueSpeed / 1000;
                        return Speed2 - Speed1;
                    })
                    .map((Opponent) => (
                        <div key={Opponent.id} className="text-bolder center-hor color-orange">
                            <span
                                className={classnames({
                                    'text-crossed':
                                        Opponent.attributes.endurance.base +
                                            Opponent.attributes.endurance.extra -
                                            Opponent.attributes.endurance.lost <=
                                        0,
                                })}
                            >
                                {Opponent.stunned && <i className="fa fa-bolt color-gold" />}
                                {Opponent.nickname}
                            </span>
                            <span className="m-l-5 m-r-5">|</span>
                        </div>
                    ))}
            </div>

            {Dm && progress > -1 && (
                <div className="m-l-auto width-95">
                    <Button
                        color="link"
                        onClick={() => {
                            if (window.confirm('Naozaj zrušiť celý boj?')) {
                                resetCombatMutation();
                            }
                        }}
                    >
                        <i className="fa fa-cancel color-red" id="cancel-all-fights" />
                    </Button>
                    <Tooltip placement="top" target="cancel-all-fights" text="Zrušiť CELÝ boj" />
                    {Dm && progress === 0 && (
                        <Empty>
                            <Button
                                color="link"
                                onClick={() => {
                                    if (window.confirm('Uzavrieť boj?')) {
                                        endCombat();
                                    }
                                }}
                            >
                                <i className="fa fa-flag-checkered color-gold" id="finish-fight" />
                            </Button>
                            <Tooltip placement="top" target="finish-fight" text="Uzavrieť boj" />
                        </Empty>
                    )}
                </div>
            )}
            {(Dm || CombatSimulator.battlefield) && progress > -1 && dataLoaded && (
                <Battlefield
                    CombatSimulator={CombatSimulator}
                    Battlefields={Battlefields}
                    Users={Users}
                />
            )}
        </div>
    );
}
