import { gql } from '@apollo/client';

export const CUSTOM_IMAGES = gql`
    query customImages {
        customImages {
            id
            createdAt
            updatedAt
            title
            url
        }
    }
`;

export const ADD_CUSTOM_IMAGE = gql`
    mutation addCustomImage($title: String!, $url: String!) {
        addCustomImage(title: $title, url: $url) {
            id
        }
    }
`;

export const CUSTOM_IMAGE_SUBSCRIPTION = gql`
    subscription customImagesSubscription {
        customImagesSubscription
    }
`;
