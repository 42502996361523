import React from 'react';
import { Button, Label, ListGroup, ListGroupItem, Input } from 'reactstrap';
import ItemEdit from './edit';
import ItemAdd from './add';
import { Tooltip, Empty } from 'components';
import { useParams, useNavigate } from 'react-router';
import { ITEMS, ITEMS_SUBSCRIPTION } from 'queries';
import { useQuery, useSubscription } from '@apollo/client';

export default function ItemList() {
    const { id } = useParams();
    const itemID = parseInt(id);
    const navigate = useNavigate();

    const [show, setShow] = React.useState([]);
    const [filter, setFilter] = React.useState('');
    const {
        data: itemsData,
        loading: itemsLoading,
        refetch: itemsRefetch,
    } = useQuery(ITEMS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(ITEMS_SUBSCRIPTION, {
        onData: () => {
            itemsRefetch();
        },
    });

    const groupItems = (Items) => {
        let GroupedItems = [];
        Items.forEach((Item) => {
            const Index = GroupedItems.findIndex((Group) => Group.id === Item.type.id);
            if (Index === -1) {
                GroupedItems.push({
                    ...Item.type,
                    Items: [Item],
                });
            } else {
                GroupedItems[Index].Items.push(Item);
            }
        });
        return GroupedItems;
    };

    const Items = itemsLoading ? [] : itemsData.items;
    const GroupedItems = groupItems(Items);

    return (
        <div className="flex-row">
            <div className="settings-left">
                <div className="flex-row">
                    <Label className="center-hor color-white">Veci</Label>
                    <Input
                        placeholder="Vyhľadať"
                        className="m-l-20 width-200 small-input"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                    />
                    <Button
                        color="link"
                        id="add-item"
                        className="center-hor m-l-auto m-b-5"
                        onClick={() => navigate('/nastavenia_hry/veci/add')}
                    >
                        <i className="fa fa-plus" />
                    </Button>
                    <Tooltip placement="right" target="add-item" text="Vytvoriť novú vec" />
                </div>
                {filter !== '' && (
                    <ListGroup className="m-t-20">
                        {Items.filter((item) =>
                            item.title.normalize('NFD').toLowerCase().includes(filter.toLowerCase())
                        ).map((Item) => (
                            <ListGroupItem
                                key={Item.id}
                                active={itemID === Item.id}
                                action
                                className="clickable"
                                onClick={() => {
                                    navigate(`/nastavenia_hry/veci/${Item.id}`);
                                }}
                            >
                                {Item.title}
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                )}
                {filter === '' && (
                    <ListGroup className="items-list-categories noselect">
                        {GroupedItems.map((Group) => (
                            <Empty key={Group.id}>
                                <ListGroupItem
                                    key={Group.id}
                                    active={show.includes(Group.id)}
                                    action
                                    className="clickable m-t-10"
                                    onClick={() => {
                                        if (show.includes(Group.id)) {
                                            setShow(show.filter((id) => id !== Group.id));
                                        } else {
                                            setShow([...show, Group.id]);
                                        }
                                    }}
                                >
                                    {Group.label}
                                    <span className="center-right-absolute">
                                        Počet predmetov: {Group.Items.length}
                                    </span>
                                </ListGroupItem>
                                {show.includes(Group.id) && (
                                    <ListGroup className="m-l-20 items-list-entries">
                                        {Group.Items.map((Item) => (
                                            <ListGroupItem
                                                key={Item.id}
                                                active={itemID === Item.id}
                                                action
                                                className="clickable"
                                                onClick={() => {
                                                    navigate(`/nastavenia_hry/veci/${Item.id}`);
                                                }}
                                            >
                                                {Item.title}
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                )}
                            </Empty>
                        ))}
                    </ListGroup>
                )}
            </div>
            {!isNaN(itemID) && Items.some((Item) => Item.id === itemID) && (
                <ItemEdit itemID={itemID} />
            )}
            {id === 'add' && <ItemAdd />}
        </div>
    );
}
