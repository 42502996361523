import React from 'react';
import { Button, Col, Input, Label } from 'reactstrap';
import OpponentDisplay from './opponent';
import { GetMyData, GetMyRights, formattedMessage } from 'helperFunctions';
import { Empty } from 'components/Empty';
import dices from 'configs/dices';
import {
    ATTACKER_ROLL_DAMAGE,
    COMBAT_ROLL_DEXTERITY,
    END_FIGHT_PREMATURALLY,
    SPECIAL_ATTACK,
    SPECIAL_ATTACK_EFFECT,
    SPECIAL_ATTACK_ROLL,
    SPECIAL_MAGIC,
    SPECIAL_MAGIC_ROLL,
    SPECIAL_MAGIC_EFFECT,
    END_FIGHT_ROUND,
    CANCEL_FIGHT,
} from 'queries';
import classnames from 'classnames';
import { useMutation } from '@apollo/client';
import { Checkbox, Tooltip } from 'components';

const d4 = dices.find((dice) => dice.id === 'd4');
const d6 = dices.find((dice) => dice.id === 'd6');
const d100 = dices.find((dice) => dice.id === 'd100');

export default function Fight({ CombatSimulator }) {
    const currentUser = GetMyData();
    const { Dm } = GetMyRights();

    const Attacker = CombatSimulator.opponents.find(
        (Opponent) => Opponent.id === CombatSimulator.currentAttacker
    );
    const Defender = CombatSimulator.opponents.find(
        (Opponent) => Opponent.id === CombatSimulator.currentDefender
    );

    const attacker = JSON.parse(CombatSimulator.attacker);
    const defender = JSON.parse(CombatSimulator.defender);

    const [extraAttackerDamage, setExtraAttackerDamage] = React.useState(0);
    const [extraAttackerStun, setExtraAttackerStun] = React.useState(false);
    const [extraAttackerKill, setExtraAttackerKill] = React.useState(false);
    const [extraAttackerHeal, setExtraAttackerHeal] = React.useState(0);

    const [extraDefenderDamage, setExtraDefenderDamage] = React.useState(0);
    const [extraDefenderStun, setExtraDefenderStun] = React.useState(false);
    const [extraDefenderKill, setExtraDefenderKill] = React.useState(false);
    const [extraDefenderHeal, setExtraDefenderHeal] = React.useState(0);

    const [reason, setReason] = React.useState('');

    const [rollDamage] = useMutation(ATTACKER_ROLL_DAMAGE);
    const [endFightPrematurally] = useMutation(END_FIGHT_PREMATURALLY);
    const [combatRollDexterity] = useMutation(COMBAT_ROLL_DEXTERITY);

    const [specialAttack] = useMutation(SPECIAL_ATTACK);
    const [specialAttackRoll] = useMutation(SPECIAL_ATTACK_ROLL);
    const [specialAttackEffect] = useMutation(SPECIAL_ATTACK_EFFECT);

    const [specialMagic] = useMutation(SPECIAL_MAGIC);
    const [specialMagicRoll] = useMutation(SPECIAL_MAGIC_ROLL);
    const [specialMagicEffect] = useMutation(SPECIAL_MAGIC_EFFECT);

    const [cancelFight] = useMutation(CANCEL_FIGHT);
    const [endFightRound] = useMutation(END_FIGHT_ROUND);

    const getAttributeCurrent = (attributes, attr) => {
        const attribute = attributes[attr];
        const maximum = attribute.base + attribute.extra;
        const current = maximum - attribute.lost;
        return {
            maximum: ['strength', 'speed', 'dexterity', 'defence'].includes(attr)
                ? current
                : maximum,
            current,
        };
    };

    const getBasicDamage = () => {
        return (
            getAttributeCurrent(Attacker.attributes, 'strength').current -
            getAttributeCurrent(Defender.attributes, 'defence').current
        );
    };

    const getMaxDamage = () => {
        return getBasicDamage() + attacker.damageRoll;
    };

    const getTotalDexterity = (Opponent, fightData) => {
        return (
            getAttributeCurrent(Opponent.attributes, 'dexterity').current + fightData.dexterityRoll
        );
    };

    const getDexterityDiff = () => {
        return getTotalDexterity(Attacker, attacker) - getTotalDexterity(Defender, defender);
    };

    const getRealDamage = () => {
        if (getDexterityDiff() >= 0) {
            return getMaxDamage();
        }
        return getMaxDamage() + getDexterityDiff();
    };

    const getDefenderOpponentKilled = () => {
        if (attacker.kill) {
            return true;
        }
        return (
            getAttributeCurrent(Defender.attributes, 'vitality').current -
                getRealDamage() -
                (attacker.damage === null ? 0 : attacker.damage) +
                (defender.heal === null ? 0 : defender.heal) <=
            0
        );
    };

    const getAttackerOpponentKilled = () => {
        if (defender.kill) {
            return true;
        }
        return (
            getAttributeCurrent(Attacker.attributes, 'vitality').current -
                (defender.damage === null ? 0 : defender.damage) +
                (attacker.heal === null ? 0 : attacker.heal) <=
            0
        );
    };

    //COMBAT COMPONENTS
    const NextRound = () => (
        <div className="flex-row">
            <Button
                color="warning"
                id={`next-round-btn`}
                className="m-t-5 center-ver"
                disabled={!Dm}
                onClick={endFightPrematurally}
            >
                Ďalšie kolo!
            </Button>
            <Tooltip
                placement="top"
                target={`next-round-btn`}
                text="Odobere útočníkovi výdrž a útok"
            />
        </div>
    );

    const StrengthTest = () => (
        <Empty>
            <div className="combat-message">
                {formattedMessage([
                    {
                        style: { color: Attacker.color },
                        className: 'text-bolder',
                        text: Attacker.nickname,
                    },
                    ' má silu ',
                    {
                        className: 'text-bolder',
                        text: getAttributeCurrent(Attacker.attributes, 'strength').current,
                    },
                    ', obránca má obranu ',
                    {
                        className: 'text-bolder',
                        text: getAttributeCurrent(Defender.attributes, 'defence').current,
                    },
                    '. Potenciálne zranenie obráncu je ',
                    {
                        className: 'text-bolder',
                        text: getBasicDamage(),
                    },
                    '. Hod extra sily!',
                ])}
            </div>
            {attacker.damageRoll === null && (
                <div className="flex-row">
                    <d4.dice
                        className={classnames(
                            {
                                'clickable': Dm || currentUser.id === Attacker.user?.id,
                            },
                            'center-ver height-120'
                        )}
                        onClick={() => {
                            if (Dm || currentUser.id === Attacker.user?.id) {
                                rollDamage();
                            }
                        }}
                    />
                </div>
            )}
        </Empty>
    );

    const StrengthConsequences = () => (
        <Empty>
            <div className="combat-roll m-t-5 text-center" style={{ color: d4.color }}>
                {formattedMessage([
                    'Útočník hodil extra ',
                    {
                        className: 'text-bolder',
                        text: attacker.damageRoll,
                    },
                    ' sily!',
                ])}
            </div>
            <div className="combat-message m-t-5">
                {formattedMessage([
                    'Maximálne zranenie počas tohto útoku teda je ',
                    {
                        className: 'text-bolder',
                        text: getMaxDamage(),
                    },
                    getMaxDamage() > 0 ? '. Čas na test obratnosti!' : '. Útok zlyhal!',
                ])}
            </div>
            {getMaxDamage() <= 0 && NextRound()}
        </Empty>
    );

    const DexterityTest = () => (
        <Empty>
            <div className="flex-row">
                <Col>
                    {attacker.dexterityRoll === null && (
                        <div className="flex-row">
                            <d6.dice
                                className={classnames(
                                    {
                                        'clickable': Dm || currentUser.id === Attacker.user?.id,
                                    },
                                    'center-ver height-120'
                                )}
                                onClick={() => {
                                    if (Dm || currentUser.id === Attacker.user?.id) {
                                        combatRollDexterity({ variables: { attacker: true } });
                                    }
                                }}
                            />
                        </div>
                    )}
                    {attacker.dexterityRoll !== null && (
                        <div
                            className="combat-roll m-t-5 text-center"
                            style={{ backgroundColor: d6.color }}
                        >
                            {formattedMessage([
                                `Útočník hodil extra `,
                                {
                                    className: 'text-bolder',
                                    text: attacker.dexterityRoll,
                                },
                                ' obratnosti!',
                            ])}
                        </div>
                    )}
                </Col>
                <Col>
                    {defender.dexterityRoll === null && (
                        <div className="flex-row">
                            <d6.dice
                                className={classnames(
                                    {
                                        'clickable': Dm || currentUser.id === Defender.user?.id,
                                    },
                                    'center-ver height-120'
                                )}
                                onClick={() => {
                                    if (Dm || currentUser.id === Defender.user?.id) {
                                        combatRollDexterity({ variables: { attacker: false } });
                                    }
                                }}
                            />
                        </div>
                    )}
                    {defender.dexterityRoll !== null && (
                        <div
                            className="combat-roll m-t-5 text-center"
                            style={{ backgroundColor: d6.color }}
                        >
                            {formattedMessage([
                                `Obránca hodil extra `,
                                {
                                    className: 'text-bolder',
                                    text: defender.dexterityRoll,
                                },
                                ' obratnosti!',
                            ])}
                        </div>
                    )}
                </Col>
            </div>
            {attacker.dexterityRoll !== null && defender.dexterityRoll !== null && (
                <div className="combat-message m-t-5">
                    {formattedMessage([
                        'Utočníková celková obratnosť je teda ',
                        {
                            className: 'text-bolder',
                            text: getTotalDexterity(Attacker, attacker),
                        },
                        ' a obráncova ',
                        {
                            className: 'text-bolder',
                            text: getTotalDexterity(Defender, defender),
                        },
                        '. ',
                        {
                            className: 'text-bolder',
                            text: `${getTotalDexterity(Attacker, attacker)} - ${getTotalDexterity(
                                Defender,
                                defender
                            )} = ${getDexterityDiff()}. `,
                        },
                        ...(getDexterityDiff() >= 0
                            ? ['Obránca nevyblokoval vôbec útok!']
                            : [
                                  'Obránca vyblokoval ',
                                  {
                                      className: 'text-bolder',
                                      text: getDexterityDiff() * -1,
                                  },
                                  ' zranenia!',
                              ]),
                    ])}
                </div>
            )}
        </Empty>
    );

    const DexterityConsequences = () => (
        <Empty>
            {getRealDamage() <= 0 ? (
                <Empty>
                    <div className="combat-message m-t-5">
                        {formattedMessage([
                            'Po teste obratnosti reálne zranenie obráncu je ',
                            {
                                className: 'text-bolder',
                                text: getRealDamage(),
                            },
                            '. Útok zlyhal!',
                        ])}
                    </div>
                    {NextRound()}
                </Empty>
            ) : (
                <div className="combat-message m-t-5">
                    {formattedMessage([
                        'Po teste obratnosti reálne zranenie obráncu je ',
                        {
                            className: 'text-bolder',
                            text: getRealDamage(),
                        },
                        '.',
                    ])}
                </div>
            )}
        </Empty>
    );

    const SpecialAttack = () => (
        <Empty>
            <div className="flex-row m-t-10">
                <Col>
                    {attacker.specialAttack === null && (
                        <div className="flex-row">
                            <Button
                                className="center-ver"
                                disabled={!Dm}
                                color="success"
                                onClick={() => {
                                    specialAttack({
                                        variables: { attacker: true, specialAttack: true },
                                    });
                                }}
                            >
                                Špeciálny útok
                            </Button>
                            <Button
                                className="center-ver"
                                disabled={!Dm}
                                color="primary"
                                onClick={() => {
                                    specialMagic({
                                        variables: { attacker: true, specialMagic: true },
                                    });
                                }}
                            >
                                Mágia
                            </Button>
                            <Button
                                className="center-ver"
                                disabled={!Dm}
                                color="warning"
                                onClick={() => {
                                    specialAttack({
                                        variables: { attacker: true, specialAttack: false },
                                    });
                                }}
                            >
                                Preskočiť
                            </Button>
                        </div>
                    )}
                    {attacker.specialAttack && (
                        <Empty>
                            {attacker.specialAttackRoll === null && (
                                <div className="flex-row">
                                    <d100.dice
                                        className={classnames(
                                            {
                                                'clickable':
                                                    Dm || Attacker.user?.id === currentUser.id,
                                            },
                                            'center-ver height-120'
                                        )}
                                        onClick={() => {
                                            if (Dm || Attacker.user?.id === currentUser.id) {
                                                specialAttackRoll({
                                                    variables: { attacker: true },
                                                });
                                            }
                                        }}
                                    />
                                </div>
                            )}
                            {attacker.specialAttackRoll !== null && (
                                <Empty>
                                    <div
                                        className="combat-roll flex text-center"
                                        style={{ backgroundColor: d100.color }}
                                    >
                                        {formattedMessage([
                                            'Útočník hodil ',
                                            {
                                                className: 'text-bolder',
                                                text: attacker.specialAttackRoll,
                                            },
                                            ' zo 100 na špeciálnom útoku! Efekt?',
                                        ])}
                                    </div>
                                    {attacker.damage === null && Dm && (
                                        <div className="flex-row m-t-5 clean-input">
                                            <Label className="center-hor m-r-10">Zranenie</Label>
                                            <Input
                                                value={extraAttackerDamage}
                                                type="number"
                                                className="width-50"
                                                placeholder="Zranenie"
                                                onChange={(e) =>
                                                    setExtraAttackerDamage(e.target.value)
                                                }
                                            />
                                            <Checkbox
                                                label="Zabiť"
                                                value={extraAttackerKill}
                                                onChange={() =>
                                                    setExtraAttackerKill(!extraAttackerKill)
                                                }
                                                centerHor
                                            />
                                            <Checkbox
                                                label="Omráčiť"
                                                value={extraAttackerStun}
                                                onChange={() =>
                                                    setExtraAttackerStun(!extraAttackerStun)
                                                }
                                                centerHor
                                            />
                                            <Button
                                                className="m-l-5"
                                                color={
                                                    (!isNaN(parseInt(extraAttackerDamage)) &&
                                                        parseInt(extraAttackerDamage) > 0) ||
                                                    extraAttackerKill ||
                                                    extraAttackerStun
                                                        ? 'success'
                                                        : 'warning'
                                                }
                                                onClick={() => {
                                                    specialAttackEffect({
                                                        variables: {
                                                            attacker: true,
                                                            kill: extraAttackerKill,
                                                            damage: isNaN(
                                                                parseInt(extraAttackerDamage)
                                                            )
                                                                ? 0
                                                                : parseInt(extraAttackerDamage),
                                                            stun: extraAttackerStun,
                                                        },
                                                    });
                                                }}
                                            >
                                                {(!isNaN(parseInt(extraAttackerDamage)) &&
                                                    parseInt(extraAttackerDamage) > 0) ||
                                                extraAttackerKill ||
                                                extraAttackerStun
                                                    ? 'Pokračovať'
                                                    : 'Preskočiť'}
                                            </Button>
                                        </div>
                                    )}
                                </Empty>
                            )}
                        </Empty>
                    )}
                    {attacker.specialMagic && (
                        <Empty>
                            {attacker.specialMagicRoll === null && (
                                <div className="flex-row">
                                    <d4.dice
                                        className={classnames(
                                            {
                                                'clickable':
                                                    Dm || Attacker.user?.id === currentUser.id,
                                            },
                                            'center-ver height-120'
                                        )}
                                        onClick={() => {
                                            if (Dm || Attacker.user?.id === currentUser.id) {
                                                specialMagicRoll({
                                                    variables: { attacker: true },
                                                });
                                            }
                                        }}
                                    />
                                </div>
                            )}
                            {attacker.specialMagicRoll !== null && Dm && (
                                <Empty>
                                    <div
                                        className="combat-roll flex text-center"
                                        style={{ backgroundColor: d4.color }}
                                    >
                                        {formattedMessage([
                                            'Útočník hodil ',
                                            {
                                                className: 'text-bolder',
                                                text: attacker.specialMagicRoll,
                                            },
                                            ' zo 4! Efekt?',
                                        ])}
                                    </div>
                                    {attacker.damage === null && (
                                        <div className="flex-row m-t-5 clean-input">
                                            <Label className="center-hor m-r-10">Zranenie</Label>
                                            <Input
                                                value={extraAttackerDamage}
                                                type="number"
                                                className="width-50"
                                                placeholder="Zranenie"
                                                onChange={(e) =>
                                                    setExtraAttackerDamage(e.target.value)
                                                }
                                            />
                                            <Label className="center-hor m-r-10">+Zdravie</Label>
                                            <Input
                                                value={extraAttackerHeal}
                                                type="number"
                                                className="width-50"
                                                placeholder="+Zdravie"
                                                onChange={(e) =>
                                                    setExtraAttackerHeal(e.target.value)
                                                }
                                            />
                                            <Checkbox
                                                label="Omráčiť"
                                                value={extraAttackerStun}
                                                onChange={() =>
                                                    setExtraAttackerStun(!extraAttackerStun)
                                                }
                                                centerHor
                                            />
                                            <Button
                                                className="m-l-5"
                                                color={
                                                    (!isNaN(parseInt(extraAttackerDamage)) &&
                                                        parseInt(extraAttackerDamage) > 0) ||
                                                    (!isNaN(parseInt(extraAttackerHeal)) &&
                                                        parseInt(extraAttackerHeal) > 0) ||
                                                    extraAttackerStun
                                                        ? 'success'
                                                        : 'warning'
                                                }
                                                onClick={() => {
                                                    specialMagicEffect({
                                                        variables: {
                                                            attacker: true,
                                                            damage: isNaN(
                                                                parseInt(extraAttackerDamage)
                                                            )
                                                                ? 0
                                                                : parseInt(extraAttackerDamage),
                                                            heal: isNaN(parseInt(extraAttackerHeal))
                                                                ? 0
                                                                : parseInt(extraAttackerHeal),
                                                            stun: extraAttackerStun,
                                                        },
                                                    });
                                                }}
                                            >
                                                {(!isNaN(parseInt(extraAttackerDamage)) &&
                                                    parseInt(extraAttackerDamage) > 0) ||
                                                (!isNaN(parseInt(extraAttackerHeal)) &&
                                                    parseInt(extraAttackerHeal) > 0) ||
                                                extraAttackerStun
                                                    ? 'Pokračovať'
                                                    : 'Preskočiť'}
                                            </Button>
                                        </div>
                                    )}
                                </Empty>
                            )}
                        </Empty>
                    )}
                </Col>
                <Col>
                    {defender.specialAttack === null && (
                        <div className="flex-row">
                            <Button
                                className="center-ver"
                                disabled={!Dm}
                                color="success"
                                onClick={() => {
                                    specialAttack({
                                        variables: { attacker: false, specialAttack: true },
                                    });
                                }}
                            >
                                Špeciálny útok
                            </Button>
                            <Button
                                className="center-ver"
                                disabled={!Dm}
                                color="primary"
                                onClick={() => {
                                    specialMagic({
                                        variables: { attacker: false, specialMagic: true },
                                    });
                                }}
                            >
                                Mágia
                            </Button>
                            <Button
                                className="center-ver"
                                disabled={!Dm}
                                color="warning"
                                onClick={() => {
                                    specialAttack({
                                        variables: { attacker: false, specialAttack: false },
                                    });
                                }}
                            >
                                Preskočiť
                            </Button>
                        </div>
                    )}
                    {defender.specialAttack && (
                        <Empty>
                            {defender.specialAttackRoll === null && (
                                <div className="flex-row">
                                    <d100.dice
                                        className={classnames(
                                            {
                                                'clickable':
                                                    Dm || Defender.user?.id === currentUser.id,
                                            },
                                            'center-ver height-120'
                                        )}
                                        onClick={() => {
                                            if (Dm || Defender.user?.id === currentUser.id) {
                                                specialAttackRoll({
                                                    variables: { attacker: false },
                                                });
                                            }
                                        }}
                                    />
                                </div>
                            )}
                            {defender.specialAttackRoll !== null && Dm && (
                                <Empty>
                                    <div
                                        className="combat-roll flex text-center"
                                        style={{ backgroundColor: d100.color }}
                                    >
                                        {formattedMessage([
                                            'Útočník hodil ',
                                            {
                                                className: 'text-bolder',
                                                text: defender.specialAttackRoll,
                                            },
                                            ' zo 100 na špeciálnom útoku! Efekt?',
                                        ])}
                                    </div>
                                    {defender.damage === null && (
                                        <div className="flex-row m-t-5 clean-input">
                                            <Label className="center-hor m-r-10">Zranenie</Label>
                                            <Input
                                                value={extraDefenderDamage}
                                                type="number"
                                                className="width-50"
                                                placeholder="Zranenie"
                                                onChange={(e) =>
                                                    setExtraDefenderDamage(e.target.value)
                                                }
                                            />
                                            <Checkbox
                                                label="Zabiť"
                                                value={extraDefenderKill}
                                                onChange={() =>
                                                    setExtraDefenderKill(!extraDefenderKill)
                                                }
                                                centerHor
                                            />
                                            <Checkbox
                                                label="Omráčiť"
                                                value={extraDefenderStun}
                                                onChange={() =>
                                                    setExtraDefenderStun(!extraDefenderStun)
                                                }
                                                centerHor
                                            />
                                            <Button
                                                className="m-l-5"
                                                color={
                                                    (!isNaN(parseInt(extraDefenderDamage)) &&
                                                        parseInt(extraDefenderDamage) > 0) ||
                                                    extraDefenderKill ||
                                                    extraDefenderStun
                                                        ? 'success'
                                                        : 'warning'
                                                }
                                                onClick={() => {
                                                    specialAttackEffect({
                                                        variables: {
                                                            attacker: false,
                                                            kill: extraDefenderKill,
                                                            damage: isNaN(
                                                                parseInt(extraDefenderDamage)
                                                            )
                                                                ? 0
                                                                : parseInt(extraDefenderDamage),
                                                            stun: extraDefenderStun,
                                                        },
                                                    });
                                                }}
                                            >
                                                {(!isNaN(parseInt(extraDefenderDamage)) &&
                                                    parseInt(extraDefenderDamage) > 0) ||
                                                extraDefenderKill ||
                                                extraDefenderStun
                                                    ? 'Pokračovať'
                                                    : 'Preskočiť'}
                                            </Button>
                                        </div>
                                    )}
                                </Empty>
                            )}
                        </Empty>
                    )}
                    {defender.specialMagic && (
                        <Empty>
                            {defender.specialMagicRoll === null && (
                                <div className="flex-row">
                                    <d4.dice
                                        className={classnames(
                                            {
                                                'clickable':
                                                    Dm || Defender.user?.id === currentUser.id,
                                            },
                                            'center-ver height-120'
                                        )}
                                        onClick={() => {
                                            if (Dm || Defender.user?.id === currentUser.id) {
                                                specialMagicRoll({
                                                    variables: { attacker: false },
                                                });
                                            }
                                        }}
                                    />
                                </div>
                            )}
                            {defender.specialMagicRoll !== null && (
                                <Empty>
                                    <div
                                        className="combat-roll flex text-center"
                                        style={{ backgroundColor: d4.color }}
                                    >
                                        {formattedMessage([
                                            'Útočník hodil ',
                                            {
                                                className: 'text-bolder',
                                                text: defender.specialMagicRoll,
                                            },
                                            ' zo 4! Efekt?',
                                        ])}
                                    </div>
                                    {defender.damage === null && Dm && (
                                        <div className="flex-row m-t-5 clean-input">
                                            <Label className="center-hor m-r-10">Zranenie</Label>
                                            <Input
                                                value={extraDefenderDamage}
                                                type="number"
                                                className="width-50"
                                                placeholder="Zranenie"
                                                onChange={(e) =>
                                                    setExtraDefenderDamage(e.target.value)
                                                }
                                            />
                                            <Label className="center-hor m-r-10">+Zdravie</Label>
                                            <Input
                                                value={extraDefenderHeal}
                                                type="number"
                                                className="width-50"
                                                placeholder="+Zdravie"
                                                onChange={(e) =>
                                                    setExtraDefenderHeal(e.target.value)
                                                }
                                            />
                                            <Checkbox
                                                label="Omráčiť"
                                                value={extraDefenderStun}
                                                onChange={() =>
                                                    setExtraDefenderStun(!extraDefenderStun)
                                                }
                                                centerHor
                                            />
                                            <Button
                                                className="m-l-5"
                                                color={
                                                    (!isNaN(parseInt(extraDefenderDamage)) &&
                                                        parseInt(extraDefenderDamage) > 0) ||
                                                    (!isNaN(parseInt(extraDefenderHeal)) &&
                                                        parseInt(extraDefenderHeal) > 0) ||
                                                    extraDefenderStun
                                                        ? 'success'
                                                        : 'warning'
                                                }
                                                onClick={() => {
                                                    specialMagicEffect({
                                                        variables: {
                                                            attacker: false,
                                                            damage: isNaN(
                                                                parseInt(extraDefenderDamage)
                                                            )
                                                                ? 0
                                                                : parseInt(extraDefenderDamage),
                                                            heal: isNaN(parseInt(extraDefenderHeal))
                                                                ? 0
                                                                : parseInt(extraDefenderHeal),
                                                            stun: extraDefenderStun,
                                                        },
                                                    });
                                                }}
                                            >
                                                {(!isNaN(parseInt(extraDefenderDamage)) &&
                                                    parseInt(extraDefenderDamage) > 0) ||
                                                (!isNaN(parseInt(extraDefenderHeal)) &&
                                                    parseInt(extraDefenderHeal) > 0) ||
                                                extraDefenderStun
                                                    ? 'Pokračovať'
                                                    : 'Preskočiť'}
                                            </Button>
                                        </div>
                                    )}
                                </Empty>
                            )}
                        </Empty>
                    )}
                </Col>
            </div>
        </Empty>
    );

    //zabit nepriatela ak jeho celkove zdravie je 0 alebo menej, zadat dovod umrtia
    //inak aplikovat damage
    const ResolveFight = () => (
        <Empty>
            {(attacker.specialAttack || attacker.specialMagic) && (
                <div
                    className="combat-roll flex text-center m-t-5"
                    style={{ backgroundColor: attacker.specialAttack ? d100.color : d4.color }}
                >
                    {`Útočníkov ${
                        attacker.specialAttack ? 'špeciálny' : 'magický'
                    } útok spôsobil: `}
                    {attacker.damage !== 0 && (
                        <span className="m-r-5">Zranenie: {attacker.damage}!</span>
                    )}
                    {attacker.stun && <span className="m-r-5">Paralizoval nepriateľa!</span>}
                    {attacker.heal !== 0 && (
                        <span className="m-r-5">Vyliečil sa za: {attacker.heal}!</span>
                    )}
                    {attacker.kill && <span className="m-r-5">Zabil nepriateľa!</span>}
                </div>
            )}
            {(defender.specialAttack || defender.specialMagic) && (
                <div
                    className="combat-roll flex text-center m-t-5"
                    style={{ backgroundColor: defender.specialAttack ? d100.color : d4.color }}
                >
                    {`Obráncov ${defender.specialAttack ? 'špeciálny' : 'magický'} útok spôsobil: `}
                    {defender.damage !== 0 && (
                        <span className="m-r-5">Zranenie: {defender.damage}!</span>
                    )}
                    {defender.stun && <span className="m-r-5">Paralizoval nepriateľa!</span>}
                    {defender.heal !== 0 && (
                        <span className="m-r-5">Vyliečil sa za: {defender.heal}!</span>
                    )}
                    {defender.kill && <span className="m-r-5">Zabil nepriateľa!</span>}
                </div>
            )}
            {(getDefenderOpponentKilled() || getAttackerOpponentKilled()) && (
                <Empty>
                    {getDefenderOpponentKilled() && (
                        <div className="combat-message text-center">
                            {formattedMessage([
                                {
                                    style: { color: Attacker.color },
                                    className: 'text-bolder',
                                    text: Attacker.nickname,
                                },
                                ' zabila obráncu ',
                                {
                                    style: { color: Defender.color },
                                    className: 'text-bolder',
                                    text: Defender.nickname,
                                },
                                '!',
                            ])}
                        </div>
                    )}
                    {getAttackerOpponentKilled() && (
                        <div className="combat-message">
                            {formattedMessage([
                                {
                                    style: { color: Defender.color },
                                    className: 'text-bolder',
                                    text: Defender.nickname,
                                },
                                ' zabil útočníka ',
                                {
                                    style: { color: Attacker.color },
                                    className: 'text-bolder',
                                    text: Attacker.nickname,
                                },
                                '!',
                            ])}
                        </div>
                    )}
                    {(Dm ||
                        (Defender.user?.id === currentUser.id && getAttackerOpponentKilled()) ||
                        (Attacker.user?.id === currentUser.id && getDefenderOpponentKilled())) && (
                        <div className="flex-row m-t-5">
                            <Input
                                className="width-200 center-ver"
                                placeholder="Spôsob úmrtia"
                                value={reason}
                                onChange={(e) => {
                                    setReason(e.target.value);
                                }}
                            />
                            <Button
                                color="danger"
                                className="m-l-10 center-ver"
                                disabled={reason.length < 5}
                                onClick={() => endFightRound({ variables: { reason } })}
                            >
                                Zabiť nepriatela
                            </Button>
                        </div>
                    )}
                </Empty>
            )}
            {Dm && !getDefenderOpponentKilled() && !getAttackerOpponentKilled() && (
                <div className="flex-row m-t-5">
                    <Button color="warning" className="center-ver" onClick={() => endFightRound()}>
                        Ukončiť kolo
                    </Button>
                </div>
            )}
        </Empty>
    );

    return (
        <Empty>
            <div className="flex-row">
                <Col className="m-r-5">
                    <OpponentDisplay type="Útočník" Opponent={Attacker} noMajorChanges />
                </Col>
                <Col className="m-l-5">
                    <OpponentDisplay type="Obránca" Opponent={Defender} noMajorChanges />
                </Col>
            </div>
            {Dm && (
                <div className="m-b-5 flex-row">
                    <Button
                        color="danger"
                        className="center-ver"
                        onClick={() => {
                            if (window.confirm('Si si istý že chceš zrušiť tento útok?')) {
                                cancelFight();
                            }
                        }}
                    >
                        Zrušiť útok
                    </Button>
                </div>
            )}
            {StrengthTest()}
            {attacker.damageRoll !== null && (
                <Empty>
                    {StrengthConsequences()}
                    {getMaxDamage() > 0 && (
                        <Empty>
                            {DexterityTest()}
                            {attacker.dexterityRoll !== null && defender.dexterityRoll !== null && (
                                <Empty>
                                    {DexterityConsequences()}
                                    {getRealDamage() > 0 && (
                                        <Empty>
                                            {SpecialAttack()}
                                            {attacker.specialAttack !== null &&
                                                defender.specialAttack !== null &&
                                                ((!attacker.specialAttack &&
                                                    !attacker.specialMagic) ||
                                                    attacker.damage !== null) &&
                                                ((!defender.specialAttack &&
                                                    !defender.specialMagic) ||
                                                    defender.damage !== null) &&
                                                ResolveFight()}
                                            {/*Resolve special or no special end round or kill*/}
                                        </Empty>
                                    )}
                                </Empty>
                            )}
                        </Empty>
                    )}
                </Empty>
            )}
        </Empty>
    );
}
