import React from 'react';
import { setIsLoggedIn, setTestedToken } from 'apollo/localSchema/actions';
import { useQuery } from '@apollo/client';
import { SketchPicker } from 'react-color';
import refreshToken from 'apollo/refreshToken';
import { GET_TESTED_TOKEN } from 'apollo/queries';
import { Button, FormGroup, Label, Input } from 'reactstrap';
import Select from 'react-select';
import { toSelArr, sameColors } from 'helperFunctions';
import genders from 'configs/genders';

export default function Login(props) {
    const { loginUser, registerUser, registrationOpen, userColors, races, occupations } = props;

    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [color, setColor] = React.useState('#f759f2');
    const [character, setCharacter] = React.useState('');
    const [nickname, setNickname] = React.useState('');
    const [race, setRace] = React.useState(null);
    const [occupation, setOccupation] = React.useState(null);
    const [age, setAge] = React.useState(20);
    const [gender, setGender] = React.useState(genders[0]);

    const [working, setWorking] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [registration, setRegistration] = React.useState(false);

    const { data: testedTokenData, loading: testedTokenLoading } = useQuery(GET_TESTED_TOKEN);

    const login = () => {
        setWorking(true);
        setError(null);

        loginUser({
            variables: {
                email,
                password,
            },
        })
            .then((response) => {
                setWorking(false);
                sessionStorage.setItem('acctok', response.data.loginUser.accessToken);
                setIsLoggedIn(true);
            })
            .catch((err) => {
                setWorking(false);
                setError(err.message);
            });
    };

    const register = () => {
        setWorking(true);
        registerUser({
            variables: {
                email: email,
                password: password,
                color: color,
                nickname: nickname,
                character: character,
                age: isNaN(parseInt(age)) ? 20 : parseInt(age),
                gender: gender.value,
                raceId: race.id,
                occupationId: occupation.id,
            },
        })
            .then((response) => {
                setWorking(false);
                sessionStorage.setItem('acctok', response.data.registerUser.accessToken);
                setIsLoggedIn(true);
            })
            .catch((err) => {
                setWorking(false);
                setError(err.message);
            });
    };

    React.useEffect(() => {
        if (!testedTokenData.testedToken) {
            setTestedToken(true);
            tryLogin();
        }
    }, [testedTokenLoading, testedTokenData.testedToken]);

    const tryLogin = () => {
        setWorking(true);
        refreshToken()
            .then((response) => {
                const { ok, accessToken } = response.data;
                if (ok) {
                    sessionStorage.setItem('acctok', accessToken);
                    setIsLoggedIn(true);
                    setWorking(false);
                } else {
                    sessionStorage.removeItem('acctok');
                    setIsLoggedIn(false);
                    setWorking(false);
                }
            })
            .catch(() => {
                setIsLoggedIn(false);
                setWorking(false);
            });
    };

    const similiarColors = userColors.filter((User) => sameColors(User.color, color));

    const cantRegister =
        working ||
        email.length < 5 ||
        password.length < 5 ||
        race === null ||
        occupation === null ||
        similiarColors.length > 0;

    const cantLogin = working || email.length === 0 || password.length === 0;

    return (
        <div className="login-bkg">
            <div className="card login-card">
                {registrationOpen && (
                    <Button
                        color={registration ? 'primary' : 'success'}
                        className="reg-button"
                        onClick={() => setRegistration(!registration)}
                    >
                        {registration ? 'Prihlásenie' : 'Registrácia'} tu
                    </Button>
                )}
                <FormGroup className="m-t-10">
                    <Label for="email">E-mail</Label>
                    <Input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Zadaj e-mail"
                        value={email}
                        disabled={working}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyUp={(e) => {
                            if (!registration && e.key === 'Enter' && !cantLogin) {
                                login();
                            }
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="pass">Heslo</Label>
                    <Input
                        type="password"
                        name="pass"
                        id="pass"
                        placeholder="Zadaj heslo"
                        value={password}
                        disabled={working}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyUp={(e) => {
                            if (!registration && e.key === 'Enter' && !cantLogin) {
                                login();
                            }
                        }}
                    />
                </FormGroup>
                {registration && (
                    <div>
                        <FormGroup>
                            <Label for="nickname">Prezývka</Label>
                            <Input
                                type="text"
                                id="nickname"
                                placeholder="Zadaj prezývku"
                                value={nickname}
                                disabled={working}
                                onChange={(e) => setNickname(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup className="flex-row">
                            <Label for="color">Farba hráča</Label>
                            <SketchPicker
                                id="color"
                                color={color}
                                onChangeComplete={(value) => setColor(value.hex)}
                            />
                            <div
                                className="height-300 width-100 p-10 text-bolder"
                                style={{ backgroundColor: color }}
                            >
                                Hráčová farba
                            </div>
                            {similiarColors.length > 0 && (
                                <div className="height-300 width-200 text-bolder">
                                    <div className="p-10">
                                        Táto farba je príliž podobná týmto hráčom!
                                    </div>
                                    {similiarColors.map((User) => (
                                        <div
                                            className="p-10"
                                            style={{
                                                height: Math.min(
                                                    70,
                                                    (300 - 24) / similiarColors.length
                                                ),
                                                backgroundColor: User.color,
                                            }}
                                        >{`${User.nickname}(${User.character})`}</div>
                                    ))}
                                </div>
                            )}
                        </FormGroup>
                        <hr />
                        <FormGroup>
                            <Label for="character">Meno charakteru</Label>
                            <Input
                                type="text"
                                id="character"
                                placeholder="Zadaj meno charakteru"
                                value={character}
                                disabled={working}
                                onChange={(e) => setCharacter(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="race">Rasa</Label>
                            <Select
                                value={race}
                                options={toSelArr(races)}
                                placeholder="Vyber rasu"
                                onChange={(race) => setRace(race)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="occupation">Povolanie</Label>
                            <Select
                                value={occupation}
                                options={toSelArr(occupations)}
                                placeholder="Vyber povolanie"
                                onChange={(occupation) => setOccupation(occupation)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="gender">Pohlavie</Label>
                            <Select
                                value={gender}
                                options={genders}
                                placeholder="Vyber pohlavie"
                                onChange={(gender) => setGender(gender)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="age">Vek</Label>
                            <Input
                                type="number"
                                id="age"
                                placeholder="Zadaj vek"
                                value={age}
                                disabled={working}
                                onChange={(e) => setAge(e.target.value)}
                            />
                        </FormGroup>
                    </div>
                )}
                <Button
                    color={registration ? 'success' : 'primary'}
                    disabled={(registration && cantRegister) || (!registration && cantLogin)}
                    onClick={registration ? register : login}
                >
                    {registration ? 'Registrovať' : 'Prihlásiť'}
                </Button>
                {error && <div className="color-red">Zlé meno/heslo!</div>}
            </div>
        </div>
    );
}
