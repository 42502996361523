import { GetMyData, GetMyRights } from 'helperFunctions';
import React from 'react';
import Select from 'react-select';
import { Empty } from './Empty';
import { Button, Label } from 'reactstrap';
import { useMutation } from '@apollo/client';
import { CLEAR_ALL_IMAGE_DRAWING, CLEAR_MY_IMAGE_DRAWING, DRAW_ON_IMAGE } from 'queries';

const sizes = [3, 5, 7, 10, 13, 15, 20];
const sizesSelect = sizes.map((size) => ({ label: size, value: size }));

export function Canvas({ image, complexID, stopDrawing, instructions }) {
    const { color: userColor } = GetMyData();
    const { Dm } = GetMyRights();

    const [newInstruction, setNewInstruction] = React.useState([]);
    const [drawing, setDrawing] = React.useState(false);
    const [red, setRed] = React.useState(false);
    const [size, setSize] = React.useState(5);
    const [mainImage, setMainImage] = React.useState(null);

    const canvasRef = React.useRef(null);
    const [drawOnImage] = useMutation(DRAW_ON_IMAGE);
    const [clearMyImageDrawing] = useMutation(CLEAR_MY_IMAGE_DRAWING);
    const [clearAllImageDrawing] = useMutation(CLEAR_ALL_IMAGE_DRAWING);

    React.useEffect(() => {
        var mainImage = new Image();
        mainImage.src = image;
        mainImage.onload = function () {
            setMainImage(mainImage);
        };
    }, [image]);

    React.useEffect(() => {
        redraw();
        // eslint-disable-next-line
    }, [instructions, mainImage]);

    const redraw = () => {
        if (mainImage === null || drawing) {
            return;
        }
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        canvas.width = mainImage.width;
        canvas.height = mainImage.height;
        ctx.drawImage(mainImage, 0, 0);

        ctx.lineCap = 'round';
        instructions.forEach((instruction) => {
            ctx.strokeStyle = instruction.color;
            ctx.lineWidth = instruction.size;
            ctx.beginPath();
            instruction.lines.forEach((line, index) => {
                if (index === 0) {
                    ctx.moveTo(line.x, line.y);
                } else {
                    ctx.lineTo(line.x, line.y);
                }
            });
            ctx.stroke();
        });
    };

    //drawing
    const startDrawing = (e) => {
        setDrawing(true);
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        //draw a dot
        ctx.beginPath();
        ctx.lineWidth = size * 1.25;
        ctx.lineCap = 'round';
        ctx.strokeStyle = red ? 'red' : userColor;
        const bounds = canvas.getBoundingClientRect();
        const xMultiplier = canvas.width / bounds.width;
        const yMultiplier = canvas.height / bounds.height;
        ctx.moveTo(
            (e.clientX - bounds.x - 1) * xMultiplier,
            (e.clientY - bounds.y - 1) * yMultiplier
        );
        ctx.lineTo((e.clientX - bounds.x) * xMultiplier, (e.clientY - bounds.y) * yMultiplier);
        ctx.stroke();

        //start drawing lines
        ctx.beginPath();
        ctx.lineWidth = size;
        ctx.moveTo((e.clientX - bounds.x) * xMultiplier, (e.clientY - bounds.y) * yMultiplier);
        setNewInstruction([
            {
                size: size * 1.25,
                color: red ? 'red' : userColor,
                lines: [
                    {
                        x: (e.clientX - bounds.x - 1) * xMultiplier,
                        y: (e.clientY - bounds.y - 1) * yMultiplier,
                    },
                    {
                        x: (e.clientX - bounds.x) * xMultiplier,
                        y: (e.clientY - bounds.y) * yMultiplier,
                    },
                ],
            },
            {
                size: size,
                color: red ? 'red' : userColor,
                lines: [],
            },
        ]);
    };

    const draw = (e) => {
        if (!drawing) {
            return;
        }
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        const bounds = canvas.getBoundingClientRect();
        const xMultiplier = canvas.width / bounds.width;
        const yMultiplier = canvas.height / bounds.height;
        ctx.lineTo((e.clientX - bounds.x) * xMultiplier, (e.clientY - bounds.y) * yMultiplier);
        ctx.stroke();
        let replacementInstruction = [...newInstruction];
        let replaced = replacementInstruction.pop();
        replaced.lines.push({
            x: (e.clientX - bounds.x) * xMultiplier,
            y: (e.clientY - bounds.y) * yMultiplier,
        });
        replacementInstruction.push(replaced);
        setNewInstruction(replacementInstruction);
    };

    const minimalizeLines = (lines) => {
        let newLines = [];
        lines.forEach((line, index) => {
            if (newLines.length === 0) {
                newLines.push(line);
            }
            const previous = newLines[newLines.length - 1];
            if (previous.x !== line.x || previous.y !== line.y) {
                if (index < 5 || index % 5 === 0) {
                    newLines.push(line);
                }
            }
        });
        return newLines;
    };

    const endDrawing = () => {
        setDrawing(false);
        //filterUselessInstructions
        let newInstructions = [newInstruction[0]];
        if (newInstruction[1]) {
            newInstructions.push({
                ...newInstruction[1],
                lines: minimalizeLines(newInstruction[1].lines),
            });
        }
        drawOnImage({
            variables: {
                drawing: JSON.stringify(newInstructions),
            },
        });
        setNewInstruction([]);
    };

    return (
        <Empty>
            <div className="center-ver flex-row">
                <Button
                    color="link"
                    onClick={() => {
                        setRed(!red);
                    }}
                >
                    {red ? 'Moja' : 'Červená'}
                </Button>

                <Button color="link" onClick={stopDrawing}>
                    Skončiť kreslenie
                </Button>
                <Button color="link" onClick={clearMyImageDrawing}>
                    Vyčistiť
                </Button>
                {Dm && (
                    <Button
                        color="link"
                        onClick={() => {
                            if (window.confirm('Vyčistiť všetko?')) {
                                clearAllImageDrawing();
                            }
                        }}
                    >
                        Vyčistiť všetko
                    </Button>
                )}
                <Label className="center-hor m-r-5">Veľkosť</Label>
                <div>
                    <Select
                        value={sizesSelect.find((sizeSelect) => sizeSelect.value === size)}
                        options={sizesSelect}
                        placeholder="Veľkosť pera"
                        onChange={(size) => {
                            setSize(size.value);
                        }}
                    />
                </div>
            </div>
            <canvas
                id={`${complexID}-drawing`}
                ref={canvasRef}
                onMouseDown={startDrawing}
                onMouseUp={endDrawing}
                onMouseMove={draw}
                onContextMenu={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            />
        </Empty>
    );
}
