import { gql } from '@apollo/client';

export const NOTES = gql`
    query notes {
        notes {
            id
            createdAt
            updatedAt
            category
            message
            isPrivate
            isSuperPrivate
            importantList
            ignoreList
            user {
                id
                color
                nickname
                character
            }
        }
    }
`;
export const ADD_NOTE = gql`
    mutation addNote($category: String!, $message: String!, $isPrivate: Boolean!) {
        addNote(category: $category, message: $message, isPrivate: $isPrivate) {
            id
        }
    }
`;
export const UPDATE_NOTE = gql`
    mutation updateNote($id: Int!, $category: String, $message: String, $isPrivate: Boolean) {
        updateNote(id: $id, category: $category, message: $message, isPrivate: $isPrivate) {
            id
        }
    }
`;
export const DELETE_NOTE = gql`
    mutation deleteNote($id: Int!) {
        deleteNote(id: $id) {
            id
        }
    }
`;
export const SET_NOTE_PRIVATE = gql`
    mutation setNotePrivate($id: Int!) {
        setNotePrivate(id: $id) {
            id
        }
    }
`;
export const SET_NOTE_SUPER_PRIVATE = gql`
    mutation setNoteSuperPrivate($id: Int!) {
        setNoteSuperPrivate(id: $id) {
            id
        }
    }
`;
export const SET_NOTE_IMPORTANT = gql`
    mutation setNoteImportant($id: Int!) {
        setNoteImportant(id: $id) {
            id
        }
    }
`;
export const SET_NOTE_IGNORED = gql`
    mutation setNoteIgnored($id: Int!) {
        setNoteIgnored(id: $id) {
            id
        }
    }
`;

export const NOTES_SUBSCRIPTION = gql`
    subscription notesSubscription {
        notesSubscription
    }
`;
