import { gql } from '@apollo/client';

export const RECIPE = gql`
    query recipe($id: Int) {
        recipe(id: $id) {
            id
            title
            createdAt
            updatedAt
            title
            description
            visibility
            icon
            order
            price
            ingredients {
                id
                createdAt
                updatedAt
                quantity
                itemId
                title
                description
                icon
                price
                type
                weight
            }
            tools {
                id
                createdAt
                updatedAt
                quantity
                itemId
                title
                description
                icon
                price
                type
                weight
            }
            results {
                id
                createdAt
                updatedAt
                quantity
                itemId
                title
                description
                icon
                price
                type
                weight
            }
            craftings {
                id
                createdAt
                quantity
                crafted
                denied
            }
        }
    }
`;

export const RECIPES = gql`
    query recipes($visibleOnly: Boolean) {
        recipes(visibleOnly: $visibleOnly) {
            id
            icon
            order
            description
            price
            title
            visibility
        }
    }
`;
export const ADD_RECIPE = gql`
    mutation addRecipe(
        $title: String
        $visibility: Boolean
        $description: String
        $icon: String
        $order: Int
        $price: Int
        $ingredients: [RecipeItemInput]
        $tools: [RecipeItemInput]
        $results: [RecipeItemInput]
    ) {
        addRecipe(
            title: $title
            visibility: $visibility
            description: $description
            icon: $icon
            order: $order
            price: $price
            ingredients: $ingredients
            tools: $tools
            results: $results
        ) {
            id
        }
    }
`;
export const UPDATE_RECIPE = gql`
    mutation updateRecipe(
        $id: Int!
        $title: String
        $description: String
        $visibility: Boolean
        $icon: String
        $removeIcon: Boolean
        $order: Int
        $price: Int
        $ingredients: [RecipeItemInput]
        $tools: [RecipeItemInput]
        $results: [RecipeItemInput]
    ) {
        updateRecipe(
            id: $id
            title: $title
            description: $description
            visibility: $visibility
            icon: $icon
            removeIcon: $removeIcon
            order: $order
            price: $price
            ingredients: $ingredients
            tools: $tools
            results: $results
        ) {
            id
        }
    }
`;
export const DELETE_RECIPE = gql`
    mutation deleteRecipe($id: Int!) {
        deleteRecipe(id: $id) {
            id
        }
    }
`;

export const RECIPES_SUBSCRIPTION = gql`
    subscription recipesSubscription {
        recipesSubscription
    }
`;
