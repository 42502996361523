import React from 'react';
import { Card, CardHeader, CardBody, Button, FormGroup, Input, Table } from 'reactstrap';
import classnames from 'classnames';
import Select from 'react-select';
import { Checkbox, ImageInput } from 'components';

import EditItem from './itemEdit';
import AddItem from './itemAdd';
import { updateArrayItem, toSelArr, toBase64 } from 'helperFunctions';
import {
    DELETE_TRADER,
    ITEMS,
    ITEMS_SUBSCRIPTION,
    ITEM_TYPES,
    RECIPES,
    RECIPES_SUBSCRIPTION,
    TRADER,
    TRADERS_SUBSCRIPTION,
    UPDATE_TRADER,
} from 'queries';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import RecipeEdit from './recipeEdit';

export default function TraderEdit(props) {
    const { traderID } = props;

    const [title, setTitle] = React.useState('');
    const [visibility, setVisibility] = React.useState(false);
    const [portrait, setPortrait] = React.useState(null);
    const [items, setItems] = React.useState([]);
    const [recipes, setRecipes] = React.useState([]);

    const [existingItem, setExistingItem] = React.useState(null);
    const [existingRecipe, setExistingRecipe] = React.useState(null);
    const [saving, setSaving] = React.useState(false);

    const [updateTrader] = useMutation(UPDATE_TRADER);
    const [deleteTrader] = useMutation(DELETE_TRADER);

    const {
        data: traderData,
        loading: traderLoading,
        refetch: traderRefetch,
    } = useQuery(TRADER, {
        variables: { id: traderID },
        fetchPolicy: 'network-only',
    });

    const Trader = traderLoading ? null : traderData.trader;

    useSubscription(TRADERS_SUBSCRIPTION, {
        onData: () => {
            traderRefetch({ id: traderID });
        },
    });

    const {
        data: itemsData,
        loading: itemsLoading,
        refetch: itemsRefetch,
    } = useQuery(ITEMS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(ITEMS_SUBSCRIPTION, {
        onData: () => {
            itemsRefetch();
        },
    });

    const Items = itemsLoading ? [] : itemsData.items;

    const {
        data: recipesData,
        loading: recipesLoading,
        refetch: recipesRefetch,
    } = useQuery(RECIPES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(RECIPES_SUBSCRIPTION, {
        onData: () => {
            recipesRefetch();
        },
    });

    const Recipes = recipesLoading ? [] : recipesData.recipes;

    const { data: itemTypesData, loading: itemTypesLoading } = useQuery(ITEM_TYPES, {
        fetchPolicy: 'network-only',
    });

    const itemTypes = itemTypesLoading ? [] : itemTypesData.itemTypes;

    React.useEffect(() => {
        if (!traderLoading && !itemTypesLoading) {
            setTitle(Trader.title);
            setVisibility(Trader.visibility);
            console.log(Trader.items);
            setItems(
                Trader.items.map((item) => ({
                    ...item,
                    id: item.itemId,
                    type: itemTypes.find((type) => item.type === type.id),
                }))
            );
            setRecipes(
                Trader.recipes.map((recipe) => ({
                    ...recipe,
                    id: recipe.recipeId,
                }))
            );
            setExistingItem(null);
            setExistingRecipe(null);
            setPortrait(null);
        }
        // eslint-disable-next-line
    }, [Trader, itemTypes]);

    const save = async () => {
        setSaving(true);

        let body = {
            id: traderID,
            title,
            visibility,
            items: items.map((item) => ({
                itemId: item.id,
                order: item.order,
            })),
            recipes: recipes.map((recipe) => ({
                recipeId: recipe.id,
                order: recipe.order,
            })),
        };
        if (portrait) {
            body.portrait = await toBase64(portrait);
        }
        updateTrader({ variables: body })
            .then(() => {
                setSaving(false);
                setPortrait(null);
            })
            .catch((e) => {
                setSaving(false);
            });
    };

    const deleteFunc = () => {
        if (window.confirm(`Si si istý že chceš zmazať obchodníka ${title}?`)) {
            deleteTrader({ variables: { id: traderID } });
        }
    };

    const newOrder =
        [...items, ...recipes].reduce((cur, item) => (cur > item.order ? cur : item.order), 0) + 1;
    return (
        <div className="settings-right">
            <Card>
                <CardHeader className="flex-row">
                    <Input
                        id="map"
                        value={title}
                        className="invisible-input"
                        placeholder="Zadaj meno obchodníka"
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </CardHeader>
                <CardBody>
                    <FormGroup>
                        <Checkbox
                            className="form-control-checkbox"
                            label="Spoznali obchodníka"
                            value={visibility}
                            right
                            centerHor
                            onChange={() => setVisibility(!visibility)}
                        />
                    </FormGroup>

                    <ImageInput
                        setImage={setPortrait}
                        image={portrait}
                        id={`traderPortrait${traderID}`}
                        label="Portrét obchodníka"
                        placeholder="Vyberte portrét obchodníka"
                        original={Trader?.portrait}
                        height={400}
                    />

                    <h4>Služby a predávané veci</h4>
                    <Table striped>
                        <thead>
                            <tr>
                                <th width="50">Ikona</th>
                                <th>Názov</th>
                                <th>Popis</th>
                                <th>Typ</th>
                                <th>Cena</th>
                                <th>Váha</th>
                                <th>Poradie</th>
                                <th width="110">Akcie</th>
                            </tr>
                        </thead>
                        <tbody>
                            {[
                                ...items.map((item) => ({ ...item, dataType: 'item' })),
                                ...recipes.map((recipe) => ({ ...recipe, dataType: 'recipe' })),
                            ]
                                .sort((item1, item2) => item1.order - item2.order)
                                .map((item) =>
                                    item.dataType === 'item' ? (
                                        <EditItem
                                            key={`item-${item.id}`}
                                            item={item}
                                            itemTypes={itemTypes}
                                            updateItem={(newItem) => {
                                                let index = items.findIndex(
                                                    (existingItem) => existingItem.id === newItem.id
                                                );
                                                let newItems = [...items];
                                                newItems[index] = newItem;
                                                setItems(newItems);
                                            }}
                                            removeFromTrader={(id) =>
                                                setItems(items.filter((item) => item.id !== id))
                                            }
                                            deleteItem={(id) => {
                                                setItems(items.filter((item) => item.id !== id));
                                            }}
                                        />
                                    ) : (
                                        <RecipeEdit
                                            key={`recipe-${item.id}`}
                                            recipe={item}
                                            removeFromTrader={() => {
                                                setRecipes(
                                                    recipes.filter(
                                                        (recipe) => recipe.id !== item.id
                                                    )
                                                );
                                            }}
                                            updateOrder={(order) => {
                                                setRecipes(
                                                    updateArrayItem(
                                                        recipes,
                                                        {
                                                            ...item,
                                                            order,
                                                        },
                                                        'id'
                                                    )
                                                );
                                            }}
                                        />
                                    )
                                )}
                            <AddItem
                                key="add"
                                itemTypes={itemTypes}
                                onSubmit={(item) => {
                                    setItems([...items, item]);
                                }}
                            />
                            <tr key="add-existing-item">
                                <td colSpan="2">Pridať existujúcu vec</td>
                                <td colSpan="5">
                                    <Select
                                        value={existingItem}
                                        onChange={(existingItem) => {
                                            setExistingItem(existingItem);
                                        }}
                                        options={toSelArr(Items).filter(
                                            (Item) => !items.some((item) => item.id === Item.id)
                                        )}
                                    />
                                </td>
                                <td>
                                    <Button
                                        color="none"
                                        disabled={existingItem === null}
                                        onClick={() => {
                                            setItems([
                                                ...items,
                                                {
                                                    ...existingItem,
                                                    order: newOrder,
                                                },
                                            ]);
                                            setExistingItem(null);
                                        }}
                                    >
                                        <i
                                            className={classnames('fa fa-plus', {
                                                'success': existingItem !== null,
                                            })}
                                        />
                                    </Button>
                                </td>
                            </tr>
                            <tr key="add-existing-recipe">
                                <td colSpan="2">Pridať existujúci recept</td>
                                <td colSpan="5">
                                    <Select
                                        value={existingRecipe}
                                        onChange={(existingRecipe) => {
                                            setExistingRecipe(existingRecipe);
                                        }}
                                        options={toSelArr(Recipes).filter(
                                            (Recipe) =>
                                                !recipes.some((recipe) => recipe.id === Recipe.id)
                                        )}
                                    />
                                </td>
                                <td>
                                    <Button
                                        color="none"
                                        disabled={existingRecipe === null}
                                        onClick={() => {
                                            setRecipes([
                                                ...recipes,
                                                {
                                                    ...existingRecipe,
                                                    order: newOrder,
                                                },
                                            ]);
                                            setExistingRecipe(null);
                                        }}
                                    >
                                        <i
                                            className={classnames('fa fa-plus', {
                                                'success': existingRecipe !== null,
                                            })}
                                        />
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>

                    <div className="flex-row">
                        <Button
                            color="success"
                            disabled={saving || title.length < 2}
                            onClick={save}
                        >
                            {saving ? 'Ukladá sa...' : 'Uložiť obchodníka'}
                        </Button>
                        <Button color="danger" className="m-l-auto" onClick={deleteFunc}>
                            Vymazať
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
