import React from 'react';
import { Card, CardHeader, CardBody, Button, FormGroup, Label, Col, Input } from 'reactstrap';
import { Checkbox, ImageInput } from 'components';
import { toBase64 } from 'helperFunctions';
import { DELETE_CREATURE, CREATURE, CREATURES_SUBSCRIPTION, UPDATE_CREATURE } from 'queries';
import { useMutation, useQuery, useSubscription } from '@apollo/client';

export default function CreatureEdit(props) {
    const { creatureID } = props;

    const [title, setTitle] = React.useState('');
    const [visibility, setVisibility] = React.useState(false);
    const [portrait, setPortrait] = React.useState(null);
    const [order, setOrder] = React.useState(0);
    const [level, setLevel] = React.useState(0);
    const [important, setImportant] = React.useState(false);

    const [vitality, setVitality] = React.useState(0);
    const [endurance, setEndurance] = React.useState(0);
    const [strength, setStrength] = React.useState(0);
    const [speed, setSpeed] = React.useState(0);
    const [dexterity, setDexterity] = React.useState(0);
    const [defence, setDefence] = React.useState(0);
    const [energy, setEnergy] = React.useState(0);

    const [saving, setSaving] = React.useState(false);

    const [updateCreature] = useMutation(UPDATE_CREATURE);
    const [deleteCreature] = useMutation(DELETE_CREATURE);

    const {
        data: creatureData,
        loading: creatureLoading,
        refetch: creatureRefetch,
    } = useQuery(CREATURE, {
        fetchPolicy: 'network-only',
        variables: {
            id: creatureID,
        },
    });

    useSubscription(CREATURES_SUBSCRIPTION, {
        onData: () => {
            creatureRefetch({ variables: { id: creatureID } });
        },
    });

    const Creature = creatureLoading ? null : creatureData.creature;

    React.useEffect(() => {
        creatureRefetch({ id: creatureID });
        // eslint-disable-next-line
    }, [creatureID]);

    React.useEffect(() => {
        if (!creatureLoading) {
            setTitle(Creature.title);
            setVisibility(Creature.visibility);
            setOrder(Creature.order);
            setLevel(Creature.level);
            setImportant(Creature.important);
            setVitality(Creature.vitality);
            setEndurance(Creature.endurance);
            setStrength(Creature.strength);
            setSpeed(Creature.speed);
            setDexterity(Creature.dexterity);
            setDefence(Creature.defence);
            setEnergy(Creature.energy);
        }
        // eslint-disable-next-line
    }, [Creature]);

    const save = async () => {
        setSaving(true);
        //save images
        let body = {
            id: creatureID,
            title: title,
            visibility: visibility,
            order: isNaN(parseInt(order)) ? 0 : parseInt(order),
            level: isNaN(parseInt(level)) ? 0 : parseInt(level),
            important: important,
            vitality: isNaN(parseInt(vitality)) === '' ? 0 : parseInt(vitality),
            endurance: isNaN(parseInt(endurance)) === '' ? 0 : parseInt(endurance),
            strength: isNaN(parseInt(strength)) === '' ? 0 : parseInt(strength),
            speed: isNaN(parseInt(speed)) === '' ? 0 : parseInt(speed),
            dexterity: isNaN(parseInt(dexterity)) === '' ? 0 : parseInt(dexterity),
            defence: isNaN(parseInt(defence)) === '' ? 0 : parseInt(defence),
            energy: isNaN(parseInt(energy)) === '' ? 0 : parseInt(energy),
        };
        if (portrait) {
            body.portrait = await toBase64(portrait);
        }
        updateCreature({ variables: body })
            .then(() => {
                //update STATE images
                setSaving(false);
                setPortrait(null);
            })
            .catch((e) => {
                setSaving(false);
                console.log(e);
            });
    };

    const deleteFunc = async () => {
        if (window.confirm(`Si si istý že chceš zmazať tvora ${title}?`)) {
            deleteCreature({ variables: { id: creatureID } });
        }
    };

    return (
        <div className="settings-right">
            <Card>
                <CardHeader className="flex-row">
                    <Input
                        id="occupation"
                        value={title}
                        className="invisible-input"
                        placeholder="Názov tvora"
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </CardHeader>
                <CardBody>
                    <FormGroup className="flex-row">
                        <Label for="occupation" sm={2}>
                            Level
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="occupation"
                                value={level}
                                type="number"
                                placeholder="Zadaj level tvora"
                                onChange={(e) => setLevel(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Checkbox
                            className="form-control-checkbox"
                            label="Poznajú tvora?"
                            value={visibility}
                            right
                            onChange={() => setVisibility(!visibility)}
                            centerHor
                        />
                    </FormGroup>
                    <ImageInput
                        setImage={setPortrait}
                        image={portrait}
                        id={`portrait${creatureID}`}
                        label="Profil tvora"
                        creatureholder="Vyberte portrét tvora"
                        original={Creature?.portrait}
                        height={400}
                    />
                    <FormGroup className="flex-row">
                        <Label for="order" sm={2}>
                            Poradie
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="order"
                                value={order}
                                placeholder="Zadaj poradie"
                                onChange={(e) => setOrder(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Checkbox
                            className="form-control-checkbox"
                            label="Dôležitý?"
                            value={important}
                            right
                            onChange={() => setImportant(!important)}
                            centerHor
                        />
                    </FormGroup>

                    <h4>Základné atribúty</h4>

                    <FormGroup className="flex-row">
                        <Label for="vitality" sm={2}>
                            Vitalita
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="vitality"
                                value={vitality}
                                placeholder="Zadaj vitalitu"
                                onChange={(e) => setVitality(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="endurance" sm={2}>
                            Výdrž
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="endurance"
                                value={endurance}
                                placeholder="Zadaj výdrž"
                                onChange={(e) => setEndurance(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="strength" sm={2}>
                            Sila
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="strength"
                                value={strength}
                                placeholder="Zadaj silu"
                                onChange={(e) => setStrength(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="speed" sm={2}>
                            Rýchlosť
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="speed"
                                value={speed}
                                placeholder="Zadaj rýchlosť"
                                onChange={(e) => setSpeed(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="dexterity" sm={2}>
                            Obratnosť
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="dexterity"
                                value={dexterity}
                                placeholder="Zadaj obratnosť"
                                onChange={(e) => setDexterity(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="defence" sm={2}>
                            Obrana
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="defence"
                                value={defence}
                                placeholder="Zadaj obranu"
                                onChange={(e) => setDefence(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="energy" sm={2}>
                            Energia
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="energy"
                                value={energy}
                                placeholder="Zadaj energiu"
                                onChange={(e) => setEnergy(e.target.value)}
                            />
                        </Col>
                    </FormGroup>

                    <div className="flex-row">
                        <Button color="primary" disabled={saving} onClick={save}>
                            {saving ? 'Ukladá sa...' : 'Uložiť tvora'}
                        </Button>
                        <Button color="danger" className="m-l-auto" onClick={deleteFunc}>
                            Vymazať
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
