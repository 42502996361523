import React from 'react';
import { Card, CardHeader, CardBody, Button, FormGroup, Label, Col, Input } from 'reactstrap';
import Textarea from 'react-textarea-autosize';
import { TextEditor } from 'components';
import Select from 'react-select/creatable';
import { ADD_LIBRARY_ENTRY, LIBRARY_CATEGORIES } from 'queries';
import { useMutation, useQuery } from '@apollo/client';
import { toSelArr } from 'helperFunctions';

export default function LibraryAdd() {
    const [title, setTitle] = React.useState('');
    const [order, setOrder] = React.useState(0);
    const [description, setDescription] = React.useState('');
    const [category, setCategory] = React.useState(null);
    const [categoryOrder, setCategoryOrder] = React.useState(0);

    const [text, setText] = React.useState('');
    const [adding, setAdding] = React.useState(false);

    const [addLibraryEntry] = useMutation(ADD_LIBRARY_ENTRY);
    const {
        data: categoriesData,
        loading: categoriesLoading,
        refetch: categoriesRefetch,
    } = useQuery(LIBRARY_CATEGORIES, {
        fetchPolicy: 'network-only',
    });
    const Categories = categoriesLoading ? [] : categoriesData.libraryCategories;

    const add = async () => {
        setAdding(true);
        let body = {
            title,
            order: parseInt(order),
            description,
            category: {
                id: category.id,
                title: category.label,
                order: parseInt(categoryOrder),
            },
            text,
        };

        addLibraryEntry({ variables: body })
            .then(() => {
                setTitle('');
                setOrder(0);
                setDescription('');
                setText('');
                setCategory(null);
                setCategoryOrder(0);
                setAdding(false);
                categoriesRefetch();
            })
            .catch((e) => {
                console.log(e);
                setAdding(false);
            });
    };

    return (
        <div className="settings-right">
            <Card>
                <CardHeader className="flex-row">Nový záznam</CardHeader>
                <CardBody>
                    <FormGroup className="flex-row">
                        <Label for="title" sm={2}>
                            Názov záznamu
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="title"
                                value={title}
                                placeholder="Zadaj názov záznamu"
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="order" sm={2}>
                            Poradie
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="order"
                                value={order}
                                placeholder="Zadaj poradie záznamu"
                                onChange={(e) => setOrder(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label sm={2}>Kategória a poradie kategórie</Label>
                        <Col sm={5}>
                            <Select
                                value={category}
                                options={toSelArr(Categories)}
                                onChange={(category) => {
                                    setCategory(category);
                                    setCategoryOrder(
                                        category.order ? category.order : categoryOrder
                                    );
                                }}
                                placeholder="Píš pre novú kategóriu"
                            />
                        </Col>
                        <Col sm={5}>
                            <Input
                                type="number"
                                id="order"
                                value={categoryOrder}
                                placeholder="Zadaj poradie kategórie"
                                onChange={(e) => setCategoryOrder(e.target.value)}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup className="flex-row">
                        <Label for="description" sm={2}>
                            Popis
                        </Label>
                        <Col sm={10}>
                            <Textarea
                                className="form-control"
                                id="description"
                                placeholder="Zadaj popis záznamu"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup className="flex-row">
                        <Label for="text" sm={2}>
                            Text
                        </Label>
                        <Col sm={10}>
                            <TextEditor
                                id="text"
                                value={text}
                                onChange={(text) => {
                                    setText(text);
                                }}
                            />
                        </Col>
                    </FormGroup>
                    <div className="flex-row">
                        <Button
                            color="success"
                            disabled={
                                adding ||
                                title.length < 2 ||
                                category === null ||
                                isNaN(parseInt(categoryOrder))
                            }
                            onClick={add}
                        >
                            {adding ? 'Pridáva sa...' : 'Pridať záznam'}
                        </Button>
                    </div>
                </CardBody>
            </Card>
            <Card>
                <CardHeader>{`Náhľad - ${title}`}</CardHeader>
                <CardBody className="p-20">
                    <div className="text-italics">{description}</div>
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                </CardBody>
            </Card>
        </div>
    );
}
