import React from 'react';
import { Col } from 'reactstrap';
import { formattedMessage } from 'helperFunctions';
import { Empty } from 'components/Empty';
import OpponentDisplay from './opponent';
import dices from 'configs/dices';

const d4 = dices.find((dice) => dice.id === 'd4');
const d6 = dices.find((dice) => dice.id === 'd6');
const d100 = dices.find((dice) => dice.id === 'd100');

export default function ShowHistory({ Fight }) {
    const { Attacker, Defender, attacker, defender, reason } = Fight;

    const getAttributeCurrent = (attributes, attr) => {
        const attribute = attributes[attr];
        const maximum = attribute.base + attribute.extra;
        const current = maximum - attribute.lost;
        return {
            maximum: ['strength', 'speed', 'dexterity', 'defence'].includes(attr)
                ? current
                : maximum,
            current,
        };
    };

    const getBasicDamage = () => {
        return (
            getAttributeCurrent(Attacker.attributes, 'strength').current -
            getAttributeCurrent(Defender.attributes, 'defence').current
        );
    };

    const getMaxDamage = () => {
        return getBasicDamage() + attacker.damageRoll;
    };

    const getTotalDexterity = (Opponent, fightData) => {
        return (
            getAttributeCurrent(Opponent.attributes, 'dexterity').current + fightData.dexterityRoll
        );
    };

    const getDexterityDiff = () => {
        return getTotalDexterity(Attacker, attacker) - getTotalDexterity(Defender, defender);
    };

    const getRealDamage = () => {
        if (getDexterityDiff() >= 0) {
            return getMaxDamage();
        }
        return getMaxDamage() + getDexterityDiff();
    };

    const getDefenderOpponentKilled = () => {
        if (attacker.kill) {
            return true;
        }
        return (
            getAttributeCurrent(Defender.attributes, 'vitality').current -
                getRealDamage() -
                (attacker.damage === null ? 0 : attacker.damage) +
                (defender.heal === null ? 0 : defender.heal) <=
            0
        );
    };

    const getAttackerOpponentKilled = () => {
        if (defender.kill) {
            return true;
        }
        return (
            getAttributeCurrent(Attacker.attributes, 'vitality').current -
                (defender.damage === null ? 0 : defender.damage) +
                (attacker.heal === null ? 0 : attacker.heal) <=
            0
        );
    };

    const StrengthTest = () => (
        <Empty>
            <div className="combat-message">
                {formattedMessage([
                    {
                        style: { color: Attacker.color },
                        className: 'text-bolder',
                        text: Attacker.nickname,
                    },
                    ' má silu ',
                    {
                        className: 'text-bolder',
                        text: getAttributeCurrent(Attacker.attributes, 'strength').current,
                    },
                    ', obránca má obranu ',
                    {
                        className: 'text-bolder',
                        text: getAttributeCurrent(Defender.attributes, 'defence').current,
                    },
                    '. Potenciálne zranenie obráncu je ',
                    {
                        className: 'text-bolder',
                        text: getBasicDamage(),
                    },
                    '. Hod extra sily!',
                ])}
            </div>
        </Empty>
    );

    const StrengthConsequences = () => (
        <Empty>
            <div className="combat-roll m-t-5 text-center" style={{ color: d4.color }}>
                {formattedMessage([
                    'Útočník hodil extra ',
                    {
                        className: 'text-bolder',
                        text: attacker.damageRoll,
                    },
                    ' sily!',
                ])}
            </div>
            <div className="combat-message m-t-5">
                {formattedMessage([
                    'Maximálne zranenie počas tohto útoku teda je ',
                    {
                        className: 'text-bolder',
                        text: getMaxDamage(),
                    },
                    getMaxDamage() > 0 ? '. Čas na test obratnosti!' : '. Útok zlyhal!',
                ])}
            </div>
        </Empty>
    );

    const DexterityTest = () => (
        <Empty>
            <div className="flex-row">
                <Col>
                    <div
                        className="combat-roll m-t-5 text-center"
                        style={{ backgroundColor: d6.color }}
                    >
                        {formattedMessage([
                            `Útočník hodil extra `,
                            {
                                className: 'text-bolder',
                                text: attacker.dexterityRoll,
                            },
                            ' obratnosti!',
                        ])}
                    </div>
                </Col>
                <Col>
                    <div
                        className="combat-roll m-t-5 text-center"
                        style={{ backgroundColor: d6.color }}
                    >
                        {formattedMessage([
                            `Obránca hodil extra `,
                            {
                                className: 'text-bolder',
                                text: defender.dexterityRoll,
                            },
                            ' obratnosti!',
                        ])}
                    </div>
                </Col>
            </div>
            <div className="combat-message m-t-5">
                {formattedMessage([
                    'Utočníková celková obratnosť je teda ',
                    {
                        className: 'text-bolder',
                        text: getTotalDexterity(Attacker, attacker),
                    },
                    ' a obráncova ',
                    {
                        className: 'text-bolder',
                        text: getTotalDexterity(Defender, defender),
                    },
                    '. ',
                    {
                        className: 'text-bolder',
                        text: `${getTotalDexterity(Attacker, attacker)} - ${getTotalDexterity(
                            Defender,
                            defender
                        )} = ${getDexterityDiff()}. `,
                    },
                    ...(getDexterityDiff() >= 0
                        ? ['Obránca nevyblokoval vôbec útok!']
                        : [
                              'Obránca vyblokoval ',
                              {
                                  className: 'text-bolder',
                                  text: getDexterityDiff() * -1,
                              },
                              ' zranenia!',
                          ]),
                ])}
            </div>
        </Empty>
    );

    const DexterityConsequences = () => (
        <Empty>
            {getRealDamage() <= 0 ? (
                <Empty>
                    <div className="combat-message m-t-5">
                        {formattedMessage([
                            'Po teste obratnosti reálne zranenie obráncu je ',
                            {
                                className: 'text-bolder',
                                text: getRealDamage(),
                            },
                            '. Útok zlyhal!',
                        ])}
                    </div>
                </Empty>
            ) : (
                <div className="combat-message m-t-5">
                    {formattedMessage([
                        'Po teste obratnosti reálne zranenie obráncu je ',
                        {
                            className: 'text-bolder',
                            text: getRealDamage(),
                        },
                        '.',
                    ])}
                </div>
            )}
        </Empty>
    );

    const SpecialAttack = () => (
        <Empty>
            <div className="flex-row m-t-10">
                <Col>
                    {attacker.specialAttack && (
                        <div
                            className="combat-roll flex text-center"
                            style={{ backgroundColor: d100.color }}
                        >
                            {formattedMessage([
                                'Útočník hodil ',
                                {
                                    className: 'text-bolder',
                                    text: attacker.specialAttackRoll,
                                },
                                ' zo 100 na špeciálnom útoku! Efekt?',
                            ])}
                        </div>
                    )}
                    {attacker.specialMagic && (
                        <div
                            className="combat-roll flex text-center"
                            style={{ backgroundColor: d4.color }}
                        >
                            {formattedMessage([
                                'Útočník hodil ',
                                {
                                    className: 'text-bolder',
                                    text: attacker.specialMagicRoll,
                                },
                                ' zo 4! Efekt?',
                            ])}
                        </div>
                    )}
                </Col>
                <Col>
                    {defender.specialAttack && (
                        <div
                            className="combat-roll flex text-center"
                            style={{ backgroundColor: d100.color }}
                        >
                            {formattedMessage([
                                'Útočník hodil ',
                                {
                                    className: 'text-bolder',
                                    text: defender.specialAttackRoll,
                                },
                                ' zo 100 na špeciálnom útoku! Efekt?',
                            ])}
                        </div>
                    )}
                    {defender.specialMagic && (
                        <div
                            className="combat-roll flex text-center"
                            style={{ backgroundColor: d4.color }}
                        >
                            {formattedMessage([
                                'Útočník hodil ',
                                {
                                    className: 'text-bolder',
                                    text: defender.specialMagicRoll,
                                },
                                ' zo 4! Efekt?',
                            ])}
                        </div>
                    )}
                </Col>
            </div>
        </Empty>
    );

    //zabit nepriatela ak jeho celkove zdravie je 0 alebo menej, zadat dovod umrtia
    //inak aplikovat damage
    const ResolveFight = () => (
        <Empty>
            {(attacker.specialAttack || attacker.specialMagic) && (
                <div
                    className="combat-roll flex text-center m-t-5"
                    style={{ backgroundColor: attacker.specialAttack ? d100.color : d4.color }}
                >
                    {`Útočníkov ${
                        attacker.specialAttack ? 'špeciálny' : 'magický'
                    } útok spôsobil: `}
                    {attacker.damage !== 0 && (
                        <span className="m-r-5">Zranenie: {attacker.damage}!</span>
                    )}
                    {attacker.stun && <span className="m-r-5">Paralizoval nepriateľa!</span>}
                    {attacker.heal !== 0 && (
                        <span className="m-r-5">Vyliečil sa za: {attacker.heal}!</span>
                    )}
                    {attacker.kill && <span className="m-r-5">Zabil nepriateľa!</span>}
                </div>
            )}
            {(defender.specialAttack || defender.specialMagic) && (
                <div
                    className="combat-roll flex text-center m-t-5"
                    style={{ backgroundColor: defender.specialAttack ? d100.color : d4.color }}
                >
                    {`Obráncov ${defender.specialAttack ? 'špeciálny' : 'magický'} útok spôsobil: `}
                    {defender.damage !== 0 && (
                        <span className="m-r-5">Zranenie: {defender.damage}!</span>
                    )}
                    {defender.stun && <span className="m-r-5">Paralizoval nepriateľa!</span>}
                    {defender.heal !== 0 && (
                        <span className="m-r-5">Vyliečil sa za: {defender.heal}!</span>
                    )}
                    {defender.kill && <span className="m-r-5">Zabil nepriateľa!</span>}
                </div>
            )}
            {(getDefenderOpponentKilled() || getAttackerOpponentKilled()) && (
                <Empty>
                    {getDefenderOpponentKilled() && (
                        <div className="combat-message text-center">
                            {formattedMessage([
                                {
                                    style: { color: Attacker.color },
                                    className: 'text-bolder',
                                    text: Attacker.nickname,
                                },
                                ' zabila obráncu ',
                                {
                                    style: { color: Defender.color },
                                    className: 'text-bolder',
                                    text: Defender.nickname,
                                },
                                '!',
                            ])}
                        </div>
                    )}
                    {getAttackerOpponentKilled() && (
                        <div className="combat-message">
                            {formattedMessage([
                                {
                                    style: { color: Defender.color },
                                    className: 'text-bolder',
                                    text: Defender.nickname,
                                },
                                ' zabil útočníka ',
                                {
                                    style: { color: Attacker.color },
                                    className: 'text-bolder',
                                    text: Attacker.nickname,
                                },
                                '!',
                            ])}
                        </div>
                    )}
                    <div className="combat-message">
                        {formattedMessage([
                            'Spôsob smrti: ',
                            {
                                style: { color: 'red' },
                                className: 'text-bolder',
                                text: reason,
                            },
                        ])}
                    </div>
                </Empty>
            )}
        </Empty>
    );

    return (
        <div>
            <div className="flex-row">
                <Col className="m-r-5">
                    <OpponentDisplay type="Útočník" Opponent={Attacker} />
                </Col>
                <Col className="m-l-5">
                    <OpponentDisplay type="Obránca" Opponent={Defender} />
                </Col>
            </div>
            {StrengthTest()}
            {attacker.damageRoll !== null && (
                <Empty>
                    {StrengthConsequences()}
                    {getMaxDamage() > 0 && (
                        <Empty>
                            {DexterityTest()}
                            {attacker.dexterityRoll !== null && defender.dexterityRoll !== null && (
                                <Empty>
                                    {DexterityConsequences()}
                                    {getRealDamage() > 0 && (
                                        <Empty>
                                            {SpecialAttack()}
                                            {attacker.specialAttack !== null &&
                                                defender.specialAttack !== null &&
                                                ((!attacker.specialAttack &&
                                                    !attacker.specialMagic) ||
                                                    attacker.damage !== null) &&
                                                ((!defender.specialAttack &&
                                                    !defender.specialMagic) ||
                                                    defender.damage !== null) &&
                                                ResolveFight()}
                                            {/*Resolve special or no special end round or kill*/}
                                        </Empty>
                                    )}
                                </Empty>
                            )}
                        </Empty>
                    )}
                </Empty>
            )}
            <hr />
        </div>
    );
}
