import { gql } from '@apollo/client';

export const DICE_POKER = gql`
    query dicePoker {
        dicePoker {
            id
            createdAt
            updatedAt
            round
            part
            player1
            player2
        }
    }
`;

export const POKER_START_GAME = gql`
    mutation pokerStartGame($player1: String, $player2: String) {
        pokerStartGame(player1: $player1, player2: $player2)
    }
`;

export const POKER_ROLL_DICE = gql`
    mutation pokerRollDice($player: Int, $index: Int) {
        pokerRollDice(player: $player, index: $index)
    }
`;

export const POKER_REPLACE_DICES = gql`
    mutation pokerReplaceDices($player: Int, $indexes: [Int]) {
        pokerReplaceDices(player: $player, indexes: $indexes)
    }
`;

export const POKER_REROLL_DICE = gql`
    mutation pokerRerollDice($player: Int, $index: Int) {
        pokerRerollDice(player: $player, index: $index)
    }
`;

export const POKER_SHOW_DICES = gql`
    mutation mutation {
        pokerShowDices
    }
`;

export const POKER_CANCEL_GAME = gql`
    mutation mutation {
        pokerCancelGame
    }
`;

export const POKER_END_ROUND = gql`
    mutation pokerEndRound($winner: Int) {
        pokerEndRound(winner: $winner)
    }
`;

export const DICE_POKER_SUBSCRIPTION = gql`
    subscription dicePokerSubscription {
        dicePokerSubscription
    }
`;
