import {
    formattedMessage,
    timestampToString,
    getRemainingColor,
    GetMyRights,
} from 'helperFunctions';
import React from 'react';
import { Empty, Tooltip } from 'components';
import { Card, CardHeader, CardBody, Col, Button } from 'reactstrap';
import { DELETE_DICE_POKER_HISTORY, DICE_POKER_HISTORY } from 'queries';
import { useQuery, useMutation } from '@apollo/client';
import { GET_LIBRARY_OPEN } from 'apollo/queries';
import { setShowLibraryList } from 'apollo/localSchema/actions';
import classnames from 'classnames';

export default function DicePokerEntry({ id }) {
    const { data: libraryOpenData } = useQuery(GET_LIBRARY_OPEN);
    const ShowLibraryList = libraryOpenData.ShowLibraryList;

    const { AdminOrDm } = GetMyRights();
    const [deleteDicePokerHistory] = useMutation(DELETE_DICE_POKER_HISTORY);
    const { data: dicePokerHistoryData, loading: dicePokerHistoryLoading } = useQuery(
        DICE_POKER_HISTORY,
        {
            fetchPolicy: 'network-only',
            variables: {
                id,
            },
        }
    );

    if (dicePokerHistoryLoading) {
        return (
            <Card className="library-right">
                <CardHeader className="thin-buttons flex-row text-hor-middle">
                    Načítava sa...
                </CardHeader>
                <CardBody className="p-20 width-700">Načítava sa...</CardBody>
            </Card>
        );
    }

    const processDicePoker = (dicePokerHistory) => {
        return {
            ...dicePokerHistory,
            player1: JSON.parse(dicePokerHistory.player1),
            player2: JSON.parse(dicePokerHistory.player2),
            history: JSON.parse(dicePokerHistory.history),
        };
    };

    const Entry = processDicePoker(dicePokerHistoryData.dicePokerHistory);

    const Winner = Entry.player1.wins > Entry.player2.wins ? Entry.player1 : Entry.player2;

    const getTotals = (Round, final = false) => {
        let p1Occurances = {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
        };
        let p2Occurances = {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
        };
        const Player1 = Round.Game.player1;
        const Player2 = Round.Game.player2;
        Player1.rolls.forEach((dice, index) => {
            let value = dice;
            if (final) {
                const replacement = Player1.replacedDices.find(
                    (replacement) => replacement.index === index
                );
                value = replacement === undefined ? dice : replacement.newRoll;
            }
            p1Occurances[value] += 1;
        });
        Player2.rolls.forEach((dice, index) => {
            let value = dice;
            if (final) {
                const replacement = Player2.replacedDices.find(
                    (replacement) => replacement.index === index
                );
                value = replacement === undefined ? dice : replacement.newRoll;
            }
            p2Occurances[value] += 1;
        });
        let p1Total = 0;
        let p1Calculation = [
            'Hráč ',
            {
                text: Player1.nickname,
                style: { color: Player1.color, text: Player1.nickname },
                className: 'text-bolder',
            },
            ' hodil ',
        ];
        if (p1Occurances[1] > 1) {
            p1Total += p1Occurances[1];
            p1Calculation.push(`${p1Occurances[1]}x`);
            p1Calculation.push({ className: 'text-bolder', text: '1 ' });
        }
        if (p1Occurances[2] > 1) {
            p1Total += p1Occurances[2] * 2;
            p1Calculation.push(`${p1Occurances[2]}x`);
            p1Calculation.push({ className: 'text-bolder', text: '2 ' });
        }
        if (p1Occurances[3] > 1) {
            p1Total += p1Occurances[3] * 3;
            p1Calculation.push(`${p1Occurances[3]}x`);
            p1Calculation.push({ className: 'text-bolder', text: '3 ' });
        }
        if (p1Occurances[4] > 1) {
            p1Total += p1Occurances[4] * 4;
            p1Calculation.push(`${p1Occurances[4]}x`);
            p1Calculation.push({ className: 'text-bolder', text: '4 ' });
        }
        if (p1Occurances[5] > 1) {
            p1Total += p1Occurances[5] * 5;
            p1Calculation.push(`${p1Occurances[5]}x`);
            p1Calculation.push({ className: 'text-bolder', text: '5 ' });
        }
        if (p1Occurances[6] > 1) {
            p1Total += p1Occurances[6] * 6;
            p1Calculation.push(`${p1Occurances[6]}x`);
            p1Calculation.push({ className: 'text-bolder', text: '6 ' });
        }
        if (p1Calculation.length === 3) {
            p1Calculation.push('postupku!');
            p1Total = 999;
            p1Occurances = {
                1: 10,
                2: 10,
                3: 10,
                4: 10,
                5: 10,
                6: 10,
            };
        } else {
            p1Calculation.push('= ');
            p1Calculation.push({ text: `${p1Total}`, className: 'text-bolder color-green' });
        }

        let p2Total = 0;
        let p2Calculation = [
            'Hráč ',
            {
                text: Player2.nickname,
                style: { color: Player2.color, text: Player2.nickname },
                className: 'text-bolder',
            },
            ' hodil ',
        ];
        if (p2Occurances[1] > 1) {
            p2Total += p2Occurances[1];
            p2Calculation.push(`${p2Occurances[1]}x`);
            p2Calculation.push({ className: 'text-bolder', text: '1 ' });
        }
        if (p2Occurances[2] > 1) {
            p2Total += p2Occurances[2] * 2;
            p2Calculation.push(`${p2Occurances[2]}x`);
            p2Calculation.push({ className: 'text-bolder', text: '2 ' });
        }
        if (p2Occurances[3] > 1) {
            p2Total += p2Occurances[3] * 3;
            p2Calculation.push(`${p2Occurances[3]}x`);
            p2Calculation.push({ className: 'text-bolder', text: '3 ' });
        }
        if (p2Occurances[4] > 1) {
            p2Total += p2Occurances[4] * 4;
            p2Calculation.push(`${p2Occurances[4]}x`);
            p2Calculation.push({ className: 'text-bolder', text: '4 ' });
        }
        if (p2Occurances[5] > 1) {
            p2Total += p2Occurances[5] * 5;
            p2Calculation.push(`${p2Occurances[5]}x`);
            p2Calculation.push({ className: 'text-bolder', text: '5 ' });
        }
        if (p2Occurances[6] > 1) {
            p2Total += p2Occurances[6] * 6;
            p2Calculation.push(`${p2Occurances[6]}x`);
            p2Calculation.push({ className: 'text-bolder', text: '6 ' });
        }
        if (p2Calculation.length === 3) {
            p2Calculation.push('postupku!');
            p2Total = 999;
            p2Occurances = {
                1: 10,
                2: 10,
                3: 10,
                4: 10,
                5: 10,
                6: 10,
            };
        } else {
            p2Calculation.push('= ');
            p2Calculation.push({ text: `${p2Total}`, className: 'text-bolder color-green' });
        }

        const player1SortedFinalDices = Player1.rolls
            .map((dice, index) => {
                const replacement = Player1.replacedDices.find(
                    (replacement) => replacement.index === index
                );
                if (replacement !== undefined) {
                    return replacement.newRoll;
                }
                return dice;
            })
            .sort((dice1, dice2) => {
                if (p1Occurances[dice1] > 1 && p1Occurances[dice2] > 1) {
                    return dice1 - dice2;
                }
                if (p1Occurances[dice1] > 1) {
                    return 1;
                }
                if (p1Occurances[dice2] > 1) {
                    return -1;
                }
                return dice1 - dice2;
            });

        const player2SortedFinalDices = Player2.rolls
            .map((dice, index) => {
                const replacement = Player2.replacedDices.find(
                    (replacement) => replacement.index === index
                );
                if (replacement !== undefined) {
                    return replacement.newRoll;
                }
                return dice;
            })
            .sort((dice1, dice2) => {
                if (p2Occurances[dice1] > 1 && p2Occurances[dice2] > 1) {
                    return dice1 - dice2;
                }
                if (p2Occurances[dice1] > 1) {
                    return 1;
                }
                if (p2Occurances[dice2] > 1) {
                    return -1;
                }
                return dice1 - dice2;
            });

        return {
            p1Total,
            p2Total,
            p1Calculation,
            p2Calculation,
            p1Occurances,
            p2Occurances,
            player1SortedFinalDices,
            player2SortedFinalDices,
        };
    };

    const renderTotal = (Round) => {
        const {
            p1Total,
            p2Total,
            p1Calculation,
            p2Calculation,
            p1Occurances,
            p2Occurances,
            player1SortedFinalDices,
            player2SortedFinalDices,
        } = getTotals(Round, true);

        const Winner =
            p1Total > p2Total ? Round.Game.player1 : p1Total < p2Total ? Round.Game.player2 : null;

        return (
            <Empty>
                <div className="combat-roll text-center m-t-5">Výsledok kola...</div>
                <div className="flex-row">
                    <Col>
                        <div className="flex-row">
                            {player1SortedFinalDices.map((roll, index) => (
                                <div
                                    key={index}
                                    className="dice-poker-result"
                                    style={{
                                        color:
                                            p1Occurances[roll] === 1
                                                ? 'grey'
                                                : getRemainingColor(roll, 6, true, 3),
                                        borderColor:
                                            p1Occurances[roll] === 1
                                                ? 'grey'
                                                : getRemainingColor(roll, 6, true, 3),
                                    }}
                                >
                                    {roll}
                                </div>
                            ))}
                        </div>
                        <div className="combat-roll text-center m-t-5">
                            {formattedMessage(p1Calculation)}
                        </div>
                    </Col>
                    <Col>
                        <div className="flex-row">
                            {player2SortedFinalDices.map((roll, index) => (
                                <div
                                    key={index}
                                    className="dice-poker-result"
                                    style={{
                                        color:
                                            p2Occurances[roll] === 1
                                                ? 'grey'
                                                : getRemainingColor(roll, 6, true, 3),
                                        borderColor:
                                            p2Occurances[roll] === 1
                                                ? 'grey'
                                                : getRemainingColor(roll, 6, true, 3),
                                    }}
                                >
                                    {roll}
                                </div>
                            ))}
                        </div>
                        <div className="combat-roll text-center m-t-5">
                            {formattedMessage(p2Calculation)}
                        </div>
                    </Col>
                </div>
                <div className="combat-roll text-center m-t-5">
                    {formattedMessage([
                        'Výhercom kola je... ',
                        {
                            text:
                                Winner === null
                                    ? 'Nikto! Čas na nové kolo!'
                                    : `${Winner.nickname}!`,
                            className: 'text-bolder',
                            style: { color: Winner === null ? 'red' : Winner.color },
                        },
                    ])}
                </div>
                {Winner?.wins === 1 && (
                    <div className="combat-roll text-center m-t-5">
                        {formattedMessage([
                            {
                                text: Winner.nickname,
                                className: 'text-bolder font-18 m-r-10',
                                style: { color: Winner.color },
                            },
                            'je výhercom celej hry!',
                        ])}
                    </div>
                )}
            </Empty>
        );
    };

    return (
        <Card className="library-right">
            <CardHeader className="thin-buttons flex-row text-hor-middle">
                <Button color="link" onClick={setShowLibraryList} id="show-hide-library-list">
                    <i
                        className={classnames({
                            'fa fa-arrow-left color-blue': ShowLibraryList,
                            'fa fa-arrow-right color-blue': !ShowLibraryList,
                        })}
                    />
                    <i className="m-l-2 fa fa-book-atlas color-blue" />
                </Button>
                <Tooltip
                    placement="top"
                    target="show-hide-library-list"
                    text={ShowLibraryList ? 'Schovať knižnicu' : 'Zobraziť knižnicu'}
                />
                {`[${timestampToString(Entry.createdAt)}] ${Entry.player1.nickname} vs. ${
                    Entry.player2.nickname
                } (História kockového pokru)`}
                {AdminOrDm && (
                    <Button
                        color="danger"
                        className="m-l-auto"
                        onClick={() => {
                            if (window.confirm('Si si istý že chceš zmazať tento záznam pokru?')) {
                                deleteDicePokerHistory({ variables: { id } });
                            }
                        }}
                    >
                        Zmazať záznam
                    </Button>
                )}
            </CardHeader>
            <CardBody className="p-20 width-700">
                <div className="text-center color-green font-18">Počet kôl: {Entry.rounds}</div>
                <div className="text-center font-22">
                    <span style={{ color: Entry.player1.color }} className="m-r-10">
                        {Entry.player1.nickname}
                    </span>
                    <span className="text-bolder" style={{ color: Entry.player1.color }}>
                        {Entry.player1.wins}
                    </span>
                    <span className="text-bolder m-r-5 m-l-5">:</span>
                    <span className="text-bolder" style={{ color: Entry.player2.color }}>
                        {Entry.player2.wins}
                    </span>
                    <span style={{ color: Entry.player2.color }} className="m-l-10">
                        {Entry.player2.nickname}
                    </span>
                </div>
                <div className="text-center font-22">
                    {formattedMessage([
                        'Hru vyhral hráč ',
                        {
                            text: Winner.nickname,
                            className: 'text-bolder',
                            style: { color: Winner.color },
                        },
                    ])}
                </div>
                <div>
                    {Entry.history.map((Round, index) => (
                        <div className="m-b-20" key={index}>
                            <h5>
                                Kolo: {index + 1} -{' '}
                                {Round.result.value === 0
                                    ? 'Remíza'
                                    : `Vyhral ${Round.result.title}`}
                            </h5>
                            <hr />
                            <div className="flex-row">
                                <Col className="m-r-10">
                                    <div className="combat-roll text-center m-t-5">
                                        {formattedMessage(getTotals(Round).p1Calculation)}
                                    </div>
                                    <div className="flex-row">
                                        {Round.Game.player1.rolls.map((roll, index) => (
                                            <div
                                                key={index}
                                                className="dice-poker-result"
                                                style={{
                                                    color: Round.Game.player1.replacedDices.some(
                                                        (replacement) => replacement.index === index
                                                    )
                                                        ? 'grey'
                                                        : getRemainingColor(roll, 6, true, 3),
                                                    borderColor:
                                                        Round.Game.player1.replacedDices.some(
                                                            (replacement) =>
                                                                replacement.index === index
                                                        )
                                                            ? 'grey'
                                                            : getRemainingColor(roll, 6, true, 3),
                                                }}
                                            >
                                                {roll}
                                            </div>
                                        ))}
                                    </div>
                                </Col>
                                <Col className="m-r-10">
                                    <div className="combat-roll text-center m-t-5">
                                        {formattedMessage(getTotals(Round).p2Calculation)}
                                    </div>
                                    <div className="flex-row">
                                        {Round.Game.player2.rolls.map((roll, index) => (
                                            <div
                                                key={index}
                                                className="dice-poker-result"
                                                style={{
                                                    color: Round.Game.player2.replacedDices.some(
                                                        (replacement) => replacement.index === index
                                                    )
                                                        ? 'grey'
                                                        : getRemainingColor(roll, 6, true, 3),
                                                    borderColor:
                                                        Round.Game.player2.replacedDices.some(
                                                            (replacement) =>
                                                                replacement.index === index
                                                        )
                                                            ? 'grey'
                                                            : getRemainingColor(roll, 6, true, 3),
                                                }}
                                            >
                                                {roll}
                                            </div>
                                        ))}
                                    </div>
                                </Col>
                            </div>
                            {renderTotal(Round)}
                        </div>
                    ))}
                </div>
            </CardBody>
        </Card>
    );
}
