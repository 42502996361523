export const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

const getImage = (path) =>
    new Promise((resolve) => {
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.onload = () => resolve(img);
        img.src = path;
    });

export const drawOnImage = async (image, instructions) => {
    var Canvas = document.createElement('canvas');
    const ctx = Canvas.getContext('2d');
    const Image = await getImage(image);
    Canvas.width = Image.width;
    Canvas.height = Image.height;
    ctx.drawImage(Image, 0, 0);

    ctx.lineCap = 'round';
    instructions.forEach((instruction) => {
        ctx.strokeStyle = instruction.color;
        ctx.lineWidth = instruction.size;
        ctx.beginPath();
        instruction.lines.forEach((line, index) => {
            if (index === 0) {
                ctx.moveTo(line.x, line.y);
            } else {
                ctx.lineTo(line.x, line.y);
            }
        });
        ctx.stroke();
    });
    return Canvas.toDataURL();
};
