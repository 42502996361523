import React from 'react';
import { Card, CardHeader, CardBody, Button, FormGroup, Label, Col, Input } from 'reactstrap';
import { ImageInput } from 'components';
import { toBase64 } from 'helperFunctions';
import { ADD_BATTLEFIELD } from 'queries';
import { useMutation } from '@apollo/client';

export default function BattlefieldAdd() {
    const [title, setTitle] = React.useState('');
    const [battlefield, setBattlefield] = React.useState(null);
    const [adding, setAdding] = React.useState(false);

    const [addBattlefield] = useMutation(ADD_BATTLEFIELD);

    const add = async () => {
        setAdding(true);
        let body = {
            title,
        };

        if (battlefield) {
            body.battlefield = await toBase64(battlefield);
        }

        addBattlefield({ variables: body })
            .then(() => {
                setTitle('');
                setAdding(false);
            })
            .catch((e) => {
                setAdding(false);
                console.log(e);
            });
    };

    return (
        <div className="settings-right">
            <Card>
                <CardHeader className="flex-row">Nové bojovisko</CardHeader>
                <CardBody>
                    <FormGroup className="flex-row">
                        <Label for="occupation" sm={2}>
                            Názov
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="occupation"
                                value={title}
                                battlefieldholder="Zadaj názov bojoviska"
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <ImageInput
                        label="Miesto"
                        id="battlefield-new"
                        setImage={setBattlefield}
                        image={battlefield}
                        battlefieldholder="Vyberte bojovisko"
                        width={1000}
                    />
                    <div className="flex-row">
                        <Button
                            color="success"
                            disabled={adding || battlefield === null}
                            onClick={add}
                        >
                            {adding ? 'Pridáva sa...' : 'Pridať bojovisko'}
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
