import React from 'react';
import { GetMyRights, GetSettings } from 'helperFunctions';
import { Button } from 'reactstrap';
import { dayNames, hourColor } from 'configs/calendarData';
import { Tooltip } from 'components';
import classnames from 'classnames';
import { ADD_HOUR, REMOVE_HOUR } from 'queries';
import { useMutation } from '@apollo/client';

export default function Calendar({ className }) {
    const [addHour] = useMutation(ADD_HOUR);
    const [removeHour] = useMutation(REMOVE_HOUR);
    const { calendar, gamePaused } = GetSettings();
    const { Dm } = GetMyRights();

    if (!calendar) {
        return <div className={`${className ? className : ''}`}>Načítava sa kalendár...</div>;
    }
    return (
        <div
            className={`${className ? className : ''} calendar flex-row center-hor`}
            style={{ borderColor: hourColor[calendar.hour] }}
        >
            <div className="center-hor text-bolder p-l-15 clickable" id="the-week">
                {dayNames[calendar.day % 37]}
            </div>
            <Tooltip
                placement="left"
                target="the-week"
                text={
                    <div className="text-left">
                        {[...Array(4).keys()].map((_i, index) => (
                            <div key={index}>{dayNames[(calendar.day + 36 + 5 - index) % 37]}</div>
                        ))}
                        <div className="color-green text-bolder">{dayNames[calendar.day % 37]}</div>
                        {[...Array(7).keys()].map((_i, index) => (
                            <div key={index}>{dayNames[(calendar.day + 37 - 1 - index) % 37]}</div>
                        ))}
                    </div>
                }
            />
            {Dm && !gamePaused && (
                <Button color="link">
                    <i className="fa fa-minus" onClick={removeHour} />
                </Button>
            )}
            <div
                className={classnames(
                    { 'm-r-10 m-l-10': !Dm || gamePaused },
                    'text-hor-middle center-hor noselect clickable'
                )}
                id="the-calendar"
            >
                {calendar.hour > 9 ? calendar.hour : `0${calendar.hour}`}:00
            </div>
            <Tooltip
                placement="left"
                target="the-calendar"
                text={
                    <div className="text-left">
                        <div>Deň: {calendar.day + 1}</div>
                        <div>Týždeň: {Math.floor(calendar.day / 37) + 1}</div>
                        <div>Mesiac: {calendar.month + 1}</div>
                        <div>Rok: {calendar.year}</div>
                        <div>Vek: {calendar.age}</div>
                    </div>
                }
            />
            {Dm && !gamePaused && (
                <Button color="link">
                    <i className="fa fa-plus" onClick={addHour} />
                </Button>
            )}
        </div>
    );
}
