import React from 'react';
import { Image, Tooltip } from 'components';
import { Button, Card, CardHeader, CardBody } from 'reactstrap';
import { stringToDiv } from 'helperFunctions';
import classnames from 'classnames';
import { SPELL, SPELLS_SUBSCRIPTION } from 'queries';
import { useQuery, useSubscription } from '@apollo/client';
import { GET_LIBRARY_OPEN } from 'apollo/queries';
import { setShowLibraryList } from 'apollo/localSchema/actions';

export default function SpellEntry({ id }) {
    const { data: libraryOpenData } = useQuery(GET_LIBRARY_OPEN);
    const ShowLibraryList = libraryOpenData.ShowLibraryList;
    const {
        data: spellData,
        loading: spellLoading,
        refetch: spellRefetch,
    } = useQuery(SPELL, {
        fetchPolicy: 'network-only',
        variables: {
            id,
        },
    });

    useSubscription(SPELLS_SUBSCRIPTION, {
        onData: () => {
            spellRefetch({ variables: { id } });
        },
    });

    const Spell = spellLoading ? null : spellData.spell;
    if (!Spell) {
        return <div>Načítava sa...</div>;
    }

    return (
        <Card className="library-right">
            <CardHeader>
                <Button color="link" onClick={setShowLibraryList} id="show-hide-library-list">
                    <i
                        className={classnames({
                            'fa fa-arrow-left color-blue': ShowLibraryList,
                            'fa fa-arrow-right color-blue': !ShowLibraryList,
                        })}
                    />
                    <i className="m-l-2 fa fa-book-atlas color-blue" />
                </Button>
                <Tooltip
                    placement="top"
                    target="show-hide-library-list"
                    text={ShowLibraryList ? 'Schovať knižnicu' : 'Zobraziť knižnicu'}
                />
                {`${Spell.title} (Kúzla)`}
            </CardHeader>
            <CardBody className="p-20">
                <h4 className="flex-row">{Spell.title}</h4>
                <div className="flex-row m-t-20">
                    <Image width={250} height={400} path={Spell.icon} hideEmpty showFull />
                    <div className="ancient-font font-25 m-l-20">
                        <div>Level: {Spell.level}</div>
                        {stringToDiv(Spell.description)}
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}
