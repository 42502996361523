import React from 'react';
import { Image } from 'components';
import CombatSimulator from 'components/combatSimulator';
import DicePoker from 'components/dicePoker';
import Diceroller from 'components/diceroller';
import { Trader } from 'components';
import BestiaryCreature from 'components/bestiaryCreature';
import { GetSettings } from 'helperFunctions';
import Battlefield from './battlefield';

export default function GameArea() {
    const { gameArea } = GetSettings();
    const getGameAreaContent = () => {
        switch (gameArea.type) {
            case 'customImage': {
                return <Image showFull className="game-content" direct url={gameArea.url} />;
            }
            case 'dicePoker': {
                return (
                    <div className="game-content">
                        <DicePoker />
                    </div>
                );
            }
            case 'combatSimulator': {
                return (
                    <div className="game-content">
                        <CombatSimulator />
                    </div>
                );
            }
            case 'dicerolls': {
                return (
                    <div className="game-content">
                        <Diceroller />
                    </div>
                );
            }
            case 'trader': {
                return (
                    <div className="game-content max-height-combat-sim overflow-x">
                        <Trader id={gameArea.id} />
                    </div>
                );
            }
            case 'creature': {
                return (
                    <div className="game-content">
                        <BestiaryCreature id={gameArea.id} />
                    </div>
                );
            }
            case 'place': {
                return <Image drawing showFull className="game-content" path={gameArea.url} />;
            }
            case 'map': {
                return <Image drawing showFull className="game-content" path={gameArea.url} />;
            }
            case 'battlefield': {
                return (
                    <div className="game-content">
                        <Battlefield id={gameArea.id} />
                    </div>
                );
            }
            default:
                return (
                    <div className="game-area">
                        <div className="game-content color-white">Prázdne</div>
                    </div>
                );
        }
    };

    return <div className="game-area min-width-400">{getGameAreaContent()}</div>;
}
