import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { useNavigate } from 'react-router';
import { sortBy, timestampToPracticalDate } from 'helperFunctions';

export default function CombatHistoryList({ fixedShow, setFixedShow, type, id, CombatHistories }) {
    const navigate = useNavigate();

    return (
        <ListGroup className="library-list-categories">
            <ListGroupItem
                active={fixedShow.includes('combat')}
                action
                className="clickable m-t-10"
                onClick={() => {
                    if (fixedShow.includes('combat')) {
                        setFixedShow(fixedShow.filter((title) => title !== 'combat'));
                    } else {
                        setFixedShow([...fixedShow, 'combat']);
                    }
                }}
            >
                História boja
            </ListGroupItem>
            {fixedShow.includes('combat') && (
                <ListGroup className="m-l-20 library-list-entries">
                    {sortBy(CombatHistories, [{ key: 'createdAt', asc: false }]).map((Entry) => (
                        <ListGroupItem
                            key={Entry.id}
                            active={type === 'combat' && id === Entry.id}
                            action
                            className="clickable"
                            onClick={() => {
                                navigate(`/library/combat/${Entry.id}`);
                            }}
                        >
                            {`[${timestampToPracticalDate(Entry.createdAt)}] ${Entry.title}`}
                        </ListGroupItem>
                    ))}
                    {CombatHistories.length === 0 && (
                        <ListGroupItem className="noselect">Polička prázdna :c</ListGroupItem>
                    )}
                </ListGroup>
            )}
        </ListGroup>
    );
}
