import React from 'react';
import { Label, FormGroup, Button, Popover } from 'reactstrap';
import Select from 'react-select/creatable';
import { Checkbox, Tooltip, TextEditor } from 'components';
import { pickSelectStyle } from 'configs/selectStyles';
import { ADD_NOTE } from 'queries';
import { useMutation } from '@apollo/client';

export default function NewNote(props) {
    const { setOpen, open, categories } = props;

    const [message, setMessage] = React.useState('');
    const [newCategory, setNewCategory] = React.useState(null);
    const [priv, setPriv] = React.useState(false);
    const [addingNote, setAddingNote] = React.useState(false);
    const [addNoteMutation] = useMutation(ADD_NOTE);

    const addNote = () => {
        setAddingNote(true);
        addNoteMutation({
            variables: {
                isPrivate: priv,
                message,
                category: newCategory.value,
            },
        })
            .then(() => {
                setMessage('');
                setAddingNote(false);
                setOpen(false);
            })
            .catch((e) => {
                console.log(e);
                setAddingNote(false);
            });
    };

    return (
        <Popover
            className="popover-note-message"
            placement="top"
            isOpen={open}
            target={`new-note-footer`}
            toggle={() => setOpen(!open)}
        >
            <div className="flex-row">
                <FormGroup className="flex">
                    <Label>Kategória</Label>
                    <Select
                        value={newCategory}
                        options={categories}
                        onChange={(category) => setNewCategory(category)}
                        styles={pickSelectStyle(['noPadding'])}
                    />
                </FormGroup>
                <Checkbox label="Súkromná" value={priv} onChange={() => setPriv(!priv)} centerHor />
                <Button
                    color="link"
                    className="m-l-auto"
                    disabled={message.length < 2 || addingNote || newCategory === null}
                    onClick={addNote}
                >
                    <i className="fa fa-plus color-green" id="add-note-button" />
                </Button>
                <Tooltip placement="top" target="add-note-button" text="Pridať poznámku" />
            </div>
            <FormGroup className="note-editor min-height-300">
                <TextEditor
                    value={message}
                    onChange={setMessage}
                    className="flex min-height-250"
                    placeholder="Sem napíš novú poznámku"
                />
            </FormGroup>
        </Popover>
    );
}
