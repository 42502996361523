import React from 'react';
import { Empty, Image, Tooltip } from 'components';
import { GetMyRights, getItemDescription } from 'helperFunctions';
import classnames from 'classnames';
import { UPDATE_CHARACTER } from 'queries';
import { useMutation } from '@apollo/client';

export default function Equipment({ User, Equipped }) {
    const { AdminOrDm } = GetMyRights();
    const [updateUser] = useMutation(UPDATE_CHARACTER);

    const Runes = Equipped.weapon
        ? Equipped.weapon.variants.find((variant) => variant.equipped)
        : null;

    const RenderAccessories = () => {
        if (Equipped.accessories.length === 0) {
            return <div key="no-accessories">Nevlastníš zatiaľ žiadne doplnky!</div>;
        } else {
            return (
                <div>
                    {Equipped.accessories.map((Accessory) =>
                        getItemDescription(Accessory, true, true)
                    )}
                </div>
            );
        }
    };

    return (
        <div className="equipment">
            <div className="flex-row">
                <i
                    className="fa fa-ankh font-35 clickable accessories"
                    id={`accessories-${User.id}`}
                />
                <Tooltip
                    placement="left"
                    target={`accessories-${User.id}`}
                    text={RenderAccessories()}
                />
                <Image
                    strech
                    fillEmpty
                    className="helmet"
                    width={62}
                    height={62}
                    path={
                        Equipped.head?.replaced ? Equipped.head?.replaced.icon : Equipped.head?.icon
                    }
                    focus={Equipped.head !== null}
                    customFocus={getItemDescription(Equipped.head, true, true)}
                />
                {User.occupation.magic && (
                    <Empty>
                        <i
                            className={classnames(
                                {
                                    'fa-wand-magic color-red': !User.dailySpell,
                                    'fa-wand-magic-sparkles color-green': User.dailySpell,
                                    'clickable': AdminOrDm,
                                },
                                'magic-icon fa font-35'
                            )}
                            id={`daily-spell-${User.id}`}
                            onClick={() => {
                                if (!AdminOrDm) {
                                    return;
                                }
                                updateUser({
                                    variables: { id: User.id, dailySpell: !User.dailySpell },
                                });
                            }}
                        />

                        <Tooltip
                            placement="top"
                            target={`daily-spell-${User.id}`}
                            text={`Denné kúzlo (${User.dailySpell ? 'dostupné' : 'použité'})`}
                        />
                    </Empty>
                )}
            </div>
            <div className="flex-row">
                <Image
                    strech
                    fillEmpty
                    className="hands"
                    width={62}
                    height={62}
                    path={
                        Equipped.hands?.replaced
                            ? Equipped.hands?.replaced.icon
                            : Equipped.hands?.icon
                    }
                    focus={Equipped.hands !== null}
                    customFocus={getItemDescription(Equipped.hands, true, true)}
                />
                <Image
                    strech
                    fillEmpty
                    className="chest"
                    width={62}
                    height={62}
                    path={
                        Equipped.chest?.replaced
                            ? Equipped.chest?.replaced.icon
                            : Equipped.chest?.icon
                    }
                    focus={Equipped.chest !== null}
                    customFocus={getItemDescription(Equipped.chest, true, true)}
                />
                <Image
                    strech
                    fillEmpty
                    className="shield"
                    width={62}
                    height={62}
                    path={
                        Equipped.shield?.replaced
                            ? Equipped.shield?.replaced.icon
                            : Equipped.shield?.icon
                    }
                    focus={Equipped.shield !== null}
                    customFocus={getItemDescription(Equipped.shield, true, true)}
                />
            </div>
            <div className="flex-row m-t-5">
                <div className="width-200 height-130">
                    <div className="flex-row">
                        <Image
                            strech
                            fillEmpty
                            className="weapon"
                            width={116}
                            height={61.5}
                            path={
                                Equipped.weapon?.replaced
                                    ? Equipped.weapon?.replaced.icon
                                    : Equipped.weapon?.icon
                            }
                            focus={Equipped.weapon !== null}
                            customFocus={getItemDescription(Equipped.weapon, true, true)}
                        />
                        <Image
                            strech
                            fillEmpty
                            className="boots"
                            width={62}
                            height={62}
                            path={
                                Equipped.boots?.replaced
                                    ? Equipped.boots?.replaced.icon
                                    : Equipped.boots?.icon
                            }
                            focus={Equipped.boots !== null}
                            customFocus={getItemDescription(Equipped.boots, true, true)}
                        />
                    </div>
                    <div className="flex-row m-t-8">
                        <Image
                            strech
                            fillEmpty
                            className="rune-1"
                            width={29}
                            height={29}
                            path={Runes?.rune1?.icon}
                            focus={![null, undefined].includes(Runes?.rune1)}
                            customFocus={getItemDescription(Runes?.rune1, true, true)}
                        />
                        <Image
                            strech
                            fillEmpty
                            className="rune-2"
                            width={29}
                            height={29}
                            path={Runes?.rune2?.icon}
                            focus={![null, undefined].includes(Runes?.rune2)}
                            customFocus={getItemDescription(Runes?.rune2, true, true)}
                        />
                        <Image
                            strech
                            fillEmpty
                            className="rune-3"
                            width={29}
                            height={29}
                            path={Runes?.rune3?.icon}
                            focus={![null, undefined].includes(Runes?.rune3)}
                            customFocus={getItemDescription(Runes?.rune3, true, true)}
                        />
                    </div>
                </div>
                <div className="width-100 m-t-3">
                    <div className="flex-row">
                        <Image
                            strech
                            fillEmpty
                            className="potion-l"
                            width={29}
                            height={29}
                            path={Equipped.potions?.p1?.icon}
                            focus={Equipped.potions.p1 !== null}
                            customFocus={getItemDescription(Equipped.potions.p1, true, true)}
                        />
                        <Image
                            strech
                            fillEmpty
                            className="potion-r"
                            width={29}
                            height={29}
                            path={Equipped.potions?.p2?.icon}
                            focus={Equipped.potions.p2 !== null}
                            customFocus={getItemDescription(Equipped.potions.p2, true, true)}
                        />
                    </div>
                    <div className="flex-row m-t-12">
                        <Image
                            strech
                            fillEmpty
                            className="potion-l"
                            width={29}
                            height={29}
                            path={Equipped.potions?.p3?.icon}
                            focus={Equipped.potions.p3 !== null}
                            customFocus={getItemDescription(Equipped.potions.p3, true, true)}
                        />
                        <Image
                            strech
                            fillEmpty
                            className="potion-r"
                            width={29}
                            height={29}
                            path={Equipped.potions?.p4?.icon}
                            focus={Equipped.potions.p4 !== null}
                            customFocus={getItemDescription(Equipped.potions.p4, true, true)}
                        />
                    </div>
                    <div className="flex-row m-t-13">
                        <Image
                            strech
                            fillEmpty
                            className="potion-l"
                            width={29}
                            height={29}
                            path={Equipped.potions?.p5?.icon}
                            focus={Equipped.potions.p5 !== null}
                            customFocus={getItemDescription(Equipped.potions.p5, true, true)}
                        />
                        <Image
                            strech
                            fillEmpty
                            className="potion-r"
                            width={29}
                            height={29}
                            path={Equipped.potions?.p6?.icon}
                            focus={Equipped.potions.p6 !== null}
                            customFocus={getItemDescription(Equipped.potions.p6, true, true)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
