import React from 'react';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import Login from './login';
import {
    LOGIN_USER,
    OCCUPATIONS,
    OCCUPATIONS_SUBSCRIPTION,
    RACES,
    RACES_SUBSCRIPTION,
    REGISTRATION_OPEN,
    REGISTRATION_SUBSCRIPTION,
    REGISTER_USER,
    USER_COLORS,
    USER_COLORS_SUBSCRIPTION,
} from 'queries';

export default function LoginContainer() {
    const [loginUser] = useMutation(LOGIN_USER);
    const [registerUser] = useMutation(REGISTER_USER);

    const {
        data: registrationOpenData,
        loading: registrationOpenLoading,
        refetch: registrationOpenRefetch,
    } = useQuery(REGISTRATION_OPEN, {
        fetchPolicy: 'network-only',
    });

    useSubscription(REGISTRATION_SUBSCRIPTION, {
        onData: () => {
            registrationOpenRefetch();
        },
    });

    const {
        data: racesData,
        loading: racesLoading,
        refetch: racesRefetch,
    } = useQuery(RACES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(RACES_SUBSCRIPTION, {
        onData: () => {
            racesRefetch();
        },
    });

    const {
        data: occupationsData,
        loading: occupationsLoading,
        refetch: occupationsRefetch,
    } = useQuery(OCCUPATIONS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(OCCUPATIONS_SUBSCRIPTION, {
        onData: () => {
            occupationsRefetch();
        },
    });

    const {
        data: userColorsData,
        loading: userColorsLoading,
        refetch: userColorsRefetch,
    } = useQuery(USER_COLORS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(USER_COLORS_SUBSCRIPTION, {
        onData: () => {
            userColorsRefetch();
        },
    });

    return (
        <Login
            loginUser={loginUser}
            registerUser={registerUser}
            registrationOpen={!registrationOpenLoading && registrationOpenData.registrationOpen}
            userColors={userColorsLoading ? [] : userColorsData.userColors}
            races={racesLoading ? [] : racesData.races}
            occupations={occupationsLoading ? [] : occupationsData.occupations}
        />
    );
}
