import { gql } from '@apollo/client';

export const DICEROLLS = gql`
    query dicerolls($today: Boolean!, $offset: Int) {
        dicerolls(today: $today, offset: $offset) {
            id
            createdAt
            updatedAt
            dice
            roll
            situation
            user {
                id
                color
                nickname
                character
                gender
            }
        }
    }
`;

export const ROLL_DICE = gql`
    mutation rollDice($situation: String, $dice: DicesEnum) {
        rollDice(situation: $situation, dice: $dice) {
            id
        }
    }
`;

export const DELETE_DICEROLL = gql`
    mutation deleteDiceroll($id: Int!) {
        deleteDiceroll(id: $id)
    }
`;

export const SET_DICEROLLER = gql`
    mutation setDiceroller(
        $userId: Int
        $dicerollerFixed: Boolean
        $dicerollerSpecialRoll: Boolean
    ) {
        setDiceroller(
            userId: $userId
            dicerollerFixed: $dicerollerFixed
            dicerollerSpecialRoll: $dicerollerSpecialRoll
        )
    }
`;

export const DICEROLLS_SUBSCRIPTION = gql`
    subscription dicerollsSubscription {
        dicerollsSubscription
    }
`;
