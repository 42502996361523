import React from 'react';
import Character from 'components/character';
import { GetMyData } from 'helperFunctions';
import CharacterInventory from 'components/inventory';
import { USERS, USERS_SUBSCRIPTION } from 'queries';
import { useQuery, useSubscription } from '@apollo/client';

export default function PlayerParty() {
    const currentUser = GetMyData();

    const {
        data: usersData,
        loading: usersLoading,
        refetch: usersRefetch,
    } = useQuery(USERS, {
        fetchPolicy: 'network-only',
        variables: {
            dm: false,
            includeInactive: false,
        },
    });

    useSubscription(USERS_SUBSCRIPTION, {
        onData: () => {
            usersRefetch({
                dm: false,
                includeInactive: false,
            });
        },
    });
    const Users = usersLoading ? [] : usersData.users;
    const SortedUsers = Users.sort((User1, User2) => {
        if (User1.id === currentUser.id) {
            return -1;
        }
        if (User2.id === currentUser.id) {
            return 1;
        }
        if (User1.nickname === User2.nickname) {
            return 0;
        }
        if (User1.nickname < User2.nickname) {
            return -1;
        }
        return 1;
    });

    return (
        <div className="overflow-x">
            <div className="flex-row-wrapped">
                {SortedUsers.map((User) => (
                    <div key={User.id} className="m-l-50 m-t-20 max-height-20 flex-column">
                        <Character id={User.id} Users={Users} />
                        <CharacterInventory id={User.id} Users={Users} />
                    </div>
                ))}
            </div>
        </div>
    );
}
