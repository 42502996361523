import React from 'react';
import { Popover, PopoverHeader, FormGroup, Label, Button, Input } from 'reactstrap';
import Select from 'react-select';
import { REMOVE_FROM_INVENTORY } from 'queries';
import { useMutation } from '@apollo/client';

export default function DeleteInventoryItem(props) {
    const { cleanup, addToCleanup, Item, uniqueId } = props;

    const [quantity, setQuantity] = React.useState(1);
    const [processing, setProcessing] = React.useState(false);
    const [variant, setVariant] = React.useState(null);
    const [removeFromInventory] = useMutation(REMOVE_FROM_INVENTORY);

    React.useEffect(() => {
        addToCleanup({
            id: 'destroy',
            cleanup: () => {
                setQuantity(1);
                setVariant(null);
            },
        });
        // eslint-disable-next-line
    }, []);

    if (Item === null) {
        return null;
    }

    const destroyItem = () => {
        setProcessing(true);
        let body = {
            id: Item.id,
            quantity: isNaN(parseInt(quantity)) ? 1 : parseInt(quantity),
        };
        if (variant !== null && variant.id !== null) {
            body.variantId = variant.id;
        }
        removeFromInventory({ variables: body })
            .then(() => {
                setProcessing(false);
                cleanup();
            })
            .catch((e) => {
                console.log(e);
                setProcessing(false);
            });
    };

    const getVariantsSelect = () => {
        let variants = [];
        let equippedVariant = Item.equipped
            ? Item.variants.find((equippedVariant) => equippedVariant.equipped)
            : null;
        if (Item.quantity > Item.variants.length) {
            variants.push({
                id: null,
                value: null,
                label:
                    Item.equipped && !equippedVariant
                        ? `Základná zbraň (vybavená)`
                        : `Základná zbraň`,
            });
        }
        Item.variants.forEach((Variant) => {
            const variantTitle = `${Variant.rune1 ? Variant.rune1.title : 'Bez runy'},${
                Variant.rune2 ? Variant.rune2.title : 'bez runy'
            },${Variant.rune3 ? Variant.rune3.title : 'bez runy'}`;
            variants.push({
                id: Variant.id,
                value: Variant.id,
                label: Variant.equipped ? `${variantTitle} (vybavená)` : variantTitle,
            });
        });
        return variants;
    };

    return (
        <Popover
            className="popover-400"
            placement="right"
            isOpen={![null, undefined].includes(Item)}
            target={`inventory-table-${uniqueId}`}
            toggle={() => {}}
        >
            <PopoverHeader>{`Odobratie z ${Item ? Item.title : ''}`}</PopoverHeader>

            <div className="p-20">
                {Item?.type?.id === 2 && (
                    <FormGroup>
                        <Label>Variant</Label>
                        <Select
                            value={variant}
                            options={getVariantsSelect()}
                            placeholder="Variant zbrane"
                            onChange={(variant) => {
                                setVariant(variant);
                            }}
                        />
                    </FormGroup>
                )}
                {Item !== null && (Item?.type?.id !== 2 || variant?.id === null) && (
                    <FormGroup>
                        <Label htmlFor="destroy-item-quantity">
                            {`Množstvo (${Item.quantity - Item.variants.length})`}
                        </Label>
                        <Input
                            type="number"
                            id="destroy-item-quantity"
                            value={quantity}
                            placeholder="Zadaj množstvo"
                            onChange={(e) => setQuantity(e.target.value)}
                        />
                    </FormGroup>
                )}
                <div className="flex-row">
                    <Button
                        color="danger"
                        disabled={
                            processing ||
                            isNaN(parseInt(quantity)) ||
                            parseInt(quantity) < 1 ||
                            Item === null ||
                            (Item === 2 && variant === null)
                        }
                        onClick={destroyItem}
                    >
                        {processing ? 'Odstraňuje sa...' : 'Odstrániť'}
                    </Button>

                    <Button color="warning" className="m-l-auto" onClick={cleanup}>
                        Zavrieť
                    </Button>
                </div>
            </div>
        </Popover>
    );
}
