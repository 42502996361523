import { gql } from '@apollo/client';

export const BATTLEFIELD = gql`
    query battlefield($id: Int!) {
        battlefield(id: $id) {
            id
            createdAt
            updatedAt
            title
            battlefield
            battlefieldData {
                id
                createdAt
                updatedAt
                X
                Y
                type
                userId
            }
        }
    }
`;

export const BATTLEFIELDS = gql`
    query battlefields {
        battlefields {
            id
            title
            battlefield
        }
    }
`;
export const ADD_BATTLEFIELD = gql`
    mutation addBattlefield($title: String, $battlefield: String) {
        addBattlefield(title: $title, battlefield: $battlefield) {
            id
        }
    }
`;
export const UPDATE_BATTLEFIELD = gql`
    mutation updateBattlefield($id: Int!, $title: String, $battlefield: String) {
        updateBattlefield(id: $id, title: $title, battlefield: $battlefield) {
            id
        }
    }
`;
export const DELETE_BATTLEFIELD = gql`
    mutation deleteBattlefield($id: Int!) {
        deleteBattlefield(id: $id) {
            id
        }
    }
`;

export const ADD_TO_BATTLEFIELD = gql`
    mutation addToBattlefield(
        $battlefieldId: Int!
        $x: Float!
        $y: Float!
        $type: BattlefieldTypeEnum!
        $userId: Int
    ) {
        addToBattlefield(battlefieldId: $battlefieldId, X: $x, Y: $y, type: $type, userId: $userId)
    }
`;

export const REMOVE_FROM_BATTLEFIELD = gql`
    mutation removeFromBattlefield($id: Int!) {
        removeFromBattlefield(id: $id)
    }
`;

export const CLEAR_BATTLEFIELD = gql`
    mutation clearBattlefield($battlefieldId: Int!) {
        clearBattlefield(battlefieldId: $battlefieldId)
    }
`;

export const MOVE_AT_BATTLEFIELD = gql`
    mutation moveAtBattlefield($id: Int!, $x: Float!, $y: Float!) {
        moveAtBattlefield(id: $id, X: $x, Y: $y)
    }
`;

export const BATTLEFIELDS_SUBSCRIPTION = gql`
    subscription battlefieldsSubscription {
        battlefieldsSubscription
    }
`;
