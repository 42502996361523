import React from 'react';
import { Card, CardBody, CardFooter, CardHeader, Label, FormGroup, Button } from 'reactstrap';
import Textarea from 'react-textarea-autosize';
import { stringToDiv, timestampToString, GetMyData } from 'helperFunctions';
import Select from 'react-select/creatable';
import { Checkbox, Empty, Tooltip } from 'components';
import classnames from 'classnames';
import { pickSelectStyle } from 'configs/selectStyles';
import {
    ADD_LIBRARY_NOTE,
    DELETE_LIBRARY_NOTE,
    LIBRARY_NOTES,
    LIBRARY_NOTES_SUBSCRIPTION,
    SET_LIBRARY_NOTE_IGNORED,
    SET_LIBRARY_NOTE_PRIVATE,
    UPDATE_LIBRARY_NOTE,
} from 'queries';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
const selectAllCharacters = {
    id: null,
    value: null,
    label: 'Všetci',
};

export default function GeneralNotes({ type, id, className }) {
    const currentUser = GetMyData();

    const [message, setMessage] = React.useState('');
    const [filterIgnored, setFilterIgnored] = React.useState(true);
    const [editingNote, setEditingNote] = React.useState(null);
    const [priv, setPriv] = React.useState(false);
    const [addingNote, setAddingNote] = React.useState(false);
    const [filterCharacter, setFilterCharacter] = React.useState(selectAllCharacters);

    const [addLibraryNote] = useMutation(ADD_LIBRARY_NOTE);
    const [updateLibraryNote] = useMutation(UPDATE_LIBRARY_NOTE);
    const [deleteLibraryNote] = useMutation(DELETE_LIBRARY_NOTE);
    const [setLibraryNoteIgnored] = useMutation(SET_LIBRARY_NOTE_IGNORED);
    const [setLibraryNotePrivate] = useMutation(SET_LIBRARY_NOTE_PRIVATE);

    const {
        data: libraryNotesData,
        loading: libraryNotesLoading,
        refetch: libraryNotesRefetch,
    } = useQuery(LIBRARY_NOTES, {
        variables: {
            libraryId: id,
            type,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(LIBRARY_NOTES_SUBSCRIPTION, {
        onData: () => {
            libraryNotesRefetch({
                variables: {
                    libraryId: id,
                    type,
                },
            });
        },
        variables: {
            libraryId: id,
            type,
        },
    });

    const LibraryNotes = libraryNotesLoading ? [] : libraryNotesData.libraryNotes;

    const filterNotes = () => {
        return LibraryNotes.filter(
            (Note) =>
                (filterCharacter.id === null || Note.user?.id === filterCharacter.id) &&
                (!filterIgnored || !Note.ignoreList.includes(currentUser.id))
        );
    };

    const getNoteCharacters = () => {
        let characterOptions = [selectAllCharacters];
        LibraryNotes.forEach((LibraryNote) => {
            if (
                LibraryNote.user !== null &&
                !characterOptions.some((option) => option.id === LibraryNote.user.id)
            ) {
                if (currentUser.id === LibraryNote.user.id) {
                    characterOptions.push({
                        id: currentUser.id,
                        value: currentUser.id,
                        label: `Ja (${currentUser.character})`,
                    });
                } else {
                    characterOptions.push({
                        id: LibraryNote.user.id,
                        value: LibraryNote.user.id,
                        label: LibraryNote.user.character,
                    });
                }
            }
        });
        return characterOptions;
    };

    const addNote = () => {
        setAddingNote(true);
        addLibraryNote({
            variables: {
                targetId: id,
                type,
                message,
                isPrivate: priv,
            },
        }).then(() => {
            setMessage('');
            setAddingNote(false);
        });
    };

    const ignoreNote = (id) => {
        setLibraryNoteIgnored({ variables: { id } });
    };

    const setPrivate = (id) => {
        setLibraryNotePrivate({ variables: { id } });
    };

    const deleteNote = (id) => {
        deleteLibraryNote({ variables: { id } });
    };

    const updateNote = () => {
        if (editingNote !== null) {
            updateLibraryNote({
                variables: {
                    id: editingNote.id,
                    message,
                    isPrivate: priv,
                },
            });
            changeEditNoteState();
        }
    };

    const changeEditNoteState = (Note = null) => {
        if (Note !== null) {
            setEditingNote(Note);
            setMessage(Note.message);
            setPriv(Note.isPrivate);
        } else {
            setMessage('');
            setPriv(false);
            setEditingNote(null);
        }
    };

    if (!currentUser || libraryNotesLoading) {
        return (
            <div className={`library-notes ${className ? className : ''}`}>
                <Card className="small-card">
                    <CardHeader>Poznámky</CardHeader>
                    <CardBody>Načítavajú sa....</CardBody>
                    <CardFooter></CardFooter>
                </Card>
            </div>
        );
    }

    return (
        <div className={`library-notes ${className ? className : ''}`}>
            <Card className="small-card">
                <CardHeader>
                    <div className="flex-row">
                        <span className="center-hor">Poznámky</span>
                        <Button
                            color="link"
                            className=" font-12"
                            onClick={() => setFilterIgnored(!filterIgnored)}
                        >
                            {filterIgnored ? 'Zobraziť ignorované' : 'Schovať ignorované'}
                        </Button>
                    </div>
                </CardHeader>
                <div className="library-note-control">
                    <div className="flex-row m-t-5">
                        <Label className="center-hor m-l-20 m-r-5">Charakter</Label>
                        <div className="flex m-r-20">
                            <Select
                                value={filterCharacter}
                                options={getNoteCharacters()}
                                onChange={(character) => setFilterCharacter(character)}
                                styles={pickSelectStyle(['noPadding'])}
                            />
                        </div>
                    </div>
                    <hr className="p-0 m-t-5 m-b-5" />
                </div>
                <CardBody>
                    {filterNotes().map((Note) => (
                        <div key={Note.id} className="double-border p-5">
                            <div className="flex-row font-14">
                                <div>
                                    {timestampToString(Note.createdAt)}
                                    {Note.user?.id === currentUser.id && (
                                        <Empty>
                                            <i
                                                id={`icon-delete-note-${Note.id}`}
                                                className="clickable fa fa-trash m-l-5 m-r-5 color-red"
                                                onClick={() => {
                                                    deleteNote(Note.id);
                                                }}
                                            />
                                            <Tooltip
                                                placement="top"
                                                target={`icon-delete-note-${Note.id}`}
                                                text="Vymazať"
                                            />
                                            <i
                                                id={`icon-edit-note-${Note.id}`}
                                                className="clickable fa fa-pen m-l-5 m-r-5 color-blue"
                                                onClick={() => {
                                                    changeEditNoteState(Note);
                                                }}
                                            />
                                            <Tooltip
                                                placement="top"
                                                target={`icon-edit-note-${Note.id}`}
                                                text="Upraviť"
                                            />
                                        </Empty>
                                    )}
                                </div>
                                <div className="m-l-auto">
                                    <i
                                        id={`icon-ignore-note-${Note.id}`}
                                        className={classnames(
                                            {
                                                'fa-eye color-green': !Note.ignoreList.includes(
                                                    currentUser.id
                                                ),
                                                'fa-eye-slash color-red': Note.ignoreList.includes(
                                                    currentUser.id
                                                ),
                                            },
                                            'fa clickable m-r-5'
                                        )}
                                        onClick={() => {
                                            ignoreNote(Note.id);
                                        }}
                                    />
                                    <Tooltip
                                        placement="top"
                                        target={`icon-ignore-note-${Note.id}`}
                                        text={
                                            Note.ignoreList.includes(currentUser.id)
                                                ? 'Zviditeľniť'
                                                : 'Ignorovať'
                                        }
                                    />
                                </div>
                                <div className="flex-row m-l-auto">
                                    <i
                                        id={`icon-private-note-${Note.id}`}
                                        className={classnames(
                                            {
                                                'color-red fa-lock': Note.isPrivate,
                                                'color-green fa-unlock': !Note.isPrivate,
                                                'clickable': Note.user?.id === currentUser.id,
                                            },
                                            'fa-solid m-r-5 center-hor'
                                        )}
                                        onClick={() => {
                                            if (
                                                Note.user?.id === currentUser.id ||
                                                currentUser.admin ||
                                                currentUser.dm
                                            ) {
                                                setPrivate(Note.id);
                                            }
                                        }}
                                    />
                                    <Tooltip
                                        placement="top"
                                        target={`icon-private-note-${Note.id}`}
                                        text={Note.isPrivate ? 'Súkromná' : 'Verejná'}
                                    />
                                    <div
                                        className="text-bolder"
                                        style={{ color: Note.user ? Note.user.color : 'grey' }}
                                    >
                                        {Note.user ? Note.user.character : 'Neexistuje'}
                                    </div>
                                </div>
                            </div>
                            {stringToDiv(Note.message)}
                        </div>
                    ))}
                    {filterNotes().length === 0 && <div>Bez poznámok</div>}
                </CardBody>
                <CardFooter className="p-10">
                    <div className="flex-row">
                        <FormGroup className="flex"></FormGroup>
                        <div className="center-hor">
                            <Checkbox
                                label="Súkromná"
                                value={priv}
                                onChange={() => setPriv(!priv)}
                                centerHor
                            />
                        </div>
                        <Button
                            color="link"
                            className="m-l-auto"
                            disabled={message.length < 2 || addingNote}
                            onClick={editingNote ? updateNote : addNote}
                        >
                            <i
                                className={classnames(
                                    {
                                        'fa-plus color-green': editingNote === null,
                                        'fa-sync color-blue': editingNote !== null,
                                    },
                                    'fa'
                                )}
                                id="add-note-button"
                            />
                        </Button>
                        <Tooltip
                            placement="top"
                            target="add-note-button"
                            text={editingNote === null ? 'Pridať poznámku' : 'Upraviť poznámku'}
                        />
                        {editingNote && (
                            <Empty>
                                <Button
                                    color="link"
                                    onClick={() => {
                                        if (
                                            (editingNote.message === message &&
                                                editingNote.isPrivate === priv) ||
                                            window.confirm('Zrušiť editáciu? Stratíš zmeny.')
                                        ) {
                                            changeEditNoteState();
                                        }
                                    }}
                                >
                                    <i
                                        className="fa fa-times color-red"
                                        id="cancel-edit-note-button"
                                    />
                                </Button>
                                <Tooltip
                                    placement="top"
                                    target="cancel-edit-note-button"
                                    text="Zrušiť úpravu poznámky"
                                />
                            </Empty>
                        )}
                    </div>
                    <FormGroup>
                        <Textarea
                            className="form-control"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </FormGroup>
                </CardFooter>
            </Card>
        </div>
    );
}
