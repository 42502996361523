import React from 'react';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { GetMyData, GetMyRights, GetSettings } from 'helperFunctions';
import {
    LOOT_MANAGER,
    LOOT_MANAGER_SUBSCRIPTION,
    SET_LOOT_MANAGER_ACTIVE,
    USERS,
    USERS_SUBSCRIPTION,
} from 'queries';
import { Button, Modal } from 'reactstrap';
import LootGold from './gold';
import LootParticipants from './participants';
import LootList from './loot';
import DebtsList from './debts';
import LootManagerLogger from './log';

export default function LootManager({ className }) {
    const [openManager, setOpenManager] = React.useState(false);

    const { AdminOrDm } = GetMyRights();
    const { lootManagerActive } = GetSettings();
    const currentUser = GetMyData();

    const {
        data: managerData,
        loading: managerLoading,
        refetch: managerRefetch,
    } = useQuery(LOOT_MANAGER, {
        fetchPolicy: 'network-only',
    });

    const {
        data: usersData,
        loading: usersLoading,
        refetch: usersRefetch,
    } = useQuery(USERS, {
        fetchPolicy: 'network-only',
        variables: {
            dm: false,
            includeInactive: false,
        },
    });

    useSubscription(USERS_SUBSCRIPTION, {
        onData: () => {
            usersRefetch({
                dm: false,
                includeInactive: false,
            });
        },
    });

    const Users = usersLoading
        ? []
        : usersData.users.sort((User1, User2) => {
              if (User1.id === currentUser.id) {
                  return -1;
              }
              if (User2.id === currentUser.id) {
                  return 1;
              }
              if (User1.character === User2.character) {
                  return 0;
              }
              if (User1.character < User2.character) {
                  return -1;
              }
              return 1;
          });

    useSubscription(LOOT_MANAGER_SUBSCRIPTION, {
        onData: () => {
            managerRefetch();
        },
    });

    const [setLootManagetActive] = useMutation(SET_LOOT_MANAGER_ACTIVE);

    const participants = managerLoading ? [] : managerData.lootManager.participants;

    return (
        <div
            className={
                className
                    ? `${className} thin-buttons flex-row noselect`
                    : 'thin-buttons flex-row noselect'
            }
        >
            <Button color="link color-gold center-hor" onClick={() => setOpenManager(!openManager)}>
                <i className="fa fa-sack-dollar m-r-5" />
                Loot manažér
            </Button>
            <Modal size="xxl" className="m-t-50" isOpen={openManager} toggle={() => {}}>
                <div className="bkg-paper min-width-800 noselect" style={{ 'borderRadius': 7 }}>
                    <div className="flex-row p-5 p-l-20 p-r-20 thin-buttons">
                        {AdminOrDm && (
                            <Button
                                color={lootManagerActive ? 'success' : 'danger'}
                                onClick={setLootManagetActive}
                                className="m-l-20"
                            >
                                Manažér {lootManagerActive ? 'beží' : 'pozastavený'}
                            </Button>
                        )}
                        <div className="m-l-auto">
                            <Button color="danger" onClick={() => setOpenManager(!openManager)}>
                                Zavrieť
                            </Button>
                        </div>
                    </div>
                    <hr className="m-t-2 m-b-0" />

                    <div className="p-20">
                        <LootGold participants={participants} />
                        <LootParticipants participants={participants} Users={Users} />

                        <LootList participants={participants} Users={Users} />
                        <DebtsList Users={Users} />
                        <LootManagerLogger />
                    </div>
                </div>
            </Modal>
        </div>
    );
}
