import { gql } from '@apollo/client';

export const PLACE = gql`
    query place($id: Int!) {
        place(id: $id) {
            id
            createdAt
            updatedAt
            title
            description
            visibility
            place
            important
        }
    }
`;

export const PLACES = gql`
    query places {
        places {
            id
            title
            visibility
            place
            important
        }
    }
`;
export const ADD_PLACE = gql`
    mutation addPlace(
        $title: String
        $description: String
        $visibility: Boolean
        $important: Boolean
        $place: String
    ) {
        addPlace(
            title: $title
            description: $description
            visibility: $visibility
            important: $important
            place: $place
        ) {
            id
        }
    }
`;
export const UPDATE_PLACE = gql`
    mutation updatePlace(
        $id: Int!
        $title: String
        $description: String
        $visibility: Boolean
        $important: Boolean
        $place: String
    ) {
        updatePlace(
            id: $id
            title: $title
            description: $description
            visibility: $visibility
            important: $important
            place: $place
        ) {
            id
        }
    }
`;
export const DELETE_PLACE = gql`
    mutation deletePlace($id: Int!) {
        deletePlace(id: $id) {
            id
        }
    }
`;

export const PLACES_SUBSCRIPTION = gql`
    subscription placesSubscription {
        placesSubscription
    }
`;
