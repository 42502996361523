import React from 'react';
import { Card, CardHeader, CardBody, Button, Input } from 'reactstrap';
import { ImageInput } from 'components';
import { toBase64 } from 'helperFunctions';
import {
    DELETE_BATTLEFIELD,
    BATTLEFIELD,
    BATTLEFIELDS_SUBSCRIPTION,
    UPDATE_BATTLEFIELD,
} from 'queries';
import { useMutation, useQuery, useSubscription } from '@apollo/client';

export default function BattlefieldEdit(props) {
    const { battlefieldID } = props;

    const [title, setTitle] = React.useState('');
    const [battlefield, setBattlefield] = React.useState(null);
    const [saving, setSaving] = React.useState(false);

    const [updateBattlefield] = useMutation(UPDATE_BATTLEFIELD);
    const [deleteBattlefield] = useMutation(DELETE_BATTLEFIELD);

    const {
        data: battlefieldData,
        loading: battlefieldLoading,
        refetch: battlefieldRefetch,
    } = useQuery(BATTLEFIELD, {
        fetchPolicy: 'network-only',
        variables: {
            id: battlefieldID,
        },
    });

    useSubscription(BATTLEFIELDS_SUBSCRIPTION, {
        onData: () => {
            battlefieldRefetch({ variables: { id: battlefieldID } });
        },
    });

    const Battlefield = battlefieldLoading ? null : battlefieldData.battlefield;

    React.useEffect(() => {
        battlefieldRefetch({ id: battlefieldID });
        // eslint-disable-next-line
    }, [battlefieldID]);

    React.useEffect(() => {
        if (!battlefieldLoading) {
            setTitle(Battlefield.title);
        }
        // eslint-disable-next-line
    }, [Battlefield]);

    const save = async () => {
        setSaving(true);
        //save images
        let body = {
            id: battlefieldID,
            title,
        };
        if (battlefield) {
            body.battlefield = await toBase64(battlefield);
        }
        updateBattlefield({ variables: body })
            .then(() => {
                //update STATE images
                setSaving(false);
                setBattlefield(null);
            })
            .catch((e) => {
                setSaving(false);
                console.log(e);
            });
    };

    const deleteFunc = async () => {
        if (window.confirm(`Si si istý že chceš zmazať bojovisko ${title}?`)) {
            deleteBattlefield({ variables: { id: battlefieldID } });
        }
    };

    return (
        <div className="settings-right">
            <Card>
                <CardHeader className="flex-row">
                    <Input
                        id="title"
                        value={title}
                        className="invisible-input"
                        battlefieldholder="Názov miesta"
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </CardHeader>
                <CardBody>
                    <ImageInput
                        setImage={setBattlefield}
                        image={battlefield}
                        id={`battlefield${battlefieldID}`}
                        label="Bojovisko"
                        battlefieldholder="Vyberte bojovisko"
                        original={Battlefield?.battlefield}
                        width={1000}
                    />

                    <div className="flex-row">
                        <Button color="primary" disabled={saving} onClick={save}>
                            {saving ? 'Ukladá sa...' : 'Uložiť bojovisko'}
                        </Button>
                        <Button color="danger" className="m-l-auto" onClick={deleteFunc}>
                            Vymazať
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
