import { gql } from '@apollo/client';

export const LIBRARY_ENTRY = gql`
    query libraryEntry($id: Int!) {
        libraryEntry(id: $id) {
            id
            createdAt
            updatedAt
            title
            description
            text
            order
            category {
                id
                title
                order
            }
        }
    }
`;

export const LIBRARY_ENTRIES = gql`
    query libraryEntries {
        libraryEntries {
            id
            title
            order
            category {
                id
                title
                order
            }
        }
    }
`;

export const LIBRARY_CATEGORIES = gql`
    query libraryCategories {
        libraryCategories {
            id
            title
            order
        }
    }
`;

export const ADD_LIBRARY_ENTRY = gql`
    mutation addLibraryEntry(
        $title: String
        $description: String
        $text: String
        $order: Int
        $category: LibraryCategoryInput
    ) {
        addLibraryEntry(
            title: $title
            description: $description
            text: $text
            order: $order
            category: $category
        ) {
            id
        }
    }
`;
export const UPDATE_LIBRARY_ENTRY = gql`
    mutation updateLibraryEntry(
        $id: Int!
        $title: String
        $description: String
        $text: String
        $order: Int
        $category: LibraryCategoryInput
    ) {
        updateLibraryEntry(
            id: $id
            title: $title
            description: $description
            text: $text
            order: $order
            category: $category
        ) {
            id
        }
    }
`;
export const DELETE_LIBRARY_ENTRY = gql`
    mutation deleteLibraryEntry($id: Int!) {
        deleteLibraryEntry(id: $id) {
            id
        }
    }
`;

export const LIBRARY_ENTRIES_SUBSCRIPTION = gql`
    subscription libraryEntriesSubscription {
        libraryEntriesSubscription
    }
`;
