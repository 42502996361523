import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';

const navClassNames = 'nav-link text-bolder';
const leftNavClassNames = `${navClassNames} m-l-5 m-r-5`;
const rightNavClassNames = `${navClassNames} m-l-auto`;

export default function SettingsHeader(props) {
    const users = props.users;

    if (users) {
        return (
            <div>
                <div className="settings-header">
                    <NavLink
                        className={({ isActive }) =>
                            isActive ? `${leftNavClassNames} active` : leftNavClassNames
                        }
                        to={`/nastavenia_pouzivatelov`}
                    >
                        Používatelia
                    </NavLink>
                    <NavLink
                        className={({ isActive }) =>
                            isActive ? `${rightNavClassNames} active` : rightNavClassNames
                        }
                        to={`/nastavenia_hry/rasy`}
                    >
                        Nastavenia hry
                    </NavLink>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="settings-header">
                <NavLink
                    className={({ isActive }) =>
                        isActive ? `${leftNavClassNames} active` : leftNavClassNames
                    }
                    to={`/nastavenia_hry/rasy`}
                >
                    Rasy
                </NavLink>
                <NavLink
                    className={({ isActive }) =>
                        isActive ? `${leftNavClassNames} active` : leftNavClassNames
                    }
                    to={`/nastavenia_hry/povolania`}
                >
                    Povolania
                </NavLink>
                <NavLink
                    className={({ isActive }) =>
                        isActive ? `${leftNavClassNames} active` : leftNavClassNames
                    }
                    to={`/nastavenia_hry/magia`}
                >
                    Mágia
                </NavLink>
                <NavLink
                    className={({ isActive }) =>
                        isActive ? `${leftNavClassNames} active` : leftNavClassNames
                    }
                    to={`/nastavenia_hry/obchodnici`}
                >
                    Obchodníci
                </NavLink>
                <NavLink
                    className={({ isActive }) =>
                        isActive ? `${leftNavClassNames} active` : leftNavClassNames
                    }
                    to={`/nastavenia_hry/mapy`}
                >
                    Mapy
                </NavLink>
                <NavLink
                    className={({ isActive }) =>
                        isActive ? `${leftNavClassNames} active` : leftNavClassNames
                    }
                    to={`/nastavenia_hry/miesta`}
                >
                    Miesta
                </NavLink>
                <NavLink
                    className={({ isActive }) =>
                        isActive ? `${leftNavClassNames} active` : leftNavClassNames
                    }
                    to={`/nastavenia_hry/bojoviska`}
                >
                    Bojoviská
                </NavLink>
                <NavLink
                    className={({ isActive }) =>
                        isActive ? `${leftNavClassNames} active` : leftNavClassNames
                    }
                    to={`/nastavenia_hry/bestiar`}
                >
                    Bestiář
                </NavLink>
                <NavLink
                    className={({ isActive }) =>
                        isActive ? `${leftNavClassNames} active` : leftNavClassNames
                    }
                    to={`/nastavenia_hry/veci`}
                >
                    Veci
                </NavLink>
                <NavLink
                    className={({ isActive }) =>
                        isActive ? `${leftNavClassNames} active` : leftNavClassNames
                    }
                    to={`/nastavenia_hry/recepty`}
                >
                    Recepty
                </NavLink>
                <NavLink
                    className={({ isActive }) =>
                        isActive ? `${leftNavClassNames} active` : leftNavClassNames
                    }
                    to={`/nastavenia_hry/kniznica`}
                >
                    Knižnica
                </NavLink>
                <NavLink
                    className={({ isActive }) =>
                        isActive ? `${rightNavClassNames} active` : rightNavClassNames
                    }
                    to={`/nastavenia_pouzivatelov`}
                >
                    Používatelia
                </NavLink>
            </div>
            <Outlet />
        </div>
    );
}
