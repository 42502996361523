import React from 'react';
import { Crafting, Image } from 'components';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { RECIPES, RECIPES_SUBSCRIPTION } from 'queries';
import { useQuery, useSubscription } from '@apollo/client';
import { useNavigate, useParams } from 'react-router';

export default function CraftingPage() {
    const { id } = useParams();
    const navigate = useNavigate();
    const recipeID = parseInt(id);

    const {
        data: recipesData,
        loading: recipesLoading,
        refetch: recipesRefetch,
    } = useQuery(RECIPES, {
        variables: {
            visibleOnly: true,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(RECIPES_SUBSCRIPTION, {
        onData: () => {
            recipesRefetch({
                visibleOnly: true,
            });
        },
    });

    const Recipes = recipesLoading ? [] : recipesData.recipes;

    return (
        <div className="flex-row">
            <div className="library-left">
                <ListGroup>
                    {Recipes.map((recipe) => (
                        <ListGroupItem
                            className="flex-row noselect clickable"
                            onClick={() => navigate(`/crafting/${recipe.id}`)}
                            active={recipeID === recipe.id}
                            action
                            key={recipe.id}
                        >
                            <Image path={recipe.icon} height={50} width={50} hideEmpty />
                            <div className="m-l-10 font-18 center-hor">{recipe.title}</div>
                        </ListGroupItem>
                    ))}
                </ListGroup>
            </div>
            {!isNaN(recipeID) && Recipes.some((Recipe) => Recipe.id === recipeID) && (
                <div className="library-right">
                    <Crafting recipeID={recipeID} />
                </div>
            )}
        </div>
    );
}
