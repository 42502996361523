import React from 'react';
import { Label, ListGroup, ListGroupItem } from 'reactstrap';
import { useNavigate } from 'react-router';

export default function SpellList({ fixedShow, setFixedShow, type, id, Spells }) {
    const navigate = useNavigate();

    const groupSpells = (Spells) => {
        let GroupedSpells = [];
        Spells.forEach((Spell) => {
            const Index = GroupedSpells.findIndex((Group) => Group.id === Spell.mageClass.id);
            if (Index === -1) {
                GroupedSpells.push({
                    ...Spell.mageClass,
                    Spells: [Spell],
                });
            } else {
                GroupedSpells[Index].Spells.push(Spell);
            }
        });
        return GroupedSpells;
    };

    const GroupedSpells = groupSpells(Spells);

    return (
        <ListGroup className="library-list-categories">
            <ListGroupItem
                active={fixedShow.includes('spells')}
                action
                className="clickable m-t-10"
                onClick={() => {
                    if (fixedShow.includes('spells')) {
                        setFixedShow(fixedShow.filter((title) => title !== 'spells'));
                    } else {
                        setFixedShow([...fixedShow, 'spells']);
                    }
                }}
            >
                Mágia
            </ListGroupItem>
            {fixedShow.includes('spells') && (
                <ListGroup className="m-l-20 library-list-entries">
                    {GroupedSpells.map((SpellGroup) => (
                        <div key={`sg-${SpellGroup.id}`}>
                            <Label className="color-grey m-t-10">{SpellGroup.label}</Label>
                            {SpellGroup.Spells.map((Spell) => (
                                <ListGroupItem
                                    key={Spell.id}
                                    active={type === 'spells' && id === Spell.id}
                                    action
                                    className="clickable"
                                    onClick={() => {
                                        navigate(`/library/spells/${Spell.id}`);
                                    }}
                                >
                                    {Spell.title}
                                </ListGroupItem>
                            ))}
                        </div>
                    ))}
                    {GroupedSpells.length === 0 && (
                        <ListGroupItem className="noselect">Polička prázdna :c</ListGroupItem>
                    )}
                </ListGroup>
            )}
        </ListGroup>
    );
}
