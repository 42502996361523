import React from 'react';
import { Image, Empty, Tooltip } from 'components';
import { Button } from 'reactstrap';
import GeneralNotes from 'pages/library/notes';

import classnames from 'classnames';
import { CREATURE, CREATURES_SUBSCRIPTION } from 'queries';
import { setShowLibraryList } from 'apollo/localSchema/actions';
import { useQuery, useSubscription } from '@apollo/client';
import { GET_LIBRARY_OPEN } from 'apollo/queries';

export default function BestiaryCreature(props) {
    const { id, inLibrary } = props;

    const { data: libraryOpenData } = useQuery(GET_LIBRARY_OPEN);
    const ShowLibraryList = libraryOpenData.ShowLibraryList;
    const {
        data: creatureData,
        loading: creatureLoading,
        refetch: creatureRefetch,
    } = useQuery(CREATURE, {
        fetchPolicy: 'network-only',
        variables: {
            id,
        },
    });

    useSubscription(CREATURES_SUBSCRIPTION, {
        onData: () => {
            creatureRefetch({ id });
        },
    });

    const Creature = creatureLoading ? null : creatureData.creature;

    if (!Creature) {
        return <div>Načítava sa...</div>;
    }

    return (
        <div className="flex-row p-20">
            <div>
                <div className="flex-row">
                    {inLibrary && (
                        <Empty>
                            <Button
                                color="link"
                                onClick={setShowLibraryList}
                                id="show-hide-library-list"
                            >
                                <i
                                    className={classnames({
                                        'fa fa-arrow-left color-blue': ShowLibraryList,
                                        'fa fa-arrow-right color-blue': !ShowLibraryList,
                                    })}
                                />
                                <i className="m-l-2 fa fa-book-atlas color-blue" />
                            </Button>
                            <Tooltip
                                placement="top"
                                target="show-hide-library-list"
                                text={ShowLibraryList ? 'Schovať knižnicu' : 'Zobraziť knižnicu'}
                            />
                        </Empty>
                    )}
                    <h1 className="text-center ancient-font color-white">{Creature.title}</h1>
                </div>
                <div className="flex-row color-grey">
                    <Image height={400} path={Creature.portrait} hideEmpty showFull />
                    <div className="ancient-font font-24 m-l-20">
                        <div className="flex-row">
                            <div className="width-120 text-bolder">{`Level: ${Creature.level}`}</div>
                            <div className="m-l-5"></div>
                        </div>
                        <div className="flex-row">
                            <div className="width-120">Vitalita</div>
                            <div className="m-l-5">{Creature.vitality}</div>
                        </div>
                        <div className="flex-row">
                            <div className="width-120">Výdrž</div>
                            <div className="m-l-5">{Creature.endurance}</div>
                        </div>
                        <div className="flex-row">
                            <div className="width-120">Sila</div>
                            <div className="m-l-5">{Creature.strength}</div>
                        </div>
                        <div className="flex-row">
                            <div className="width-120">Rýchlosť</div>
                            <div className="m-l-5">{Creature.speed}</div>
                        </div>
                        <div className="flex-row">
                            <div className="width-120">Obratnosť</div>
                            <div className="m-l-5">{Creature.dexterity}</div>
                        </div>
                        <div className="flex-row">
                            <div className="width-120">Obrana</div>
                            <div className="m-l-5">{Creature.defence}</div>
                        </div>
                        <div className="flex-row">
                            <div className="width-120">Energia</div>
                            <div className="m-l-5">{Creature.energy}</div>
                        </div>
                    </div>
                </div>
            </div>
            {inLibrary && (
                <div className="flex">
                    <GeneralNotes id={id} type="creature" />
                </div>
            )}
        </div>
    );
}
