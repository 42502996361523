import React from 'react';
import Attribute from './attribute';
import { GetMyRights, randomSimpleString } from 'helperFunctions';
import { Input, Label, FormGroup } from 'reactstrap';

export default function Attributes(props) {
    const { Opponent, updateUniqueSpeed, extraDiv } = props;
    const { Dm } = GetMyRights();
    const [randomID] = React.useState(randomSimpleString());
    const [uniqueSpeed, setUniqueSpeed] = React.useState(null);

    return (
        <div className="combat-attributes">
            <Attribute {...props} randomID={randomID} title="Vitalita" attribute="vitality" />
            <Attribute {...props} randomID={randomID} title="Výdrž" attribute="endurance" />
            <Attribute {...props} randomID={randomID} title="Sila" attribute="strength" />
            <Attribute {...props} randomID={randomID} title="Rýchlosť" attribute="speed" />
            <Attribute {...props} randomID={randomID} title="Obratnosť" attribute="dexterity" />
            <Attribute {...props} randomID={randomID} title="Obrana" attribute="defence" />
            <Attribute {...props} randomID={randomID} title="Energia" attribute="energy" />
            <FormGroup className="flex-row m-t-3 clean-input m-b-0">
                <Label className="width-100 center-hor">Extra rýchosť</Label>
                <Input
                    className="m-l-5 m-b-5 width-40 hidden-input"
                    type="number"
                    value={uniqueSpeed !== null ? uniqueSpeed : Opponent.uniqueSpeed}
                    onFocus={() => setUniqueSpeed(Opponent.uniqueSpeed)}
                    disabled={!Dm}
                    onBlur={() => {
                        if (!isNaN(parseInt(uniqueSpeed))) {
                            updateUniqueSpeed(parseInt(uniqueSpeed));
                        }
                        setUniqueSpeed(null);
                    }}
                    onChange={(e) => setUniqueSpeed(e.target.value)}
                />
            </FormGroup>
            {extraDiv ? extraDiv : null}
        </div>
    );
}
