import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { useNavigate } from 'react-router';

export default function BestiaryList({ fixedShow, setFixedShow, type, id, Creatures }) {
    const navigate = useNavigate();

    return (
        <ListGroup className="library-list-categories">
            <ListGroupItem
                active={fixedShow.includes('bestiary')}
                action
                className="clickable m-t-10"
                onClick={() => {
                    if (fixedShow.includes('bestiary')) {
                        setFixedShow(fixedShow.filter((title) => title !== 'bestiary'));
                    } else {
                        setFixedShow([...fixedShow, 'bestiary']);
                    }
                }}
            >
                Bestiář
            </ListGroupItem>
            {fixedShow.includes('bestiary') && (
                <ListGroup className="m-l-20 library-list-entries">
                    {Creatures.map((Entry) => (
                        <ListGroupItem
                            key={Entry.id}
                            active={type === 'bestiary' && id === Entry.id}
                            action
                            className="clickable"
                            onClick={() => {
                                navigate(`/library/bestiary/${Entry.id}`);
                            }}
                        >
                            {Entry.title}
                        </ListGroupItem>
                    ))}
                    {Creatures.length === 0 && (
                        <ListGroupItem className="noselect">Polička prázdna :c</ListGroupItem>
                    )}
                </ListGroup>
            )}
        </ListGroup>
    );
}
