import React from 'react';
import { Button, Label, ListGroup, ListGroupItem } from 'reactstrap';
import CreatureEdit from './edit';
import CreatureAdd from './add';
import { Tooltip } from 'components';
import { useParams, useNavigate } from 'react-router';
import { CREATURES, CREATURES_SUBSCRIPTION } from 'queries';
import { useQuery, useSubscription } from '@apollo/client';

export default function CreatureList() {
    const { id } = useParams();
    const creatureID = parseInt(id);
    const navigate = useNavigate();
    const {
        data: creaturesData,
        loading: creaturesLoading,
        refetch: creaturesRefetch,
    } = useQuery(CREATURES, {
        variables: {
            visibleOnly: false,
        },
        fetchPolicy: 'network-only',
    });

    useSubscription(CREATURES_SUBSCRIPTION, {
        onData: () => {
            creaturesRefetch({
                variables: {
                    visibleOnly: false,
                },
            });
        },
    });

    const Creatures = creaturesLoading ? [] : creaturesData.creatures;
    return (
        <div className="flex-row">
            <div className="settings-left">
                <div className="flex-row">
                    <Label className="m-t-5 color-white">Bestiář</Label>
                    <Button
                        color="link"
                        id="add-creature"
                        className="center-hor m-l-auto m-b-5"
                        onClick={() => navigate('/nastavenia_hry/bestiar/add')}
                    >
                        <i className="fa fa-plus" />
                    </Button>
                    <Tooltip placement="right" target="add-creature" text="Vytvoriť nového tvora" />
                </div>
                <ListGroup>
                    {Creatures.map((creature) => (
                        <ListGroupItem
                            key={creature.id}
                            active={creatureID === creature.id}
                            action
                            className="clickable"
                            onClick={() => {
                                navigate(`/nastavenia_hry/bestiar/${creature.id}`);
                            }}
                        >
                            {creature.title}
                            <span className="center-right-absolute highlighted-text">
                                <span
                                    className="p-r-5"
                                    style={{
                                        color: creature.visibility ? '#218838' : '#bd2130',
                                    }}
                                >
                                    {creature.important && (
                                        <i className="fa color-gold fa-star m-r-5" />
                                    )}
                                    {creature.visibility ? 'Spoznané' : 'Neznáme'}
                                </span>
                                {creature.order}
                            </span>
                        </ListGroupItem>
                    ))}
                </ListGroup>
            </div>
            {!isNaN(creatureID) && Creatures.some((creature) => creature.id === creatureID) && (
                <CreatureEdit creatureID={creatureID} />
            )}
            {id === 'add' && <CreatureAdd />}
        </div>
    );
}
