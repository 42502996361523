import { Tooltip, Image } from 'components';
import React from 'react';
import { Label, Col, Input, Button, FormGroup } from 'reactstrap';

export function ImageInput(props) {
    const {
        setImage,
        image,
        id,
        label,
        placeholder,
        original,
        setDeleteImage,
        deleteImage,
        height,
        width,
    } = props;

    const dimentions = {};
    if (height) {
        dimentions.height = height;
    }
    if (width) {
        dimentions.width = width;
    }

    return (
        <FormGroup className="flex-row">
            <Label for="raceIcon" sm={2}>
                {label}
            </Label>
            <Col sm={10}>
                <span className="flex-row">
                    <Input
                        type="file"
                        id={id}
                        key={id}
                        accept="image/png, image/jpeg"
                        placeholder={placeholder}
                        style={{ width: 'auto' }}
                        onChange={(e) => {
                            let files = e.target.files;
                            if (files.length === 1) {
                                setImage(files[0]);
                            }
                            if (setDeleteImage) {
                                setDeleteImage(false);
                            }
                        }}
                    />
                    {image !== null && (
                        <Button
                            color="link"
                            className="pt-0 pb-0 pl-1 pr-1"
                            onClick={() => {
                                setImage(null);
                            }}
                            id={`delete-${id}`}
                        >
                            <i className="fa fa-times color-red" />
                            <Tooltip
                                placement="bottom"
                                target={`delete-${id}`}
                                text={`Vymazať ${original ? `nový` : ''} obrázok ${
                                    original ? `a ponechať pôvodný` : ''
                                }`}
                            />
                        </Button>
                    )}
                    {setDeleteImage && !deleteImage && image === null && original && (
                        <Button
                            color="link"
                            className="pt-0 pb-0 pl-1 pr-1"
                            onClick={() => {
                                setDeleteImage(true);
                            }}
                            id={`delete-original-${id}`}
                        >
                            <i className="fa fa-times color-red" />
                            <Tooltip
                                placement="bottom"
                                target={`delete-original-${id}`}
                                text="Vymazať pôvodný obrázok"
                            />
                        </Button>
                    )}
                    {deleteImage && (
                        <Button
                            color="link"
                            className="pt-0 pb-0 pl-1 pr-1"
                            onClick={() => {
                                setDeleteImage(false);
                            }}
                            id={`restore-original-${id}`}
                        >
                            <i className="fa fa-rotate color-blue" />
                            <Tooltip
                                placement="bottom"
                                target={`restore-original-${id}`}
                                text="Obnoviť pôvodný obrázok"
                            />
                        </Button>
                    )}
                </span>
                {!deleteImage && image !== null && (
                    <img {...dimentions} alt="new missing" src={URL.createObjectURL(image)} />
                )}
                {!deleteImage && image === null && original && (
                    <Image path={original} {...dimentions} />
                )}
            </Col>
        </FormGroup>
    );
}
