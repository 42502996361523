import React from 'react';
import { stringToDiv } from './string';

export const getEquipped = (Inventory) => {
    let equipped = {
        boots: null,
        head: null,
        chest: null,
        hands: null,
        shield: null,
        weapon: null,
        potions: {
            p1: null,
            p2: null,
            p3: null,
            p4: null,
            p5: null,
            p6: null,
        },
        accessories: Inventory.filter((Item) => Item.type.id === 12 && Item.equipped),
    };
    Inventory.filter((Item) => Item.equipped).forEach((Item) => {
        switch (Item.type.id) {
            //boots
            case 8: {
                equipped.boots = Item;
                break;
            }
            //head
            case 5: {
                equipped.head = Item;
                break;
            }
            //chest
            case 6: {
                equipped.chest = Item;
                break;
            }
            //hands
            case 7: {
                equipped.hands = Item;
                break;
            }
            //shield
            case 9: {
                equipped.shield = Item;
                break;
            }
            //weapon
            case 2: {
                equipped.weapon = Item;
                break;
            }
            //potions
            case 3: {
                Item.equippedSlots?.forEach((slot) => {
                    equipped.potions[`p${slot}`] = Item;
                });
                break;
            }
            default:
                break;
        }
    });
    equipped.accessories.forEach((Item) => {
        switch (Item.replaces) {
            //boots
            case 8: {
                if (equipped.boots === null) {
                    equipped.boots = Item;
                } else {
                    equipped.boots.replaced = Item;
                }
                break;
            }
            //head
            case 5: {
                if (equipped.head === null) {
                    equipped.head = Item;
                } else {
                    equipped.head.replaced = Item;
                }
                break;
            }
            //chest
            case 6: {
                if (equipped.chest === null) {
                    equipped.chest = Item;
                } else {
                    equipped.chest.replaced = Item;
                }
                break;
            }
            //hands
            case 7: {
                if (equipped.hands === null) {
                    equipped.hands = Item;
                } else {
                    equipped.hands.replaced = Item;
                }
                break;
            }
            //shield
            case 9: {
                if (equipped.shield === null) {
                    equipped.shield = Item;
                } else {
                    equipped.shield.replaced = Item;
                }
                break;
            }
            //weapon
            case 2: {
                if (equipped.weapon === null) {
                    equipped.weapon = Item;
                } else {
                    equipped.weapon.replaced = Item;
                }
                break;
            }
            default:
                break;
        }
    });
    return equipped;
};

export const getEquippedItem = (equipped, type) => {
    switch (type) {
        //boots
        case 8: {
            return equipped.boots;
        }
        //head
        case 5: {
            return equipped.head;
        }
        //chest
        case 6: {
            return equipped.chest;
        }
        //hands
        case 7: {
            return equipped.hands;
        }
        //shield
        case 9: {
            return equipped.shield;
        }
        //weapon
        case 2: {
            return equipped.weapon;
        }
        //potions
        case 3: {
            return equipped.potions;
        }

        default:
            return null;
    }
};

export const breakdownItemToAttributes = (Item) => {
    let attributes = [];
    if (Item.vitality !== 0) {
        attributes.push({ label: 'Vitalita', attribute: 'vitality', value: Item.vitality });
    }
    if (Item.endurance !== 0) {
        attributes.push({ label: 'Výdrž', attribute: 'endurance', value: Item.endurance });
    }
    if (Item.strength !== 0) {
        attributes.push({ label: 'Sila', attribute: 'strength', value: Item.strength });
    }
    if (Item.speed !== 0) {
        attributes.push({ label: 'Rýchlosť', attribute: 'speed', value: Item.speed });
    }
    if (Item.dexterity !== 0) {
        attributes.push({ label: 'Obratnosť', attribute: 'dexterity', value: Item.dexterity });
    }
    if (Item.defence !== 0) {
        attributes.push({ label: 'Obrana', attribute: 'defence', value: Item.defence });
    }
    if (Item.energy !== 0) {
        attributes.push({ label: 'Energia', attribute: 'energy', value: Item.energy });
    }
    if (Item.capacity !== 0) {
        attributes.push({ label: 'Nosnosť', attribute: 'capacity', value: Item.capacity });
    }
    return attributes;
};

export const getItemDescription = (Item, title = false, stripped = false, price = false) => {
    if (!Item) {
        return null;
    }

    const attributes = breakdownItemToAttributes(Item);
    const replacedAttributes = Item.replaced ? breakdownItemToAttributes(Item.replaced) : null;

    if (!title && !price && attributes.length === 0 && Item.description.length === 0) {
        return null;
    }
    return (
        <div key={Item.id}>
            {Item.replaced && (
                <div>
                    {title && <div className="text-bolder font-16">{Item.replaced.title}</div>}
                    {replacedAttributes.length > 0 && (
                        <div className="min-width-100">
                            {!stripped && (
                                <div className="text-bolder text-left font-16">Attribúty</div>
                            )}
                            {replacedAttributes.map((Attribute) => (
                                <div className="text-right" key={Attribute.attribute}>
                                    <span className="text-bolder">{Attribute.label}</span>:
                                    <span className="m-l-5">{Attribute.value}</span>
                                </div>
                            ))}
                        </div>
                    )}
                    {Item.replaced.description.length > 0 && (
                        <div>
                            {!stripped && (
                                <div className="m-r-5 text-bolder text-left font-16">Popis: </div>
                            )}
                            <div className="text-right text-italics">
                                {stringToDiv(Item.replaced.description)}
                            </div>
                        </div>
                    )}
                    <hr />
                </div>
            )}
            {title && <div className="text-bolder font-16">{Item.title}</div>}
            {price && (
                <div className="text-bolder font-15">
                    <span>Cena:</span>
                    <span className="color-gold m-l-5">
                        {Item.price}g({Math.round(Item.price * 0.6)}g)
                    </span>
                </div>
            )}
            {attributes.length > 0 && (
                <div className="min-width-100">
                    {!stripped && <div className="text-bolder text-left font-16">Attribúty</div>}
                    {attributes.map((Attribute) => (
                        <div className="text-right" key={Attribute.attribute}>
                            <span className="text-bolder">{Attribute.label}</span>:
                            <span className="m-l-5">{Attribute.value}</span>
                        </div>
                    ))}
                </div>
            )}
            {Item.description.length > 0 && (
                <div>
                    {!stripped && (
                        <div className="m-r-5 text-bolder text-left font-16">Popis: </div>
                    )}
                    <div className="text-right text-italics">{stringToDiv(Item.description)}</div>
                </div>
            )}
        </div>
    );
};

export const getAttributesFromItems = (Equipped) => {
    let equippedAttributes = {
        energy: [],
        defence: [],
        dexterity: [],
        speed: [],
        strength: [],
        vitality: [],
        endurance: [],
        capacity: [],
    };
    if (Equipped.head) {
        breakdownItemToAttributes(Equipped.head).forEach((Attribute) =>
            equippedAttributes[Attribute.attribute].push({
                title: Equipped.head.title,
                value: Attribute.value,
            })
        );
    }
    if (Equipped.chest) {
        breakdownItemToAttributes(Equipped.chest).forEach((Attribute) =>
            equippedAttributes[Attribute.attribute].push({
                title: Equipped.chest.title,
                value: Attribute.value,
            })
        );
    }
    if (Equipped.hands) {
        breakdownItemToAttributes(Equipped.hands).forEach((Attribute) =>
            equippedAttributes[Attribute.attribute].push({
                title: Equipped.hands.title,
                value: Attribute.value,
            })
        );
    }
    if (Equipped.shield) {
        breakdownItemToAttributes(Equipped.shield).forEach((Attribute) =>
            equippedAttributes[Attribute.attribute].push({
                title: Equipped.shield.title,
                value: Attribute.value,
            })
        );
    }
    if (Equipped.weapon) {
        breakdownItemToAttributes(Equipped.weapon).forEach((Attribute) =>
            equippedAttributes[Attribute.attribute].push({
                title: Equipped.weapon.title,
                value: Attribute.value,
            })
        );
        const Variant = Equipped.weapon.variants.find((variant) => variant.equipped);
        if (Variant) {
            [Variant.rune1, Variant.rune2, Variant.rune3].forEach((Rune, index) => {
                if (Rune) {
                    breakdownItemToAttributes(Rune).forEach((Attribute) =>
                        equippedAttributes[Attribute.attribute].push({
                            title: `R${index + 1}: ${Rune.title}`,
                            value: Attribute.value,
                        })
                    );
                }
            });
        }
    }
    if (Equipped.boots) {
        breakdownItemToAttributes(Equipped.boots).forEach((Attribute) =>
            equippedAttributes[Attribute.attribute].push({
                title: Equipped.boots.title,
                value: Attribute.value,
            })
        );
    }
    Equipped.accessories.forEach((Accessory) => {
        breakdownItemToAttributes(Accessory).forEach((Attribute) =>
            equippedAttributes[Attribute.attribute].push({
                title: Accessory.title,
                value: Attribute.value,
            })
        );
    });
    return equippedAttributes;
};
