import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useQuery, useMutation, useSubscription } from '@apollo/client';
import { GET_MY_DATA, USER_DATA_SUBSCRIPTION } from 'apollo/queries';
import { LOGOUT_USER, SETTINGS, SETTINGS_SUBSCRIPTION } from 'queries';
import { GetMyData } from 'helperFunctions';
import { Empty } from 'components';

import PageHeader from 'components/pageHeader';
import Party from 'pages/party';
import Game from 'pages/game';
import Diceroller from 'components/diceroller';
import Library from 'pages/library';

import SettingsHeader from 'pages/settings/settingsHeader';
import Users from 'pages/settings/users';
import Races from 'pages/settings/races';
import Occupations from 'pages/settings/occupations';
import Spells from 'pages/settings/spells';
import Maps from 'pages/settings/maps';
import Traders from 'pages/settings/traders';
import Places from 'pages/settings/places';
import Battlefields from 'pages/settings/battlefields';
import Bestiary from 'pages/settings/bestiary';
import Items from 'pages/settings/items';
import SettingsLibrary from 'pages/settings/library';
import Recipes from 'pages/settings/recipes';
import { Notes } from 'components/note';
import CraftingPage from 'pages/crafting';
import CombatSimulator from 'components/combatSimulator';
import Logout from 'pages/login/logout';
import { setAsAdmin, setAsDm, setAsUser, setSettings } from 'apollo/localSchema/actions';
import GameArea from 'pages/game/gameArea';
import NoGameArea from 'pages/game/noGameArea';

export default function Navigation() {
    const [logoutUser] = useMutation(LOGOUT_USER);

    const { loading: userDataLoading, refetch: userDataRefetch } = useQuery(GET_MY_DATA, {
        fetchPolicy: 'network-only',
    });
    useSubscription(USER_DATA_SUBSCRIPTION, {
        onData: () => {
            userDataRefetch();
        },
    });

    const {
        data: settingsData,
        loading: settingsLoading,
        refetch: settingsRefetch,
    } = useQuery(SETTINGS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(SETTINGS_SUBSCRIPTION, {
        onData: () => {
            settingsRefetch();
        },
    });

    const currentUser = GetMyData();

    React.useEffect(() => {
        if (!userDataLoading) {
            if (currentUser.admin) {
                setAsAdmin();
            } else if (currentUser.dm) {
                setAsDm();
            } else {
                setAsUser();
            }
        }
        // eslint-disable-next-line
    }, [userDataLoading]);

    React.useEffect(() => {
        if (!settingsLoading) {
            setSettings(settingsData.settings);
        }
        // eslint-disable-next-line
    }, [settingsData]);

    if (!userDataLoading && currentUser === null) {
        logoutUser().then(() => {
            window.location.reload(false);
        });
        return null;
    }

    if (userDataLoading || !currentUser.activated) {
        return <Logout />;
    }

    return (
        <BrowserRouter>
            <Empty>
                <div className="center-ver row center flex page-header">
                    <PageHeader />
                </div>

                <Routes>
                    <Route path="/" element={<Game />} />
                    <Route path="party" element={<Party />} />
                    <Route path="library" element={<Library />}>
                        <Route path=":id" element={<Library />} />
                        <Route path=":type/:id" element={<Library />} />
                    </Route>
                    <Route path="crafting" element={<CraftingPage />}>
                        <Route path=":id" element={<CraftingPage />} />
                    </Route>

                    <Route path="dices" element={<Diceroller />} />
                    <Route path="combatSimulator" element={<CombatSimulator />} />
                    <Route path="notes" element={<Notes />} />

                    <Route path="noGameArea" element={<NoGameArea />} />
                    <Route path="gameArea" element={<GameArea />} />

                    <Route
                        path="nastavenia_pouzivatelov"
                        element={
                            <Empty>
                                <SettingsHeader users={true} />
                                <Users />
                            </Empty>
                        }
                    >
                        <Route
                            path=":id"
                            element={
                                <Empty>
                                    <SettingsHeader users={true} />
                                    <Users />
                                </Empty>
                            }
                        />
                    </Route>
                    {(currentUser.dm || currentUser.admin) && (
                        <Route path="nastavenia_hry" element={<SettingsHeader users={false} />}>
                            <Route path="rasy" element={<Races />}>
                                <Route path=":id" element={<Races />} />
                            </Route>
                            <Route path="povolania" element={<Occupations />}>
                                <Route path=":id" element={<Occupations />} />
                            </Route>
                            <Route path="magia" element={<Spells />}>
                                <Route path=":id" element={<Spells />} />
                            </Route>
                            <Route path="obchodnici" element={<Traders />}>
                                <Route path=":id" element={<Traders />} />
                            </Route>
                            <Route path="mapy" element={<Maps />}>
                                <Route path=":id" element={<Maps />} />
                            </Route>
                            <Route path="miesta" element={<Places />}>
                                <Route path=":id" element={<Places />} />
                            </Route>
                            <Route path="bojoviska" element={<Battlefields />}>
                                <Route path=":id" element={<Battlefields />} />
                            </Route>
                            <Route path="bestiar" element={<Bestiary />}>
                                <Route path=":id" element={<Bestiary />} />
                            </Route>
                            <Route path="veci" element={<Items />}>
                                <Route path=":id" element={<Items />} />
                            </Route>
                            <Route path="kniznica" element={<SettingsLibrary />}>
                                <Route path=":id" element={<SettingsLibrary />} />
                            </Route>
                            <Route path="recepty" element={<Recipes />}>
                                <Route path=":id" element={<Recipes />} />
                            </Route>
                        </Route>
                    )}
                </Routes>
            </Empty>
        </BrowserRouter>
    );
}
