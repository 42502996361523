import React from 'react';
import { Popover as Popovers } from 'reactstrap';
import { Empty } from './Empty';
import { randomSimpleString } from 'helperFunctions';

export function Popover(props) {
    const { children, ...args } = props;
    const [target] = React.useState(randomSimpleString() + randomSimpleString());

    return (
        <Empty>
            <span id={target} />
            <Popovers target={`${target}`} {...args}>
                {children}
            </Popovers>
        </Empty>
    );
}
