import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from 'reactstrap';
import classnames from 'classnames';
import { UPDATE_GAME_AREA } from 'queries';
import { useMutation } from '@apollo/client';
import { DISCOVER } from 'queries/dmExtra';
import { Image, Tooltip } from 'components';

export default function Display(props) {
    const {
        open,
        setOpen,
        type,
        title,
        data,
        dataFilter,
        setDataFilter,
        urlPath,
        miniature,
        miniatureZoom,
        imagePath,
    } = props;
    const [discover] = useMutation(DISCOVER);
    const [updateGameArea] = useMutation(UPDATE_GAME_AREA);

    return (
        <Dropdown
            isOpen={open === `display-${type}`}
            toggle={() => {
                if (open === `display-${type}`) {
                    setOpen(null);
                } else {
                    setOpen(`display-${type}`);
                }
            }}
            className="commandbar-dropdown-inner center-hor"
            direction="end"
        >
            <DropdownToggle
                className="commandbar-dropdown-inner-toggle full-width text-left"
                disabled={data.length === 0}
            >
                {title}
            </DropdownToggle>
            <DropdownMenu start="true" className="max-height-50vh overflow-x">
                <div className="p-10">
                    <Input
                        value={dataFilter}
                        placeholder="Hľadať"
                        onChange={(e) => setDataFilter(e.target.value)}
                    />
                </div>
                {data
                    .filter((Item) => Item.title.toLowerCase().includes(dataFilter.toLowerCase()))
                    .map((Item) => (
                        <DropdownItem
                            key={Item.id}
                            onClick={() => {
                                updateGameArea({
                                    variables: {
                                        type,
                                        id: Item.id,
                                        url: urlPath ? Item[urlPath] : null,
                                    },
                                });
                                if (Item.visibility === false) {
                                    discover({ variables: { id: Item.id, type } });
                                }
                            }}
                            id={`show-${type}-miniature-${Item.id}`}
                        >
                            <i
                                className={classnames('fa m-r-10', {
                                    'fa-eye color-green': Item.visibility === true,
                                    'fa-eye-slash color-red': Item.visibility === false,
                                })}
                            />
                            {Item.title}
                            {miniature && <Image path={Item[imagePath]} small width={70} />}
                            {miniatureZoom && (
                                <Tooltip
                                    placement="right"
                                    target={`show-${type}-miniature-${Item.id}`}
                                    text={<Image path={Item[imagePath]} small />}
                                    style={{ backgroundColor: 'transparent' }}
                                />
                            )}
                        </DropdownItem>
                    ))}
            </DropdownMenu>
        </Dropdown>
    );
}
