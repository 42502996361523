import React from 'react';
import CharacterSheet from 'components/character';
import { GetMyData, GetMyRights } from 'helperFunctions';
import { Empty, Image, Tooltip } from 'components';
import classnames from 'classnames';
import { Notes } from 'components/note';
import CharacterInventory from 'components/inventory';
import Calendar from './calendar';
import GameCommandbar from './commandbar';
import { CHANGE_LAYOUTS, USERS, USERS_SUBSCRIPTION } from 'queries';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import LootManager from './lootManager';

export default function NoGameArea() {
    const currentUser = GetMyData();
    const { Dm } = GetMyRights();
    const [selectedUser, setSelectedUser] = React.useState(currentUser.id);
    const [changeLayouts] = useMutation(CHANGE_LAYOUTS);
    const {
        data: usersData,
        loading: usersLoading,
        refetch: usersRefetch,
    } = useQuery(USERS, {
        fetchPolicy: 'network-only',
        variables: {
            dm: false,
            includeInactive: false,
        },
    });

    useSubscription(USERS_SUBSCRIPTION, {
        onData: () => {
            usersRefetch({
                dm: false,
                includeInactive: false,
            });
        },
    });

    const Users = usersLoading ? [] : usersData.users;

    React.useEffect(() => {
        if (!usersLoading && !Users.some((User) => User.id === selectedUser) && Users.length > 0) {
            setSelectedUser(Users[0].id);
        }
        // eslint-disable-next-line
    }, [Users]);

    if (usersLoading) {
        return <div>Načítava sa...</div>;
    }

    const SortedUsers = Users.sort((User1, User2) => {
        if (User1.id === currentUser.id) {
            return -1;
        }
        if (User2.id === currentUser.id) {
            return 1;
        }
        if (User1.character === User2.character) {
            return 0;
        }
        if (User1.character < User2.character) {
            return -1;
        }
        return 1;
    });

    const DataSwitch = (
        <div className="flex-row m-t-auto">
            {SortedUsers.map((User) => (
                <Image
                    className={classnames({
                        'focus-border': User.id === selectedUser,
                    })}
                    key={User.id}
                    path={User.icon}
                    width={75}
                    height={75}
                    focus
                    customFocus={`${User.character} (${User.nickname})`}
                    onClick={() => {
                        if (User.id !== selectedUser) {
                            setSelectedUser(User.id);
                        }
                    }}
                />
            ))}
            <div className="m-l-10">
                <div className="font-22">
                    {currentUser.charsheetLayout !== 0 && (
                        <Empty>
                            <i
                                className="fa fa-eye-slash color-red clickable"
                                id="hide-charsheet-icon"
                                onClick={() => {
                                    changeLayouts({ variables: { charsheetLayout: 0 } });
                                }}
                            />
                            <Tooltip
                                placement="left"
                                target="hide-charsheet-icon"
                                text="Schovať charakter"
                            />
                        </Empty>
                    )}
                    {currentUser.charsheetLayout !== 1 && (
                        <Empty>
                            <i
                                className="fa fa-eye color-green m-l-5 clickable"
                                id="show-charsheet-icon"
                                onClick={() => {
                                    changeLayouts({ variables: { charsheetLayout: 1 } });
                                }}
                            />
                            <Tooltip
                                placement="left"
                                target="show-charsheet-icon"
                                text="Zobraziť charakter"
                            />
                        </Empty>
                    )}
                </div>
                <div className="font-22">
                    {currentUser.inventoryLayout !== 0 && (
                        <Empty>
                            <i
                                className="fa fa-eye-slash color-red clickable"
                                id="hide-inventory-icon"
                                onClick={() => {
                                    changeLayouts({ variables: { inventoryLayout: 0 } });
                                }}
                            />
                            <Tooltip
                                placement="left"
                                target="hide-inventory-icon"
                                text="Schovať inventár"
                            />
                        </Empty>
                    )}
                    {currentUser.inventoryLayout !== 1 && (
                        <Empty>
                            <i
                                className="fa fa-outdent color-green m-l-5 clickable"
                                id="show-inventory-left-icon"
                                onClick={() => {
                                    changeLayouts({ variables: { inventoryLayout: 1 } });
                                }}
                            />
                            <Tooltip
                                placement="left"
                                target="show-inventory-left-icon"
                                text="Zobraziť inventár naľavo"
                            />
                        </Empty>
                    )}
                    {currentUser.inventoryLayout !== 2 && (
                        <Empty>
                            <i
                                className="fa fa-indent color-blue m-l-5 clickable"
                                id="show-inventory-right-icon"
                                onClick={() => {
                                    changeLayouts({ variables: { inventoryLayout: 2 } });
                                }}
                            />
                            <Tooltip
                                placement="left"
                                target="show-inventory-right-icon"
                                text="Zobraziť inventár napravo"
                            />
                        </Empty>
                    )}
                </div>
            </div>
        </div>
    );

    return (
        <Empty>
            <div className="game-bar flex-row">
                {Dm && <GameCommandbar />}
                <LootManager className="m-l-auto m-r-20" />
                <Calendar className="m-r-20" />
            </div>
            <div className="flex-row max-height-game">
                {(currentUser.charsheetLayout === 1 || currentUser.inventoryLayout === 1) && (
                    <div className="flex-column">
                        {currentUser.charsheetLayout === 1 && (
                            <CharacterSheet Users={Users} id={selectedUser} />
                        )}
                        {currentUser.inventoryLayout === 1 && (
                            <CharacterInventory Users={Users} id={selectedUser} />
                        )}
                        {DataSwitch}
                    </div>
                )}
                <div className={classnames('flex-2 max-notes-width flex-column')}>
                    {currentUser.inventoryLayout === 2 && (
                        <CharacterInventory
                            Users={Users}
                            id={selectedUser}
                            className="center-ver"
                        />
                    )}
                    <Notes />
                    {currentUser.charsheetLayout !== 1 &&
                        currentUser.inventoryLayout !== 1 &&
                        DataSwitch}
                </div>
            </div>
        </Empty>
    );
}
