import React from 'react';
import { Button, Label, ListGroup, ListGroupItem } from 'reactstrap';
import RecipeEdit from './edit';
import RecipeAdd from './add';
import { Tooltip } from 'components';
import { useParams, useNavigate } from 'react-router';
import { RECIPES, RECIPES_SUBSCRIPTION } from 'queries';
import { useQuery, useSubscription } from '@apollo/client';

export default function RecipeList() {
    const { id } = useParams();
    const recipeID = parseInt(id);
    const navigate = useNavigate();
    const {
        data: recipesData,
        loading: recipesLoading,
        refetch: recipesRefetch,
    } = useQuery(RECIPES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(RECIPES_SUBSCRIPTION, {
        onData: () => {
            recipesRefetch();
        },
    });

    const Recipes = recipesLoading ? [] : recipesData.recipes;

    return (
        <div className="flex-row">
            <div className="settings-left">
                <div className="flex-row">
                    <Label className="m-t-5 color-white">Recepty</Label>
                    <Button
                        color="link"
                        id="add-recept"
                        className="center-hor m-l-auto m-b-5"
                        onClick={() => navigate('/nastavenia_hry/recepty/add')}
                    >
                        <i className="fa fa-plus" />
                    </Button>
                    <Tooltip placement="right" target="add-recept" text="Vytvoriť novú recept" />
                </div>
                <ListGroup>
                    {Recipes.map((Recipe) => (
                        <ListGroupItem
                            key={Recipe.id}
                            active={recipeID === Recipe.id}
                            action
                            className="clickable"
                            onClick={() => {
                                navigate(`/nastavenia_hry/recepty/${Recipe.id}`);
                            }}
                        >
                            {Recipe.title}
                            <span className="center-right-absolute highlighted-text">
                                <span
                                    className="p-r-5"
                                    style={{ color: Recipe.visibility ? '#218838' : '#bd2130' }}
                                >
                                    {Recipe.visibility ? 'Spoznané' : 'Neznáme'}
                                </span>
                                {Recipe.order}
                            </span>
                        </ListGroupItem>
                    ))}
                </ListGroup>
            </div>
            {!isNaN(recipeID) && Recipes.some((Recipe) => Recipe.id === recipeID) && (
                <RecipeEdit recipeID={recipeID} />
            )}
            {id === 'add' && <RecipeAdd />}
        </div>
    );
}
