import { gql } from '@apollo/client';
import { userData } from './user';

export const LOGIN_USER = gql`
    mutation loginUser($email: String!, $password: String!) {
        loginUser(email: $email, password: $password) {
            accessToken
            user {
                ${userData}
            }
        }
    }
`;

export const LOGOUT_USER = gql`
    mutation logoutUser {
        logoutUser
    }
`;

export const REGISTRATION_OPEN = gql`
    query registrationOpen {
        registrationOpen
    }
`;

export const REGISTRATION_SUBSCRIPTION = gql`
    subscription registrationSubscription {
        registrationSubscription
    }
`;
