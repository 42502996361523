const dayNames = [
    'koniec',
    'úklid',
    'zerno',
    'lučeník',
    'trhovník',
    'slavník',
    'tretí vrchovník',
    'radovánok',
    'honosaj',
    'pivník',
    'slanč',
    'stušák',
    'horan',
    'večerník',
    'oblievačik',
    'šibáň',
    'druhý vrchovník',
    'cesta',
    'tažoň',
    'pecník',
    'leháň',
    'počin',
    'gobota',
    'hompác',
    'umiračik',
    'návštevník',
    'prvý vrchovník',
    'sklizeň',
    'svižeň',
    'švihác',
    'lasočas',
    'opad',
    'ospevník',
    'slavonoj',
    'zábresk',
    'sejač',
    'začiatok',
];

const hourColor = {
    0: '#3F3939',
    1: '#201C1C',
    2: '#181515',
    3: '#373232',
    4: '#666553',
    5: '#A4A27E',
    6: '#E0DB8A',
    7: '#F6EF6B',
    8: '#FFF336',
    9: '#D8F100',
    10: '#D9F8FC',
    11: '#CFF6FB',
    12: '#C6F4FB',
    13: '#BCF3FA',
    14: '#00BCF1',
    15: '#7A7FFF',
    16: '#A17AFF',
    17: '#B857F1',
    18: '#959595',
    19: '#5E5E5E',
};

export { dayNames, hourColor };
