import React from 'react';
import { useMutation } from '@apollo/client';
import { GetMyRights, sortBy } from 'helperFunctions';
import { RESET_COMBAT } from 'queries';
import { Button } from 'reactstrap';
import OpponentDisplay from './opponent';

export default function CombatSummary({ CombatSimulator }) {
    const { Dm } = GetMyRights();

    const [resetCombatMutation] = useMutation(RESET_COMBAT);

    return (
        <div>
            <div className="text-center text-bolder color-green font-20">Zhrnutie boja</div>
            <div className="flex-row-wrapped min-width-1000">
                {sortBy(CombatSimulator.opponents, [
                    { key: 'user.id', asc: false },
                    { key: 'dead', asc: true },
                ]).map((Opponent) => (
                    <div className="p-10" key={Opponent.id}>
                        <OpponentDisplay
                            type={Opponent.dead ? 'Mŕtvy' : 'Živý'}
                            Opponent={Opponent}
                            noChanges
                        />
                        <div className="combat-message">
                            <div>
                                <span className="color-red text-bolder font-18">
                                    Celkové zranenie nepriateľov:
                                </span>
                                <span className="color-red m-l-10 font-20">{Opponent.damage}</span>
                            </div>
                            <div>
                                <span className="color-orange text-bolder font-18">
                                    Skóre za útok:
                                </span>
                                <span className="color-orange m-l-10 font-20">
                                    {Opponent.attackPoints}
                                </span>
                            </div>
                            <div>
                                <span className="color-blue text-bolder font-18">
                                    Skóre za obranu:
                                </span>
                                <span className="color-blue m-l-10 font-20">
                                    {Opponent.defencePoints}
                                </span>
                            </div>
                            <div>
                                <span className="color-gold text-bolder font-18">
                                    Celkové skóre:
                                </span>
                                <span className="color-gold m-l-10 font-20">{`${
                                    Opponent.score + Opponent.damage / 4
                                } (${Opponent.damage / 4} zo zranenia)`}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {Dm && (
                <div className="flex-row m-t-5">
                    <Button
                        color="success"
                        className="center-ver"
                        onClick={() => {
                            if (window.confirm('Ukončiť zhrnutie boja?')) {
                                resetCombatMutation();
                            }
                        }}
                    >
                        Ukončiť zhrnutie boja
                    </Button>
                </div>
            )}
        </div>
    );
}
