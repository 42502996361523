import { GetMyData, GetMyRights, GetSettings } from 'helperFunctions';

export function Hide(props) {
    const { admin, dm, adminOrDm, currentUser, gamePaused, id } = props;

    const { Admin, Dm, AdminOrDm } = GetMyRights();
    const User = GetMyData();
    const Settings = GetSettings();
    const { gamePaused: GamePaused } = Settings;

    if (
        (gamePaused && !GamePaused && (!adminOrDm || AdminOrDm)) ||
        (admin && Admin) ||
        (dm && Dm) ||
        (adminOrDm && AdminOrDm) ||
        (currentUser && User.id === id && (!gamePaused || !GamePaused)) ||
        (!admin && !dm && !adminOrDm && !currentUser && !gamePaused)
    ) {
        return props.children;
    }
    return null;
}
