export const toSelArr = (arr, label = 'title', value = 'id') =>
    arr.map((item) => {
        return {
            ...item,
            value: item[value],
            label: item[label],
        };
    });

export const toSelItem = (item, label = 'title') => {
    return {
        ...item,
        value: item.id,
        label: item[label],
    };
};
