import { makeVar } from '@apollo/client';

export const settingsVar = makeVar({
    gamePaused: true,
    calendar: {
        hour: 0,
        day: 0,
        month: 0,
        year: 0,
        age: 0,
    },
    gameArea: {
        type: 'race',
        id: 0,
        url: null,
    },
    userRolling: {
        id: null,
        color: 'red',
        nickname: 'Loading...',
        character: 'Loading...',
    },
    testMode: {
        active: false,
        advantage: false,
        d4: 0,
        d6: 0,
        d8: 0,
        d10: 0,
        d100: 0,
        poetic: false,
        strict: false,
    },
    dicerollerFixed: false,
    dicerollerSpecialRoll: false,
    lootManagerActive: false,
});
