import React from 'react';
import { Tooltip, Hide } from 'components';
import Select from 'react-select';
import { timestampToPracticalDate, GetSettings, GetMyRights, toSelArr } from 'helperFunctions';
import { PopoverHeader, PopoverBody, Button, Input, Popover } from 'reactstrap';
import {
    GOLD_CHANGE,
    GOLD_HISTORY,
    GOLD_HISTORY_SUBSCRIPTION,
    GOLD_STEAL,
    GOLD_TRANSFER,
} from 'queries';
import { useMutation, useQuery, useSubscription } from '@apollo/client';

export default function Gold(props) {
    const { User, Users, open, toggle, uniqueId } = props;

    const { gamePaused } = GetSettings();
    const { AdminOrDm, Dm } = GetMyRights();

    const [goldChange, setGoldChange] = React.useState(0);
    const [goldChangeDescription, setGoldChangeDescription] = React.useState('');
    const [goldTransferTo, setGoldTransferTo] = React.useState(null);

    const [transferingGold, setTransferingGold] = React.useState(false);
    const [stealingGold, setStealingGold] = React.useState(false);
    const [changingGold, setChangingGold] = React.useState(false);

    const [goldProcessing, setGoldProcessing] = React.useState(false);
    const [offset, setOffset] = React.useState(0);

    const [GoldHistory, setGoldHistory] = React.useState([]);

    const [goldChangeMutation] = useMutation(GOLD_CHANGE);
    const [goldTransferMutation] = useMutation(GOLD_TRANSFER);
    const [goldStealMutation] = useMutation(GOLD_STEAL);
    const { data: historyData } = useQuery(GOLD_HISTORY, {
        fetchPolicy: 'network-only',
        variables: {
            userId: User.id,
            today: false,
            offset,
        },
    });

    React.useEffect(() => {
        if (historyData?.goldHistory) {
            setGoldHistory(historyData?.goldHistory);
        }
        // eslint-disable-next-line
    }, [historyData?.goldHistory]);

    const {
        data: currentHistoryData,
        loading: currentHistoryLoading,
        refetch: currentHistoryRefetch,
    } = useQuery(GOLD_HISTORY, {
        fetchPolicy: 'network-only',
        variables: {
            userId: User.id,
            today: true,
        },
    });

    const CurrentGoldHistory = currentHistoryLoading ? [] : currentHistoryData.goldHistory;

    useSubscription(GOLD_HISTORY_SUBSCRIPTION, {
        onData: () => {
            currentHistoryRefetch({ userId: User.id });
        },
        variables: {
            userId: User.id,
        },
    });

    const modifyGold = () => {
        setGoldProcessing(true);
        goldChangeMutation({
            variables: {
                userId: User.id,
                quantity: parseInt(goldChange),
                reason: goldChangeDescription,
            },
        })
            .then(() => {
                setGoldProcessing(false);
                setGoldChange(0);
                setGoldChangeDescription('');
            })
            .catch((e) => {
                console.log(e);
                setGoldProcessing(false);
            });
    };

    const transferGold = async () => {
        setGoldProcessing(true);
        goldTransferMutation({
            variables: {
                quantity: parseInt(goldChange),
                transferedFrom: User.id,
                transferedTo: goldTransferTo.id,
            },
        })
            .then(() => {
                setGoldProcessing(false);
                setGoldChange(0);
                setGoldChangeDescription('');
            })
            .catch((e) => {
                console.log(e);
                setGoldProcessing(false);
            });
    };

    const stealGold = () => {
        setGoldProcessing(true);
        goldStealMutation({
            variables: {
                userId: User.id,
                quantity: parseInt(goldChange),
                reason: goldChangeDescription,
            },
        })
            .then(() => {
                setGoldProcessing(false);
                setGoldChange(0);
                setGoldChangeDescription('');
            })
            .catch((e) => {
                console.log(e);
                setGoldProcessing(false);
            });
    };

    const loadMoreHistory = () => {
        const newLimit = offset + 1;
        setOffset(newLimit);
    };

    const EntireHistory = [...CurrentGoldHistory, null, ...GoldHistory];

    const cantChangeGold =
        goldProcessing ||
        isNaN(parseInt(goldChange)) ||
        parseInt(goldChange) === 0 ||
        goldChangeDescription.length < 4 ||
        User.gold + parseInt(goldChange) < 0;
    const cantTransferGold =
        goldProcessing ||
        isNaN(parseInt(goldChange)) ||
        parseInt(goldChange) < 1 ||
        goldTransferTo === null ||
        User.gold - parseInt(goldChange) < 0;

    return (
        <Popover
            className="popover-600"
            placement="top"
            isOpen={open}
            target={`gold-${uniqueId}`}
            toggle={toggle}
        >
            <PopoverHeader>
                <div className="flex-row">
                    <Hide adminOrDm currentUser id={User.id}>
                        <Button
                            color="link"
                            id={`change-gold-button-${uniqueId}`}
                            disabled={gamePaused && !AdminOrDm}
                            onClick={() => {
                                setChangingGold(!changingGold);
                                setTransferingGold(false);
                                setStealingGold(false);
                            }}
                        >
                            <i className="fa fa-plus" />
                        </Button>
                        <Tooltip
                            placement="top"
                            target={`change-gold-button-${uniqueId}`}
                            text="Zmena v zlate"
                        />
                        <Button
                            color="link"
                            id={`transfer-gold-button-${uniqueId}`}
                            onClick={() => {
                                setTransferingGold(!transferingGold);
                                setChangingGold(false);
                                setStealingGold(false);
                            }}
                            disabled={gamePaused && !AdminOrDm}
                        >
                            <i className="fa fa-retweet" />
                        </Button>
                        <Tooltip
                            placement="top"
                            target={`transfer-gold-button-${uniqueId}`}
                            text="Darovať zlato"
                        />
                    </Hide>
                    <Hide dm>
                        <Button
                            color="link"
                            className="color-red"
                            id={`steal-gold-button-${uniqueId}`}
                            onClick={() => {
                                setStealingGold(!stealingGold);
                                setChangingGold(false);
                                setTransferingGold(false);
                            }}
                        >
                            <i className="fa fa-people-robbery" />
                        </Button>
                        <Tooltip
                            placement="top"
                            target={`steal-gold-button-${uniqueId}`}
                            text="Kradnutie zlata"
                        />
                    </Hide>
                    <Button
                        color="link"
                        className="m-l-auto"
                        disabled={GoldHistory.length !== 15 * (offset + 1)}
                        onClick={loadMoreHistory}
                    >
                        Viac histórie
                    </Button>
                </div>
                {(!gamePaused || AdminOrDm) && changingGold && (
                    <div className="flex-row">
                        <Input
                            className="width-100"
                            type="number"
                            value={goldChange}
                            placeholder="Suma"
                            onChange={(e) => setGoldChange(e.target.value)}
                        />
                        <Input
                            value={goldChangeDescription}
                            className="m-l-20"
                            placeholder="Dôvod"
                            onChange={(e) => setGoldChangeDescription(e.target.value)}
                        />
                        <Button color="link" disabled={cantChangeGold} onClick={modifyGold}>
                            <i className="fa fa-check" />
                        </Button>
                    </div>
                )}
                {(!gamePaused || AdminOrDm) && transferingGold && (
                    <div className="flex-row">
                        <Input
                            className="width-100"
                            type="number"
                            value={goldChange}
                            placeholder="Suma"
                            onChange={(e) => setGoldChange(e.target.value)}
                        />
                        <Select
                            value={goldTransferTo}
                            className="m-l-20"
                            options={toSelArr(Users, 'character')}
                            placeholder="Darovať hráčovi"
                            onChange={(character) => {
                                setGoldTransferTo(character);
                            }}
                        />
                        <Button
                            color="link"
                            className="m-l-auto"
                            disabled={cantTransferGold}
                            onClick={transferGold}
                        >
                            <i className="fa fa-check" />
                        </Button>
                    </div>
                )}
                {stealingGold && (
                    <div className="flex-row">
                        <Input
                            className="width-100"
                            type="number"
                            value={goldChange}
                            placeholder="Suma"
                            onChange={(e) => setGoldChange(e.target.value)}
                        />
                        <Input
                            value={goldChangeDescription}
                            className="m-l-20"
                            placeholder="Dôvod"
                            onChange={(e) => setGoldChangeDescription(e.target.value)}
                        />
                        <Button
                            color="link"
                            className="m-l-auto"
                            disabled={
                                isNaN(parseInt(goldChange)) ||
                                parseInt(goldChange) === 0 ||
                                goldProcessing
                            }
                            onClick={stealGold}
                        >
                            <i className="fa fa-check" />
                        </Button>
                    </div>
                )}
            </PopoverHeader>
            <PopoverBody className="overflow-x max-height-350 width-595">
                <div>
                    {EntireHistory.map((history) => {
                        if (history === null) {
                            return (
                                <div
                                    key="startOfHistory"
                                    className="text-bolder font-17 text-center"
                                >
                                    -----História----
                                </div>
                            );
                        }
                        if (history.stolen) {
                            if (!Dm) {
                                return null;
                            }
                            return (
                                <div key={history.id} className="gold-history">
                                    <div>
                                        <span className="m-r-5">
                                            [{timestampToPracticalDate(history.createdAt)}]
                                        </span>
                                        <span className="color-red m-r-5">Stratil/a</span>
                                        nevedomky
                                        <span className="color-gold m-l-5 text-bolder">
                                            {history.quantity}g
                                        </span>
                                        .
                                    </div>
                                    {history.reason.length > 0 && (
                                        <div>Dôvod: {history.reason}</div>
                                    )}
                                </div>
                            );
                        } else if (!history.transfer) {
                            return (
                                <div key={history.id} className="gold-history">
                                    <div>
                                        <span className="m-r-5">
                                            [{timestampToPracticalDate(history.createdAt)}]
                                        </span>
                                        {history.quantity < 0 && (
                                            <span className="color-red">Stratil/a</span>
                                        )}
                                        {history.quantity > 0 && (
                                            <span className="color-green">Ziskal/a</span>
                                        )}
                                        <span className="color-gold m-l-5 text-bolder">
                                            {history.quantity}g
                                        </span>
                                        .
                                    </div>
                                    <div>Dôvod: {history.reason}</div>
                                </div>
                            );
                        } else if (history.recieved) {
                            return (
                                <div key={history.id} className="gold-history">
                                    <span className="m-r-5">
                                        [{timestampToPracticalDate(history.createdAt)}]
                                    </span>
                                    <span className="color-green m-r-5">Dostal/a</span>
                                    od hráča
                                    <span
                                        className="m-l-5"
                                        style={{ color: history.transferedFrom.color }}
                                    >
                                        {history.transferedFrom.character}
                                    </span>
                                    <span className="color-gold m-l-5 text-bolder">
                                        {history.quantity}g
                                    </span>
                                    .
                                </div>
                            );
                        } else {
                            return (
                                <div key={history.id} className="gold-history">
                                    <span className="m-r-5">
                                        [{timestampToPracticalDate(history.createdAt)}]
                                    </span>
                                    <span className="color-red m-r-5">Daroval/a</span>
                                    hráčovi
                                    <span
                                        className="m-l-5"
                                        style={{ color: history.transferedTo.color }}
                                    >
                                        {history.transferedTo.character}
                                    </span>
                                    <span className="color-gold m-l-5 text-bolder">
                                        {history.quantity}g
                                    </span>
                                    .
                                </div>
                            );
                        }
                    })}
                    {EntireHistory.length === 0 && <div>História zlata je prázdna</div>}
                </div>
            </PopoverBody>
        </Popover>
    );
}
