import * as fns from 'date-fns';

const toDate = (date) => {
    if (typeof date === 'string' && !isNaN(parseInt(date))) {
        date = new Date(parseInt(date));
    } else if (typeof date === 'number' || date === 'string') {
        date = new Date(date);
    }
    if (isNaN(date.valueOf())) {
        return null;
    }
    return date;
};

export const timestampToString = (date, format = '') => {
    date = toDate(date);
    if (date === null) {
        return 'Not a date';
    }
    if (format) {
        return fns.format(date, format);
    }
    return fns.format(date, 'HH:mm dd.MM.y');
};

export const timestampToPracticalDate = (date) => {
    date = toDate(date);
    if (date === null) {
        return 'Not a date';
    }
    if (fns.isSameDay(date, new Date())) {
        return fns.format(date, 'HH:mm');
    }
    return fns.format(date, 'HH:mm dd.MM');
};

export const afterNow = (unix) => {
    return unix > fns.getUnixTime(new Date());
};
