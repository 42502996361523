import { gql } from '@apollo/client';

export const LOOT_MANAGER_LOGS = gql`
    query lootManagerLogs {
        lootManagerLogs {
            id
            createdAt
            message
        }
    }
`;

export const CLEAR_ALL_LOOT_MANAGER_LOGS = gql`
    mutation clearAllLootManagerLogs {
        clearAllLootManagerLogs
    }
`;

export const LOOT_MANAGER_LOGS_SUBSCRIPTION = gql`
    subscription lootManagerLogsSubscription {
        lootManagerLogsSubscription
    }
`;
