import React from 'react';
import { Popover, FormGroup, Label, Button, PopoverHeader, Row, Col } from 'reactstrap';
import { Checkbox, Empty } from 'components';
import Select from 'react-select';
import { getEquipped, getEquippedItem, GetMyRights, toSelArr } from 'helperFunctions';

import {
    ADD_RUNE,
    EQUIP_ITEM,
    ITEMS,
    ITEMS_SUBSCRIPTION,
    REMOVE_RUNE,
    UNEQUIP_ITEM,
} from 'queries';
import { useMutation, useQuery, useSubscription } from '@apollo/client';

const removeRuneOption = {
    id: null,
    value: null,
    label: 'Odstrániť runu',
};

const getEquippedCount = (Item) => {
    if (!Item.equipped) {
        return 0;
    }
    if (Item.type.id === 3) {
        return Item.equippedSlots.length;
    } else {
        return 1;
    }
};

export default function EquipItem(props) {
    const { cleanup, addToCleanup, Item, uniqueId, Inventory } = props;
    const { AdminOrDm } = GetMyRights();
    const [unequipping, setUnequipping] = React.useState(false);
    const [variant, setVariant] = React.useState(null);

    const [equippingRunes, setEquippingRunes] = React.useState(false);
    const [rune, setRune] = React.useState(removeRuneOption);
    const [allRunes, setAllRunes] = React.useState(false);

    const [processing, setProcessing] = React.useState(false);
    const [equipItemMutation] = useMutation(EQUIP_ITEM);
    const [unequipItemMutation] = useMutation(UNEQUIP_ITEM);
    const [addRuneMutation] = useMutation(ADD_RUNE);
    const [removeRuneMutation] = useMutation(REMOVE_RUNE);

    const {
        data: itemsData,
        loading: itemsLoading,
        refetch: itemsRefetch,
    } = useQuery(ITEMS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(ITEMS_SUBSCRIPTION, {
        onData: () => {
            itemsRefetch();
        },
    });

    const AllRunes = itemsLoading ? [] : itemsData.items.filter((Item) => Item.type.id === 4);
    const InventoryRunes = Inventory.filter((Item) => Item.type.id === 4);

    React.useEffect(() => {
        addToCleanup({
            id: 'equip',
            cleanup: () => {
                setUnequipping(false);
                setVariant(null);
                setEquippingRunes(false);
                setRune(removeRuneOption);
                setAllRunes(false);
            },
        });
        // eslint-disable-next-line
    }, []);

    if (Item === null) {
        return null;
    }

    const equipItem = (slot = null) => {
        setProcessing(true);
        let body = { id: Item.id };
        if (Item.type.id === 2) {
            body.variantId = variant.id;
        }
        if (Item.type.id === 3) {
            body.slot = slot;
        }
        equipItemMutation({ variables: body })
            .then(() => {
                setProcessing(false);
                setEquippingRunes(false);
                setVariant(null);
            })
            .catch((e) => {
                console.log(e);
                setProcessing(false);
            });
    };

    const unequipItem = (slot = null) => {
        let body = { id: Item.id };
        if (Item.type.id === 3) {
            body.slot = slot;
        }
        unequipItemMutation({ variables: body })
            .then(() => {
                setProcessing(false);
            })
            .catch((e) => {
                console.log(e);
                setProcessing(false);
            });
    };

    const addRune = async (slot, variantId) => {
        setProcessing(true);
        let body = {
            weaponId: Item.id,
            runeId: rune.id,
            slot,
            free: allRunes,
            variantId,
        };
        addRuneMutation({ variables: body })
            .then(() => {
                setProcessing(false);
            })
            .catch((e) => {
                console.log(e);
                setProcessing(false);
            });
    };

    const removeRune = async (slot, variantId) => {
        setProcessing(true);
        let body = {
            slot,
            variantId,
        };

        removeRuneMutation({ variables: body })
            .then(() => {
                setProcessing(false);
            })
            .catch((e) => {
                console.log(e);
                setProcessing(false);
            });
    };

    const getVariantsSelect = () => {
        let variants = [];
        let equippedVariant = Item.equipped
            ? Item.variants.find((equippedVariant) => equippedVariant.equipped)
            : null;
        if (Item.quantity > Item.variants.length) {
            variants.push({
                id: null,
                equipped: Item.equipped && !equippedVariant,
                value: null,
                label:
                    Item.equipped && !equippedVariant
                        ? `Základná zbraň (vybavená)`
                        : `Základná zbraň`,
                rune1: null,
                rune2: null,
                rune3: null,
            });
        }
        Item.variants.forEach((Variant) => {
            const variantTitle = `${Variant.rune1 ? Variant.rune1.title : 'Bez runy'},${
                Variant.rune2 ? Variant.rune2.title : 'bez runy'
            },${Variant.rune3 ? Variant.rune3.title : 'bez runy'}`;
            variants.push({
                ...Variant,
                value: Variant.id,
                label: Variant.equipped ? `${variantTitle} (vybavená)` : variantTitle,
            });
        });
        return variants;
    };
    const Equipped = getEquipped(Inventory);
    const typeId = Item?.type?.id;

    let equippedItem = getEquippedItem(Equipped, typeId);
    if (equippedItem?.type?.id !== typeId) {
        equippedItem = null;
    }
    const Potions = () => (
        <Empty>
            <Row>
                <Col>
                    <Button
                        className="m-l-10 m-r-10 m-t-10 m-b-10"
                        color="success"
                        disabled={
                            (!unequipping && getEquippedCount(Item) >= Item.quantity) ||
                            (unequipping && Equipped.potions.p1 === null)
                        }
                        onClick={() => {
                            if (unequipping) {
                                unequipItem(1);
                            } else {
                                equipItem(1);
                            }
                        }}
                    >
                        {Equipped.potions.p1
                            ? `Elixír 1L ${Equipped.potions.p1.title}`
                            : `Vybaviť na Elixír 1L`}
                    </Button>
                </Col>
                <Col>
                    <Button
                        className="m-l-10 m-r-10 m-t-10 m-b-10"
                        color="success"
                        disabled={
                            (!unequipping && getEquippedCount(Item) >= Item.quantity) ||
                            (unequipping && Equipped.potions.p2 === null)
                        }
                        onClick={() => {
                            if (unequipping) {
                                unequipItem(2);
                            } else {
                                equipItem(2);
                            }
                        }}
                    >
                        {Equipped.potions.p2
                            ? `Elixír 1P ${Equipped.potions.p2.title}`
                            : `Vybaviť na Elixír 1P`}
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button
                        className="m-l-10 m-r-10 m-t-10 m-b-10"
                        color="success"
                        disabled={
                            (!unequipping && getEquippedCount(Item) >= Item.quantity) ||
                            (unequipping && Equipped.potions.p3 === null)
                        }
                        onClick={() => {
                            if (unequipping) {
                                unequipItem(3);
                            } else {
                                equipItem(3);
                            }
                        }}
                    >
                        {Equipped.potions.p3
                            ? `Elixír 2L ${Equipped.potions.p3.title}`
                            : `Vybaviť na Elixír 2L`}
                    </Button>
                </Col>
                <Col>
                    <Button
                        className="m-l-10 m-r-10 m-t-10 m-b-10"
                        color="success"
                        disabled={
                            (!unequipping && getEquippedCount(Item) >= Item.quantity) ||
                            (unequipping && Equipped.potions.p4 === null)
                        }
                        onClick={() => {
                            if (unequipping) {
                                unequipItem(4);
                            } else {
                                equipItem(4);
                            }
                        }}
                    >
                        {Equipped.potions.p4
                            ? `Elixír 2P ${Equipped.potions.p4.title}`
                            : `Vybaviť na Elixír 2P`}
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button
                        className="m-l-10 m-r-10 m-t-10 m-b-10"
                        color="success"
                        disabled={
                            (!unequipping && getEquippedCount(Item) >= Item.quantity) ||
                            (unequipping && Equipped.potions.p5 === null)
                        }
                        onClick={() => {
                            if (unequipping) {
                                unequipItem(5);
                            } else {
                                equipItem(5);
                            }
                        }}
                    >
                        {Equipped.potions.p5
                            ? `Elixír 3L ${Equipped.potions.p5.title}`
                            : `Vybaviť na Elixír 3L`}
                    </Button>
                </Col>
                <Col>
                    <Button
                        className="m-l-10 m-r-10 m-t-10 m-b-10"
                        color="success"
                        disabled={
                            (!unequipping && getEquippedCount(Item) >= Item.quantity) ||
                            (unequipping && Equipped.potions.p6 === null)
                        }
                        onClick={() => {
                            if (unequipping) {
                                unequipItem(6);
                            } else {
                                equipItem(6);
                            }
                        }}
                    >
                        {Equipped.potions.p6
                            ? `Elixír 3P ${Equipped.potions.p6.title}`
                            : `Vybaviť na Elixír 3P`}
                    </Button>
                </Col>
            </Row>
        </Empty>
    );

    const EquippingRunes = () => (
        <Empty>
            {getVariantsSelect().map((variant, index) => (
                <div key={index} className="thin-buttons m-b-10">
                    <span>{`V.${index + 1}${variant.equipped ? '(v ruke)' : ''}`}</span>
                    <Button
                        color={variant.rune1 === null ? 'secondary' : 'info'}
                        className="m-l-10"
                        disabled={rune.value === null && variant.rune1 === null}
                        onClick={() =>
                            rune.value === null ? removeRune(1, variant.id) : addRune(1, variant.id)
                        }
                    >
                        R.1 ({variant.rune1 === null ? 'Prázdne' : variant.rune1.title})
                    </Button>
                    <Button
                        color={variant.rune2 === null ? 'secondary' : 'info'}
                        className="m-l-10"
                        disabled={rune.value === null && variant.rune2 === null}
                        onClick={() =>
                            rune.value === null ? removeRune(2, variant.id) : addRune(2, variant.id)
                        }
                    >
                        R.2 ({variant.rune2 === null ? 'Prázdne' : variant.rune2.title})
                    </Button>
                    <Button
                        color={variant.rune3 === null ? 'secondary' : 'info'}
                        className="m-l-10"
                        disabled={rune.value === null && variant.rune3 === null}
                        onClick={() =>
                            rune.value === null ? removeRune(3, variant.id) : addRune(3, variant.id)
                        }
                    >
                        R.3 ({variant.rune3 === null ? 'Prázdne' : variant.rune3.title})
                    </Button>
                </div>
            ))}
            <div className="flex-row">
                <FormGroup className="flex">
                    <Label>Runa:</Label>
                    <Select
                        value={rune}
                        options={
                            allRunes
                                ? [removeRuneOption, ...toSelArr(AllRunes)]
                                : [removeRuneOption, ...toSelArr(InventoryRunes)]
                        }
                        placeholder="Runa"
                        onChange={(rune) => {
                            setRune(rune);
                        }}
                    />
                </FormGroup>
                <Checkbox
                    className="m-l-10"
                    label="Všetky runy? (neodoberajú sa z inventára)"
                    value={allRunes}
                    right
                    centerHor
                    onChange={() => {
                        setAllRunes(!allRunes);
                        setRune(removeRuneOption);
                    }}
                />
            </div>
        </Empty>
    );

    const Variant = () => (
        <FormGroup>
            <Label>Variant</Label>
            <Select
                value={variant}
                options={getVariantsSelect()}
                placeholder="Variant zbrane"
                onChange={(variant) => {
                    setVariant(variant);
                }}
            />
        </FormGroup>
    );

    return (
        <Popover
            className="popover-600"
            placement="right"
            isOpen={![null, undefined].includes(Item)}
            target={`inventory-table-${uniqueId}`}
            toggle={() => {}}
        >
            <PopoverHeader>
                {`Vybaviť ${Item.type.inventoryLabel}: ${Item.title} (${Item.quantity}ks)`}
            </PopoverHeader>
            {Item && (
                <div className="p-20">
                    {typeId === 3 && Potions()}
                    {typeId === 2 && equippingRunes && EquippingRunes()}
                    {typeId === 2 && Variant()}
                    <div className="flex-row thin-buttons m-t-20">
                        {[2, 5, 6, 7, 8, 9, 12].includes(typeId) && (
                            <Empty>
                                <Button
                                    color="success"
                                    disabled={processing || (typeId === 2 && variant === null)}
                                    onClick={equipItem}
                                >
                                    {processing
                                        ? 'Vybavuje sa...'
                                        : equippedItem
                                        ? `Nahradiť ${equippedItem.title}`
                                        : 'Vybaviť'}
                                </Button>
                                {Item.equipped && (
                                    <Button
                                        color="danger"
                                        className="m-l-auto"
                                        disabled={processing}
                                        onClick={unequipItem}
                                    >
                                        Odložiť
                                    </Button>
                                )}
                                {typeId === 2 && AdminOrDm && (
                                    <Button
                                        color="success"
                                        className="m-l-auto"
                                        onClick={() => setEquippingRunes(!equippingRunes)}
                                    >
                                        Spravovať runy
                                    </Button>
                                )}
                            </Empty>
                        )}
                        {typeId === 3 && (
                            <Button
                                color="danger"
                                className="m-l-10"
                                disabled={processing}
                                onClick={() => setUnequipping(!unequipping)}
                            >
                                {unequipping ? `Ukončiť odkladanie` : `Odložiť do inventára`}
                            </Button>
                        )}

                        <Button color="warning" className="m-l-auto" onClick={cleanup}>
                            Zavrieť
                        </Button>
                    </div>
                </div>
            )}
        </Popover>
    );
}
