import React from 'react';
import { Tooltip } from 'components';
import { Button, Card, CardHeader, CardBody } from 'reactstrap';
import classnames from 'classnames';
import { GET_LIBRARY_OPEN } from 'apollo/queries';
import { useQuery, useSubscription } from '@apollo/client';
import { setShowLibraryList } from 'apollo/localSchema/actions';
import { LIBRARY_ENTRIES_SUBSCRIPTION, LIBRARY_ENTRY } from 'queries';
import LoadingEntry from './loading';

export default function LibraryEntry({ id }) {
    const { data: libraryOpenData } = useQuery(GET_LIBRARY_OPEN);
    const ShowLibraryList = libraryOpenData.ShowLibraryList;

    const {
        data: libraryData,
        loading: libraryLoading,
        refetch: libraryRefetch,
    } = useQuery(LIBRARY_ENTRY, {
        fetchPolicy: 'network-only',
        variables: {
            id,
        },
    });

    useSubscription(LIBRARY_ENTRIES_SUBSCRIPTION, {
        onData: () => {
            libraryRefetch({ variables: { id } });
        },
    });
    const LibraryEntry = libraryLoading ? null : libraryData.libraryEntry;

    if (libraryLoading) {
        return <LoadingEntry />;
    }

    return (
        <Card className="library-right">
            <CardHeader>
                <Button color="link" onClick={setShowLibraryList} id="show-hide-library-list">
                    <i
                        className={classnames({
                            'fa fa-arrow-left color-blue': ShowLibraryList,
                            'fa fa-arrow-right color-blue': !ShowLibraryList,
                        })}
                    />
                    <i className="m-l-2 fa fa-book-atlas color-blue" />
                </Button>
                <Tooltip
                    placement="top"
                    target="show-hide-library-list"
                    text={ShowLibraryList ? 'Schovať knižnicu' : 'Zobraziť knižnicu'}
                />
                {`${LibraryEntry.title} (${LibraryEntry.category.title})`}
            </CardHeader>
            <CardBody className="p-20">
                <div className="text-italics">{LibraryEntry.description}</div>
                <div dangerouslySetInnerHTML={{ __html: LibraryEntry.text }} />
            </CardBody>
        </Card>
    );
}
