import React from 'react';
import { Card, CardHeader, CardBody, Button, FormGroup, Label, Col, Input } from 'reactstrap';
import { Checkbox, ImageInput } from 'components';
import Textarea from 'react-textarea-autosize';
import Select from 'react-select';

import { toBase64, toSelArr, updateArrayItem } from 'helperFunctions';
import {
    DELETE_RECIPE,
    ITEMS,
    ITEMS_SUBSCRIPTION,
    ITEM_TYPES,
    RECIPE,
    UPDATE_RECIPE,
} from 'queries';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
const allTypes = { id: null, value: null, label: 'Všetky' };

export default function RecipeEdit(props) {
    const { recipeID } = props;

    const [title, setTitle] = React.useState('');
    const [order, setOrder] = React.useState(0);
    const [description, setDescription] = React.useState('');
    const [visibility, setVisibility] = React.useState(false);
    const [ingredients, setIngredients] = React.useState([]);
    const [tools, setTools] = React.useState([]);
    const [results, setResults] = React.useState([]);

    const [icon, setIcon] = React.useState(null);

    const [saving, setSaving] = React.useState(false);
    const [ingredientCategory, setIngredientCategory] = React.useState(allTypes);
    const [toolCategory, setToolCategory] = React.useState(allTypes);
    const [resultCategory, setResultCategory] = React.useState(allTypes);
    const [selectedIngredient, setSelectedIngredient] = React.useState(null);
    const [selectedTool, setSelectedTool] = React.useState(null);
    const [selectedResult, setSelectedResult] = React.useState(null);
    const [ingredientQuantity, setIngredientQuantity] = React.useState(1);
    const [toolQuantity, setToolQuantity] = React.useState(1);
    const [resultQuantity, setResultQuantity] = React.useState(1);

    const [updateRecipe] = useMutation(UPDATE_RECIPE);
    const [deleteRecipe] = useMutation(DELETE_RECIPE);
    //load items
    const {
        data: itemsData,
        loading: itemsLoading,
        refetch: itemsRefetch,
    } = useQuery(ITEMS, {
        fetchPolicy: 'network-only',
    });

    const Items = itemsLoading ? [] : itemsData.items;

    const { data: itemTypesData, loading: itemTypesLoading } = useQuery(ITEM_TYPES, {
        fetchPolicy: 'network-only',
    });

    const itemTypes = itemTypesLoading ? [] : itemTypesData.itemTypes;

    const allItemTypes = itemTypesLoading ? [allTypes] : [allTypes, ...itemTypesData.itemTypes];

    const groupItems = (Items) => {
        let GroupedItems = (itemTypesLoading ? [] : itemTypesData.itemTypes).map((group) => ({
            ...group,
            Items: [],
        }));
        Items.forEach((Item) => {
            const Index = GroupedItems.findIndex((Group) => Group.id === Item.type.id);
            GroupedItems[Index].Items.push(Item);
        });
        return GroupedItems;
    };

    const GroupedItems = groupItems(Items);

    const {
        data: recipeData,
        loading: recipeLoading,
        refetch: recipeRefetch,
    } = useQuery(RECIPE, {
        fetchPolicy: 'network-only',
        variables: {
            id: recipeID,
        },
    });

    useSubscription(ITEMS_SUBSCRIPTION, {
        onData: () => {
            itemsRefetch();
            recipeRefetch();
        },
    });

    const Recipe = recipeLoading ? null : recipeData.recipe;

    React.useEffect(() => {
        if (!recipeLoading && !itemTypesLoading) {
            setTitle(Recipe.title);
            setOrder(Recipe.order);
            setDescription(Recipe.description);
            setVisibility(Recipe.visibility);

            setIngredients(
                toSelArr(
                    Recipe.ingredients.map((ingredient) => ({
                        ...ingredient,
                        type: itemTypes.find((itemType) => itemType.id === ingredient.type),
                        item: {
                            ...ingredient,
                        },
                    }))
                )
            );
            setTools(
                toSelArr(
                    Recipe.tools.map((tool) => ({
                        ...tool,
                        type: itemTypes.find((itemType) => itemType.id === tool.type),
                        item: {
                            ...tool,
                        },
                    }))
                )
            );
            setResults(
                toSelArr(
                    Recipe.results.map((result) => ({
                        ...result,
                        type: itemTypes.find((itemType) => itemType.id === result.type),
                        item: {
                            ...result,
                        },
                    }))
                )
            );

            setIngredientCategory(allTypes);
            setToolCategory(allTypes);
            setResultCategory(allTypes);
            setSelectedIngredient(null);
            setSelectedTool(null);
            setSelectedResult(null);
            setIngredientQuantity(1);
            setToolQuantity(1);
            setResultQuantity(1);
        }
        // eslint-disable-next-line
    }, [Recipe, itemTypes]);

    const save = async () => {
        setSaving(true);
        let body = {
            id: recipeID,
            title,
            order: isNaN(parseFloat(order)) ? 0 : parseFloat(order),
            description,
            visibility,
            ingredients: ingredients
                .filter((ingredient) => ingredient.quantity > 0)
                .map((ingredient) => ({
                    quantity: ingredient.quantity,
                    itemId: ingredient.itemId,
                })),
            tools: tools
                .filter((tool) => tool.quantity > 0)
                .map((tool) => ({ quantity: tool.quantity, itemId: tool.itemId })),
            results: results
                .filter((result) => result.quantity > 0)
                .map((result) => ({ quantity: result.quantity, itemId: result.itemId })),
        };
        if (icon) {
            body.icon = await toBase64(icon);
        }
        updateRecipe({ variables: body })
            .then(() => {
                //update STATE images
                setSaving(false);
                setIcon(null);
            })
            .catch((e) => {
                setSaving(false);
                console.log(e);
            });
    };

    const deleteFunc = async () => {
        if (window.confirm(`Si si istý že chceš zmazať recept ${title}?`)) {
            deleteRecipe({ variables: { id: recipeID } });
        }
    };

    return (
        <div className="settings-right">
            <Card>
                <CardHeader className="flex-row">Nový recept</CardHeader>
                <CardBody>
                    <FormGroup className="flex-row">
                        <Label for="occupation" sm={2}>
                            Názov
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="occupation"
                                value={title}
                                placeholder="Zadaj názov receptu"
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="order" sm={2}>
                            Poradie
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="order"
                                value={order}
                                placeholder="Zadaj poradie/silu receptu"
                                onChange={(e) => setOrder(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="description" sm={2}>
                            Popis
                        </Label>
                        <Col sm={10}>
                            <Textarea
                                className="form-control"
                                id="description"
                                placeholder="Zadaj popis receptu"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Checkbox
                            className="form-control-checkbox"
                            label="Spoznali recept"
                            value={visibility}
                            right
                            centerHor
                            onChange={() => setVisibility(!visibility)}
                        />
                    </FormGroup>
                    <ImageInput
                        setImage={setIcon}
                        image={icon}
                        id={`recipeIcon${recipeID}`}
                        label="Ikona"
                        placeholder="Vyberte ikonu"
                        original={Recipe?.icon}
                        height={170}
                    />
                    <hr />
                    <div className="flex-row">
                        <Label className="m-t-30 m-r-20 width-100">Ingrediencie</Label>
                        <FormGroup className=" min-width-250">
                            <Label>Kategoria</Label>
                            <Select
                                value={ingredientCategory}
                                options={allItemTypes}
                                placeholder="Kategória"
                                onChange={(ingredientCategory) => {
                                    setIngredientCategory(ingredientCategory);
                                }}
                            />
                        </FormGroup>
                        <FormGroup className="m-l-20 min-width-250">
                            <Label>Vec</Label>
                            <Select
                                value={selectedIngredient}
                                options={toSelArr(
                                    ingredientCategory.id !== null
                                        ? GroupedItems[ingredientCategory.id]
                                        : Items
                                )}
                                placeholder="Vec"
                                onChange={(selectedIngredient) => {
                                    setSelectedIngredient(selectedIngredient);
                                }}
                            />
                        </FormGroup>
                        <FormGroup className="m-l-20 min-width-50">
                            <Label htmlFor="selectedIngredientQuanity">Kvantita</Label>
                            <Input
                                type="number"
                                id="selectedIngredientQuanity"
                                value={ingredientQuantity}
                                placeholder="Zadaj kvantitu"
                                onChange={(e) => setIngredientQuantity(e.target.value)}
                            />
                        </FormGroup>
                        <Button
                            color="success"
                            className="max-height-45 center-hor m-l-20 width-170"
                            disabled={
                                isNaN(parseInt(ingredientQuantity)) || selectedIngredient === null
                            }
                            onClick={() => {
                                if (
                                    ingredients.some(
                                        (Ingredient) => Ingredient.itemId === selectedIngredient.id
                                    )
                                ) {
                                    setIngredients(
                                        updateArrayItem(
                                            ingredients,
                                            {
                                                quantity:
                                                    parseInt(ingredientQuantity) +
                                                    ingredients.find(
                                                        (Ingredient) =>
                                                            Ingredient.itemId ===
                                                            selectedIngredient.id
                                                    ).quantity,
                                                itemId: selectedIngredient.id,
                                                item: selectedIngredient,
                                            },
                                            'itemId'
                                        )
                                    );
                                } else {
                                    setIngredients([
                                        ...ingredients,
                                        {
                                            quantity: parseInt(ingredientQuantity),
                                            itemId: selectedIngredient.id,
                                            item: selectedIngredient,
                                        },
                                    ]);
                                }
                                setSelectedIngredient(null);
                                setIngredientQuantity(1);
                            }}
                        >
                            Pridať ingredienciu
                        </Button>
                        <Button
                            color="danger"
                            className="max-height-45 center-hor m-l-20 width-270"
                            disabled={ingredients.length === 0}
                            onClick={() => {
                                if (window.confirm('Vyčistiť všetky ingrediencie ?')) {
                                    setIngredients([]);
                                    setSelectedIngredient(null);
                                    setIngredientQuantity(1);
                                }
                            }}
                        >
                            Vyčistiť všetky ingrediencie
                        </Button>
                    </div>
                    <div className="flex-row">
                        <Label className="m-t-30 m-r-20 width-100">Nástroje</Label>
                        <FormGroup className=" min-width-250">
                            <Label>Kategoria</Label>
                            <Select
                                value={toolCategory}
                                options={allItemTypes}
                                placeholder="Kategória"
                                onChange={(toolCategory) => {
                                    setToolCategory(toolCategory);
                                }}
                            />
                        </FormGroup>
                        <FormGroup className="m-l-20 min-width-250">
                            <Label>Vec</Label>
                            <Select
                                value={selectedTool}
                                options={toSelArr(
                                    toolCategory.id !== null ? GroupedItems[toolCategory.id] : Items
                                )}
                                placeholder="Vec"
                                onChange={(selectedTool) => {
                                    setSelectedTool(selectedTool);
                                }}
                            />
                        </FormGroup>
                        <FormGroup className="m-l-20 min-width-50">
                            <Label htmlFor="selectedToolQuanity">Kvantita</Label>
                            <Input
                                type="number"
                                id="selectedToolQuanity"
                                value={toolQuantity}
                                placeholder="Zadaj kvantitu"
                                onChange={(e) => setToolQuantity(e.target.value)}
                            />
                        </FormGroup>

                        <Button
                            color="success"
                            className="max-height-45 center-hor m-l-20 width-170"
                            disabled={isNaN(parseInt(toolQuantity)) || selectedTool === null}
                            onClick={() => {
                                if (tools.some((Tool) => Tool.itemId === selectedTool.id)) {
                                    setTools(
                                        updateArrayItem(
                                            tools,
                                            {
                                                quantity:
                                                    parseInt(toolQuantity) +
                                                    tools.find(
                                                        (Tool) => Tool.itemId === selectedTool.id
                                                    ).quantity,
                                                itemId: selectedTool.id,
                                                item: selectedTool,
                                            },
                                            'itemId'
                                        )
                                    );
                                } else {
                                    setTools([
                                        ...tools,
                                        {
                                            quantity: parseInt(toolQuantity),
                                            itemId: selectedTool.id,
                                            item: selectedTool,
                                        },
                                    ]);
                                }
                                setSelectedTool(null);
                                setToolQuantity(1);
                            }}
                        >
                            Pridať nástroj
                        </Button>
                        <Button
                            color="danger"
                            className="max-height-45 center-hor m-l-20 width-270"
                            disabled={tools.length === 0}
                            onClick={() => {
                                if (window.confirm('Vyčistiť všetky ingrediencie ?')) {
                                    setTools([]);
                                    setSelectedTool(null);
                                    setToolQuantity(1);
                                }
                            }}
                        >
                            Vyčistiť všetky nástroje
                        </Button>
                    </div>
                    <div className="flex-row">
                        <Label className="m-t-30 m-r-20 width-100">Výsledky</Label>
                        <FormGroup className=" min-width-250">
                            <Label>Kategoria</Label>
                            <Select
                                value={resultCategory}
                                options={allItemTypes}
                                placeholder="Kategória"
                                onChange={(resultCategory) => {
                                    setResultCategory(resultCategory);
                                }}
                            />
                        </FormGroup>
                        <FormGroup className="m-l-20 min-width-250">
                            <Label>Vec</Label>
                            <Select
                                value={selectedResult}
                                options={toSelArr(
                                    (resultCategory.id !== null
                                        ? GroupedItems[resultCategory.id]
                                        : Items
                                    ).filter(
                                        (Item) =>
                                            !results.some((result) => result.itemId === Item.id)
                                    )
                                )}
                                placeholder="Vec"
                                onChange={(selectedResult) => {
                                    setSelectedResult(selectedResult);
                                }}
                            />
                        </FormGroup>
                        <FormGroup className="m-l-20 min-width-50">
                            <Label htmlFor="selectedResultQuanity">Kvantita</Label>
                            <Input
                                type="number"
                                id="selectedResultQuanity"
                                value={resultQuantity}
                                placeholder="Zadaj kvantitu"
                                onChange={(e) => setResultQuantity(e.target.value)}
                            />
                        </FormGroup>
                        <Button
                            color="success"
                            className="max-height-45 center-hor m-l-20 width-170"
                            disabled={isNaN(parseInt(resultQuantity)) || selectedResult === null}
                            onClick={() => {
                                if (results.some((Result) => Result.itemId === selectedResult.id)) {
                                    setResults(
                                        updateArrayItem(
                                            results,
                                            {
                                                quantity:
                                                    parseInt(resultQuantity) +
                                                    results.find(
                                                        (Result) =>
                                                            Result.itemId === selectedResult.id
                                                    ).quantity,
                                                itemId: selectedResult.id,
                                                item: selectedResult,
                                            },
                                            'itemId'
                                        )
                                    );
                                } else {
                                    setResults([
                                        ...results,
                                        {
                                            quantity: parseInt(resultQuantity),
                                            itemId: selectedResult.id,
                                            item: selectedResult,
                                        },
                                    ]);
                                }
                                setSelectedResult(null);
                                setResultQuantity(1);
                            }}
                        >
                            Pridať výsledok
                        </Button>
                        <Button
                            color="danger"
                            className="max-height-45 center-hor m-l-20 width-270"
                            disabled={results.length === 0}
                            onClick={() => {
                                if (window.confirm('Vyčistiť všetky výsledky?')) {
                                    setResults([]);
                                    setSelectedResult(null);
                                    setResultQuantity(1);
                                }
                            }}
                        >
                            Vyčistiť všetky výsledky
                        </Button>
                    </div>
                    <div>
                        {`
                        ${ingredients
                            .map(
                                (ingredient) => `${ingredient.quantity} x ${ingredient.item.title}`
                            )
                            .join(' + ')}
                        with
                        ${
                            tools.length === 0
                                ? 'nothing'
                                : tools
                                      .map((tool) => `${tool.quantity} x ${tool.item.title}`)
                                      .join(', ')
                        } =
                        ${results
                            .map((result) => `${result.quantity} x ${result.item.title}`)
                            .join(', ')}
                        `}
                    </div>
                    <hr />
                    <div className="flex-row">
                        <Button
                            color="primary"
                            disabled={saving || ingredients.length === 0 || results.length === 0}
                            onClick={save}
                        >
                            {saving ? 'Ukladá sa...' : 'Uložiť recept'}
                        </Button>
                        <Button color="danger" className="m-l-auto" onClick={deleteFunc}>
                            Vymazať
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
