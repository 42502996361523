import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { useNavigate } from 'react-router';

export default function RecipeList({ fixedShow, setFixedShow, type, id, Recipes }) {
    const navigate = useNavigate();

    return (
        <ListGroup className="library-list-categories">
            <ListGroupItem
                active={fixedShow.includes('recipes')}
                action
                className="clickable m-t-10"
                onClick={() => {
                    if (fixedShow.includes('recipes')) {
                        setFixedShow(fixedShow.filter((title) => title !== 'recipes'));
                    } else {
                        setFixedShow([...fixedShow, 'recipes']);
                    }
                }}
            >
                Recepty
            </ListGroupItem>
            {fixedShow.includes('recipes') && (
                <ListGroup className="m-l-20 library-list-entries">
                    {Recipes.map((Entry) => (
                        <ListGroupItem
                            key={Entry.id}
                            active={type === 'recipes' && id === Entry.id}
                            action
                            className="clickable"
                            onClick={() => {
                                navigate(`/library/recipes/${Entry.id}`);
                            }}
                        >
                            {Entry.title}
                        </ListGroupItem>
                    ))}
                    {Recipes.length === 0 && (
                        <ListGroupItem className="noselect">Polička prázdna :c</ListGroupItem>
                    )}
                </ListGroup>
            )}
        </ListGroup>
    );
}
