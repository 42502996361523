import { gql } from '@apollo/client';

export const LOOT_MANAGER_GOLDS = gql`
    query lootManagerGolds {
        lootManagerGolds {
            id
            createdAt
            updatedAt
            quantity
        }
    }
`;

export const ADD_LOOT_MANAGER_GOLD = gql`
    mutation addLootManagerGold($gold: Int!) {
        addLootManagerGold(gold: $gold) {
            id
            createdAt
            updatedAt
            quantity
        }
    }
`;

export const REMOVE_LOOT_MANAGER_GOLD = gql`
    mutation removeLootManagerGold($id: Int!) {
        removeLootManagerGold(id: $id) {
            id
            createdAt
            updatedAt
            quantity
        }
    }
`;

export const SPLIT_ALL_GOLD = gql`
    mutation splitAllGold {
        splitAllGold {
            id
            createdAt
            updatedAt
            quantity
        }
    }
`;

export const LOOT_MANAGER_GOLDS_SUBSCRIPTION = gql`
    subscription lootManagerGoldsSubscription {
        lootManagerGoldsSubscription
    }
`;
