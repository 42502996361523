import React from 'react';
import { noItemImage } from 'configs/constants';
import { Tooltip } from './tooltip';
import { Button, Modal } from 'reactstrap';
import { GetMyRights, GetSettings, replaceFromString, drawOnImage } from 'helperFunctions';
import classnames from 'classnames';
import { IMAGE_SUBSCRIPTION } from 'queries';
import { makeVar, useReactiveVar, useSubscription } from '@apollo/client';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { Empty, Canvas } from 'components';
import { REST_URL } from 'configs/restAPI';
import { updateImageId } from 'apollo/localSchema/actions';
import { GetImageId } from 'apollo/localSchema/resolvers';

export const Image = (props) => {
    let {
        path,
        url,
        width,
        maxWidth,
        height,
        bottom,
        focus,
        className,
        inFocus,
        customFocus,
        focusDimensions,
        focusPlacement,
        fillEmpty,
        hideEmpty,
        showFull,
        onClick,
        strech,
        drawing,
        customModal,
        style,
        small,
    } = props;

    const [image, setImage] = React.useState(null);
    const [ID] = React.useState(Math.floor(Math.random() * 100000));
    const [openModal, setOpenModal] = React.useState(false);
    const [startDrawing, setStartDrawing] = React.useState(false);
    const { AdminOrDm } = GetMyRights();
    const { gamePaused, gameArea } = GetSettings();

    const currentURL = path ? path : url;
    const idVar = GetImageId(currentURL);
    const imageId = useReactiveVar(idVar ? idVar : makeVar(1));

    const fullURL = url
        ? url
        : path
        ? `${REST_URL}/${path}${small ? '-small.png' : '.png'}?${imageId}`
        : null;

    useSubscription(IMAGE_SUBSCRIPTION, {
        variables: {
            path: currentURL,
        },
        shouldResubscribe: true,
        onData: () => {
            updateImageId(path);
        },
    });

    React.useEffect(() => {
        if (drawing) {
            drawOnImage(fullURL, JSON.parse(gameArea.extra).drawing).then((data) => setImage(data));
        } else {
            setImage(fullURL);
        }
        // eslint-disable-next-line
    }, [fullURL]);

    React.useEffect(() => {
        if (drawing) {
            drawOnImage(fullURL, JSON.parse(gameArea.extra).drawing).then((data) => setImage(data));
        }
        // eslint-disable-next-line
    }, [gameArea.extra]);

    if (image === null) {
        if (hideEmpty) {
            return null;
        }
        return (
            <div
                className={`generic-image-container ${className ? className : ''}`}
                style={{
                    ...(width ? { width } : {}),
                    ...(maxWidth ? { maxWidth } : {}),
                    ...(height ? { height } : {}),
                    ...style,
                }}
                id={`generic-image-${ID}`}
            >
                {!fillEmpty && (
                    <img
                        className={classnames({
                            'clickable': onClick,
                        })}
                        alt="default-img"
                        src={noItemImage}
                        onClick={onClick ? onClick : () => {}}
                    />
                )}
                {focus && customFocus && (
                    <Tooltip
                        placement={focusPlacement ? focusPlacement : 'top'}
                        target={`generic-image-${ID}`}
                        text={customFocus}
                    />
                )}
            </div>
        );
    }

    const complexID = `generic-image-${replaceFromString(
        currentURL.substring(currentURL.length - 12),
        ['/', '.', ':', ' ', '(', ')'],
        '-'
    )}-${ID}`;

    const imageStyle = {
        ...(width ? { width } : {}),
        ...(height ? { height } : {}),
        ...(maxWidth ? { maxWidth } : {}),
        ...(inFocus
            ? { backgroundImage: 'url("https://images2.imgbox.com/41/cf/xUWlojlm_o.png")' }
            : {}),
        ...style,
    };

    return (
        <div
            className={`generic-image-container ${className ? className : ''} ${
                bottom ? 'img-bottom-cont' : ''
            }`}
            id={complexID}
            style={imageStyle}
        >
            <img
                alt="some-random-alt"
                className={classnames({
                    'clickable': showFull || onClick,
                    'img-bottom': bottom,
                })}
                style={strech ? { width: '100%', height: '100%' } : {}}
                src={image}
                onClick={() => {
                    if (showFull) {
                        setOpenModal(true);
                    } else if (onClick) {
                        onClick();
                    }
                }}
            />
            {focus && !customFocus && (
                <Tooltip
                    placement={focusPlacement ? focusPlacement : 'top'}
                    target={complexID}
                    text={
                        <Image
                            path={path}
                            url={url}
                            width={focusDimensions ? focusDimensions.width : 200}
                            height={focusDimensions ? focusDimensions.height : 200}
                            inFocus
                            className="in-tooltip"
                        />
                    }
                />
            )}
            {focus && customFocus && (
                <Tooltip
                    placement={focusPlacement ? focusPlacement : 'top'}
                    target={complexID}
                    text={customFocus}
                />
            )}
            {showFull && (
                <Modal
                    size="xxl"
                    className="m-t-50"
                    isOpen={openModal}
                    toggle={() => setOpenModal(false)}
                >
                    {customModal && customModal(image)}
                    {!customModal && (!startDrawing || !drawing || (gamePaused && !AdminOrDm)) && (
                        <TransformWrapper>
                            {({ zoomIn, zoomOut, resetTransform }) => (
                                <Empty>
                                    <div className="center-ver">
                                        <Button color="link">
                                            <i
                                                className="fa fa-plus color-green"
                                                onClick={() => {
                                                    zoomIn();
                                                }}
                                            />
                                        </Button>
                                        <Button
                                            color="link"
                                            onClick={() => {
                                                zoomOut();
                                            }}
                                        >
                                            <i className="fa fa-minus color-red" />
                                        </Button>
                                        <Button
                                            color="link"
                                            onClick={() => {
                                                resetTransform();
                                            }}
                                        >
                                            Reset
                                        </Button>
                                        {drawing && (!gamePaused || AdminOrDm) && (
                                            <Button
                                                color="link"
                                                onClick={() => {
                                                    setStartDrawing(true);
                                                }}
                                            >
                                                Kreslenie
                                            </Button>
                                        )}
                                    </div>
                                    <TransformComponent>
                                        <img alt="some-random-alt" src={image} />
                                    </TransformComponent>
                                </Empty>
                            )}
                        </TransformWrapper>
                    )}
                    {!customModal && drawing && startDrawing && (!gamePaused || AdminOrDm) && (
                        <Canvas
                            complexID={complexID}
                            image={image}
                            stopDrawing={() => setStartDrawing(false)}
                            instructions={JSON.parse(gameArea.extra).drawing}
                        />
                    )}
                </Modal>
            )}
        </div>
    );
};
