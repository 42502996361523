import React from 'react';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import {
    ADD_LOOT_MANAGER_GOLD,
    LOOT_MANAGER_GOLDS,
    LOOT_MANAGER_GOLDS_SUBSCRIPTION,
    REMOVE_LOOT_MANAGER_GOLD,
    SPLIT_ALL_GOLD,
} from 'queries';
import { Button, Input } from 'reactstrap';
import { GetMyRights, sortBy } from 'helperFunctions';
import { Tooltip } from 'components';

export default function LootGold({ participants }) {
    const {
        data: lootGoldsData,
        loading: lootGoldsLoading,
        refetch: lootGoldsRefetch,
    } = useQuery(LOOT_MANAGER_GOLDS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LOOT_MANAGER_GOLDS_SUBSCRIPTION, {
        onData: () => {
            lootGoldsRefetch();
        },
    });

    const [newGold, setNewGold] = React.useState('0');
    const [addingGold, setAddingGold] = React.useState(false);

    const [addLootManagerGold] = useMutation(ADD_LOOT_MANAGER_GOLD);
    const [removeLootManagerGold] = useMutation(REMOVE_LOOT_MANAGER_GOLD);
    const [splitAllGold] = useMutation(SPLIT_ALL_GOLD);

    const { AdminOrDm } = GetMyRights();

    const LootGolds = lootGoldsLoading ? [] : lootGoldsData.lootManagerGolds;
    const total = LootGolds.reduce((acc, cur) => acc + cur.quantity, 0);
    return (
        <div className="flex-row-wrapped">
            <div className="loot-gold-entry">
                Spolu: <span className="color-gold">{total}g</span>
            </div>
            {sortBy(LootGolds, [{ key: 'createdAt', asc: false }]).map((LootGold) => (
                <div className="loot-gold-entry color-gold" key={LootGold.id}>
                    {LootGold.quantity}g
                    {AdminOrDm && (
                        <Button
                            color="link"
                            className="p-0 font-13 color-gold hover-color-red m-l-5"
                            onClick={() => {
                                if (window.confirm(`Odstrániť sumu ${LootGold.quantity}?`)) {
                                    removeLootManagerGold({ variables: { id: LootGold.id } });
                                }
                            }}
                        >
                            <i className="fa fa-times center-hor" />
                        </Button>
                    )}
                </div>
            ))}
            {AdminOrDm && (
                <div className="loot-gold-entry height-33 flex-row clean-input">
                    <Input
                        className="hidden-input small-input width-30 p-0 m-r-5"
                        value={newGold}
                        onChange={(e) => setNewGold(e.target.value)}
                        type="number"
                        placeholder="gold"
                    />
                    <Button
                        color="link"
                        className="p-0 font-13 color-gold"
                        disabled={(addingGold && isNaN(parseInt(newGold))) || parseInt(newGold) < 1}
                        onClick={() => {
                            setAddingGold(true);
                            addLootManagerGold({ variables: { gold: parseInt(newGold) } }).then(
                                () => {
                                    setAddingGold(false);
                                    setNewGold('0');
                                }
                            );
                        }}
                    >
                        <i className="fa fa-plus center-hor" />
                    </Button>
                </div>
            )}
            {AdminOrDm && (
                <div className="loot-gold-entry height-33 flex-row clean-input">
                    <Button
                        color="link"
                        className="p-0 font-13 color-gold"
                        id={`distribute-loot-gold`}
                        disabled={participants.length < 1}
                        onClick={() => {
                            if (window.confirm(`Rozdeliť zlato účastníkom?`)) {
                                splitAllGold();
                            }
                        }}
                    >
                        <i className="fa fa-hand-holding-hand center-hor" />
                        <Tooltip
                            placement="top"
                            target={`distribute-loot-gold`}
                            text="Rozdeliť zlato"
                        />
                    </Button>
                </div>
            )}
        </div>
    );
}
