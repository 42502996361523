const defaultByAttributes = [
    {
        key: 'title',
        asc: true,
    },
];

const getSortValue = (item, key) => {
    let value = item;
    key.split('.').forEach((key) => {
        if (value === null || value === undefined) {
            value = null;
        } else {
            value = value[key];
        }
    });
    return value;
};

export const sortBy = (originalArray, byAttributes = defaultByAttributes) => {
    let array = [...originalArray];
    if (byAttributes.length === 0) {
        return array;
    }

    return array.sort((item1, item2) => {
        const results = byAttributes.map((attribute) => {
            const value = attribute.asc ? 1 : -1;
            if (getSortValue(item1, attribute.key) > getSortValue(item2, attribute.key)) {
                return value;
            }
            if (getSortValue(item1, attribute.key) < getSortValue(item2, attribute.key)) {
                return -1 * value;
            }
            return 0;
        });
        const result = results.find((res) => res !== 0);
        return result || 0;
    });
};

export const updateArrayItem = (array, item, key = 'id') => {
    const index = array.findIndex((item2) => item2[key] === item[key]);
    if (index === -1) {
        return array;
    }
    let newArray = [...array];
    newArray[index] = item;
    return newArray;
};

export const filterUnique = (array, key = null) => {
    if (key === null) {
        return array.filter((item, index) => array.indexOf(item) === index);
    } else {
        let keys = array.map((item) => item[key]);
        return array.filter((item, index) => keys.indexOf(item[key]) === index);
    }
};

export const removeFirstOccurance = (arr, item, compareFunc) => {
    const index = arr.findIndex((item2) => compareFunc(item, item2));
    const newArr = [...arr];
    newArr.splice(index, 1);
    return newArr;
};

export const splitArrayByFilter = (array, filter) => {
    return array.reduce(([p, f], e) => (filter(e) ? [[...p, e], f] : [p, [...f, e]]), [[], []]);
};
