import React from 'react';
import Select from 'react-select';
import {
    GetMyData,
    GetMyRights,
    getAttributesFromItems,
    getEquipped,
    randomSimpleString,
    stringToDiv,
} from 'helperFunctions';
import { Image, Tooltip } from 'components';
import borders from './borders';
import Gold from './gold';
import Equipment from './equipment';
import Attributes from './attributes';
import deadImage from 'components/dead';
import { Button, Input, Label } from 'reactstrap';
import { pickSelectStyle } from 'configs/selectStyles';
import genders from 'configs/genders';
import {
    GET_EQUIPPED,
    INVENTORY_SUBSCRIPTION,
    INVENTORY_WEIGHT,
    ITEMS_SUBSCRIPTION,
    UPDATE_CHARACTER,
    USER,
    USERS_SUBSCRIPTION,
    CHANGE_LOCATION,
} from 'queries';
import { useMutation, useQuery, useSubscription } from '@apollo/client';

export default function CharacterSheet({ id, Users }) {
    const [uniqueId] = React.useState(randomSimpleString() + '-' + id);
    const [goldOpened, setGoldOpened] = React.useState(false);
    const [editCharacter, setEditCharacter] = React.useState(false);
    const [edittedAttribute, setEdittedAttribute] = React.useState(null);

    const [updateCharacter] = useMutation(UPDATE_CHARACTER);
    const [changeLocation] = useMutation(CHANGE_LOCATION);

    const { AdminOrDm } = GetMyRights();
    const currentUser = GetMyData();

    const {
        data: userData,
        loading: userLoading,
        refetch: userRefetch,
    } = useQuery(USER, {
        fetchPolicy: 'network-only',
        variables: {
            id,
        },
    });

    useSubscription(USERS_SUBSCRIPTION, {
        onData: () => {
            userRefetch({ id });
        },
        variables: {
            id,
        },
    });
    const User = userLoading ? [] : userData.user;

    const {
        data: equippedData,
        loading: equippedLoading,
        refetch: equippedRefetch,
    } = useQuery(GET_EQUIPPED, {
        fetchPolicy: 'network-only',
        variables: {
            id,
        },
    });

    const {
        data: inventoryWeightData,
        loading: inventoryWeightLoading,
        refetch: inventoryWeightRefetch,
    } = useQuery(INVENTORY_WEIGHT, {
        fetchPolicy: 'network-only',
        variables: {
            id,
        },
    });

    const inventoryWeight = inventoryWeightLoading ? 0 : inventoryWeightData.inventoryWeight;

    useSubscription(INVENTORY_SUBSCRIPTION, {
        onData: () => {
            inventoryWeightRefetch({ id });
            equippedRefetch({ id });
        },
        variables: {
            id,
        },
    });
    useSubscription(ITEMS_SUBSCRIPTION, {
        onData: () => {
            equippedRefetch({ id });
        },
    });

    React.useEffect(() => {
        setGoldOpened(false);
        setEdittedAttribute(null);
    }, [id]);

    const Equipped = equippedLoading ? [] : equippedData.getEquipped;

    if (userLoading || equippedLoading) {
        return null;
    }

    const Vitality = User.attributes.vitality;
    const isDead =
        Vitality.lost >=
        Vitality.extra +
            Vitality.levelup +
            Vitality.base +
            getAttributesFromItems(getEquipped(Equipped)).vitality.reduce(
                (acc, equip) => acc + equip.value,
                0
            );

    return (
        <div className="charsheet" style={{ backgroundImage: 'url("/images/charsheet.png")' }}>
            <div className="flex-row height-170">
                <div className="width-300 flex-row">
                    <div className="charsheet-race-icon">
                        <Image path={User.race.icon} />
                    </div>
                    <span className="charsheet-race-title">
                        <div>{User.race.title}</div>
                        <div className="kill-count thin-buttons">
                            <span className="m-r-5">Vrážd:</span>
                            {AdminOrDm && editCharacter && (
                                <Button
                                    color="link"
                                    onClick={() => {
                                        updateCharacter({
                                            variables: { id, killCount: User.killCount - 1 },
                                        });
                                    }}
                                >
                                    <i className="fa fa-minus color-white m-r-5" />
                                </Button>
                            )}
                            {User.killCount}
                            {AdminOrDm && editCharacter && (
                                <Button
                                    color="link"
                                    onClick={() => {
                                        updateCharacter({
                                            variables: { id, killCount: User.killCount + 1 },
                                        });
                                    }}
                                >
                                    <i className="fa fa-plus color-white m-l-5" />
                                </Button>
                            )}
                        </div>
                    </span>
                </div>
                <div className="width-300 flex-row">
                    <div className="charsheet-occupation">
                        <div className="charsheet-occupation-icon">
                            <Image path={User.occupation.icon} />
                        </div>
                        <div
                            className="charsheet-occupation-border"
                            id={`occupation-portrait-${id}`}
                        >
                            <img alt="missing" src={borders.raceBorder} />
                        </div>
                        <Tooltip
                            placement="bottom"
                            target={`occupation-portrait-${id}`}
                            text={stringToDiv(User.occupation.description)}
                        />
                    </div>
                    <div>
                        <div className="char-info">
                            <div className="occupation-title">{User.occupation.title}</div>
                            <div className="flex-row">
                                <div className="center-hor m-r-5">Meno:</div>
                                {!editCharacter || !AdminOrDm ? (
                                    User.character
                                ) : (
                                    <Input
                                        value={
                                            edittedAttribute?.type === 'character'
                                                ? edittedAttribute.value
                                                : User.character
                                        }
                                        className="hidden-input"
                                        onFocus={() =>
                                            setEdittedAttribute({
                                                type: 'character',
                                                value: User.character,
                                            })
                                        }
                                        onBlur={() => {
                                            updateCharacter({
                                                variables: {
                                                    id,
                                                    character: edittedAttribute.value,
                                                },
                                            });
                                            setEdittedAttribute(null);
                                        }}
                                        onChange={(e) => {
                                            setEdittedAttribute({
                                                type: 'character',
                                                value: e.target.value,
                                            });
                                        }}
                                    />
                                )}
                            </div>
                            <div className="flex-row">
                                <div className="center-hor m-r-5">Vek:</div>
                                {!editCharacter || !AdminOrDm ? (
                                    User.age
                                ) : (
                                    <Input
                                        type="number"
                                        value={
                                            edittedAttribute?.type === 'age'
                                                ? edittedAttribute.value
                                                : parseInt(User.age)
                                        }
                                        className="hidden-input"
                                        onFocus={() =>
                                            setEdittedAttribute({
                                                type: 'age',
                                                value: parseInt(User.age),
                                            })
                                        }
                                        onBlur={() => {
                                            updateCharacter({
                                                variables: {
                                                    id,
                                                    age: isNaN(parseInt(edittedAttribute.value))
                                                        ? User.age
                                                        : parseInt(edittedAttribute.value),
                                                },
                                            });
                                            setEdittedAttribute(null);
                                        }}
                                        onChange={(e) => {
                                            setEdittedAttribute({
                                                type: 'age',
                                                value: e.target.value,
                                            });
                                        }}
                                    />
                                )}
                            </div>
                            <div className="flex-row">
                                <div className="center-hor m-r-5">Pohlavie:</div>
                                {!editCharacter || !AdminOrDm ? (
                                    User.gender === 0 ? (
                                        'samica'
                                    ) : User.gender === 1 ? (
                                        'samec'
                                    ) : (
                                        'iné'
                                    )
                                ) : (
                                    <div className="text-black">
                                        <Select
                                            value={{
                                                value: User.gender,
                                                label:
                                                    User.gender === 0
                                                        ? 'samica'
                                                        : User.gender === 1
                                                        ? 'samec'
                                                        : 'iné',
                                            }}
                                            options={genders}
                                            placeholder="Pohlavie"
                                            styles={pickSelectStyle([
                                                'invisible',
                                                'noPadding',
                                                'whiteText',
                                            ])}
                                            onChange={(gender) => {
                                                updateCharacter({
                                                    variables: {
                                                        id,
                                                        gender: gender.value,
                                                    },
                                                });
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="character-level flex-row">
                            <div className="center-hor m-r-5">Level:</div>
                            {!editCharacter || !AdminOrDm ? (
                                User.level
                            ) : (
                                <Input
                                    type="number"
                                    value={
                                        edittedAttribute?.type === 'level'
                                            ? edittedAttribute.value
                                            : parseInt(User.level)
                                    }
                                    className="hidden-input"
                                    onFocus={() =>
                                        setEdittedAttribute({
                                            type: 'level',
                                            value: parseInt(User.level),
                                        })
                                    }
                                    onBlur={() => {
                                        updateCharacter({
                                            variables: {
                                                id,
                                                level: isNaN(parseInt(edittedAttribute.value))
                                                    ? User.level
                                                    : parseInt(edittedAttribute.value),
                                            },
                                        });
                                        setEdittedAttribute(null);
                                    }}
                                    onChange={(e) => {
                                        setEdittedAttribute({
                                            type: 'level',
                                            value: e.target.value,
                                        });
                                    }}
                                />
                            )}
                        </div>
                        {AdminOrDm && (
                            <i
                                className="fa fa-edit edit-button font-20 clickable"
                                onClick={() => setEditCharacter(!editCharacter)}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="flex-row height-250">
                <div className="width-300">
                    <div className="charsheet-portrait">
                        <Image
                            width={190}
                            height={250}
                            path={User.portrait ? User.portrait : User.race.portrait}
                        />
                    </div>
                    <div>
                        {isDead && (
                            <div className="charsheet-dead">
                                <img alt="dead" src={deadImage} />
                            </div>
                        )}
                    </div>
                </div>
                <Equipment User={User} Equipped={getEquipped(Equipped)} />
            </div>
            <div className="flex-row height-220">
                <div>
                    <div className=" height-170 charsheet-description">{User.race.description}</div>
                    <div className="flex-row">
                        <div
                            className="m-l-25 width-60 height-50 clickable"
                            id={`gold-${uniqueId}`}
                            onClick={() => setGoldOpened(true)}
                        />
                        <Tooltip
                            placement="top"
                            target={`gold-${uniqueId}`}
                            text="Spravovať zlato"
                        />
                        <Gold
                            open={goldOpened}
                            toggle={() => {
                                setGoldOpened(!goldOpened);
                            }}
                            User={User}
                            uniqueId={uniqueId}
                            Users={Users.filter((User2) => User2.id !== User.id)}
                        />
                        <div className="gold-value">{User.gold}</div>
                    </div>
                </div>
                <Attributes
                    User={User}
                    uniqueId={uniqueId}
                    equipmentAttributes={getAttributesFromItems(getEquipped(Equipped))}
                    inventoryWeight={inventoryWeight}
                />
            </div>
            <div className="flex-row height-30 m-l-30 location">
                <Label className="center-hor">Poloha:</Label>
                <Input
                    value={
                        edittedAttribute?.type === 'location'
                            ? edittedAttribute.value
                            : User.location
                    }
                    disabled={currentUser.id !== id && !AdminOrDm}
                    className="hidden-input"
                    onFocus={() =>
                        setEdittedAttribute({
                            type: 'location',
                            value: User.location,
                        })
                    }
                    onBlur={() => {
                        changeLocation({
                            variables: {
                                id,
                                location: edittedAttribute.value,
                            },
                        });
                        setEdittedAttribute(null);
                    }}
                    onChange={(e) => {
                        setEdittedAttribute({
                            type: 'location',
                            value: e.target.value,
                        });
                    }}
                />
            </div>
        </div>
    );
}
