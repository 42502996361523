import React from 'react';
import OpponentDisplay from './opponent';
import Select from 'react-select';
import { pickSelectStyle } from 'configs/selectStyles';
import { GetMyRights, toSelItem } from 'helperFunctions';
import { CHANGE_DEFENDER, SKIP_TURN, START_FIGHT } from 'queries';
import { useMutation } from '@apollo/client';
import { Button, Col } from 'reactstrap';

export default function PreFight({ CombatSimulator }) {
    const [changeDefender] = useMutation(CHANGE_DEFENDER);
    const [startFight] = useMutation(START_FIGHT);
    const [skipTurn] = useMutation(SKIP_TURN);
    const { Dm } = GetMyRights();

    const Attacker = CombatSimulator.opponents.find(
        (Opponent) => Opponent.id === CombatSimulator.currentAttacker
    );
    const Defender = CombatSimulator.opponents.find(
        (Opponent) => Opponent.id === CombatSimulator.currentDefender
    );

    const AvailableDefenders = CombatSimulator.opponents
        .filter(
            (Opponent) =>
                !Opponent.dead &&
                ![CombatSimulator.currentAttacker, CombatSimulator.currentDefender].includes(
                    Opponent.id
                ) &&
                Opponent.team !== Attacker?.team
        )
        .map((Opponent) => ({ ...Opponent, value: Opponent.id, label: Opponent.nickname }));

    return (
        <div className="flex-row">
            {CombatSimulator.currentAttacker !== null && Attacker && (
                <Col className="m-r-5">
                    <OpponentDisplay type="Útočník" Opponent={Attacker} />
                    <div className="flex-row">
                        {Dm && (
                            <Button
                                color="danger"
                                className="full-width m-r-10 m-l-10"
                                onClick={skipTurn}
                            >
                                {Attacker.stunned && <i className="fa fa-bolt color-gold" />}
                                Preskočiť kolo
                            </Button>
                        )}
                        {Dm &&
                            !Attacker.stunned &&
                            Attacker.attributes.endurance.base +
                                Attacker.attributes.endurance.extra -
                                Attacker.attributes.endurance.lost >
                                0 && (
                                <Button
                                    color="success"
                                    className="full-width m-r-10 m-l-10"
                                    disabled={CombatSimulator.currentDefender === null}
                                    onClick={startFight}
                                >
                                    Útočí
                                </Button>
                            )}
                    </div>
                </Col>
            )}
            {Attacker && (
                <Col className="m-l-5 flex-column">
                    {CombatSimulator.currentDefender !== null && Defender ? (
                        <OpponentDisplay type="Obránca" Opponent={Defender} />
                    ) : (
                        <div className="flex" />
                    )}
                    {Dm && (
                        <Select
                            value={Defender ? toSelItem(Defender, 'nickname') : null}
                            options={AvailableDefenders}
                            placeholder="Výber obráncu"
                            onChange={(Opponent) => {
                                changeDefender({ variables: { id: Opponent.id } });
                            }}
                            styles={pickSelectStyle(['colored'])}
                        />
                    )}
                </Col>
            )}
        </div>
    );
}
