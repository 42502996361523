import React from 'react';
import { useMutation } from '@apollo/client';
import { LOGOUT_USER } from 'queries';
import { Button } from 'reactstrap';

export default function Logout() {
    const [logoutUser] = useMutation(LOGOUT_USER);

    return (
        <div className="login-bkg">
            <div className="card login-card">
                <div className="color-green">
                    Ste prihlásený, ale registráciu vám musí potvrdiť DM! Ak chcete skúsiť iný účet,
                    odhláste sa.
                </div>
                <Button
                    color="danger"
                    onClick={() => {
                        if (window.confirm('Naozaj sa chceš odhlásiť?')) {
                            logoutUser().then(() => {
                                window.location.reload(false);
                            });
                        }
                    }}
                >
                    Odhlásiť sa
                </Button>
            </div>
        </div>
    );
}
