import React from 'react';

// eslint-disable-next-line
export default [
    {
        id: 'd4',
        max: 4,
        dice: ({ ...other }) => dice('./images/d4.png', other),
    },
    { id: 'd6', max: 6, dice: ({ ...other }) => dice('./images/d6.png', other) },
    { id: 'd8', max: 8, dice: ({ ...other }) => dice('./images/d8.png', other) },
    { id: 'd10', max: 10, dice: ({ ...other }) => dice('./images/d10.png', other) },
    { id: 'd100', max: 100, dice: ({ ...other }) => dice('./images/d100.png', other) },
];

function dice(path, { className, ...other }) {
    return (
        <img
            src={path}
            alt={path}
            {...other}
            className={className ? `noselect ${className}` : 'noselect'}
        />
    );
}
