import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { useNavigate } from 'react-router';

export default function RaceList({ fixedShow, setFixedShow, type, id, Races }) {
    const navigate = useNavigate();

    return (
        <ListGroup className="library-list-categories">
            <ListGroupItem
                active={fixedShow.includes('races')}
                action
                className="clickable m-t-10"
                onClick={() => {
                    if (fixedShow.includes('races')) {
                        setFixedShow(fixedShow.filter((title) => title !== 'races'));
                    } else {
                        setFixedShow([...fixedShow, 'races']);
                    }
                }}
            >
                Rasy
            </ListGroupItem>
            {fixedShow.includes('races') && (
                <ListGroup className="m-l-20 library-list-entries">
                    {Races.map((Entry) => (
                        <ListGroupItem
                            key={Entry.id}
                            active={type === 'races' && id === Entry.id}
                            action
                            className="clickable"
                            onClick={() => {
                                navigate(`/library/races/${Entry.id}`);
                            }}
                        >
                            {Entry.title}
                        </ListGroupItem>
                    ))}
                    {Races.length === 0 && (
                        <ListGroupItem className="noselect">Polička prázdna :c</ListGroupItem>
                    )}
                </ListGroup>
            )}
        </ListGroup>
    );
}
