import { gql } from '@apollo/client';

export const ITEMS = gql`
    query items {
        items {
            id
            title
            price
            weight
            description
            icon
            replaces
            type {
                id
                value
                label
                inventoryTitle
                inventoryLabel
                inventoryTab
                inventoryOrder
                color
            }
        }
    }
`;

export const ITEM = gql`
    query item($id: Int!) {
        item(id: $id) {
            createdAt
            defence
            description
            dexterity
            endurance
            capacity
            replaces
            icon
            energy
            id
            price
            speed
            strength
            title
            type {
                id
                value
                label
                inventoryTitle
                inventoryLabel
                inventoryTab
                inventoryOrder
                color
            }
            updatedAt
            vitality
            weight
        }
    }
`;

export const ITEM_TYPES = gql`
    query itemTypes {
        itemTypes {
            id
            value
            label
            inventoryTitle
            inventoryLabel
            inventoryTab
            inventoryOrder
            color
        }
    }
`;
export const ADD_ITEM = gql`
    mutation addItem(
        $title: String
        $description: String
        $icon: String
        $price: Int
        $type: Int
        $weight: Float
        $energy: Int
        $defence: Int
        $dexterity: Int
        $speed: Int
        $strength: Int
        $vitality: Int
        $endurance: Int
        $capacity: Int
        $replaces: Int
    ) {
        addItem(
            title: $title
            description: $description
            icon: $icon
            price: $price
            type: $type
            weight: $weight
            energy: $energy
            defence: $defence
            dexterity: $dexterity
            speed: $speed
            strength: $strength
            vitality: $vitality
            endurance: $endurance
            capacity: $capacity
            replaces: $replaces
        ) {
            id
            icon
        }
    }
`;
export const UPDATE_ITEM = gql`
    mutation updateItem(
        $id: Int!
        $removeIcon: Boolean
        $title: String
        $description: String
        $icon: String
        $price: Int
        $weight: Float
        $type: Int
        $energy: Int
        $defence: Int
        $dexterity: Int
        $speed: Int
        $strength: Int
        $vitality: Int
        $endurance: Int
        $capacity: Int
        $replaces: Int
    ) {
        updateItem(
            id: $id
            removeIcon: $removeIcon
            title: $title
            description: $description
            icon: $icon
            price: $price
            weight: $weight
            type: $type
            energy: $energy
            defence: $defence
            dexterity: $dexterity
            speed: $speed
            strength: $strength
            vitality: $vitality
            endurance: $endurance
            capacity: $capacity
            replaces: $replaces
        ) {
            id
            icon
        }
    }
`;
export const DELETE_ITEM = gql`
    mutation deleteItem($id: Int!) {
        deleteItem(id: $id) {
            id
        }
    }
`;

export const ITEMS_SUBSCRIPTION = gql`
    subscription itemsSubscription {
        itemsSubscription
    }
`;
