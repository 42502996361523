import React from 'react';
import { Button, Label, ListGroup, ListGroupItem } from 'reactstrap';
import PlaceEdit from './edit';
import PlaceAdd from './add';
import { Tooltip } from 'components';
import { useParams, useNavigate } from 'react-router';
import { PLACES, PLACES_SUBSCRIPTION } from 'queries';
import { useQuery, useSubscription } from '@apollo/client';
import { sortBy } from 'helperFunctions';

export default function PlaceList() {
    const { id } = useParams();
    const placeID = parseInt(id);
    const navigate = useNavigate();
    const {
        data: placesData,
        loading: placesLoading,
        refetch: placesRefetch,
    } = useQuery(PLACES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(PLACES_SUBSCRIPTION, {
        onData: () => {
            placesRefetch();
        },
    });

    const Places = placesLoading
        ? []
        : sortBy(placesData.places, [
              { key: 'important', asc: false },
              { key: 'title', asc: true },
          ]);

    return (
        <div className="flex-row">
            <div className="settings-left">
                <div className="flex-row">
                    <Label className="color-white">Miesta</Label>
                    <Button
                        color="link"
                        id="add-place"
                        className="center-hor m-l-auto m-b-5"
                        onClick={() => navigate('/nastavenia_hry/miesta/add')}
                    >
                        <i className="fa fa-plus" />
                    </Button>
                </div>
                <Tooltip placement="right" target="add-place" text="Vytvoriť nové miesto" />
                <ListGroup>
                    {Places.map((place) => (
                        <ListGroupItem
                            key={place.id}
                            active={placeID === place.id}
                            action
                            className="clickable"
                            onClick={() => {
                                navigate(`/nastavenia_hry/miesta/${place.id}`);
                            }}
                        >
                            {place.title}
                            {place.important && (
                                <i className="center-right-absolute fa color-gold fa-star" />
                            )}
                        </ListGroupItem>
                    ))}
                </ListGroup>
            </div>
            {!isNaN(placeID) && Places.some((place) => place.id === placeID) && (
                <PlaceEdit placeID={placeID} />
            )}
            {id === 'add' && <PlaceAdd className="settings-right" />}
        </div>
    );
}
