import React from 'react';
import { Button, Label, ListGroup, ListGroupItem } from 'reactstrap';
import MapEdit from './edit';
import MapAdd from './add';
import { Tooltip } from 'components';
import { useParams, useNavigate } from 'react-router';
import { MAPS, MAPS_SUBSCRIPTION } from 'queries';
import { useQuery, useSubscription } from '@apollo/client';

export default function MapList() {
    const { id } = useParams();
    const mapID = parseInt(id);
    const navigate = useNavigate();
    const {
        data: mapsData,
        loading: mapsLoading,
        refetch: mapsRefetch,
    } = useQuery(MAPS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(MAPS_SUBSCRIPTION, {
        onData: () => {
            mapsRefetch();
        },
    });

    const Maps = mapsLoading ? [] : mapsData.maps;

    return (
        <div className="flex-row">
            <div className="settings-left">
                <div className="flex-row">
                    <Label className="m-t-5 color-white">Mapy</Label>
                    <Button
                        color="link"
                        id="add-map"
                        className="center-hor m-l-auto m-b-5"
                        onClick={() => navigate('/nastavenia_hry/mapy/add')}
                    >
                        <i className="fa fa-plus" />
                    </Button>
                    <Tooltip mapment="right" target="add-map" text="Vytvoriť novú mapu" />
                </div>
                <ListGroup>
                    {Maps.map((Map) => (
                        <ListGroupItem
                            key={Map.id}
                            active={mapID === Map.id}
                            action
                            className="clickable"
                            onClick={() => {
                                navigate(`/nastavenia_hry/mapy/${Map.id}`);
                            }}
                        >
                            {Map.title}
                            <span
                                className="center-right-absolute highlighted-text"
                                style={{ color: Map.visibility ? '#218838' : '#bd2130' }}
                            >
                                {Map.visibility ? 'Spoznané' : 'Neznáme'}
                            </span>
                        </ListGroupItem>
                    ))}
                </ListGroup>
            </div>
            {!isNaN(mapID) && Maps.some((Map) => Map.id === mapID) && <MapEdit mapID={mapID} />}
            {id === 'add' && <MapAdd />}
        </div>
    );
}
