import React from 'react';
import { Card, CardHeader, CardBody, Button, FormGroup, Label, Col, Input } from 'reactstrap';
import { Checkbox, ImageInput } from 'components';
import Select from 'react-select';
import Textarea from 'react-textarea-autosize';
import { toBase64, toSelItem } from 'helperFunctions';
import { DELETE_SPELL, MAGE_CLASSES, SPELL, SPELLS_SUBSCRIPTION, UPDATE_SPELL } from 'queries';
import { useMutation, useQuery, useSubscription } from '@apollo/client';

export default function SpellEdit(props) {
    const { spellID } = props;

    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [visibility, setVisibility] = React.useState(false);
    const [icon, setIcon] = React.useState(null);
    const [level, setLevel] = React.useState(0);
    const [mageClass, setMageClass] = React.useState(null);
    const [saving, setSaving] = React.useState(false);

    const [updateSpell] = useMutation(UPDATE_SPELL);
    const [deleteSpell] = useMutation(DELETE_SPELL);

    const {
        data: spellData,
        loading: spellLoading,
        refetch: spellRefetch,
    } = useQuery(SPELL, {
        fetchPolicy: 'network-only',
        variables: {
            id: spellID,
        },
    });
    const Spell = spellLoading ? null : spellData.spell;

    const { data: mageClassesData, loading: mageClassesLoading } = useQuery(MAGE_CLASSES, {
        fetchPolicy: 'network-only',
    });
    const mageClasses = mageClassesLoading ? [] : mageClassesData.mageClasses;

    useSubscription(SPELLS_SUBSCRIPTION, {
        onData: () => {
            spellRefetch({
                variables: {
                    id: spellID,
                },
            });
        },
    });

    React.useEffect(() => {
        spellRefetch({ id: spellID });
        // eslint-disable-next-line
    }, [spellID]);

    React.useEffect(() => {
        if (!spellLoading && !mageClassesLoading) {
            setTitle(Spell.title);
            setDescription(Spell.description);
            setVisibility(Spell.visibility);
            setLevel(Spell.level);
            setMageClass(toSelItem(Spell.mageClass, 'label'));
            setSaving(Spell.saving);
        }
        // eslint-disable-next-line
    }, [Spell, mageClasses]);

    const save = async () => {
        setSaving(true);
        //save images
        let body = {
            title,
            description,
            visibility,
            level: parseInt(level),
            mageClass,
        };

        if (icon) {
            body.icon = await toBase64(icon);
        }
        updateSpell({ variables: body })
            .then(() => {
                //update STATE images
                setSaving(false);
                setIcon(null);
            })
            .catch((e) => {
                setSaving(false);
                console.log(e);
            });
    };

    const deleteFunc = async () => {
        if (window.confirm(`Si si istý že chceš zmazať kúzlo ${title}?`)) {
            deleteSpell({ variables: { id: spellID } });
        }
    };

    return (
        <div className="settings-right">
            <Card>
                <CardHeader className="flex-row">
                    <Input
                        id="spell"
                        value={title}
                        className="invisible-input"
                        placeholder="Názov kúzla"
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </CardHeader>
                <CardBody>
                    <FormGroup>
                        <Checkbox
                            className="form-control-checkbox"
                            label="Spoznali kúzlo"
                            value={visibility}
                            right
                            centerHor
                            onChange={() => setVisibility(!visibility)}
                        />
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="level" sm={2}>
                            Úroveň
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="level"
                                value={level}
                                placeholder="Zadaj úroveň mágie"
                                onChange={(e) => setLevel(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label sm={2}>Trieda mágie</Label>
                        <Col sm={10}>
                            <Select
                                value={mageClass}
                                options={mageClasses}
                                placeholder="Vyber triedu mágie"
                                onChange={(mageClass) => setMageClass(mageClass)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="description" sm={2}>
                            Popis
                        </Label>
                        <Col sm={10}>
                            <Textarea
                                className="form-control"
                                id="description"
                                placeholder="Zadaj popis kúzla"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <ImageInput
                        setImage={setIcon}
                        image={icon}
                        id={`spellIcon${spellID}`}
                        label="Ikona kúzla"
                        original={Spell?.icon}
                        placeholder="Vyberte ikonu kúzla"
                        height={300}
                    />

                    <div className="flex-row">
                        <Button color="primary" disabled={saving} onClick={save}>
                            {saving ? 'Ukladá sa...' : 'Uložiť kúzlo'}
                        </Button>
                        <Button color="danger" className="m-l-auto" onClick={deleteFunc}>
                            Vymazať
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
