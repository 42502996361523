import React from 'react';
import { Card, CardHeader, CardBody, Button, FormGroup, Label, Col, Input } from 'reactstrap';
import { Checkbox, ImageInput } from 'components';
import Textarea from 'react-textarea-autosize';
import { toBase64 } from 'helperFunctions';
import { DELETE_PLACE, PLACE, PLACES_SUBSCRIPTION, UPDATE_PLACE } from 'queries';
import { useMutation, useQuery, useSubscription } from '@apollo/client';

export default function PlaceEdit(props) {
    const { placeID } = props;

    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [visibility, setVisibility] = React.useState(false);
    const [place, setPlace] = React.useState(null);
    const [important, setImportant] = React.useState(false);
    const [saving, setSaving] = React.useState(false);

    const [updatePlace] = useMutation(UPDATE_PLACE);
    const [deletePlace] = useMutation(DELETE_PLACE);

    const {
        data: placeData,
        loading: placeLoading,
        refetch: placeRefetch,
    } = useQuery(PLACE, {
        fetchPolicy: 'network-only',
        variables: {
            id: placeID,
        },
    });

    useSubscription(PLACES_SUBSCRIPTION, {
        onData: () => {
            placeRefetch({ variables: { id: placeID } });
        },
    });

    const Place = placeLoading ? null : placeData.place;

    React.useEffect(() => {
        placeRefetch({ id: placeID });
        // eslint-disable-next-line
    }, [placeID]);

    React.useEffect(() => {
        if (!placeLoading) {
            setTitle(Place.title);
            setDescription(Place.description);
            setVisibility(Place.visibility);
            setImportant(Place.important);
        }
        // eslint-disable-next-line
    }, [Place]);

    const save = async () => {
        setSaving(true);
        //save images
        let body = {
            id: placeID,
            title,
            description,
            visibility,
            important,
        };
        if (place) {
            body.place = await toBase64(place);
        }
        updatePlace({ variables: body })
            .then(() => {
                //update STATE images
                setSaving(false);
                setPlace(null);
            })
            .catch((e) => {
                setSaving(false);
                console.log(e);
            });
    };

    const deleteFunc = async () => {
        if (window.confirm(`Si si istý že chceš zmazať miesto ${title}?`)) {
            deletePlace({ variables: { id: placeID } });
        }
    };

    return (
        <div className="settings-right">
            <Card>
                <CardHeader className="flex-row">
                    <Input
                        id="title"
                        value={title}
                        className="invisible-input"
                        placeholder="Názov miesta"
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </CardHeader>
                <CardBody>
                    <FormGroup>
                        <Checkbox
                            className="form-control-checkbox"
                            label="Spoznali miesto"
                            value={visibility}
                            right
                            centerHor
                            onChange={() => setVisibility(!visibility)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Checkbox
                            className="form-control-checkbox"
                            label="Dôležité?"
                            value={important}
                            right
                            centerHor
                            onChange={() => setImportant(!important)}
                        />
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="description" sm={2}>
                            Popis
                        </Label>
                        <Col sm={10}>
                            <Textarea
                                className="form-control"
                                id="description"
                                placeholder="Zadaj popis miesta"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Col>
                    </FormGroup>

                    <ImageInput
                        setImage={setPlace}
                        image={place}
                        id={`place${placeID}`}
                        label="Miesto"
                        placeholder="Vyberte miesto"
                        original={Place?.place}
                        width={500}
                    />

                    <div className="flex-row">
                        <Button color="primary" disabled={saving} onClick={save}>
                            {saving ? 'Ukladá sa...' : 'Uložiť miesto'}
                        </Button>
                        <Button color="danger" className="m-l-auto" onClick={deleteFunc}>
                            Vymazať
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
