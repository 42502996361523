import { makeVar } from '@apollo/client';
import { imageChangeIds, updateImageChangeIds } from './variables';

export const GetImageId = (path) => {
    if ([null, undefined].includes(path)) {
        return null;
    }
    if ([null, undefined].includes(imageChangeIds[path])) {
        const newVar = makeVar(1);
        updateImageChangeIds({ ...imageChangeIds, [path]: newVar });
        return newVar;
    }
    return imageChangeIds[path];
};
