import React from 'react';
import { Card, CardHeader, CardBody, Button, FormGroup, Label, Col, Input } from 'reactstrap';
import { ImageInput } from 'components';
import { DELETE_RACE, RACE, RACES_SUBSCRIPTION, UPDATE_RACE } from 'queries';
import { useQuery, useMutation, useSubscription } from '@apollo/client';
import { toBase64 } from 'helperFunctions';

export default function RaceEdit(props) {
    const { raceID } = props;

    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [icon, setIcon] = React.useState(null);
    const [portrait, setPortrait] = React.useState(null);
    const [size, setSize] = React.useState(0);

    const [vitality, setVitality] = React.useState(0);
    const [endurance, setEndurance] = React.useState(0);
    const [strength, setStrength] = React.useState(0);
    const [speed, setSpeed] = React.useState(0);
    const [dexterity, setDexterity] = React.useState(0);
    const [defence, setDefence] = React.useState(0);
    const [energy, setEnergy] = React.useState(0);
    const [capacity, setCapacity] = React.useState(0);
    const [saving, setSaving] = React.useState(false);

    const [updateRace] = useMutation(UPDATE_RACE);
    const [deleteRace] = useMutation(DELETE_RACE);

    const {
        data: raceData,
        loading: raceLoading,
        refetch: raceRefetch,
    } = useQuery(RACE, {
        fetchPolicy: 'network-only',
        variables: {
            id: raceID,
        },
    });

    useSubscription(RACES_SUBSCRIPTION, {
        onData: () => {
            raceRefetch({ variables: { id: raceID } });
        },
    });

    const Race = raceLoading ? null : raceData.race;

    React.useEffect(() => {
        raceRefetch({ id: raceID });
        // eslint-disable-next-line
    }, [raceID]);

    React.useEffect(() => {
        if (!raceLoading) {
            setTitle(Race.title);
            setDescription(Race.description);
            setIcon(null);
            setPortrait(null);

            setSize(Race.size);
            setVitality(Race.vitality);
            setEndurance(Race.endurance);
            setStrength(Race.strength);
            setSpeed(Race.speed);
            setDexterity(Race.dexterity);
            setDefence(Race.defence);
            setEnergy(Race.energy);
            setCapacity(Race.capacity);
            setSaving(Race.saving);
        }
        // eslint-disable-next-line
    }, [Race]);

    const save = async () => {
        setSaving(true);
        //save images
        let body = {
            id: raceID,
            description,
            title,
            size: size === '' ? 0 : parseInt(size),

            vitality: vitality === '' ? 0 : parseInt(vitality),
            endurance: endurance === '' ? 0 : parseInt(endurance),
            strength: strength === '' ? 0 : parseInt(strength),
            speed: speed === '' ? 0 : parseInt(speed),
            dexterity: dexterity === '' ? 0 : parseInt(dexterity),
            defence: defence === '' ? 0 : parseInt(defence),
            energy: energy === '' ? 0 : parseInt(energy),
            capacity: capacity === '' ? 0 : parseInt(capacity),
        };
        if (icon) {
            body.icon = await toBase64(icon);
        }
        if (portrait) {
            body.portrait = await toBase64(portrait);
        }
        updateRace({ variables: body })
            .then(() => {
                //update STATE images
                setSaving(false);
                setIcon(null);
                setPortrait(null);
            })
            .catch((e) => {
                setSaving(false);
                console.log(e);
            });
    };

    return (
        <div className="settings-right">
            <Card>
                <CardHeader className="flex-row">
                    <Input
                        id="race"
                        value={title}
                        className="invisible-input"
                        placeholder="Názov rasy"
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </CardHeader>
                <CardBody>
                    <FormGroup className="flex-row">
                        <Label for="description" sm={2}>
                            Popis
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="textarea"
                                id="description"
                                value={description}
                                placeholder="Zadaj popis rasy"
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <ImageInput
                        label="Ikona rasy"
                        id={`raceIcon${raceID}`}
                        setImage={setIcon}
                        image={icon}
                        placeholder="Vyberte ikonu rasy"
                        original={Race?.icon}
                        height={150}
                    />
                    <ImageInput
                        setImage={setPortrait}
                        image={portrait}
                        id={`racePortrait${raceID}`}
                        label="Portrét rasy"
                        placeholder="Vyberte portrét rasy"
                        original={Race?.portrait}
                        height={400}
                    />
                    <FormGroup className="flex-row">
                        <Label for="size" sm={2}>
                            Veľkosť (poradie)
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="size"
                                value={size}
                                placeholder="Zadaj veľkosť"
                                onChange={(e) => setSize(e.target.value)}
                            />
                        </Col>
                    </FormGroup>

                    <h4>Základné atribúty</h4>
                    <FormGroup className="flex-row">
                        <Label for="vitality" sm={2}>
                            Vitalita
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="vitality"
                                value={vitality}
                                placeholder="Zadaj vitalitu"
                                onChange={(e) => setVitality(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="endurance" sm={2}>
                            Výdrž
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="endurance"
                                value={endurance}
                                placeholder="Zadaj výdrž"
                                onChange={(e) => setEndurance(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="strength" sm={2}>
                            Sila
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="strength"
                                value={strength}
                                placeholder="Zadaj silu"
                                onChange={(e) => setStrength(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="speed" sm={2}>
                            Rýchlosť
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="speed"
                                value={speed}
                                placeholder="Zadaj rýchlosť"
                                onChange={(e) => setSpeed(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="dexterity" sm={2}>
                            Obratnosť
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="dexterity"
                                value={dexterity}
                                placeholder="Zadaj obratnosť"
                                onChange={(e) => setDexterity(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="defence" sm={2}>
                            Obrana
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="defence"
                                value={defence}
                                placeholder="Zadaj obranu"
                                onChange={(e) => setDefence(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="energy" sm={2}>
                            Energia
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="energy"
                                value={energy}
                                placeholder="Zadaj energiu"
                                onChange={(e) => setEnergy(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label for="capacity" sm={2}>
                            Nosnosť
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="capacity"
                                value={capacity}
                                placeholder="Zadaj nosnosť"
                                onChange={(e) => setCapacity(e.target.value)}
                            />
                        </Col>
                    </FormGroup>

                    <div className="flex-row">
                        <Button color="primary" disabled={saving} onClick={save}>
                            {saving ? 'Ukladá sa...' : 'Uložiť rasu'}
                        </Button>
                        <Button
                            color="danger"
                            className="m-l-auto"
                            onClick={async () => {
                                if (window.confirm('Si si istý?')) {
                                    deleteRace({ variables: { id: raceID } });
                                }
                            }}
                        >
                            Vymazať
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
