import { gql } from '@apollo/client';

export const userData = `
            id
            email
            activated
            admin
            dm
            icon
            portrait
            color
            nickname
            character
            age
            charsheetLayout
            inventoryLayout
            dailySpell
            gold
            killCount
            level
            location
            gender
            attributes {
                capacity {
                    base
                    extra
                    levelup
                    lost
                }
                defence {
                    base
                    extra
                    levelup
                    lost
                }

                dexterity {
                    base
                    extra
                    levelup
                    lost
                }

                endurance {
                    base
                    extra
                    levelup
                    lost
                }

                energy {
                    base
                    extra
                    levelup
                    lost
                }

                speed {
                    base
                    extra
                    levelup
                    lost
                }

                strength {
                    base
                    extra
                    levelup
                    lost
                }

                vitality {
                    base
                    extra
                    levelup
                    lost
                }
            }
            race {
                id
                title
                description
                size
                icon
                portrait

                capacity
                energy
                defence
                dexterity
                speed
                strength
                vitality
                endurance
            }
            occupation {
                id
                title
                description
                icon
                magic
            }
            `;

export const USER = gql`
    query user($id: Int!) {
        user(id: $id) {
            ${userData}
        }
    }
`;
export const USERS = gql`
    query users($dm: Boolean, $includeInactive: Boolean) {
        users(dm: $dm, includeInactive: $includeInactive) {
            id
            color
            character
            nickname
            activated
            icon
            admin
            dm
        }
    }
`;
export const REGISTER_USER = gql`
    mutation registerUser(
        $email: String!
        $password: String!
        $color: String!
        $nickname: String!
        $character: String!
        $age: Int!
        $gender: Int!
        $raceId: Int!
        $occupationId: Int!
    ) {
        registerUser(
            email: $email
            password: $password
            color: $color
            nickname: $nickname
            character: $character
            age: $age
            gender: $gender
            raceId: $raceId
            occupationId: $occupationId
        ) {
            accessToken
            user {
                id
            }
        }
    }
`;
export const UPDATE_USER = gql`
    mutation UpdateUser(
        $id: Int!
        $email: String
        $activated: Boolean
        $password: String
        $color: String
        $nickname: String
        $character: String
        $raceId: Int
        $occupationId: Int
        $icon: String
        $portrait: String
        $dm: Boolean
    ) {
        updateUser(
            id: $id
            email: $email
            activated: $activated
            password: $password
            color: $color
            nickname: $nickname
            character: $character
            raceId: $raceId
            occupationId: $occupationId
            icon: $icon
            portrait: $portrait
            dm: $dm
        ) {
            id
        }
    }
`;
export const UPDATE_PROFILE = gql`
    mutation updateProfile(
        $email: String
        $password: String
        $color: String
        $nickname: String
        $character: String
    ) {
        updateProfile(
            email: $email
            password: $password
            color: $color
            nickname: $nickname
            character: $character
        ) {
            accessToken
            user {
                id
            }
        }
    }
`;

export const CHANGE_LAYOUTS = gql`
    mutation changeLayouts($charsheetLayout: Int, $inventoryLayout: Int) {
        changeLayouts(charsheetLayout: $charsheetLayout, inventoryLayout: $inventoryLayout) {
            id
        }
    }
`;

export const UPDATE_CHARACTER = gql`
    mutation updateCharacter(
        $id: Int!
        $character: String
        $age: Int
        $gender: Int
        $level: Int
        $killCount: Int
        $location: String
        $dailySpell: Boolean
    ) {
        updateCharacter(
            id: $id
            character: $character
            age: $age
            gender: $gender
            level: $level
            killCount: $killCount
            location: $location
            dailySpell: $dailySpell
        ) {
            id
        }
    }
`;

export const CHANGE_LOCATION = gql`
    mutation changeLocation($location: String!, $id: Int) {
        changeLocation(location: $location, id: $id) {
            id
        }
    }
`;

export const UPDATE_ATTRIBUTES = gql`
    mutation updateAttributes(
        $id: Int!
        $type: AttributeTypeEnum!
        $modifier: AttributeModifierEnum!
        $value: Int!
    ) {
        updateAttributes(id: $id, type: $type, modifier: $modifier, value: $value) {
            id
        }
    }
`;

export const UPDATE_ATTRIBUTE_SIMPLE = gql`
    mutation updateAttributeSimple(
        $id: Int!
        $type: AttributeTypeEnum!
        $modifier: AttributeModifierEnum!
        $increment: Boolean
    ) {
        updateAttributeSimple(id: $id, type: $type, modifier: $modifier, increment: $increment) {
            id
        }
    }
`;

export const DELETE_USER = gql`
    mutation deleteUser($id: Int!) {
        deleteUser(id: $id) {
            id
        }
    }
`;

export const USER_COLORS = gql`
    query userColors($filterCurrent: Boolean) {
        userColors(filterCurrent: $filterCurrent) {
            id
            character
            color
            nickname
        }
    }
`;

export const USERS_SUBSCRIPTION = gql`
    subscription usersSubscription {
        usersSubscription
    }
`;

export const USER_COLORS_SUBSCRIPTION = gql`
    subscription userColorsSubscription {
        userColorsSubscription
    }
`;
