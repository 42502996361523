import React from 'react';
import { Card, CardHeader, CardBody, Button, FormGroup, Label, Col, Input } from 'reactstrap';
import Textarea from 'react-textarea-autosize';
import { TextEditor } from 'components';
import Select from 'react-select/creatable';
import {
    DELETE_LIBRARY_ENTRY,
    LIBRARY_CATEGORIES,
    LIBRARY_ENTRIES_SUBSCRIPTION,
    LIBRARY_ENTRY,
    UPDATE_LIBRARY_ENTRY,
} from 'queries';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { toSelArr, toSelItem } from 'helperFunctions';

export default function LibraryEdit(props) {
    const { libraryID } = props;

    const [title, setTitle] = React.useState('');
    const [order, setOrder] = React.useState(0);
    const [description, setDescription] = React.useState('');
    const [category, setCategory] = React.useState(null);
    const [categoryOrder, setCategoryOrder] = React.useState(0);
    const [text, setText] = React.useState('');

    const [saving, setSaving] = React.useState(false);

    const [updateLibraryEntry] = useMutation(UPDATE_LIBRARY_ENTRY);
    const [deleteLibraryEntry] = useMutation(DELETE_LIBRARY_ENTRY);
    const {
        data: categoriesData,
        loading: categoriesLoading,
        refetch: categoriesRefetch,
    } = useQuery(LIBRARY_CATEGORIES, {
        fetchPolicy: 'network-only',
    });
    const Categories = categoriesLoading ? [] : categoriesData.libraryCategories;

    const {
        data: libraryData,
        loading: libraryLoading,
        refetch: libraryRefetch,
    } = useQuery(LIBRARY_ENTRY, {
        fetchPolicy: 'network-only',
        variables: {
            id: libraryID,
        },
    });

    useSubscription(LIBRARY_ENTRIES_SUBSCRIPTION, {
        onData: () => {
            libraryRefetch({ variables: { id: libraryID } });
            categoriesRefetch();
        },
    });
    const LibraryEntry = libraryLoading ? null : libraryData.libraryEntry;
    React.useEffect(() => {
        if (!libraryLoading && LibraryEntry) {
            setTitle(LibraryEntry.title);
            setOrder(LibraryEntry.order);
            setDescription(LibraryEntry.description);
            setCategory(toSelItem(LibraryEntry.category));
            setCategoryOrder(LibraryEntry.category.order);
            setText(LibraryEntry.text);
        }
        // eslint-disable-next-line
    }, [LibraryEntry]);

    const save = async () => {
        setSaving(true);

        //save images
        let body = {
            id: libraryID,
            title,
            order: isNaN(parseInt(order)) ? 0 : parseInt(order),
            description,
            category: {
                id: category.id,
                title: category.label,
                order: parseInt(categoryOrder),
            },
            text,
        };
        updateLibraryEntry({ variables: body })
            .then(() => {
                //update STATE images
                setSaving(false);
            })
            .catch((e) => {
                setSaving(false);
                console.log(e);
            });
    };

    const deleteFunc = async () => {
        if (window.confirm(`Si si istý že chceš zmazať záznam ${title}?`)) {
            deleteLibraryEntry({ variables: { id: libraryID } });
        }
    };
    console.log(category);
    return (
        <div className="settings-right">
            <Card>
                <CardHeader className="flex-row">
                    <Input
                        id="map"
                        value={title}
                        className="invisible-input"
                        placeholder="Zadaj názov záznamu"
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </CardHeader>
                <CardBody>
                    <FormGroup className="flex-row">
                        <Label for="order" sm={2}>
                            Poradie
                        </Label>
                        <Col sm={10}>
                            <Input
                                type="number"
                                id="order"
                                value={order}
                                placeholder="Zadaj poradie záznamu"
                                onChange={(e) => setOrder(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="flex-row">
                        <Label sm={2}>Kategória a poradie kategórie</Label>
                        <Col sm={5}>
                            <Select
                                value={category}
                                options={toSelArr(Categories)}
                                onChange={(category) => {
                                    setCategory(category);
                                    setCategoryOrder(
                                        category.order ? category.order : categoryOrder
                                    );
                                }}
                                placeholder="Píš pre novú kategóriu"
                            />
                        </Col>
                        <Col sm={5}>
                            <Input
                                type="number"
                                id="order"
                                value={categoryOrder}
                                placeholder="Zadaj poradie kategórie"
                                onChange={(e) => setCategoryOrder(e.target.value)}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup className="flex-row">
                        <Label for="description" sm={2}>
                            Popis
                        </Label>
                        <Col sm={10}>
                            <Textarea
                                className="form-control"
                                id="description"
                                placeholder="Zadaj popis záznamu"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Col>
                    </FormGroup>

                    <FormGroup className="flex-row">
                        <Label for="text1" sm={2}>
                            Text
                        </Label>
                        <Col sm={10}>
                            <TextEditor
                                id="text1"
                                value={text}
                                onChange={(text) => {
                                    setText(text);
                                }}
                            />
                        </Col>
                    </FormGroup>
                    <div className="flex-row">
                        <Button
                            color="primary"
                            disabled={
                                saving ||
                                title.length < 2 ||
                                category === null ||
                                isNaN(parseInt(categoryOrder))
                            }
                            onClick={save}
                        >
                            {saving ? 'Ukladá sa...' : 'Uložiť záznam'}
                        </Button>
                        <Button color="danger" className="m-l-auto" onClick={deleteFunc}>
                            Vymazať
                        </Button>
                    </div>
                </CardBody>
            </Card>
            <Card>
                <CardHeader>{`Náhľad - ${title}`}</CardHeader>
                <CardBody className="p-20">
                    <div className="text-italics">{description}</div>
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                </CardBody>
            </Card>
        </div>
    );
}
