import { gql } from '@apollo/client';

export const DICE_POKER_HISTORY = gql`
    query dicePokerHistory($id: Int!) {
        dicePokerHistory(id: $id) {
            id
            createdAt
            updatedAt
            rounds
            history
            player1
            player2
            title
        }
    }
`;

export const DICE_POKER_HISTORIES = gql`
    query dicePokerHistories {
        dicePokerHistories {
            id
            createdAt
            title
        }
    }
`;

export const DELETE_DICE_POKER_HISTORY = gql`
    mutation deleteDicePokerHistory($id: Int!) {
        deleteDicePokerHistory(id: $id)
    }
`;

export const DICE_POKER_HISTORIES_SUBSCRIPTION = gql`
    subscription dicePokerHistoriesSubscription {
        dicePokerHistoriesSubscription
    }
`;
