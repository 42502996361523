import React from 'react';
import { Popover, FormGroup, Label, Button, Input } from 'reactstrap';
import Select from 'react-select';
import { UPDATE_LOOT_MANAGER_ITEM } from 'queries';
import { useMutation } from '@apollo/client';
import { toSelArr, toSelItem } from 'helperFunctions';
import { pickSelectStyle } from 'configs/selectStyles';

const NoUser = { id: null, value: null, label: 'Nikomu' };

export default function AssignLoot(props) {
    const { open, closeAssignLoot, Users } = props;

    const [user, setUser] = React.useState(NoUser);
    const [processing, setProcessing] = React.useState(false);

    const [updateLootManagerItem] = useMutation(UPDATE_LOOT_MANAGER_ITEM);

    const assignLoot = async () => {
        setProcessing(true);
        updateLootManagerItem({
            variables: {
                id: open.id,
                userId: user.id,
            },
        })
            .then(() => {
                setUser(NoUser);
                setProcessing(false);
            })
            .catch((e) => {
                console.log(e);
                setProcessing(false);
            });
    };

    React.useEffect(() => {
        if (open !== null) {
            if (open.user) {
                setUser(toSelItem(open.user, 'character'));
            } else {
                setUser(NoUser);
            }
        }
        // eslint-disable-next-line
    }, [open]);

    if (open === null) {
        return null;
    }

    return (
        <Popover
            className="popover-400"
            placement="top"
            isOpen={open !== null}
            target={`assign-loot-tooltip-${open?.id}`}
            toggle={closeAssignLoot}
        >
            <div className="p-20">
                <FormGroup>
                    <Label>Hráč</Label>
                    <Select
                        value={user}
                        options={[NoUser, ...toSelArr(Users, 'character')]}
                        onChange={(user) => {
                            setUser(user);
                        }}
                        styles={pickSelectStyle(['colored'])}
                    />
                </FormGroup>
                <div className="flex-row">
                    <Button color="success" disabled={processing} onClick={assignLoot}>
                        {processing ? 'Priradzuje sa...' : 'Priadiť'}
                    </Button>
                    <Button color="warning" className="m-l-auto" onClick={closeAssignLoot}>
                        Zavrieť
                    </Button>
                </div>
            </div>
        </Popover>
    );
}
