import React from 'react';
import { Card, CardHeader, CardBody, Button, FormGroup, Label, Col, Input } from 'reactstrap';
import Textarea from 'react-textarea-autosize';
import {
    OCCUPATION,
    UPDATE_OCCUPATION,
    DELETE_OCCUPATION,
    OCCUPATIONS_SUBSCRIPTION,
} from 'queries';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { toBase64 } from 'helperFunctions';
import { ImageInput, Checkbox } from 'components';

export default function OccupationEdit(props) {
    const { occupationID } = props;

    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [icon, setIcon] = React.useState(null);
    const [magic, setMagic] = React.useState(false);
    const [saving, setSaving] = React.useState(false);

    const [updateOccupation] = useMutation(UPDATE_OCCUPATION);
    const [deleteOccupation] = useMutation(DELETE_OCCUPATION);

    const {
        data: occupationData,
        loading: occupationLoading,
        refetch: occupationRefetch,
    } = useQuery(OCCUPATION, {
        fetchPolicy: 'network-only',
        variables: {
            id: occupationID,
        },
    });
    useSubscription(OCCUPATIONS_SUBSCRIPTION, {
        onData: () => {
            occupationRefetch({
                variables: {
                    id: occupationID,
                },
            });
        },
    });

    const Occupation = occupationLoading ? null : occupationData.occupation;
    React.useEffect(() => {
        occupationRefetch({ id: occupationID });
        // eslint-disable-next-line
    }, [occupationID]);

    React.useEffect(() => {
        if (!occupationLoading) {
            setTitle(Occupation.title);
            setDescription(Occupation.description);
            setMagic(Occupation.magic);
        }
        // eslint-disable-next-line
    }, [Occupation]);

    const save = async () => {
        setSaving(true);
        //save images
        let body = {
            id: occupationID,
            description,
            title,
            magic: magic === true,
        };
        if (icon) {
            body.icon = await toBase64(icon);
        }
        updateOccupation({ variables: body })
            .then(() => {
                //update STATE images
                setSaving(false);
                setIcon(null);
            })
            .catch((e) => {
                setSaving(false);
                console.log(e);
            });
    };

    const deleteFunc = async () => {
        if (window.confirm(`Si si istý že chceš zmazať povolanie ${title}?`)) {
            deleteOccupation({ variables: { id: occupationID } });
        }
    };

    return (
        <div className="settings-right">
            <Card>
                <CardHeader className="flex-row">
                    <Input
                        id="occupation"
                        value={title}
                        className="invisible-input"
                        placeholder="Názov povolania"
                        onChange={(e) => setTitle(title)}
                    />
                </CardHeader>
                <CardBody>
                    <FormGroup className="flex-row">
                        <Label for="description" sm={2}>
                            Popis
                        </Label>
                        <Col sm={10}>
                            <Textarea
                                className="form-control"
                                id="description"
                                placeholder="Zadaj popis povolania"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Checkbox
                            className="form-control-checkbox"
                            label="Používa mágiu?"
                            value={magic}
                            right
                            centerHor
                            onChange={() => setMagic(!magic)}
                        />
                    </FormGroup>
                    <ImageInput
                        setImage={setIcon}
                        image={icon}
                        id={`racePortrait${occupationID}`}
                        label="Ikona povolania"
                        placeholder="Vyberte ikonu povolania"
                        original={Occupation?.icon}
                        height={150}
                    />

                    <div className="flex-row">
                        <Button color="primary" disabled={saving} onClick={save}>
                            {saving ? 'Ukladá sa...' : 'Uložiť povolanie'}
                        </Button>
                        <Button color="danger" className="m-l-auto" onClick={deleteFunc}>
                            Vymazať
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
