import { gql } from '@apollo/client';

const inventoryData = `quantity
id
itemId
title
description
icon
price
equipped
equippedSlots
replaces
type {
    id
    value
    label
    inventoryTitle
    inventoryLabel
    inventoryTab
    inventoryOrder
    color
}
weight
energy
defence
dexterity
speed
strength
vitality
endurance
capacity
variants {
    id
    equipped
    rune1 {
        title
        description
        icon
        weight
        energy
        defence
        dexterity
        speed
        strength
        vitality
        endurance
        capacity
    }
    rune2 {
        title
        description
        icon
        weight
        energy
        defence
        dexterity
        speed
        strength
        vitality
        endurance
        capacity
    }
    rune3 {
        title
        description
        icon
        weight
        energy
        defence
        dexterity
        speed
        strength
        vitality
        endurance
        capacity
    }
}`;

export const INVENTORY = gql`
    query inventory($id: Int!) {
        inventory(id: $id) {
            ${inventoryData}
        }
    }
`;

export const INVENTORY_WEIGHT = gql`
    query inventoryWeight($id: Int!) {
        inventoryWeight(id: $id)
    }
`;

export const GET_EQUIPPED = gql`
    query getEquipped($id: Int!) {
        getEquipped(id: $id) {
            ${inventoryData}
        }
    }
`;

export const ADD_TO_INVENTORY = gql`
    mutation addToInventory($userId: Int!, $itemId: Int!, $quantity: Int!) {
        addToInventory(userId: $userId, itemId: $itemId, quantity: $quantity)
    }
`;
export const REMOVE_FROM_INVENTORY = gql`
    mutation removeFromInventory($id: Int!, $quantity: Int!, $variantId: Int) {
        removeFromInventory(id: $id, quantity: $quantity, variantId: $variantId)
    }
`;
export const GIFT_ITEM = gql`
    mutation giftItem($id: Int!, $toChar: Int!, $quantity: Int!, $variantId: Int) {
        giftItem(id: $id, toChar: $toChar, quantity: $quantity, variantId: $variantId)
    }
`;
export const EQUIP_ITEM = gql`
    mutation equipItem($id: Int!, $variantId: Int, $slot: Int) {
        equipItem(id: $id, variantId: $variantId, slot: $slot)
    }
`;
export const UNEQUIP_ITEM = gql`
    mutation unequipItem($id: Int!, $slot: Int) {
        unequipItem(id: $id, slot: $slot)
    }
`;
export const ADD_RUNE = gql`
    mutation addRune(
        $weaponId: Int!
        $runeId: Int!
        $slot: Int!
        $free: Boolean!
        $variantId: Int
    ) {
        addRune(
            weaponId: $weaponId
            runeId: $runeId
            slot: $slot
            free: $free
            variantId: $variantId
        )
    }
`;
export const REMOVE_RUNE = gql`
    mutation removeRune($variantId: Int!, $slot: Int!) {
        removeRune(variantId: $variantId, slot: $slot)
    }
`;

export const INVENTORY_SUBSCRIPTION = gql`
    subscription inventorySubscription($id: Int) {
        inventorySubscription(id: $id)
    }
`;
