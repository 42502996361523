import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';

export default function LoadingEntry() {
    return (
        <div className="settings-right">
            <Card>
                <CardHeader className="flex-row">Načítava sa...</CardHeader>
                <CardBody>Načítava sa...</CardBody>
            </Card>
        </div>
    );
}
