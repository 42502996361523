import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const colors = [
    '#e6b0aa',
    '#f5b7b1',
    '#d7bde2',
    '#d2b4de',
    '#a9cce3',
    '#aed6f1',
    '#a3e4d7',
    '#a2d9ce',
    '#a9dfbf',
    '#abebc6',
    '#f9e79f',
    '#fad7a0',
    '#f5cba7',
    '#edbb99',
    '#f7f9f9',
    '#e5e7e9',
    '#ccd1d1',
    '#abb2b9',
    '#d98880',
    '#f1948a',
    '#c39bd3',
    '#bb8fce',
    '#7fb3d5',
    '#85c1e9',
    '#76d7c4',
    '#73c6b6',
    '#7dcea0',
    '#82e0aa',
    '#f7dc6f',
    '#f8c471',
    '#f0b27a',
    '#e59866',
    '#f4f6f7',
    '#d7dbdd',
    '#b2babb',
    '#808b96',
    '#cd6155',
    '#ec7063',
    '#af7ac5',
    '#a569bd',
    '#5499c7',
    '#5dade2',
    '#48c9b0',
    '#45b39d',
    '#52be80',
    '#58d68d',
    '#f4d03f',
    '#f5b041',
    '#eb984e',
    '#dc7633',
    '#f0f3f4',
    '#cacfd2',
    '#99a3a4',
    '#566573',
    '#c0392b',
    '#e74c3c',
    '#9b59b6',
    '#8e44ad',
    '#2980b9',
    '#3498db',
    '#1abc9c',
    '#16a085',
    '#27ae60',
    '#2ecc71',
    '#f1c40f',
    '#f39c12',
    '#e67e22',
    '#d35400',
    '#ecf0f1',
    '#bdc3c7',
    '#7f8c8d',
    '#2c3e50',
    '#a93226',
    '#cb4335',
    '#884ea0',
    '#7d3c98',
    '#2471a3',
    '#2e86c1',
    '#17a589',
    '#138d75',
    '#229954',
    '#28b463',
    '#d4ac0d',
    '#d68910',
    '#ca6f1e',
    '#ba4a00',
    '#d0d3d4',
    '#a6acaf',
    '#707b7c',
    '#273746',
    '#922b21',
    '#b03a2e',
    '#76448a',
    '#6c3483',
    '#1f618d',
    '#2874a6',
    '#148f77',
    '#117a65',
    '#1e8449',
    '#239b56',
    '#b7950b',
    '#b9770e',
    '#af601a',
    '#a04000',
    '#b3b6b7',
    '#909497',
    '#616a6b',
    '#212f3d',
    '#7b241c',
    '#943126',
    '#633974',
    '#5b2c6f',
    '#1a5276',
    '#21618c',
    '#117864',
    '#0e6655',
    '#196f3d',
    '#1d8348',
    '#9a7d0a',
    '#9c640c',
    '#935116',
    '#873600',
    '#979a9a',
    '#797d7f',
    '#515a5a',
    '#1c2833',
    '#641e16',
    '#78281f',
    '#512e5f',
    '#4a235a',
    '#154360',
    '#1b4f72',
    '#0e6251',
    '#0b5345',
    '#145a32',
    '#186a3b',
    '#7d6608',
    '#7e5109',
    '#784212',
    '#6e2c00',
    '#7b7d7d',
    '#626567',
    '#424949',
    '#17202a',
];

let Font = ReactQuill.Quill.import('formats/font');
Font.whitelist = ['Arial', 'Spirax'];
ReactQuill.Quill.register(Font, true);

export function TextEditor(props) {
    return (
        <ReactQuill
            {...props}
            theme="snow"
            modules={{
                toolbar: [
                    ['bold', 'italic', 'strike', { 'color': colors }, { 'background': colors }],
                    [
                        { 'font': Font.whitelist },
                        { 'header': 1 },
                        { 'header': 2 },
                        { 'size': ['small', false, 'large'] },
                    ],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, 'image', 'clean'],
                ],
            }}
            formats={[
                'background',
                'bold',
                'color',
                'font',
                'italic',
                'link',
                'size',
                'strike',
                'script',
                'underline',
                'blockquote',
                'header',
                'list',
                'align',
                'image',
                // 'video'
            ]}
        />
    );
}
