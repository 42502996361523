import React from 'react';
import {
    BATTLEFIELDS_SUBSCRIPTION,
    BATTLEFIELD,
    USERS,
    USERS_SUBSCRIPTION,
    ADD_TO_BATTLEFIELD,
    CLEAR_BATTLEFIELD,
    IMAGE_SUBSCRIPTION,
    MOVE_AT_BATTLEFIELD,
    REMOVE_FROM_BATTLEFIELD,
} from 'queries';
import { makeVar, useMutation, useQuery, useReactiveVar, useSubscription } from '@apollo/client';
import { GetMyRights } from 'helperFunctions';
import { Image } from 'components/Image';
import { Empty } from 'components/Empty';
import classnames from 'classnames';
import { Button } from 'reactstrap';
import { Tooltip } from 'components/tooltip';
import { genericTypeMap } from 'components/combatSimulator/battlefield';
import { GetImageId } from 'apollo/localSchema/resolvers';
import { REST_URL } from 'configs/restAPI';
import { updateImageId } from 'apollo/localSchema/actions';

const offsetLeft = 5;
const offsetRight = 55;
const offsetTop = 5;
const offsetBottom = 55;

export default function Battlefield({ id }) {
    const { Dm } = GetMyRights();
    const [selectedElement, setSelectedElement] = React.useState(null);
    const imageRef = React.useRef(null);

    const [addToBattlefield] = useMutation(ADD_TO_BATTLEFIELD);
    const [removeFromBattlefield] = useMutation(REMOVE_FROM_BATTLEFIELD);
    const [clearBattlefield] = useMutation(CLEAR_BATTLEFIELD);
    const [moveAtBattlefield] = useMutation(MOVE_AT_BATTLEFIELD);

    const {
        data: battlefieldData,
        loading: battlefieldLoading,
        refetch: battlefieldRefetch,
    } = useQuery(BATTLEFIELD, {
        fetchPolicy: 'network-only',
        variables: {
            id,
        },
    });

    useSubscription(BATTLEFIELDS_SUBSCRIPTION, {
        onData: () => {
            battlefieldRefetch({
                id,
            });
        },
    });

    const {
        data: usersData,
        loading: usersLoading,
        refetch: usersRefetch,
    } = useQuery(USERS, {
        fetchPolicy: 'network-only',
        variables: {
            dm: false,
            includeInactive: false,
        },
    });

    useSubscription(USERS_SUBSCRIPTION, {
        onData: () => {
            usersRefetch({
                dm: false,
                includeInactive: false,
            });
        },
    });
    const Battlefield = battlefieldLoading ? null : battlefieldData.battlefield;
    const idVar = GetImageId(Battlefield?.battlefield);
    const imageId = useReactiveVar(idVar ? idVar : makeVar(1));
    const fullURL = Battlefield?.battlefield
        ? `${REST_URL}/${Battlefield?.battlefield}.png?${imageId}`
        : null;

    useSubscription(IMAGE_SUBSCRIPTION, {
        variables: {
            path: Battlefield?.battlefield,
        },
        shouldResubscribe: true,
        onData: () => {
            updateImageId(Battlefield?.battlefield);
        },
    });

    const getProcessedBattlefieldData = () => {
        return BattlefieldData.map((data) => {
            if (data.type === 'user') {
                const User = Users.find((User) => User.id === data.userId);
                if (!User) {
                    return null;
                }
                return {
                    ...data,
                    path: User.icon,
                    title: User.character,
                };
            } else {
                const minId =
                    Math.min(
                        ...BattlefieldData.filter((Data) => Data.type === data.type).map(
                            (Data) => Data.id
                        )
                    ) - 1;
                return {
                    ...data,
                    url: `./images/${data.type}.png`,
                    title: `${genericTypeMap[data.type]}(${data.id - minId})`,
                };
            }
        }).filter((data) => data !== null);
    };

    if (battlefieldLoading || usersLoading) {
        return <div className="color-white">Načítava sa...</div>;
    }

    const Users = usersData.users;
    const BattlefieldData = Battlefield.battlefieldData;

    return (
        <div className="color-white">
            {Dm && (
                <div className="flex-row">
                    <div className="center-ver flex-row p-l-20 p-r-20 height-50">
                        {Battlefield.value !== null && (
                            <Empty>
                                <Image
                                    url="./images/enemy.png"
                                    width={50}
                                    height={50}
                                    onClick={() => {
                                        if (!Dm) {
                                            return;
                                        }

                                        if (
                                            selectedElement !== null &&
                                            selectedElement.action === 'add' &&
                                            selectedElement.type === 'enemy'
                                        ) {
                                            setSelectedElement(null);
                                        } else {
                                            setSelectedElement({ action: 'add', type: 'enemy' });
                                        }
                                    }}
                                    className={classnames({
                                        'focus-border':
                                            selectedElement !== null &&
                                            selectedElement.action === 'add' &&
                                            selectedElement.type === 'enemy',
                                    })}
                                />
                                <Image
                                    url="./images/enemyMissing.png"
                                    width={50}
                                    height={50}
                                    onClick={() => {
                                        if (!Dm) {
                                            return;
                                        }

                                        if (
                                            selectedElement !== null &&
                                            selectedElement.action === 'add' &&
                                            selectedElement.type === 'enemyMissing'
                                        ) {
                                            setSelectedElement(null);
                                        } else {
                                            setSelectedElement({
                                                action: 'add',
                                                type: 'enemyMissing',
                                            });
                                        }
                                    }}
                                    className={classnames('m-l-20', {
                                        'focus-border':
                                            selectedElement !== null &&
                                            selectedElement.action === 'add' &&
                                            selectedElement.type === 'enemyMissing',
                                    })}
                                />
                                <Image
                                    url="./images/other.png"
                                    width={50}
                                    height={50}
                                    onClick={() => {
                                        if (!Dm) {
                                            return;
                                        }

                                        if (
                                            selectedElement !== null &&
                                            selectedElement.action === 'add' &&
                                            selectedElement.type === 'other'
                                        ) {
                                            setSelectedElement(null);
                                        } else {
                                            setSelectedElement({ action: 'add', type: 'other' });
                                        }
                                    }}
                                    className={classnames({
                                        'focus-border':
                                            selectedElement !== null &&
                                            selectedElement.action === 'add' &&
                                            selectedElement.type === 'other',
                                    })}
                                />
                                {Users.filter(
                                    (User) =>
                                        !BattlefieldData.some(
                                            (data) =>
                                                data.userId === User.id && data.type === 'user'
                                        )
                                ).map((User) => (
                                    <Image
                                        key={User.id}
                                        path={User.icon}
                                        width={50}
                                        height={50}
                                        onClick={() => {
                                            if (!Dm) {
                                                return;
                                            }
                                            if (
                                                selectedElement !== null &&
                                                selectedElement.action === 'add' &&
                                                selectedElement.type === 'user' &&
                                                selectedElement.userId === User.id
                                            ) {
                                                setSelectedElement(null);
                                            } else {
                                                setSelectedElement({
                                                    action: 'add',
                                                    type: 'user',
                                                    userId: User.id,
                                                });
                                            }
                                        }}
                                        className={classnames({
                                            'focus-border':
                                                selectedElement !== null &&
                                                selectedElement.action === 'add' &&
                                                selectedElement.type === 'user' &&
                                                selectedElement.userId === User.id,
                                        })}
                                    />
                                ))}
                                <Button
                                    color="link"
                                    disabled={
                                        selectedElement === null ||
                                        selectedElement.action !== 'change'
                                    }
                                    onClick={() => {
                                        if (!Dm) {
                                            return;
                                        }
                                        removeFromBattlefield({
                                            variables: { id: selectedElement.id },
                                        });
                                        setSelectedElement(null);
                                    }}
                                >
                                    <i
                                        className="fa fa-times color-red font-35"
                                        id="delete-from-battlefield-button"
                                    />
                                    <Tooltip
                                        placement="top"
                                        target="delete-from-battlefield-button"
                                        text="Označ a klikni sem na zmazanie"
                                    />
                                </Button>
                                <Button
                                    color="link"
                                    onClick={() => {
                                        if (!Dm) {
                                            return;
                                        }
                                        if (window.confirm('Naozaj všetko zmazať?')) {
                                            clearBattlefield({
                                                variables: { battlefieldId: Battlefield.id },
                                            });
                                        }
                                    }}
                                >
                                    <i
                                        className="fa fa-cancel color-red"
                                        id="clear-whole-battlefield"
                                    />
                                    <Tooltip
                                        placement="top"
                                        target="clear-whole-battlefield"
                                        text="Vyčistiť bojovisko"
                                    />
                                </Button>
                            </Empty>
                        )}
                    </div>
                </div>
            )}
            {Battlefield.value !== null && (
                <div className="generic-image-container relative">
                    <img
                        alt="some-random-alt"
                        ref={imageRef}
                        src={fullURL}
                        draggable="false"
                        onClick={(e) => {
                            if (!Dm) {
                                return;
                            }
                            const bounds = e.target.getBoundingClientRect();
                            const baseX = e.clientX - bounds.x - 15;
                            const baseY = e.clientY - bounds.y - 25;
                            const X =
                                ((baseX < offsetLeft
                                    ? offsetLeft
                                    : baseX > bounds.width - offsetRight
                                    ? bounds.width - offsetRight
                                    : baseX) /
                                    bounds.width) *
                                100;
                            const Y =
                                ((baseY < offsetTop
                                    ? offsetTop
                                    : baseY > bounds.height - offsetBottom
                                    ? bounds.height - offsetBottom
                                    : baseY) /
                                    bounds.height) *
                                100;
                            if (selectedElement !== null && selectedElement.action === 'add') {
                                addToBattlefield({
                                    variables: {
                                        battlefieldId: Battlefield.id,
                                        type: selectedElement.type,
                                        userId: selectedElement.userId,
                                        x: X,
                                        y: Y,
                                    },
                                });
                                if (selectedElement.type === 'user') {
                                    setSelectedElement(null);
                                }
                            } else if (
                                selectedElement !== null &&
                                selectedElement.action === 'change'
                            ) {
                                moveAtBattlefield({
                                    variables: {
                                        id: selectedElement.id,
                                        x: X,
                                        y: Y,
                                    },
                                });
                                setSelectedElement(null);
                            }
                        }}
                    />
                    {getProcessedBattlefieldData().map((icon) => (
                        <Image
                            key={`${icon.X} + ${icon.Y}`}
                            height={50}
                            width={50}
                            {...{ path: icon.path, url: icon.url }}
                            focus
                            customFocus={icon.title}
                            className={classnames({
                                'focus-border':
                                    selectedElement !== null &&
                                    selectedElement.action === 'change' &&
                                    selectedElement.id === icon.id,
                            })}
                            style={{
                                position: 'absolute',
                                top: `${icon.Y}%`,
                                left: `${icon.X}%`,
                            }}
                            onClick={() => {
                                if (!Dm) {
                                    return;
                                }
                                if (
                                    selectedElement !== null &&
                                    selectedElement.action === 'change' &&
                                    selectedElement.id === icon.id
                                ) {
                                    setSelectedElement(null);
                                } else {
                                    setSelectedElement({
                                        action: 'change',
                                        id: icon.id,
                                        data: icon,
                                    });
                                }
                            }}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}
