import React from 'react';
import Attribute from './attribute';
import AttributeEdit from './edit';

export default function Attributes({ User, uniqueId, equipmentAttributes, inventoryWeight }) {
    const [editingAttribute, setEditingAttribute] = React.useState(null);

    React.useEffect(() => {
        setEditingAttribute(null);
    }, [User.id]);

    return (
        <div className="charsheet-attributes">
            <h2 className="m-b-0">Atribúty</h2>
            <Attribute
                fromEquipment={equipmentAttributes.vitality}
                attribute="vitality"
                title="Vitalita"
                User={User}
                uniqueId={uniqueId}
                setEditing={setEditingAttribute}
                editing={editingAttribute}
            />
            <Attribute
                fromEquipment={equipmentAttributes.endurance}
                attribute="endurance"
                title="Výdrž"
                User={User}
                uniqueId={uniqueId}
                setEditing={setEditingAttribute}
                editing={editingAttribute}
            />
            <Attribute
                fromEquipment={equipmentAttributes.strength}
                attribute="strength"
                title="Sila"
                User={User}
                uniqueId={uniqueId}
                setEditing={setEditingAttribute}
                editing={editingAttribute}
            />
            <Attribute
                fromEquipment={equipmentAttributes.speed}
                attribute="speed"
                title="Rýchlosť"
                User={User}
                uniqueId={uniqueId}
                setEditing={setEditingAttribute}
                editing={editingAttribute}
            />
            <Attribute
                fromEquipment={equipmentAttributes.dexterity}
                attribute="dexterity"
                title="Obratnosť"
                User={User}
                uniqueId={uniqueId}
                setEditing={setEditingAttribute}
                editing={editingAttribute}
            />
            <Attribute
                fromEquipment={equipmentAttributes.defence}
                attribute="defence"
                title="Obrana"
                User={User}
                uniqueId={uniqueId}
                setEditing={setEditingAttribute}
                editing={editingAttribute}
            />
            <Attribute
                fromEquipment={equipmentAttributes.energy}
                attribute="energy"
                title="Energia"
                User={User}
                uniqueId={uniqueId}
                setEditing={setEditingAttribute}
                editing={editingAttribute}
            />
            <Attribute
                fromEquipment={equipmentAttributes.capacity}
                attribute="capacity"
                title="Nosnosť"
                User={User}
                uniqueId={uniqueId}
                setEditing={setEditingAttribute}
                editing={editingAttribute}
                inventoryWeight={inventoryWeight}
            />

            <AttributeEdit
                attribute={editingAttribute}
                User={User}
                uniqueId={uniqueId}
                close={() => setEditingAttribute(null)}
            />
        </div>
    );
}
